import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    LoadingService,
    Menu,
    MenuCategory,
    MenuCategoryItem,
    MenuCategoryItemGroup,
    MenuCategoryService,
    MenuItem,
    MenuItemGroup,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-menu-category',
    templateUrl: './edit-menu-category.component.html',
    styleUrls: ['./edit-menu-category.component.scss'],
})
export class EditMenuCategoryComponent extends BaseStepperFormComponent implements OnInit {
    informationFormGroup: FormGroup = new FormGroup({});
    @Input() menuCategory: MenuCategory;
    @Input() menu: Menu;
    @Input() tab: number;

    @Input() addMenuItem: any;

    selectedItems = [];
    availableItems = [];

    constructor(
        modalController: ModalController,
        private menuCategoryService: MenuCategoryService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuCategory = this.menuCategory == null ? new MenuCategory() : this.menuCategory;

        this.selectedItems = this.menuCategory.menuItems == null ? [] : [
            ...this.menuCategory.menuItems, ...this.menuCategory.menuItemGroups
        ].sort((a: any, b: any) => a.sortOrder - b.sortOrder);

        this.availableItems = [...this.menu.menuItems, ...this.menu.menuItemGroups]
            .sort((a: any, b: any) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        this.informationFormGroup = this.formBuilder.group({
            id: this.menuCategory.id,
            parentId: this.menu.id,
            name: [this.menuCategory.name, Validators.required],
            description: this.menuCategory.description,
            sortOrder: this.menuCategory.sortOrder,
            featured: this.menuCategory.featured,
            color: [!!this.menuCategory ? this.menuCategory.color : '#fddb31', Validators.required],
            contrastColor: [!!this.menuCategory ? this.menuCategory.contrastColor : '#000000', Validators.required],
        });
    }

    updateSortOrder(items: any[]) {
        this.selectedItems = items;
    }

    selectItem(item: MenuItem | MenuItemGroup) {
        if (!this.selectedItems.some(obj => {
            return (!this.isMenuItemGroup(item) && !this.isMenuCategoryItemGroup(obj) && obj.menuItem.id === item.id)
                || (this.isMenuItemGroup(item) && this.isMenuCategoryItemGroup(obj) && obj.menuItemGroup.id === item.id);
        })) {
            if (this.isMenuItemGroup(item)) {
                const menuCategoryItemGroup = new MenuCategoryItemGroup();
                menuCategoryItemGroup.menuItemGroup = item as MenuItemGroup;
                menuCategoryItemGroup.sortOrder = this.selectedItems.length + 1;
                this.selectedItems = [...this.selectedItems, menuCategoryItemGroup];
            } else {
                const menuCategoryItem = new MenuCategoryItem();
                menuCategoryItem.menuItem = item as MenuItem;
                menuCategoryItem.name = item.name;
                menuCategoryItem.sortOrder = this.selectedItems.length + 1;
                this.selectedItems = [...this.selectedItems, menuCategoryItem];
            }
        }
    }

    async openAddMenuItem() {
        const l = await this.addMenuItem(null, true);
        if (l != null) {
            this.selectItem(l);
        }
    }

    async onSubmit() {
        this.loadingService.present();
        const mc: MenuCategory = new MenuCategory(this.informationFormGroup.value);
        mc.menuItems = this.selectedItems.filter(i => !this.isMenuCategoryItemGroup(i));
        mc.menuItemGroups = this.selectedItems.filter(i => this.isMenuCategoryItemGroup(i));

        if (mc.id != null) {
            this.menuCategoryService.update(mc).subscribe(
                (cat: MenuCategory) => {
                    this.toastService.success(`${cat.name} updated.`);
                    this.loadingService.dismiss();
                    this.close(cat);
                }
            );
        } else {
            this.menuCategoryService.create(mc).subscribe(
                (cat: MenuCategory) => {
                    this.toastService.success(`${cat.name} added.`);
                    this.loadingService.dismiss();
                    this.close(cat);
                }
            );
        }
    }

    isMenuCategoryItemGroup(item: any) {
        return !!item && !!item.menuItemGroup;
    }

    isMenuItemGroup(item: any) {
        return !!item && !!item.menuItems;
    }

    setValue(field, val) {
        this.informationFormGroup.get(field).setValue(val);
    }
}
