import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent, Column, ColumnField, ColumnFormats, isNumberFormat, Menu, ReportColumns, TaxColumns} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-tab-report-column',
    templateUrl: './edit-tab-report-column.component.html',
    styleUrls: ['./edit-tab-report-column.component.scss'],
})
export class EditTabReportColumnComponent extends BaseModalComponent implements OnInit {
    @Input() column: Column;
    @Input() menu: Menu;
    @Input() requireAggregate = false;
    @Input() restrictAggregate = false;
    @Input() hidePercent = false;
    @Input() showSummaryAggregate = false;
    @Input() hideRowSummary = false;

    formGroup: FormGroup;

    loaded = false;
    selectedColumnField: ColumnField;
    displaySelectColumn = false;

    isNumberFormat = isNumberFormat;
    columnFormats = ColumnFormats;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.column ? this.column.id : null,
            label: [!!this.column ? this.column.label : '', Validators.required],
            rowLabel: !!this.column ? this.column.rowLabel : false,
            filter: '',
            key: ['', Validators.required],
            aggregation: !!this.column ? this.column.aggregation : null,
            summaryAggregation: !!this.column ? this.column.summaryAggregation : null,
            percent: !!this.column && this.column.format === ColumnFormats.PERCENT
        });

        if (this.requireAggregate) {
            this.formGroup.controls.aggregation.setValidators([Validators.required]);
        }

        this.loaded = true;
    }

    fieldChange(columnField: ColumnField) {
        if (!!columnField && columnField.key !== this.formGroup.controls.key.value) {
            const loadingFirst = !this.formGroup.controls.key.value
                && !!this.formGroup.controls.label.value;
            this.formGroup.controls.key.setValue(columnField.key);
            this.selectedColumnField = columnField;
            if (!loadingFirst) {
                this.formGroup.controls.label.setValue(columnField.display);
            }
        }
    }

    async onSubmit() {
        if (this.formGroup.valid) {
            const column = new Column(this.formGroup.value);

            column.aggregation = !!column.aggregation ? column.aggregation : null;
            column.summaryAggregation = !!column.summaryAggregation ? column.summaryAggregation : null;

            const field = ReportColumns.find(c => c.key === column.key);
            const formKey = column.key;

            if (!!field) {
                column.format = ((column.aggregation === 'COUNT' || column.aggregation === 'SUM') && this.formGroup.controls.percent.value)
                    ? ColumnFormats.PERCENT : field.format;
            } else {
                if (column.key.endsWith(TaxColumns.ORDER_ITEM_TAX_RATE)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.ORDER_ITEM_TAX_RATE.length - 1);
                    column.key = TaxColumns.ORDER_ITEM_TAX_RATE;
                    column.format = ColumnFormats.NUMBER;
                } else if (column.key.endsWith(TaxColumns.ORDER_ITEM_TAX_INCLUDED)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.ORDER_ITEM_TAX_INCLUDED.length - 1);
                    column.key = TaxColumns.ORDER_ITEM_TAX_INCLUDED;
                    column.format = ColumnFormats.BOOLEAN;
                } else if (column.key.endsWith(TaxColumns.ORDER_ITEM_TAXED_TOTAL)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.ORDER_ITEM_TAXED_TOTAL.length - 1);
                    column.key = TaxColumns.ORDER_ITEM_TAXED_TOTAL;
                    column.format = ColumnFormats.CURRENCY;
                } else if (column.key.endsWith(TaxColumns.ORDER_ITEM_TAX)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.ORDER_ITEM_TAX.length - 1);
                    column.key = TaxColumns.ORDER_ITEM_TAX;
                    column.format = ColumnFormats.CURRENCY;
                } else if (column.key.endsWith(TaxColumns.TAX_RATE)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.TAX_RATE.length - 1);
                    column.key = TaxColumns.TAX_RATE;
                    column.format = ColumnFormats.NUMBER;
                } else if (column.key.endsWith(TaxColumns.TAX_INCLUDED)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.TAX_INCLUDED.length - 1);
                    column.key = TaxColumns.TAX_INCLUDED;
                    column.format = ColumnFormats.BOOLEAN;
                } else if (column.key.endsWith(TaxColumns.TAXED_TOTAL)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.TAXED_TOTAL.length - 1);
                    column.key = TaxColumns.TAXED_TOTAL;
                    column.format = ColumnFormats.CURRENCY;
                } else if (column.key.endsWith(TaxColumns.TAX)) {
                    column.keyValue = column.key.substring(0, column.key.length - TaxColumns.TAX.length - 1);
                    column.key = TaxColumns.TAX;
                    column.format = ColumnFormats.CURRENCY;
                }
            }

            let accessKey = formKey;

            if (!!column.aggregation) {
                accessKey = accessKey + '_' + column.aggregation;
            }
            if (column.format === ColumnFormats.PERCENT) {
                accessKey = accessKey + '_PERCENT';
            }
            column.accessKey = accessKey;
            this.close(column);
        }
    }
}
