import {Component, OnInit} from '@angular/core';
import {
    CompDefinition,
    FeatureKeys,
    LoadingService,
    Organization,
    OrganizationService,
    SubscriberComponent,
    User,
    UserService,
    Venue,
    VenueService
} from 'brewbill-lib';

@Component({
    template: ''
})
export abstract class BaseOrganizationComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    venue: Venue;
    currentUser: User;
    authorizedToSupport = false;
    compDefinitions: CompDefinition[];

    featureKeys = FeatureKeys;

    protected constructor(
        protected organizationService: OrganizationService,
        protected pickupLocationService: VenueService,
        protected userService: UserService,
        protected venueService: VenueService,
        protected loadingService: LoadingService
    ) {
        super();
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;

        this.subscribe(this.userService.current.subscribe(u => {
            this.currentUser = !!u ? new User(u) : null;
            this.authorizedToSupport = !!this.currentUser && this.currentUser.siteAdmin;
        }));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.compDefinitions = this.organization.compDefinitions.filter(c => !c.membershipProgram);
        }));
        this.subscribe(this.venueService.current.subscribe(v => this.venue = !!v ? new Venue(v) : null));
    }

    async refreshOrganization() {
        this.loadingService.present();
        this.organizationService.get(this.organization.id).subscribe((organization: Organization) => {
            this.organizationService.setCurrent(organization);
            this.loadingService.dismiss();
        });
    }
}
