import {Component, Input, OnInit} from '@angular/core';
import {BaseStepperFormComponent, LoadingService, Menu, MenuLabel, MenuTopping, MenuToppingService, ToastService} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-menu-topping',
    templateUrl: './edit-menu-topping.component.html',
    styleUrls: ['./edit-menu-topping.component.scss'],
})
export class EditMenuToppingComponent extends BaseStepperFormComponent implements OnInit {
    @Input() topping: MenuTopping;
    @Input() menu: Menu;
    @Input() addMenuLabel: any;

    selectedLabels: MenuLabel[] = [];

    informationFormGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private menuToppingService: MenuToppingService,
        private loadingService: LoadingService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.topping = this.topping == null ? new MenuTopping() : this.topping;

        this.selectedLabels = this.topping.labels == null ? [] : [...this.topping.labels];

        this.informationFormGroup = this.formBuilder.group(
            {
                id: this.topping.id,
                parentId: this.menu.id,
                available: this.topping.available == null ? true : this.topping.available,
                name: [this.topping.name, Validators.required],
                description: this.topping.description
            }
        );
    }

    valid() {
        return this.informationFormGroup.valid;
    }

    selectLabel(label: MenuLabel) {
        if (this.selectedLabels.find(obj => obj.id === label.id) == null) {
            this.selectedLabels = [...this.selectedLabels, label];
        }
    }

    async openAddMenuLabel() {
        const l = await this.addMenuLabel(null);
        if (l != null) {
            this.selectLabel(l);
        }
    }

    onSubmit() {
        this.loadingService.present();
        const menuTopping: MenuTopping = new MenuTopping(this.informationFormGroup.value);
        menuTopping.labels = this.selectedLabels;

        if (menuTopping.id != null) {
            this.menuToppingService.update(menuTopping).subscribe(
                async (t: MenuTopping) => {
                    await this.toastService.success('Topping updated.');
                    this.loadingService.dismiss();
                    await this.close(t);
                }
            );
        } else {
            this.menuToppingService.create(menuTopping).subscribe(
                async (t: MenuTopping) => {
                    await this.toastService.success(`${t.name} added.`);
                    this.loadingService.dismiss();
                    await this.close(t);
                }
            );
        }
    }
}
