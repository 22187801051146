import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, LoadingService, Menu, MenuMarqueeItem, MenuMarqueeItemService, MenuTaxLabel, ToastService} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-menu-marquee-item',
    templateUrl: './edit-menu-marquee-item.component.html',
    styleUrls: ['./edit-menu-marquee-item.component.scss'],
})
export class EditMenuMarqueeItemComponent extends BaseModalComponent implements OnInit {
    formGroup: FormGroup;
    @Input() menuMarqueeItem: MenuMarqueeItem;
    @Input() menu: Menu;

    constructor(
        modalController: ModalController,
        private menuMarqueeItemService: MenuMarqueeItemService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
        this.menuMarqueeItem = this.menuMarqueeItem == null ? new MenuMarqueeItem() : this.menuMarqueeItem;
    }

    ngOnInit() {
        this.formGroup = new FormGroup({});
        const menuItemId = !!this.menuMarqueeItem.menuItem ? this.menuMarqueeItem.menuItem.id : null;

        this.formGroup = this.formBuilder.group({
            id: this.menuMarqueeItem.id,
            parentId: this.menu.id,
            message: [this.menuMarqueeItem.message, Validators.required],
            menuItemId: [menuItemId, Validators.required]
        });
    }

    onSubmit() {
        this.loadingService.present();
        const ml: MenuMarqueeItem = new MenuMarqueeItem(this.formGroup.value);

        ml.menuItem = this.menu.menuItems.find(i => i.id === this.formGroup.controls.menuItemId.value);
        if (ml.id != null) {
            this.menuMarqueeItemService.update(ml).subscribe(
                (menuTaxLabel: MenuMarqueeItem) => {
                    this.toastService.success('Marquee item updated.');
                    this.loadingService.dismiss();
                    this.close(menuTaxLabel);
                }
            );
        } else {
            this.menuMarqueeItemService.create(ml).subscribe(
                (menuTaxLabel: MenuTaxLabel) => {
                    this.toastService.success('Marquee item added.');
                    this.loadingService.dismiss();
                    this.close(menuTaxLabel);
                }
            );
        }
    }
}
