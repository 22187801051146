import {Component, OnInit, ViewChild} from '@angular/core';
import {
    DateFilterService,
    FeatureKeys,
    formatToHoursAndMinutes,
    formatToHoursPartial,
    LoadingService,
    Organization,
    OrganizationService,
    SubscriberComponent,
    Timecard,
    TimecardComponent,
    TimecardService,
    TimecardSummary,
    TimecardTypes,
    ToastService,
    User,
    UserService,
    Venue,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {ActivatedRoute} from '@angular/router';
import write_blob from 'capacitor-blob-writer';
import {Directory} from '@capacitor/filesystem';
import moment from 'moment';
import {debounce} from 'lodash';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'bb-timecards',
    templateUrl: './timecards.component.html',
    styleUrls: ['./timecards.component.scss'],
})
export class TimecardsComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    user: User;
    searchCriteria: any;
    timecardTypes: any;
    tippedCompareWith: any;
    timecards: Timecard[];
    timecardSummaries: TimecardSummary[];
    currentTimecardSummary: TimecardSummary;
    venue: Venue;
    mode = 'DETAILS';

    dataSource: MatTableDataSource<Timecard>;
    displayedColumns: string[] = ['name', 'pickupLocationName', 'tipped', 'clockInTime', 'clockOutTime', 'duration'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    featureKeys = FeatureKeys;

    constructor(
        private modalController: ModalController,
        private alertController: AlertController,
        private organizationService: OrganizationService,
        private timecardService: TimecardService,
        private userService: UserService,
        private toastService: ToastService,
        private venueService: VenueService,
        private loadingService: LoadingService,
        private platform: Platform,
        private fileOpener: FileOpener,
        private dateFilterService: DateFilterService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.timecardTypes = TimecardTypes;
        this.dataSource = new MatTableDataSource([]);

        this.tippedCompareWith = this.tippedCompareWithFn;
        this.searchCriteria = this.timecardService.getTimecardSearchCriteria();

        if (!!this.dateFilterService.getStartDate()) {
            this.searchCriteria.startDate = this.dateFilterService.getStartDate();
            this.searchCriteria.endDate = this.dateFilterService.getEndDate();
        }

        this.subscribe(this.organizationService.current.subscribe(o => !!o ? this.organization = new Organization(o) : null));
        this.subscribe(this.venueService.current.subscribe(v => this.venue = !!v ? new Venue(v) : null));
        this.subscribe(this.userService.current.subscribe(u => this.user = !!u ? new User(u) : null));
        this.refreshTimecardSummaries = debounce(this.refreshTimecardSummaries, 250);

        this.subscribe(this.timecardService.event.subscribe(() => {
            this.refreshTimecardSummaries();
        }));
    }

    async deleteTimecard(event, timecard) {
        event.stopPropagation();

        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to delete this ${this.formatTotalTime(timecard.shiftDurationSecs)} timecard for ${timecard.person.name}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: async () => {
                        this.loadingService.present();
                        this.timecardService.delete(timecard.id).subscribe(async () => {
                            await this.toastService.success('The timecard has been deleted.');
                            this.refreshTimecardSummaries();
                            this.loadingService.dismiss();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    refreshTimecardSummaries() {
        this.loadTimecardSummaries();

        if (!!this.currentTimecardSummary) {
            this.loadTimecards(this.currentTimecardSummary);
        }
        this.loadTable();
    }

    loadTimecardSummaries() {
        this.timecardService.setTimecardSearchCriteria(this.searchCriteria);
        this.timecardService.getSummaryDateRangeTimecardsSearch(this.organization.id,
            this.searchCriteria.pickupLocations,
            this.searchCriteria.startDate,
            this.searchCriteria.endDate,
            this.searchCriteria.tipped,
            this.searchCriteria.searchString
        ).subscribe(async (timecards: Timecard[]) => {
            await this.sanitizeTimecardSummaries(timecards);
        });
    }

    loadTable() {
        this.timecardService.setTimecardSearchCriteria(this.searchCriteria);

        this.timecardService
            .getDateRangeTimecardsSearch(this.organization.id,
                this.searchCriteria.pickupLocations,
                null,
                this.searchCriteria.startDate,
                this.searchCriteria.endDate,
                this.searchCriteria.tipped,
                this.searchCriteria.searchString
            ).subscribe(async (timecards: Timecard[]) => {

            this.dataSource = new MatTableDataSource(timecards);
            this.dataSource.sort = this.sort;
            this.dataSource.sortData = (data: Timecard[], sort: MatSort) => {

                // 'name', 'pickupLocationName', 'tipped', 'clockInTime', 'clockOutTime', 'duration'
                return data.sort((a: Timecard, b: Timecard) => {
                    const isAsc = sort.direction === 'asc';
                    switch (sort.active) {
                    case 'name':
                        return this.compare(a.person.name, b.person.name, isAsc);
                    case 'pickupLocationName':
                        return this.compare(!!a.pickupLocation ? a.pickupLocation.name : '',
                            !!b.pickupLocation ? b.pickupLocation.name : '', isAsc);
                    case 'tipped':
                        return this.compare(a.tipped + '', b.tipped + '', isAsc);
                    case 'duration':
                        return this.compare(a.shiftDurationSecs, b.shiftDurationSecs, isAsc);
                    default:
                        return this.compare(a[sort.active], b[sort.active], isAsc);
                    }
                });
            };
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    loadTimecards(timecardSummary: TimecardSummary, event?) {
        if (!!timecardSummary) {
            this.timecardService.setTimecardSearchCriteria(this.searchCriteria);

            this.timecardService
                .getDateRangeTimecardsSearch(timecardSummary.orgId,
                    this.searchCriteria.pickupLocations,
                    timecardSummary.personId,
                    this.searchCriteria.startDate,
                    this.searchCriteria.endDate,
                    this.searchCriteria.tipped,
                    this.searchCriteria.searchString
                ).subscribe(async (timecards: Timecard[]) => {
                await this.sanitizeTimecards(timecards);
            }, async () => {
            }, () => {
                if (!!event) {
                    event.target.complete();
                }
            });
        }
    }

    async sanitizeTimecardSummaries(timecardSummaries) {
        const sanitizedSummaryTimecards = [];
        if (!!timecardSummaries) {
            timecardSummaries.forEach(t => {
                sanitizedSummaryTimecards.push(new TimecardSummary(t));
            });
        }

        this.timecardSummaries = sanitizedSummaryTimecards;
    }

    sanitizeTimecards(timecards) {
        const sanitizedTimecards = [];
        if (!!timecards) {
            timecards.forEach(t => {
                sanitizedTimecards.push(new Timecard(t));
            });
        }

        this.timecards = sanitizedTimecards;
    }

    async viewDetails(timecard: Timecard) {
        if (this.user.authorizedTo(FeatureKeys.TIMECARD_ADMIN, this.organization.id)) {
            this.loadingService.present();

            const modal = await this.modalController.create({
                component: TimecardComponent,
                componentProps: {
                    timecard
                }
            });

            modal.onDidDismiss().then(dataReturned => {
                if (dataReturned) {
                    this.refreshTimecardSummaries();
                    this.getSummaryDetails(this.currentTimecardSummary);
                }
            });

            await modal.present();
            this.loadingService.dismiss();
        }
    }

    async getSummaryDetails(timecardSummary: TimecardSummary) {
        this.currentTimecardSummary = timecardSummary;
        this.loadTimecards(timecardSummary);
    }

    async exportRawData(event?) {
        this.loadingService.present();

        this.timecardService.setTimecardSearchCriteria(this.searchCriteria);

        this.timecardService.rawData(
            this.organization.id,
            this.searchCriteria.pickupLocations,
            this.searchCriteria.startDate,
            this.searchCriteria.endDate,
            this.searchCriteria.tipped,
            this.searchCriteria.searchString
        ).subscribe(async (data: any) => {
            await this.saveFile(data, 'rawData.csv');
        }, async () => {
            await this.toastService.error('Error generating the timecard export.');
        }, () => {
            if (!!event) {
                event.target.complete();
            }
        });
    }

    async saveFile(dataBlob, filename) {
        if (this.platform.is('ios') || this.platform.is('android')) {
            write_blob({
                path: filename,
                directory: Directory.Data,
                blob: dataBlob,
            }).then(async uri => {
                await this.fileOpener.open(uri, 'text/csv').catch(e => {
                    console.log(e);
                    this.toastService.error('The file was saved but could not be opened.');
                });
            }).catch(err => {
                console.log(err);
                this.toastService.error('The file was not saved.');
            }).finally(() => this.loadingService.dismiss());
        } else {
            const downloadURL = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
            this.loadingService.dismiss();
        }
    }

    formatTotalTime(timeInSec) {
        return formatToHoursAndMinutes(moment.duration(timeInSec * 1000));
    }

    formatToHours(timeInSec) {
        return formatToHoursPartial(moment.duration(timeInSec * 1000));
    }

    getPersonId(obj: TimecardSummary) {
        return obj.personId;
    }

    tippedCompareWithFn = (o1, o2) => {
        return o1 === o2;
    };
}
