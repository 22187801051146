import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, CustomerAreaPosition, CustomerAreaService, LoadingService, Organization, ToastService} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-customer-area-position',
    templateUrl: './edit-customer-area-position.component.html',
    styleUrls: ['./edit-customer-area-position.component.scss'],
})
export class EditCustomerAreaPositionComponent extends BaseModalComponent implements OnInit {
    @Input() positions: CustomerAreaPosition[];
    @Input() organization: Organization;
    @Input() customerAreaPosition: CustomerAreaPosition;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private customerAreaService: CustomerAreaService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.customerAreaPosition ? this.customerAreaPosition.id : null,
            name: [!!this.customerAreaPosition ? this.customerAreaPosition.name : '', Validators.required]
        });
    }

    onSubmit() {
        if (!!this.customerAreaPosition) {
            this.positions[this.positions.indexOf(this.customerAreaPosition)]
                = new CustomerAreaPosition(this.formGroup.value);
        } else {
            this.positions.push(new CustomerAreaPosition(this.formGroup.value));
        }
        this.close(this.positions);
    }
}
