import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, LoadingService, Menu, MenuLabel, MenuLabelService, ToastService} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-menu-label',
    templateUrl: './edit-menu-label.component.html',
    styleUrls: ['./edit-menu-label.component.scss'],
})
export class EditMenuLabelComponent extends BaseModalComponent implements OnInit {
    formGroup: FormGroup = new FormGroup({});
    @Input() menuLabel: MenuLabel;
    @Input() menu: Menu;

    gfg = '#05f211';

    constructor(
        modalController: ModalController,
        private menuLabelService: MenuLabelService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuLabel = this.menuLabel == null ? new MenuLabel() : this.menuLabel;
        if (!this.menuLabel.id) {
            this.menuLabel.display = false;
        }
        this.formGroup = this.formBuilder.group({
            id: this.menuLabel.id,
            parentId: this.menu.id,
            name: [this.menuLabel.name, Validators.required],
            color: this.menuLabel.color,
            display: this.menuLabel.display
        });

        this.formGroup.get('display').valueChanges.subscribe(value => {
            console.log(value);
            if (value) {
                this.formGroup.get('color').setValidators([Validators.required]);
            } else {
                this.formGroup.get('color').setValidators([]);
            }
            this.formGroup.updateValueAndValidity();
        });
    }

    async onSubmit() {
        this.loadingService.present();
        const ml: MenuLabel = new MenuLabel(this.formGroup.value);
        if (!ml.display) {
            ml.color = null;
        }

        if (ml.id != null) {
            this.menuLabelService.update(ml).subscribe(
                (menuLabel: MenuLabel) => {
                    this.toastService.success('Label updated.');
                    this.loadingService.dismiss();
                    this.close(menuLabel);
                }
            );
        } else {
            this.menuLabelService.create(ml).subscribe(
                async (menuLabel: MenuLabel) => {
                    this.toastService.success('Label added.');
                    this.loadingService.dismiss();
                    await this.close(menuLabel);
                }
            );
        }
    }
}
