import {Component, Input, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    FeatureKeys,
    LoadingService,
    Organization,
    OrganizationUser,
    TabReport,
    TabReportEmail,
    TabReportEmailService,
    User,
    UserService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-tab-report-email',
    templateUrl: './edit-tab-report-email.component.html',
    styleUrls: ['./edit-tab-report-email.component.scss'],
})
export class EditTabReportEmailComponent extends BaseModalComponent implements OnInit {
    @Input() tabReportEmail: TabReportEmail;
    @Input() tabReport: TabReport;
    @Input() organization: Organization;

    formGroup: FormGroup;
    users: User[];

    constructor(
        modalController: ModalController,
        private loadingService: LoadingService,
        private userService: UserService,
        private tabReportEmailService: TabReportEmailService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        const recipients = !!this.tabReportEmail && !!this.tabReportEmail.recipients
            ? this.tabReportEmail.recipients.map(uo => uo.id) : [];

        const filters = !!this.tabReportEmail && !!this.tabReportEmail.filter
            ? this.tabReportEmail.filter.substring(1, this.tabReportEmail.filter.length - 1).split('|') : [];
        this.formGroup = this.formBuilder.group({
            id: !!this.tabReportEmail ? this.tabReportEmail.id : null,
            cronMarker: [!!this.tabReportEmail ? this.tabReportEmail.cronMarker : null, Validators.required],
            cronFilters: [filters],
            selectedRecipients: [recipients, Validators.required],
            attachmentType: !!this.tabReportEmail ? this.tabReportEmail.attachmentType : null
        });

        if (this.formGroup.controls.cronMarker.value === 'WEEK' || this.formGroup.controls.cronMarker.value === 'MONTH') {
            this.formGroup.get('cronFilters').setValidators([Validators.required]);
        } else {
            this.formGroup.get('cronFilters').setValidators([]);
        }

        this.load();
    }

    resetFilter() {
        if (this.formGroup.controls.cronMarker.value === 'WEEK' || this.formGroup.controls.cronMarker.value === 'MONTH') {
            this.formGroup.get('cronFilters').setValidators([Validators.required]);
        } else {
            this.formGroup.get('cronFilters').setValidators([]);
        }
        this.formGroup.controls.cronFilters.setValue([]);
    }

    load() {
        this.loadingService.present();
        this.userService.getOrgUsers(this.organization.id, [
            FeatureKeys.REPORT, FeatureKeys.REPORT_ADMIN, FeatureKeys.ORG_ADMIN, FeatureKeys.ORG_OWNER
        ]).subscribe((users: User[]) => {
            this.users = users;
            this.loadingService.dismiss();
        });
    }

    onSubmit() {
        const tabReportEmail = new TabReportEmail(this.formGroup.value);
        if (tabReportEmail.cronMarker === 'WEEK' || tabReportEmail.cronMarker === 'MONTH') {
            tabReportEmail.filter = `|${this.formGroup.controls.cronFilters.value.join('|')}|`;
        }
        tabReportEmail.parentId = this.tabReport.id;
        tabReportEmail.recipients = [];
        this.formGroup.controls.selectedRecipients.value.forEach(uo => {
            const organizationUser = new OrganizationUser();
            organizationUser.id = uo;
            tabReportEmail.recipients.push(organizationUser);
        });
        tabReportEmail.attachmentType = !!tabReportEmail.attachmentType ? tabReportEmail.attachmentType : null;

        this.loadingService.present();
        if (!!tabReportEmail.id) {
            this.tabReportEmailService.update(tabReportEmail).subscribe(tre => {
                this.loadingService.dismiss();
                this.close(tre);
            });
        } else {
            this.tabReportEmailService.create(tabReportEmail).subscribe(tre => {
                this.loadingService.dismiss();
                this.close(tre);
            });
        }
    }
}
