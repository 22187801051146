import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    BaseModalComponent,
    buildGiftCardReceipt,
    CashDrawer,
    CashDrawerService,
    GiftCard,
    GiftCardAction,
    GiftCardService,
    LoadingService,
    multiplyCurrency,
    Organization,
    OrganizationTerminal,
    OrganizationTerminalService,
    PrinterService,
    ToastService
} from 'brewbill-lib';
import {AlertController, IonInput, ModalController, NavController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-gift-card',
    templateUrl: './gift-card.component.html',
    styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent extends BaseModalComponent implements OnInit {
    @ViewChild('email') set emailInput(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 250);
        }
    }

    @Input() giftCard: GiftCard;
    @Input() organization: Organization;
    formGroup: FormGroup;
    terminal: OrganizationTerminal;
    cashDrawer: CashDrawer;
    canPrint = false;
    emailActionId = null;

    multiplyCurrency = multiplyCurrency;

    constructor(
        modalController: ModalController,
        private giftCardService: GiftCardService,
        private alertController: AlertController,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private organizationTerminalService: OrganizationTerminalService,
        private printerService: PrinterService,
        private cashDrawerService: CashDrawerService,
        private navController: NavController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.canPrint = this.printerService.isConnected();
        this.subscribe(this.organizationTerminalService.current.subscribe(t => this.terminal = t));
        this.subscribe(this.cashDrawerService.current.subscribe(t => this.cashDrawer = !!t ? new CashDrawer(t) : null));
        this.subscribe(this.printerService.connected.subscribe(c => this.canPrint = c));

        this.formGroup = this.formBuilder.group({
            optIn: false,
            email: [
                '', [
                    Validators.required, Validators.email,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                ]
            ]
        });
    }

    async addFunds() {
        await this.close(this.giftCard);
    }

    async complete() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm',
            message: `Are you sure you want to reset this card? The balance will be set to 0 and all activity will be cleared. The activity can still be viewed in your completed cards history.`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.giftCardService.complete(this.giftCard).subscribe(g => {
                            this.giftCard = new GiftCard(g);
                            this.loadingService.dismiss();
                        }, error => {
                            if (error.status === 409) {
                                this.toastService.error('The card has already been completed.');
                            }
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    openTab(action: GiftCardAction) {
        if (!!action.tabId) {
            this.navController.navigateForward(`/gift-card/${action.orgId}/${action.parentId}/tab/${action.tabId}`);
            this.close();
        }
    }

    emailReceipt(email: string) {
        this.loadingService.present();
        this.giftCardService.emailReceipt(this.emailActionId, email).subscribe(() => {
            this.toastService.success(`Email sent to ${email}.`);
            this.emailActionId = null;
            this.loadingService.dismiss();
        });
    }

    async print(giftCardAction: GiftCardAction) {
        const output = buildGiftCardReceipt(this.organization, giftCardAction, this.giftCard.balance, this.printerService.columns);
        if (this.printerService.isConnected()) {
            this.printerService.print(output);
        } else {
            this.toastService.error('The receipt could not be printed.');
        }
    }
}
