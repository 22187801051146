import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
    AuthenticationService,
    BaseManagerComponent,
    DeviceService,
    MasonryGridComponent,
    MembershipService,
    Order,
    OrderService,
    OrderStatuses,
    OrganizationService,
    PickupLocationService,
    ToastService,
    UserService
} from 'brewbill-lib';
import {NavController} from '@ionic/angular';
import {debounce} from 'lodash';

@Component({
    selector: 'bb-open-orders',
    templateUrl: './open-orders.component.html',
    styleUrls: ['./open-orders.component.scss'],
})
export class OpenOrdersComponent extends BaseManagerComponent implements OnInit {
    masonry: MasonryGridComponent;

    @ViewChild(MasonryGridComponent) set content(content: MasonryGridComponent) {
        if (content) {
            this.masonry = content;
        }
    }

    orders: Order[];
    filteredOrders: Order[] = [];
    orderStatuses = OrderStatuses;
    statusFilter = '';
    searchString = '';

    pins: number[] = [];
    deviceId: string;

    constructor(
        private orderService: OrderService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private navController: NavController,
        private deviceService: DeviceService,
        authenticationService: AuthenticationService,
        organizationService: OrganizationService,
        pickupLocationService: PickupLocationService,
        userService: UserService,
        membershipService: MembershipService
    ) {
        super(authenticationService,
            organizationService,
            pickupLocationService,
            userService,
            membershipService);

        this.filter = debounce(this.filter, 250);
    }

    ngOnInit() {
        super.ngOnInit();

        this.deviceService.getDeviceId().then(d => this.deviceId = d);

        this.subscribe(this.orderService.event.subscribe(async (o: Order) => {
            if (!!o && !!this.orders) {
                const t = [...this.orders];
                const existingIndex = t.findIndex((i) => i.id === o.id);

                const deviceId = await this.deviceService.getDeviceId();
                if (o.isActive() && existingIndex > -1) {
                    t[existingIndex] = o;
                } else if (!o.isActive() && existingIndex > -1) {
                    t.splice(existingIndex, 1);
                } else if (o.isActive() && existingIndex === -1) {
                    if (o.deviceId === deviceId) {
                        t.unshift(o);
                    } else {
                        t.push(o);
                    }
                }
                this.orders = t;
                this.changeDetectorRef.detectChanges();

                this.filter();
            }
        }));

        this.subscribe(this.orderService.activeOrders.subscribe(o => {
            // this.pins = [];
            const initializedOrders = !!o ? o.map(i => new Order(i)) : [];
            this.orders = this.sortOrders(initializedOrders);
            this.filter();
            this.changeDetectorRef.detectChanges();
            this.masonry.layout();
        }));
    }

    gridLoaded() {
        setTimeout(() => {
            this.masonry.layout();
        }, 150);
    }

    init() {
        // this.refreshOrders();
    }

    async masonryFilter(expected) {
        const adds = expected.filter(o => !this.filteredOrders.find(i => i.id === o.id));
        const removes = this.filteredOrders.filter(o => !expected.find(i => i.id === o.id));

        if (this.filteredOrders.length === 0) {
            // clean
            adds.forEach(o => {
                this.filteredOrders.push(o);
                this.changeDetectorRef.detectChanges();
            });
        } else {
            removes.forEach(o => {
                const el = document.getElementById('order' + o.id);
                this.masonry.masonry.remove(el);
                this.filteredOrders.splice(this.filteredOrders.findIndex(i => i.id === o.id), 1);
                this.changeDetectorRef.detectChanges();
            });

            adds.forEach(o => {
                const index = this.filteredOrders.findIndex(i => new Date(i.date).getTime() > new Date(o.date).getTime());

                if (index > -1) {
                    const previous = this.filteredOrders.slice(0, index - 1);
                    previous.forEach(p => {
                        const el = document.getElementById('order' + p.id);
                        this.masonry.masonry.remove(el);
                        this.filteredOrders.splice(this.filteredOrders.findIndex(i => i.id === p.id), 1);
                        this.changeDetectorRef.detectChanges();
                    });
                    this.filteredOrders.unshift(o);
                    previous.forEach(p => {
                        this.filteredOrders.unshift(p);
                        this.changeDetectorRef.detectChanges();
                    });
                } else {
                    this.filteredOrders.push(o);
                    this.masonry.prependChanges = this.deviceId === o.deviceId;
                }

                this.changeDetectorRef.detectChanges();
            });
        }
    }

    async filter() {
        if (!this.statusFilter && this.searchString === '') {
            await this.masonryFilter(this.orders);
        } else {
            const names = this.searchString.split(' ');
            await this.masonryFilter(this.orders.filter(o => (o.status === this.statusFilter || this.statusFilter === '')
                && o.status !== OrderStatuses.PENDING
                && (this.searchString === '' || (
                    (names.length === 1
                        && ((!!o.tabPersonEmail && o.tabPersonEmail.toLowerCase().includes(names[0].toLowerCase()))
                            || (!!o.tabPersonName && o.tabPersonName.toLowerCase().includes(names[0].toLowerCase()))
                            || (!!o.tabPersonName && o.tabPersonName.toLowerCase().includes(names[0].toLowerCase()))))
                    || (!!o.tabPersonName && o.tabPersonName.toLowerCase().includes(names[0].toLowerCase())
                        && o.tabPersonName.toLowerCase().includes(names[1].toLowerCase()))
                ))));
        }
    }

    refreshOrders(event?) {
        this.orders = [];
        this.changeDetectorRef.detectChanges();
        this.pickupLocationService.getActiveOrders(this.pickupLocation.id).subscribe((o: Order[]) => {
            this.orderService.setActiveOrders(o);
            if (!!event) {
                event.target.complete();
            }
        }, async () => {
            await this.toastService.error('Could not retrieve active orders.');
            if (!!event) {
                event.target.complete();
            }
        });
    }

    sortOrders(orders: Order[]) {
        if (!orders) {
            return orders;
        } else {
            return orders.sort((a: Order, b: Order) => {
                if (a.deviceId === this.deviceId && b.deviceId !== this.deviceId) {
                    return 1;
                } else if (b.deviceId === this.deviceId && a.deviceId !== this.deviceId) {
                    return -1;
                } else {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                }
            });
        }
    }

    stamp(order: Order) {
        const found = this.pins.includes(order.id);
        const el = document.getElementById(`order_${order.id}`);
        if (!found) {
            this.pins.push(order.id);
            if (!!el) {
                this.masonry.masonry.stamp(el);
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    unStamp(event, order: Order) {
        event.stopPropagation();
        const found = this.pins.includes(order.id);
        const el = document.getElementById(`order_${order.id}`);
        if (found) {
            this.pins.splice(this.pins.indexOf(order.id), 1);
            if (!!el) {
                this.masonry.masonry.unstamp(el);
            }
            this.changeDetectorRef.detectChanges();
            this.masonry.layout();
        }
    }

    checkStamp(order: Order) {
        return this.pins.includes(order.id);
    }

    async viewDetails(order: Order) {
        await this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${order.parentId}`);
    }
}
