import {Component, Input, OnInit} from '@angular/core';
import {SubscriberComponent, Tab, TabSearchResult, TabService} from 'brewbill-lib';

@Component({
    selector: 'bb-tab-card-header',
    templateUrl: './tab-card-header.component.html',
    styleUrls: ['./tab-card-header.component.scss'],
})
export class TabCardHeaderComponent extends SubscriberComponent implements OnInit {
    @Input() tab: TabSearchResult | Tab;
    @Input() editable = false;

    email: string;
    name: string;
    editNameOverride = false;

    constructor(private tabService: TabService) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t && !!this.tab && t.id === this.tab.id) {
                this.init(t);
            }
        }));
        this.init(this.tab);
    }

    init(t: any) {
        this.editNameOverride = false;
        if (!!t) {
            this.tab = new Tab(t);
        }

        if (!!t && t.paymentInformation !== undefined) {
            const tab = this.tab as Tab;
            if (!!tab.nameOverride) {
                this.name = tab.nameOverride;
            } else {
                this.name = !!tab.person ? tab.person.name : 'Terminal Order';
            }
            this.email = !!tab.person ? tab.person.email : '';
        } else {
            const tab = this.tab as TabSearchResult;
            this.name = !!tab.name ? tab.name : 'Terminal Order';
            this.email = tab.email;
        }
    }

    getServerName() {
        if ((this.tab as Tab).server !== undefined) {
            const server = (this.tab as Tab).server;
            return !!server ? server.name : '';
        } else {
            return (this.tab as TabSearchResult).serverName;
        }
    }

    editName(event) {
        event.stopPropagation();
        this.editNameOverride = true;
    }

    getTab() {
        return this.tab as Tab;
    }
}
