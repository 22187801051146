import {Component} from '@angular/core';
import {
    CompDefinition,
    CompDefinitionService,
    LoadingService,
    MenuItemPriceSummaryService,
    Organization,
    OrganizationRewardProgramService,
    OrganizationService,
    reorder,
    TabNoteDefinition,
    TabNoteDefinitionService,
    ToastService,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';
import {EditOrganizationRewardProgramComponent} from '../edit-organization-reward-program/edit-organization-reward-program.component';
import {BaseOrganizationComponent} from '../base-organization.component';
import {EditCompDefinitionComponent} from '../edit-comp-definition/edit-comp-definition.component';
import {EditTabNoteDefinitionComponent} from '../edit-tab-note-definition/edit-tab-note-definition.component';
import clone from 'clone';

@Component({
    selector: 'bb-organization-other',
    templateUrl: './organization-other.component.html',
    styleUrls: ['./organization-other.component.scss'],
})
export class OrganizationOtherComponent extends BaseOrganizationComponent {

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private menuItemPriceSummaryService: MenuItemPriceSummaryService,
        private organizationRewardProgramService: OrganizationRewardProgramService,
        private compDefinitionService: CompDefinitionService,
        private tabNoteDefinitionService: TabNoteDefinitionService,
        private alertController: AlertController,
        private modalController: ModalController,
        private toastService: ToastService
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    async confirmDeleteRewardProgram() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Remove',
            message: 'Are you sure you want to remove this reward program?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.confirmRewardGrandfather();
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmRewardGrandfather() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Grandfather',
            message: 'Would you like to grandfather in existing rewards users or have this change take affect for all users immediately?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }, {
                    text: 'Delete Existing',
                    handler: () => {
                        this.deleteRewardProgram(false);
                    }
                }, {
                    text: 'Grandfather Existing',
                    handler: () => {
                        this.deleteRewardProgram(true);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteRewardProgram(grandfather) {
        const paramMap = {grandfather};
        this.organizationRewardProgramService.delete(this.organization.rewardProgram.id, paramMap).subscribe(async () => {
            if (grandfather) {
                await this.toastService.success('The reward program has been deleted. Any existing participants will continue until completion but no new participants will be added.');
            } else {
                await this.toastService.success('The reward program has been deleted and all existing participation has been ended.');
            }
            await this.refreshOrganization();
        });
    }

    async openChangeRewardProgram() {
        this.loadingService.present();
        this.menuItemPriceSummaryService.get(this.organization.menuId).subscribe(async (priceSummaries) => {
            const modal = await this.modalController.create({
                component: EditOrganizationRewardProgramComponent,
                componentProps: {
                    organizationRewardProgram: this.organization.rewardProgram,
                    organizationId: this.organization.id,
                    priceSummaries
                }
            });

            this.loadingService.dismiss();
            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.organization.rewardProgram = dataReturned.data;
                    this.refreshOrganization();
                }
            });
        });
    }

    async cloneCompDefinition(compDefinition: CompDefinition) {
        const cloned = clone<CompDefinition>(compDefinition);

        cloned.id = null;
        cloned.originId = null;
        cloned.name = `${cloned.name} (COPY)`;
        await this.openEditCompDefinition(cloned);
    }

    async openEditCompDefinition(compDefinition: CompDefinition = null) {
        this.loadingService.present();
        this.menuItemPriceSummaryService.get(this.organization.menuId).subscribe(async (priceSummaries) => {
            const modal = await this.modalController.create({
                component: EditCompDefinitionComponent,
                cssClass: 'menu-item-modal',
                componentProps: {
                    compDefinition,
                    organization: this.organization,
                    priceSummaries
                }
            });

            this.loadingService.dismiss();
            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.refreshOrganization();
                }
            });
        });
    }

    async removeCompDef(def: CompDefinition) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to remove ${def.name}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.compDefinitionService.delete(def.id).subscribe(() => {
                            this.organization.compDefinitions = this.organization.compDefinitions.filter(c => c.id !== def.id);
                            this.loadingService.dismiss();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async openEditTabNoteDefinition(tabNoteDefinition: TabNoteDefinition = null) {
        this.loadingService.present();
        const modal = await this.modalController.create({
            component: EditTabNoteDefinitionComponent,
            cssClass: 'menu-item-modal',
            componentProps: {
                tabNoteDefinition,
                organization: this.organization
            }
        });

        this.loadingService.dismiss();
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshOrganization();
            }
        });
    }

    async removeTabNoteDef(def: TabNoteDefinition) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to remove ${def.name}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.tabNoteDefinitionService.delete(def.id).subscribe(() => {
                            this.organization.tabNoteDefinitions = this.organization.tabNoteDefinitions.filter(c => c.id !== def.id);
                            this.loadingService.dismiss();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    rewardProgramDescription() {
        return this.organizationRewardProgramService.description(this.organization.rewardProgram);
    }

    reorderCompDefinitions(event) {
        reorder(this.compDefinitions, event);
        this.compDefinitionService.sort(this.compDefinitions).subscribe(async (o) => {
            this.organizationService.setEvent(new Organization(o));
        });
    }
}
