import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import {EditMenuCategoryComponent} from '../edit-menu-category/edit-menu-category.component';
import {EditMenuLabelComponent} from '../edit-menu-label/edit-menu-label.component';
import {EditMenuItemComponent} from '../edit-menu-item/edit-menu-item.component';
import {LabelHelpComponent} from '../label-help/label-help.component';
import {EditMenuToppingComponent} from '../edit-menu-topping/edit-menu-topping.component';
import {EditMenuSelectionComponent} from '../edit-menu-selection/edit-menu-selection.component';
import {EditMenuTaxLabelComponent} from '../edit-menu-tax-label/edit-menu-tax-label.component';
import {EditMenuMarqueeItemComponent} from '../edit-menu-marquee-item/edit-menu-marquee-item.component';
import {
    Availabilities,
    CompDefinition,
    CompDefinitionAvailabilities,
    FileUploadService,
    LoadingService,
    Menu,
    MenuCategory,
    MenuCategoryItem,
    MenuCategoryItemService,
    MenuCategoryService,
    MenuItem,
    MenuItemGroup,
    MenuItemGroupService,
    MenuItemService,
    MenuLabel,
    MenuLabelService,
    MenuMarqueeItem,
    MenuMarqueeItemService,
    MenuSelection,
    MenuSelectionService,
    MenuService,
    MenuSubscriberComponent,
    MenuTaxLabel,
    MenuTaxLabelService,
    MenuTopping,
    MenuToppingService,
    OrderItemService,
    Organization,
    OrganizationService,
    PickupLocation,
    PickupLocationMenu,
    PickupLocationMenuService,
    PickupLocationService,
    reorder,
    RewardProgramTypes,
    RewardTypes,
    SelectMenuItemTypeComponent,
    SortModalComponent,
    ToastService,
    Venue,
    VenueService
} from 'brewbill-lib';
import {EventService} from '../../_services/event.service';
import clone from 'clone';
import {EditMenuItemGroupComponent} from '../edit-menu-item-group/edit-menu-item-group.component';

@Component({
    selector: 'bb-organization-menu',
    templateUrl: './organization-menu.component.html',
    styleUrls: ['./organization-menu.component.scss'],
})
export class OrganizationMenuComponent extends MenuSubscriberComponent implements OnInit {
    venue: Venue;
    pickupLocation: PickupLocation;
    itemFilter = '';
    filteredItems = [];
    availableOrderComps: CompDefinition[] = [];

    availabilities = Availabilities;

    constructor(
        eventService: EventService,
        orderItemService: OrderItemService,
        pickupLocationService: PickupLocationService,
        toastService: ToastService,
        changeDetectorRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private alertController: AlertController,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private menuLabelService: MenuLabelService,
        private menuTaxLabelService: MenuTaxLabelService,
        private menuCategoryService: MenuCategoryService,
        private menuItemService: MenuItemService,
        private menuItemGroupService: MenuItemGroupService,
        private menuMarqueeItemService: MenuMarqueeItemService,
        private menuSelectionService: MenuSelectionService,
        private menuCategoryItemService: MenuCategoryItemService,
        private menuToppingService: MenuToppingService,
        private menuService: MenuService,
        private organizationService: OrganizationService,
        private fileUploadService: FileUploadService,
        private venueService: VenueService,
        private pickupLocationMenuService: PickupLocationMenuService
    ) {
        super(eventService, toastService, pickupLocationService, changeDetectorRef);
    }

    async ngOnInit() {
        this.menuService.setCurrent(this.route.snapshot.data.menu);
        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            if (!!this.organization) {
                this.availableOrderComps = !!this.organization && !!this.organization.compDefinitions
                    ? this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.ORDER_ITEM) : [];

                if (!this.pickupLocation && !!this.organization.pickupLocations && this.organization.pickupLocations.length > 0) {
                    this.pickupLocation = this.organization.pickupLocations[0];
                    this.changePickupLocation();
                }
            }
        }));
        this.subscribe(this.venueService.current.subscribe(v => this.venue = v));
        this.subscribe(this.menuService.current.subscribe(m => {
            this.menu = !!m ? new Menu(m) : null;
            this.filterItems();
        }));

        this.refreshMenu();
    }

    filterItems() {
        if (!!this.menu) {
            this.filteredItems = !!this.itemFilter ?
                this.menu.menuItems.filter((item: any) => item.name.toLowerCase().includes(this.itemFilter.toLowerCase())
                    || item.labels.some(l => l.name.toLowerCase().includes(this.itemFilter.toLowerCase())))
                : [...this.menu.menuItems];
        }
    }

    async confirmDeleteTaxLabel(event, label: MenuTaxLabel) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to delete ' + label.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuTaxLabelService.delete(label.id).subscribe(() => {
                            this.toastService.success('The tax label has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmDeleteMarqueeItem(event, item: MenuMarqueeItem) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to delete '${item.message}'?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuMarqueeItemService.delete(item.id).subscribe(() => {
                            this.toastService.success('The marquee item has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmDeleteLabel(event, label: MenuLabel) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to delete ' + label.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuLabelService.delete(label.id).subscribe(() => {
                            this.toastService.success('The label has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async openEditTaxLabel(label: MenuTaxLabel) {
        const componentProps = label == null ? {menu: this.menu} : {menu: this.menu, menuTaxLabel: label};
        const modal = await this.modalController.create({
            component: EditMenuTaxLabelComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openEditLabel(label: MenuLabel) {
        const componentProps = label == null ? {menu: this.menu} : {menu: this.menu, menuLabel: label};
        const modal = await this.modalController.create({
            component: EditMenuLabelComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openEditMarqueeItem(item: MenuMarqueeItem) {
        const componentProps = item == null ? {menu: this.menu} : {menu: this.menu, menuMarqueeItem: item};
        const modal = await this.modalController.create({
            component: EditMenuMarqueeItemComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openEditTopping(topping: MenuTopping) {
        const componentProps = topping == null ? {menu: this.menu} : {menu: this.menu, topping};
        const modal = await this.modalController.create({
            component: EditMenuToppingComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        Object.assign(componentProps, {addMenuLabel: this.openEditLabel.bind(this)});

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async confirmDeleteTopping(event, topping: MenuTopping) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to delete ' + topping.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuToppingService.delete(topping.id).subscribe(() => {
                            this.toastService.success('The topping has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmRemoveCategory(cat: MenuCategory, event?) {
        if (!!event) {
            event.stopPropagation();
        }

        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to delete ' + cat.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuCategoryService.delete(cat.id).subscribe(() => {
                            this.toastService.success('The category has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async editCurrentItem(currentItem) {
        switch (currentItem.type) {
        case 'category':
            await this.openEditCategory(currentItem.item);
            break;
        case 'item':
            await this.openEditItem(currentItem.item);
            break;
        case 'selection':
            await this.openEditSelection(currentItem.item);
            break;
        }
    }

    async openEditCategory(cat: MenuCategory, tab = 0) {
        const componentProps = {
            menu: this.menu,
            menuCategory: cat,
            tab
        };
        Object.assign(componentProps, {addMenuItem: this.openEditItem.bind(this)});
        const modal = await this.modalController.create({
            component: EditMenuCategoryComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openEditItemGroup(menuItemGroup: MenuItemGroup) {
        const componentProps = {
            menu: this.menu,
            menuItemGroup
        };
        Object.assign(componentProps, {addMenuItem: this.openEditItem.bind(this)});
        const modal = await this.modalController.create({
            component: EditMenuItemGroupComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openEditSelection(selection: MenuSelection) {
        const componentProps = selection == null ? {menu: this.menu} : {
            menuSelection: selection,
            menu: this.menu
        };

        Object.assign(componentProps, {addMenuItem: this.openEditItem.bind(this)});

        const modal = await this.modalController.create({
            component: EditMenuSelectionComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async confirmRemoveSelection(event, selection: MenuSelection) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Remove',
            message: 'Are you sure you want to delete ' + selection.name + '? This will remove it from all menu items.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuSelectionService.delete(selection.id).subscribe(() => {
                            this.toastService.success('The selection has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async cloneMenuItem(event, item: MenuItem) {
        event.stopPropagation();

        const cloned = clone<MenuItem>(item);

        cloned.id = null;
        cloned.originId = null;
        cloned.name = `${cloned.name} (COPY)`;
        if (!!cloned.prices) {
            cloned.prices.forEach(p => {
                p.qualifiedItem = !!this.organization.rewardProgram && this.organization.rewardProgram.type === RewardProgramTypes.PER_ITEM
                    && this.organization.rewardProgram.qualifiedItems.some(q => q.priceId === p.id);

                p.rewardItem = !!this.organization.rewardProgram && this.organization.rewardProgram.rewardType === RewardTypes.ITEM
                    && this.organization.rewardProgram.rewardItems.some(q => q.priceId === p.id);

                p.compDefinitionIds = this.availableOrderComps
                    .filter(c => c.items.some(i => i.priceId === p.id))
                    .map(c => c.id);

                p.tokenGroupIds = this.organization.tokenGroups
                    .filter(c => c.items.some(i => i.priceId === p.id))
                    .map(c => c.id);

                p.id = null;
            });
        }

        if (!!item && !!item.id) {
            const menuCategories = this.menu.menuCategories.filter(m => m.menuItems.some(i => i.menuItem.id === item.id));
            if (!!menuCategories) {
                cloned.menuCategoryIds = menuCategories.map(cat => cat.id);
            }

            const menuSelections = this.menu.menuSelections.filter(m => m.options.some(i => i.menuItem.id === item.id));
            if (!!menuSelections) {
                cloned.menuSelectionIds = menuSelections.map(s => s.id);
            }
        }
        await this.openEditItem(cloned);
    }

    async openEditItem(item: MenuItem, hideCategories = false) {
        const clonedItem = !!item ? clone<MenuItem>(item) : null;
        const componentProps = {
            menu: this.menu,
            hideCategories,
            rewardProgram: this.organization.rewardProgram,
            menuItem: null,
            organization: this.organization
        };

        Object.assign(componentProps, {addMenuLabel: this.openEditLabel.bind(this)});
        Object.assign(componentProps, {addMenuTaxLabel: this.openEditTaxLabel.bind(this)});
        Object.assign(componentProps, {addMenuTopping: this.openEditTopping.bind(this)});
        Object.assign(componentProps, {addMenuSelection: this.openEditSelection.bind(this)});

        if (!!clonedItem && !!clonedItem.id) {
            if (!!clonedItem.prices) {
                clonedItem.prices.forEach(p => {
                    p.qualifiedItem = !!this.organization.rewardProgram
                        && this.organization.rewardProgram.type === RewardProgramTypes.PER_ITEM
                        && this.organization.rewardProgram.qualifiedItems.some(q => !!p.id && q.priceId === p.id);

                    p.rewardItem = !!this.organization.rewardProgram && this.organization.rewardProgram.rewardType === RewardTypes.ITEM
                        && this.organization.rewardProgram.rewardItems.some(q => !!p.id && q.priceId === p.id);

                    p.compDefinitionIds = this.availableOrderComps
                        .filter(c => c.items.some(i => i.priceId === p.id))
                        .map(c => c.id);

                    p.tokenGroupIds = this.organization.tokenGroups
                        .filter(c => c.items.some(i => i.priceId === p.id))
                        .map(c => c.id);

                    p.ancestorId = p.id;
                    p.id = null;
                });
            }

            const menuCategories = this.menu.menuCategories.filter(m => m.menuItems.some(i => i.menuItem.id === clonedItem.id));
            if (!!menuCategories) {
                clonedItem.menuCategoryIds = menuCategories.map(cat => cat.id);
            }

            const menuSelections = this.menu.menuSelections.filter(m => m.options.some(i => i.menuItem.id === clonedItem.id));
            if (!!menuSelections) {
                clonedItem.menuSelectionIds = menuSelections.map(s => s.id);
            }
        }

        componentProps.menuItem = clonedItem;

        const modal = await this.modalController.create({
            component: EditMenuItemComponent,
            componentProps,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshMenu();
                return dataReturned.data;
            }
        });
    }

    async openSelectMenuItemType() {
        const modal = await this.modalController.create({
            component: SelectMenuItemTypeComponent,
            cssClass: 'menu-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                return this.openEditItem(null);
            }
        });
    }

    async confirmRemoveItemGroup(event, itemGroup: MenuItemGroup) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Remove',
            message: 'Are you sure you want to delete ' + itemGroup.name + '? This will remove it from all categories.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuItemGroupService.delete(itemGroup.id).subscribe(() => {
                            this.toastService.success('The item group has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmRemoveItem(event, item: MenuItem) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Remove',
            message: 'Are you sure you want to delete ' + item.name + '? This will remove it from all categories.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuItemService.delete(item.id).subscribe(() => {
                            this.toastService.success('The item has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmRemoveCategoryItem(item: MenuCategoryItem) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Remove',
            message: 'Are you sure you want to remove ' + item.menuItem.name + '?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuCategoryItemService.delete(item.id).subscribe(() => {
                            this.toastService.success('The item has been removed.');
                            this.loadingService.dismiss();
                            this.refreshMenu();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async refreshMenu() {
        this.loadingService.present();
        this.menuService.findByOrganizationId(this.organization.id).subscribe(async (m: Menu) => {
            this.menuService.setCurrent(m);
            this.loadingService.dismiss();
        });
    }

    async openLabelHelp() {
        const modal = await this.modalController.create({
            component: LabelHelpComponent,
            cssClass: 'menu-modal'
        });

        await modal.present();
    }

    async openSortCategories() {
        const modal = await this.modalController.create({
            component: SortModalComponent,
            componentProps: {items: [...this.menu.menuCategories]},
            cssClass: 'menu-modal'
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {

                this.loadingService.present();

                this.menuCategoryService.sort(dataReturned.data).subscribe(() => {
                    this.refreshMenu();
                });
            }
        });
    }

    async openSortItems(cat: MenuCategory) {
        const modal = await this.modalController.create({
            component: SortModalComponent,
            componentProps: {items: [...cat.menuItems]},
            cssClass: 'menu-modal'
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.loadingService.present();

                this.menuCategoryItemService.sort(dataReturned.data).subscribe(() => {
                    this.refreshMenu();
                });
            }
        });
    }

    updateAvailability(event, item) {
        const old = item.availability;
        this.menuItemService.updateAvailability(item.id, event.value).subscribe(async () => {
            await this.refreshMenu();
        }, (err) => {
            console.log(err);
            item.availability = old;
        });
    }

    expand(expandable) {
        if (!this[expandable].expanded) {
            this[expandable].toggle();
        }
    }

    async reorderCategories(event) {
        reorder(this.menu.menuCategories, event);
        this.loadingService.present();
        this.menuCategoryService.sort(this.menu.menuCategories).subscribe(async () => {
            await this.refreshMenu();
        });
    }

    async reorderMarqueeItems(event) {
        reorder(this.menu.menuMarqueeItems, event);
        this.loadingService.present();
        this.menuMarqueeItemService.sort(this.menu.menuMarqueeItems).subscribe(async () => {
            await this.refreshMenu();
        });
    }

    async changePickupLocation() {
        this.loadingService.present();
        this.pickupLocationService.get(this.pickupLocation.id).subscribe(async p => {
            if (!!p) {
                const pickupLocation = new PickupLocation(p);
                await this.pickupLocationService.setCurrent(pickupLocation);
                this.pickupLocationMenuService.findByPickupLocationId(this.pickupLocation.id).subscribe(m => {
                    this.pickupLocationMenuService.setCurrent(new PickupLocationMenu(m));
                    this.loadingService.dismiss();
                });
            }
        });
    }

    async showTerminalOnlyMessage(event) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Terminal Only',
            message: 'This item is only available from the terminal. Mobile users will not see it.',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel'
                }
            ]
        });

        await alert.present();
    }
}
