import {ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
    CreatePushModalComponent,
    MarketingPush,
    MarketingPushService,
    MasonryGridComponent,
    Organization,
    OrganizationService,
    SubscriberComponent,
    Venue,
    VenueService
} from 'brewbill-lib';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.scss'],
})
export class MarketingComponent extends SubscriberComponent implements OnInit {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(MasonryGridComponent) masonry: MasonryGridComponent;

    @ViewChildren('.grid-item', {read: ElementRef}) gridItems: QueryList<ElementRef>;

    organization: Organization;
    venue: Venue;
    currentPage = 0;
    total: 0;
    totalPages: 0;
    marketingPushes: MarketingPush[];

    constructor(
        private modalController: ModalController,
        private organizationService: OrganizationService,
        private marketingPushService: MarketingPushService,
        private venueService: VenueService,
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.organizationService.current.subscribe(async o => {
            if (!!o && (!this.organization || this.organization.id !== o.id)) {
                this.organization = new Organization(o);
                await this.reset();
            }
        }));

        this.subscribe(this.venueService.current.subscribe(v => this.venue = v));
    }

    async reset() {
        this.marketingPushes = [];
        this.currentPage = 0;
        await this.load();
    }

    async openCreatePush() {
        const modal = await this.modalController.create({
            component: CreatePushModalComponent,
            componentProps: {
                orgUrl: this.organization.website
            }
        });
        await modal.present();
        modal.onDidDismiss().then(async (dataReturned) => {
            if (dataReturned != null && dataReturned.data != null) {
                this.marketingPushes.unshift(new MarketingPush(dataReturned.data));
                await this.changeDetectorRef.detectChanges();
            }
        });
    }

    async load() {
        await this.marketingPushService.findByOrganization(this.organization.id, this.currentPage, 20).subscribe(async (page: any) => {
            this.total = page.totalElements;
            this.totalPages = page.totalPages;

            page.content.forEach(item => {
                this.marketingPushes.push(new MarketingPush(item));
                this.changeDetectorRef.detectChanges();
            });

            if (!!this.infiniteScroll && this.currentPage === (this.totalPages - 1)) {
                this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
            }
        });
    }

    async doInfinite(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            await this.load();
            event.target.complete();
            this.masonry.layout();
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
