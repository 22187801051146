import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {
    addCurrency,
    CashSession,
    CashSessionEmployee,
    CashSessionSummary,
    multiplyCurrency,
    Organization,
    SubscriberComponent,
    subtractCurrency,
    TipOutTypes
} from 'brewbill-lib';
import {debounce} from 'lodash';

@Component({
    selector: 'bb-cash-session-employees',
    templateUrl: './cash-session-employees.component.html',
    styleUrls: ['./cash-session-employees.component.scss'],
})
export class CashSessionEmployeesComponent extends SubscriberComponent implements OnChanges {
    @Input() cashSession: CashSession;
    @Input() cashSessionSummary: CashSessionSummary;
    @Input() organization: Organization;
    @Input() includeTotals = false;
    @Input() hoursOnly = false;
    @Input() editable = false;
    @Output() employeeClick = new EventEmitter<CashSessionEmployee>();
    @Output() remainingAmount = new EventEmitter<number>();

    totalHours: number;
    totalCash: number;
    totalPayroll: number;
    total: number;
    remaining: number;

    tipOutTypes = TipOutTypes;

    constructor(
    ) {
        super();
        this.initSession = debounce(this.initSession, 100);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initSession();
    }

    initSession() {
        if (!!this.cashSession.employees && this.cashSession.employees.length > 0) {
            const totalHours = this.cashSession.employees
                .reduce((accum, value) => accum += value.hours * 100, 0);
            this.totalHours = totalHours / 100;

            let totalCashTemp = 0;
            let totalPayrollTemp = 0;
            this.cashSession.employees.forEach(e => {
                e.percent = (e.hours * 100) / totalHours;
                e.expected = multiplyCurrency(this.cashSessionSummary.tipTotal, e.percent);
                totalCashTemp = addCurrency(totalCashTemp, e.cashTips);
                totalPayrollTemp = addCurrency(totalPayrollTemp, e.payrollTips);
            });

            this.totalCash = totalCashTemp;
            this.totalPayroll = totalPayrollTemp;
            this.total = addCurrency(totalCashTemp, totalPayrollTemp);
            this.remaining = subtractCurrency(this.cashSessionSummary.tipTotal, this.total);
            this.remainingAmount.emit(this.remaining);
        }
    }
}
