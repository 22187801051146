import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompDefinitionTypes, TabNoteDefinition} from 'brewbill-lib';

@Component({
    selector: 'bb-note-definition',
    templateUrl: './note-definition.component.html',
    styleUrls: ['./note-definition.component.scss'],
})
export class NoteDefinitionComponent implements OnInit {
    @Input() noteDefinition: TabNoteDefinition;
    @Input() selectOption = false;
    @Input() fullDetails = false;
    @Output() definitionClick = new EventEmitter();

    expanded = false;

    altColor = '#ffffff';

    compDefinitionTypes = CompDefinitionTypes;

    ngOnInit(): void {
        const color = this.noteDefinition.color.startsWith('#') ? this.noteDefinition.color.substring(1) : this.noteDefinition.color;

        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        R = (R > 125) ? R * (100 - 50) / 100 : R * (100 + 50) / 100;
        G = (G > 125) ? G * (100 - 50) / 100 : G * (100 + 50) / 100;
        B = (B > 125) ? B * (100 - 50) / 100 : B * (100 + 50) / 100;

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
        const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
        const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

        this.altColor = '#' + RR + GG + BB;

        this.expanded = this.fullDetails;
    }

    toggle() {
        if (!this.fullDetails) {
            this.expanded = !this.expanded;
        }
    }

    checkClick() {
        this.toggle();
        this.definitionClick.emit(this.noteDefinition);
    }
}
