import {Component, OnInit} from '@angular/core';
import {EditCashDrawerComponent} from '../edit-cash-drawer/edit-cash-drawer.component';

import {
    CashDrawer,
    CashDrawerService,
    CashDrawerSummary,
    LoadingService,
    Organization,
    OrganizationService,
    SubscriberComponent,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-safes',
    templateUrl: './safes.component.html',
    styleUrls: ['./safes.component.scss'],
})
export class SafesComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    safes = [];

    constructor(
        private organizationService: OrganizationService,
        private cashDrawerService: CashDrawerService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private modalController: ModalController
    ) {
        super();
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        if (!!this.organization) {
            this.refreshSummaries();
        }

        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
    }

    async openEditDrawer(event, cashDrawer: CashDrawer) {
        if (!!event) {
            event.stopPropagation();
        }

        const modal = await this.modalController.create({
            component: EditCashDrawerComponent,
            componentProps: {
                cashDrawer,
                organization: this.organization
            }
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshSummaries();
            }
        });
    }

    refreshSummaries() {
        this.loadingService.present();
        this.cashDrawerService.safes(this.organization.id).subscribe((s: CashDrawerSummary[]) => {
            this.safes = s;
            this.loadingService.dismiss();
        });
    }
}
