import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent, CardSummary, LoadingService, Membership, MembershipService, Person, ToastService} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-membership',
    templateUrl: './edit-membership.component.html',
    styleUrls: ['./edit-membership.component.scss'],
})
export class EditMembershipComponent extends BaseModalComponent implements OnInit {
    @Input() member: Membership;
    @Input() skipSave = false;
    @Input() title = 'Member Information';
    @Input() personUpdate = false;
    @Input() cardSummary: CardSummary;

    editPersonForm: FormGroup = new FormGroup({});
    formGroup: FormGroup;
    person: Person;

    constructor(
        private membershipService: MembershipService,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.person = !!this.member.person ? this.member.person : new Person();

        this.formGroup = this.formBuilder.group({
            id: this.member.id,
            status: this.member.status,
            denyCardRequest: this.member.denyCardRequest,
            associateCard: true
        });

        if (!this.personUpdate) {
            this.formGroup.addControl('memberNumber', new FormControl(this.member.memberNumber, Validators.required));
        }
    }

    async onSubmit() {
        const p = new Person(this.editPersonForm.value);
        const m = new Membership(this.formGroup.value);
        m.person = p;
        if (!this.skipSave) {
            this.loadingService.present();
            this.membershipService.update(m).subscribe(
                async (member: Membership) => {
                    this.loadingService.dismiss();
                    await this.toastService.success('Member information updated.');
                    await this.close(member);
                }
            );
        } else {
            if (this.formGroup.controls.associateCard.value) {
                m.cardSummary = this.cardSummary;
            }
            await this.close(m);
        }
    }

    formInitialized(form: FormGroup) {
        this.editPersonForm = form;
    }
}
