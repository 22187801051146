import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    Address,
    AddressService,
    BaseStepperFormComponent,
    LoadingService,
    Organization,
    PickupLocation,
    PickupLocationService,
    ToastService,
    Venue,
    VenueMapComponent
} from 'brewbill-lib';
import clone from 'clone';
import {GoogleMap} from '@angular/google-maps';

@Component({
    selector: 'bb-edit-pickup-location-component',
    templateUrl: './edit-pickup-location.component.html',
    styleUrls: ['./edit-pickup-location.component.scss'],
})
export class EditPickupLocationComponent extends BaseStepperFormComponent implements OnInit, AfterViewInit {
    @Input() organization: Organization;
    @Input() venue: Venue;
    @Input() pickupLocation: PickupLocation;
    @ViewChild('venueMap') venueMap: VenueMapComponent;
    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    location: google.maps.Marker;
    addressFormGroup: FormGroup = new FormGroup({});
    editPickupLocationForm: FormGroup;
    markers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    availableMarkers = [];
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        streetViewControl: false,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        clickableIcons: false
    };

    constructor(
        modalController: ModalController,
        private addressService: AddressService,
        private formBuilder: FormBuilder,
        private pickupLocationService: PickupLocationService,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.editPickupLocationForm = this.setupFormGroup();

        if (!!this.pickupLocation) {
            // need to initialize this to satisfy the condition check on the submit button
            this.location = new google.maps.Marker();
        }
    }

    ngAfterViewInit(): void {
        if (!this.pickupLocation.mobile && !this.location.getPosition()) {
            this.location = new google.maps.Marker({
                position: new google.maps.LatLng(this.pickupLocation.address.latitude, this.pickupLocation.address.longitude),
                map: !!this.venueMap ? this.venueMap.map.googleMap : this.map.googleMap,
            });

            if (!this.venue) {
                this.map.googleMap.setCenter(this.location.getPosition());
            }
        }
    }

    setupFormGroup(): FormGroup {
        this.initPickupLocation();

        const am = !!this.pickupLocation && this.pickupLocation.availableMarkers != null && this.pickupLocation.availableMarkers.length > 0
            ? this.pickupLocation.availableMarkers : '';

        this.availableMarkers = (!!this.pickupLocation && !!this.pickupLocation.id) ?
            am.split(',') : ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        return this.formBuilder.group({
            id: this.pickupLocation.id,
            parentId: this.organization.id,
            organization: this.organization,
            imageAddress: this.pickupLocation.imageAddress,
            name: [this.pickupLocation.name, [Validators.required, Validators.maxLength(250)]],
            phone: [!!this.pickupLocation.id ? this.pickupLocation.phone : this.organization.phone],
            direction: [this.pickupLocation.direction, Validators.maxLength(2000)]
        });
    }

    toggleAvailable(marker) {
        const index = this.availableMarkers.indexOf(marker);
        if (index > -1) {
            this.availableMarkers.splice(index, 1);
        } else {
            this.availableMarkers.push(marker);
        }
    }

    setLocation(event) {
        if (!this.location.getPosition()) {
            this.location = new google.maps.Marker({
                position: event.latLng,
                map: !!this.venueMap ? this.venueMap.map.googleMap : this.map.googleMap,
            });
        } else {
            this.location.setPosition(event.latLng);
        }
    }

    addressFormInitialized(form: FormGroup) {
        this.addressFormGroup = form;
    }

    setCenter(event?) {
        if (event.selectedIndex === 3) {
            if (!this.venue) {
                this.addressService.getGeocoder().geocode({
                    address: this.addressService.fullAddress(this.addressFormGroup.value)
                }, async (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK && results[0].geometry.location_type === 'ROOFTOP') {
                        this.map.googleMap.setCenter(
                            new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()));

                        if (!this.location.getPosition()) {
                            this.location = new google.maps.Marker({
                                position: new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()),
                                map: !!this.venueMap ? this.venueMap.map.googleMap : this.map.googleMap,
                            });
                        }
                    } else {
                        await this.toastService.error('The address could not be found.');
                    }
                });
            }
        }
    }

    valid() {
        return this.editPickupLocationForm.valid && this.addressFormGroup.valid && !!this.location;
    }

    async onSubmit() {
        this.loadingService.present();

        const formValue: PickupLocation = {...this.pickupLocation, ...this.editPickupLocationForm.value};
        formValue.address = new Address(this.addressFormGroup.value);
        formValue.availableMarkers = this.availableMarkers.join();

        if (!this.pickupLocation.mobile) {
            formValue.address.latitude = this.location.getPosition().lat();
            formValue.address.longitude = this.location.getPosition().lng();
        }

        if (formValue.id != null) {
            this.pickupLocationService.update(formValue).subscribe((p) => {
                    this.toastService.success('Pickup location updated.');
                    this.loadingService.dismiss();
                    this.close(p);
                }
            );
        } else {
            this.pickupLocationService.create(formValue).subscribe((p) => {
                    this.toastService.success('Pickup location added.');
                    this.loadingService.dismiss();
                    this.close(p);
                }
            );
        }
    }

    initPickupLocation() {
        if (!this.pickupLocation) {
            this.pickupLocation = new PickupLocation();
            this.pickupLocation.organization = this.organization;
            this.pickupLocation.parentId = this.organization.id;
            this.pickupLocation.address = clone<Address>(this.organization.address);
            this.pickupLocation.address.id = null;
        }
    }
}
