import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    FeatureKeys,
    LoadingService,
    Organization,
    OrganizationService,
    QuickbooksDefinition,
    QuickbooksDefinitionService,
    QuickbooksFieldMapping,
    QuickbooksReports,
    QuickbooksService,
    TabReportService,
    ToastService,
    Venue,
    VenueService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'bb-edit-quickbooks-definition',
    templateUrl: './edit-quickbooks-definition.component.html',
    styleUrls: ['./edit-quickbooks-definition.component.scss'],
})
export class EditQuickbooksDefinitionComponent extends BaseStepperFormComponent implements OnInit {
    @Input() definition: QuickbooksDefinition;

    organization: Organization;
    venue: Venue;
    loading = true;
    formGroup: FormGroup;
    testDate: string;

    quickbooksReports: QuickbooksReports;
    accounts: string[];
    customers: string[];
    categories: string[];
    items: string[];

    labels = [];

    featureKeys = FeatureKeys;

    reportOptions: any[] = [];

    constructor(
        private organizationService: OrganizationService,
        private venueService: VenueService,
        private quickbooksService: QuickbooksService,
        private quickbooksDefinitionService: QuickbooksDefinitionService,
        private tabReportService: TabReportService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        this.venue = this.venueService.currentValue;

        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
        this.subscribe(this.venueService.current.subscribe(v => this.venue = !!v ? new Venue(v) : null));

        this.formGroup = this.formBuilder.group({
            id: !!this.definition ? this.definition.id : null,
            active: !this.definition || this.definition.active,
            useLabels: !!this.definition && this.definition.useLabels,
            parentId: this.organization.id,
            customer: !!this.definition ? this.definition.customer : 'Taproom',
            category: !!this.definition ? this.definition.category : 'Daily Sales',
            comps: !!this.definition ? this.definition.comps : 'Comps',
            tips: !!this.definition ? this.definition.tips : 'Tips',
            includedTaxes: !!this.definition ? this.definition.includedTaxes : 'Included Taxes',
            addedTaxes: !!this.definition ? this.definition.addedTaxes : 'Added Taxes',
            fees: !!this.definition ? this.definition.fees : 'Mobile User Fees',
            surcharges: !!this.definition ? this.definition.surcharges : 'Credit Card Surcharges',
            cash: !!this.definition ? this.definition.cash : 'Cash Payments',
            creditCards: !!this.definition ? this.definition.creditCards : 'Credit Card Payments',
            giftCards: !!this.definition ? this.definition.giftCards : 'Gift Card Payments',
            giftCardPurchases: !!this.definition ? this.definition.giftCardPurchases : 'Gift Card Purchases',
            outstanding: !!this.definition ? this.definition.outstanding : 'Outstanding',
            appliedToPrevious: !!this.definition ? this.definition.appliedToPrevious : 'Applied to Past Tabs',
            unknown: !!this.definition ? this.definition.unknown : 'Unknown'
        });

        this.refresh();
    }

    refresh() {
        this.loadingService.present();
        forkJoin([
            this.tabReportService.quickbooksReports(this.organization.id),
            this.quickbooksService.accounts(this.organization.id),
            this.quickbooksService.customers(this.organization.id),
            this.quickbooksService.categories(this.organization.id),
            this.quickbooksService.items(this.organization.id)
        ]).subscribe(([quickbooksReports, accounts, customers, categories, items]) => {
            this.accounts = (accounts as any[]).reduce((acc, c) => {
                acc.push(c.name);
                return acc;
            }, []);

            this.customers = (customers as any[]).reduce((acc, c) => {
                acc.push(c.displayName);
                return acc;
            }, []);

            this.categories = (categories as any[]).reduce((acc, c) => {
                acc.push(c.name);
                return acc;
            }, []);

            this.items = (items as any[]).reduce((acc, c) => {
                acc.push(c.name);
                return acc;
            }, []);

            this.quickbooksReports = quickbooksReports as QuickbooksReports;

            if (!!this.quickbooksReports && !!this.quickbooksReports.itemTypeSales) {
                let index = 0;
                const labelColumn = this.quickbooksReports.itemTypeSales.columns[0].accessKey;
                this.quickbooksReports.itemTypeSales.data.forEach(d => {
                    const label = d.data[labelColumn];
                    const mapping = (!!this.definition && !!this.definition.fieldMappings) ?
                        this.definition.fieldMappings.find(f => f.key === label) : null;

                    const prefix = this.generateSalesPrefix(label);
                    this.labels.push(label);
                    this.formGroup.addControl(`${prefix}-id`, new FormControl(!!mapping ? mapping.id : null));
                    this.formGroup.addControl(`${prefix}-sortOrder`, new FormControl(index++));
                    this.formGroup.addControl(`${prefix}-include`, new FormControl(!!mapping ? mapping.include : true));
                    this.formGroup.addControl(`${prefix}-itemName`, new FormControl(!!mapping
                        ? mapping.itemName
                        : `Net ${label}`, [Validators.required]));
                });
            }

            this.populateReportOptions();
            this.loadingService.dismiss();
        });
    }

    loadReports() {
        this.loadingService.present();
        this.tabReportService.quickbooksReports(this.organization.id, this.formGroup.controls.useLabels.value)
            .subscribe((q: QuickbooksReports) => {
                this.quickbooksReports = q;
                this.populateReportOptions();
                this.loadingService.dismiss();
            });
    }

    populateReportOptions() {
        this.reportOptions = [];
        if (!!this.quickbooksReports) {
            if (!!this.quickbooksReports.itemTypeSales) {
                this.quickbooksReports.itemTypeSales.data.forEach(d =>
                    this.reportOptions.push({report: 'ITEM_TYPE_SALES', label: d.id, key: d.id}));
            }

            if (!!this.quickbooksReports.paymentInformation) {
                this.quickbooksReports.paymentInformation.columns.forEach(c =>
                    this.reportOptions.push({report: 'PAYMENT_INFORMATION', label: c.label, key: c.accessKey}));
            }
        }
    }

    submit() {
        const q = new QuickbooksDefinition(this.formGroup.value);
        q.fieldMappings = [];
        this.labels.forEach(l => {
            q.fieldMappings.push(new QuickbooksFieldMapping({
                id: this.formGroup.get(`${this.generateSalesPrefix(l)}-id`).value,
                key: l,
                include: this.formGroup.get(`${this.generateSalesPrefix(l)}-include`).value,
                itemName: this.formGroup.get(`${this.generateSalesPrefix(l)}-itemName`).value,
                sortOrder: this.formGroup.get(`${this.generateSalesPrefix(l)}-sortOrder`).value,
            }));
        });

        this.loadingService.present();
        if (!q.id) {
            this.quickbooksDefinitionService.create(q).subscribe(r => {
                this.definition = new QuickbooksDefinition(r);
                this.toastService.success('The Quickbooks integration has been saved.');
                this.loadingService.dismiss();
                this.close(this.definition);
            });
        } else {
            this.quickbooksDefinitionService.update(q).subscribe(r => {
                this.definition = new QuickbooksDefinition(r);
                this.toastService.success('The Quickbooks integration has been saved.');
                this.loadingService.dismiss();
                this.close(this.definition);
            });
        }
    }

    generateSalesPrefix(s: string) {
        return 'SALES-' + (!!s ? s.replace(' ', '_').replace(/[^a-z0-9_]/gi, '') : '');
    }

    setValue(field: string, val) {
        this.formGroup.get(field).setValue(val);
    }
}
