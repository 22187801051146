import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    Availabilities,
    BaseStepperFormComponent,
    CompDefinitionAvailabilities,
    FileUploadService,
    FileUploadType,
    LoadingService,
    Menu,
    MenuItem,
    MenuItemLabel,
    MenuItemPrice,
    MenuItemSelection,
    MenuItemService,
    MenuItemTopping,
    MenuLabel,
    MenuSelection,
    MenuTaxLabel,
    MenuTopping,
    Organization,
    OrganizationRewardProgram,
    OrganizationService,
    reorder,
    RewardProgramTypes,
    RewardTypes,
    ToastService,
    UploadImageModalComponent
} from 'brewbill-lib';
import {ModalController, Platform} from '@ionic/angular';
import {EditMenuItemPriceComponent} from '../edit-menu-item-price/edit-menu-item-price.component';

@Component({
    selector: 'app-edit-menu-item',
    templateUrl: './edit-menu-item.component.html',
    styleUrls: ['./edit-menu-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EditMenuItemComponent extends BaseStepperFormComponent implements OnInit {
    @Input() menuItem: any;
    @Input() menu: Menu;
    @Input() organization: Organization;
    @Input() addMenuLabel: any;
    @Input() addMenuTaxLabel: any;
    @Input() addMenuTopping: any;
    @Input() addMenuSelection: any;
    @Input() hideCategories: boolean;
    @Input() rewardProgram: OrganizationRewardProgram;
    availability: string;

    prices: MenuItemPrice[] = [];
    selectedLabels: MenuItemLabel[] = [];
    selectedTaxLabels: MenuTaxLabel[] = [];
    selectedToppings: MenuItemTopping[] = [];
    selectedSelections: MenuItemSelection[];
    filteredSelections: MenuSelection[];
    informationFormGroup: FormGroup = new FormGroup({});
    availableOrderComps = [];

    availabilities = Availabilities;
    rewardProgramTypes = RewardProgramTypes;
    rewardTypes = RewardTypes;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private menuItemService: MenuItemService,
        private organizationService: OrganizationService,
        private platform: Platform,
        private fileUploadService: FileUploadService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.availability = this.menuItem != null ? this.menuItem.availability : Availabilities.ALL;
        this.menuItem = this.menuItem == null ? new MenuItem() : this.menuItem;
        this.selectedLabels = this.menuItem.labels == null ? [] : [...this.menuItem.labels];
        this.selectedTaxLabels = this.menuItem.taxLabels == null ? [] : [...this.menuItem.taxLabels];
        this.selectedToppings = this.menuItem.toppings == null ? [] : [...this.menuItem.toppings];
        this.selectedSelections = this.menuItem.selections == null ? [] : [...this.menuItem.selections];
        this.prices = this.menuItem.prices == null ? [] : [...this.menuItem.prices];

        this.availableOrderComps = !!this.organization && !!this.organization.compDefinitions
            ? this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.ORDER_ITEM) : [];

        this.filteredSelections = !!this.menuItem.id
            ? this.menu.menuSelections.filter(s =>
                !s.options.some(o => this.menuItem.id === o.menuItem.id)) : this.menu.menuSelections;

        if (this.prices.length === 0) {
            const price = new MenuItemPrice();
            price.name = 'Default';
            price.price = 0;
            price.sortOrder = 0;
            price.availability = Availabilities.ALL;
            price.defaultPrice = true;
            this.prices = [price];
        }

        this.informationFormGroup = this.formBuilder.group(
            {
                id: this.menuItem.id,
                parentId: this.menu.id,
                available: this.menuItem.availability == null ? Availabilities.ALL : this.menuItem.availability,
                name: [this.menuItem.name, Validators.required],
                sku: this.menuItem.sku,
                flavorText: this.menuItem.flavorText,
                description: this.menuItem.description,
                priceLabel: this.menuItem.priceLabel,
                verifyAge: this.menuItem.verifyAge,
                terminalOnly: this.menuItem.terminalOnly,
                imageAddress: this.menuItem.imageAddress,
                featuredColor: !!this.menuItem.featuredColor ? this.menuItem.featuredColor : 'primary',
                taxOverride: !!this.menuItem.taxOverride ? this.menuItem.taxOverride : '',
                menuCategoryIds: [this.menuItem.menuCategoryIds],
                menuSelectionIds: [this.menuItem.menuSelectionIds]
            }
        );
    }

    valid() {
        return this.informationFormGroup.valid && this.validPrices();
    }

    validPrices() {
        return this.prices.length > 0
            && this.prices.filter(s => {
                return s.price != null;
            }).length === this.prices.length;
    }

    selectLabel(label: MenuLabel) {
        if (this.selectedLabels.find(obj => obj.id === label.id) == null) {
            const menuItemLabel = new MenuItemLabel();
            menuItemLabel.label = label;
            menuItemLabel.sortOrder = this.selectedLabels.length;
            this.selectedLabels = [...this.selectedLabels, menuItemLabel];
        }
    }

    selectTaxLabel(label: MenuTaxLabel) {
        if (this.selectedTaxLabels.find(obj => obj.id === label.id) == null) {
            this.selectedTaxLabels = [...this.selectedTaxLabels, label];
        }
    }

    selectTopping(topping: MenuTopping) {
        if (this.selectedToppings.find(obj => obj.topping.id === topping.id) == null) {
            const mit = new MenuItemTopping();
            mit.included = true;
            mit.topping = topping;
            mit.sortOrder = this.selectedToppings.length;
            this.selectedToppings = [...this.selectedToppings, mit];
        }
    }

    selectSelection(selection: MenuSelection) {
        if (this.selectedSelections.find(obj => obj.menuSelection.id === selection.id) == null) {
            const mit = new MenuItemSelection();
            mit.menuSelection = selection;
            mit.sortOrder = this.selectedSelections.length;
            this.selectedSelections = [...this.selectedSelections, mit];
        }
    }

    updateSelectionSortOrder(list: MenuItemSelection[]) {
        this.selectedSelections = list;
    }

    reorderPrices(event) {
        reorder(this.prices, event);
    }

    async openEditPrice(menuItemPrice: MenuItemPrice) {
        const index = menuItemPrice != null ? this.prices.indexOf(menuItemPrice) : -1;

        const modal = await this.modalController.create({
            component: EditMenuItemPriceComponent,
            componentProps: {
                menuItemPrice,
                defaultAvailability: this.availability
            },
            cssClass: 'menu-modal'
        });

        await modal.present();
        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                const price = dataReturned.data;
                if (price.defaultPrice) {
                    for (const p of this.prices) {
                        p.defaultPrice = false;
                    }
                }

                if (index === -1) {
                    price.sortOrder = this.prices.length;
                    this.prices.push(price);
                } else {
                    const existing = this.prices[index];
                    price.compDefinitionIds = existing.compDefinitionIds;
                    price.tokenGroupIds = existing.tokenGroupIds;
                    this.prices.splice(index, 1, price);
                }
            }
        });
    }

    removePrice(event, menuItemPrice: MenuItemPrice) {
        event.stopPropagation();
        this.prices.splice(this.prices.indexOf(menuItemPrice), 1);
    }

    async openAddMenuLabel() {
        const l = await this.addMenuLabel(null);
        if (l != null) {
            this.selectLabel(l);
        }
    }

    async openAddMenuTaxLabel() {
        const l = await this.addMenuTaxLabel(null);
        if (l != null) {
            this.selectTaxLabel(l);
        }
    }

    async openAddMenuTopping() {
        const t = await this.addMenuTopping(null);
        if (t != null) {
            this.selectTopping(t);
        }
    }

    async openAddMenuSelection() {
        const t = await this.addMenuSelection(null);
        if (t != null) {
            this.selectSelection(t);
        }
    }

    selected(event) {
        this.availability = event.value;
    }

    onStepChange(event: any): void {
        if (event.selectedIndex === 1) {
            const overlay: any = document.getElementById('beerDescriptionOverlay');
            const textArea: any = document.getElementById('beerDescriptionTextArea');
            if (!!textArea) {
                const label: any = textArea.previousSibling;
                const textAreaStyles = window.getComputedStyle(textArea);
                overlay.style.top = (label.offsetHeight
                    + parseInt(textAreaStyles.marginTop.substring(0, textAreaStyles.marginTop.length - 2), 10)) + 'px';
                overlay.style.width = textArea.offsetWidth + 'px';
                overlay.style.height = textArea.offsetHeight + 'px';
            }
        }
    }

    async openUploadImageModal() {
        if (this.platform.is('desktop')) {
            const modal = await this.modalController.create({
                component: UploadImageModalComponent,
                cssClass: 'menu-modal'
            });

            modal.onDidDismiss().then(async (dataReturned) => {
                if (dataReturned != null && dataReturned.data != null) {
                    await this.uploadImage(dataReturned.data);
                }
            });

            await modal.present();
        } else {
            const image = await this.fileUploadService.getPhoto();
            this.loadingService.present();
            const imgBlob = this.fileUploadService.b64toBlob(image.base64String, `image/${image.format}`);
            this.uploadImage(imgBlob);
        }
    }

    uploadImage(data: any) {
        this.loadingService.present();
        this.fileUploadService.uploadImage(this.menu.orgId, FileUploadType.MENU_ITEM, data).subscribe(async (imageAddress: any) => {
            this.informationFormGroup.controls.imageAddress.setValue(imageAddress.response);
            this.loadingService.dismiss();
        }, async (err) => {
            console.log(err);
            this.loadingService.dismiss();
            await this.toastService.error('Error uploading image.');
        });
    }

    readFile(menuItem: MenuItem, file: any) {
        const reader = new FileReader();
        reader.onload = async () => {
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });

            this.uploadImage(imgBlob);
        };
        reader.readAsArrayBuffer(file);
    }

    async onSubmit() {
        this.loadingService.present();
        const item: MenuItem = new MenuItem(this.informationFormGroup.value);
        if (!item.taxOverride) {
            item.taxOverride = null;
        }
        item.labels = this.selectedLabels;
        item.prices = this.prices;
        item.toppings = this.selectedToppings;
        item.selections = this.selectedSelections;
        item.taxLabels = this.selectedTaxLabels;
        item.availability = this.availability;

        if (item.id != null) {
            this.menuItemService.update(item).subscribe(
                (menuItem: MenuItem) => {
                    this.toastService.success('Item updated.');
                    this.loadingService.dismiss();
                    this.close(menuItem);

                }, (error) => this.errorHandler(error)
            );
        } else {
            this.menuItemService.create(item).subscribe(
                (menuItem: MenuItem) => {
                    this.toastService.success(`${menuItem.name} added.`);
                    this.loadingService.dismiss();
                    this.close(menuItem);
                }, (error) => this.errorHandler(error)
            );
        }
    }

    removeImage(event) {
        event.stopPropagation();
        this.informationFormGroup.controls.imageAddress.setValue(null);
    }

    errorHandler(error) {
        this.loadingService.dismiss();
        if (error.status === 424) {
            this.toastService.error('Failed dependency.');
        } else if (error.status === 409) {
            this.toastService.error('The item cannot contain any selections that also include this item.');
        } else {
            this.toastService.error('A server error occurred.');
        }
    }
}
