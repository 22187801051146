import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    LoadingService,
    OrganizationPreference,
    OrganizationPreferenceService,
    TimeZones,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-organization-preference',
    templateUrl: './organization-preference.component.html',
    styleUrls: ['./organization-preference.component.scss'],
})
export class OrganizationPreferenceComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organizationPreference: OrganizationPreference;
    formGroup: FormGroup;
    timeZones = TimeZones;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private organizationPreferenceService: OrganizationPreferenceService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
                id: this.organizationPreference.id,
                parentId: this.organizationPreference.orgId,
            surcharge: [this.organizationPreference.surcharge, [Validators.min(0)]],
            surchargeIncludeTip: this.organizationPreference.surchargeIncludeTip,
                percent: [this.organizationPreference.percent, [Validators.required, Validators.min(0)]],
                organizationPercent: [this.organizationPreference.organizationPercent, [Validators.required, Validators.min(0)]],
                minFee: [this.organizationPreference.minFee, [Validators.min(0)]],
                maxFee: [this.organizationPreference.maxFee, [Validators.min(0)]],
                preAuthAmount: [this.organizationPreference.preAuthAmount, [Validators.min(0)]],
                currentTabNumber: this.organizationPreference.currentTabNumber,
                tabNumberLength: this.organizationPreference.tabNumberLength,
                currentOrderNumber: this.organizationPreference.currentOrderNumber,
                orderNumberLength: this.organizationPreference.orderNumberLength,
                timeZone: [this.organizationPreference.timeZone, Validators.required],
            testMode: this.organizationPreference.testMode,
            allowMobileTabStart: this.organizationPreference.allowMobileTabStart
            }
        );
    }

    onSubmit() {
        if (this.formGroup.valid) {
            if (this.formGroup.value.currentTabNumber < this.organizationPreference.currentTabNumber) {
                this.toastService.error('The next tab number cannot be lower than the current value. Please try again.');
                return;
            }

            if (this.formGroup.value.currentOrderNumber < this.organizationPreference.currentOrderNumber) {
                this.toastService.error('The next order number cannot be lower than the current value. Please try again.');
                return;
            }

            this.loadingService.present();
            this.organizationPreferenceService.update(new OrganizationPreference(this.formGroup.value)).subscribe((op) => {
                this.toastService.success('The preferences have been updated.');
                this.loadingService.dismiss();
                this.close(op);
            });
        }
    }
}
