import {Component, OnInit, ViewChild} from '@angular/core';
import {
    Deposit,
    DepositService,
    LoadingService,
    Organization,
    OrganizationDepositSummary,
    OrganizationService,
    SubscriberComponent,
    ToastService
} from 'brewbill-lib';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ModalController} from '@ionic/angular';
import {forkJoin} from 'rxjs';
import {DepositRefundComponent} from '../deposit-refund/deposit-refund.component';

@Component({
    selector: 'bb-deposits',
    templateUrl: './deposits.component.html',
    styleUrls: ['./deposits.component.scss'],
})
export class DepositsComponent extends SubscriberComponent implements OnInit {
    searchCriteria: any;
    organization: Organization;
    dataSource: MatTableDataSource<Deposit>;
    filterString = '';
    organizationSummary: OrganizationDepositSummary;
    status = ['ACTIVE'];
    displayedColumns: string[] = ['name', 'email', 'mobile', 'label', 'status', 'createDate', 'createdBy', 'type', 'amount'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private depositService: DepositService,
        private organizationService: OrganizationService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private modalController: ModalController
    ) {
        super();
    }

    ngOnInit() {
        this.searchCriteria = this.depositService.getSearchCriteria();
        if (!!this.searchCriteria) {
            this.filterString = this.searchCriteria.filter;
        }

        this.dataSource = new MatTableDataSource([]);

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.refresh();
        }));
    }

    ionViewWillEnter() {
        if (!!this.organization) {
            this.refresh();
        }
    }

    refresh() {
        this.loadingService.present();

        forkJoin([
            this.depositService.findAllByOrganization(this.organization.id, (!!this.status && this.status.length === 1)
                ? this.status[0]
                : null),
            this.depositService.findSummaryByOrganization(this.organization.id, (!!this.status && this.status.length === 1)
                ? this.status[0]
                : null)
        ]).subscribe(([deposits, summary]) => {
            this.dataSource = new MatTableDataSource(deposits as Deposit[]);

            this.dataSource.filterPredicate = (data, filter: string): boolean => {
                return (!!data.person && data.person.name.toLowerCase().includes(filter))
                    || (!!data.label && data.label.toLowerCase().includes(filter))
                    || (!!data.paymentCharge
                        && !!data.paymentCharge.cardSummary
                        && data.paymentCharge.cardSummary.lastFour.toString().includes(filter));
            };

            this.dataSource.sort = this.sort;
            this.organizationSummary = summary as OrganizationDepositSummary;
            this.applyFilter(this.filterString);
            this.loadingService.dismiss();
        });
    }

    applyFilter(filterValue: string) {
        this.depositService.setSearchCriteria({filter: filterValue});
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    async displayDeposit(deposit: Deposit) {
        this.loadingService.present();
        const deposits = !!deposit.paymentCharge ?
            await this.depositService.findByPaymentCharge(deposit.paymentCharge.id).toPromise() :
            await this.depositService.findByCashAudit(deposit.cashAudit.id).toPromise();
        this.loadingService.dismiss();
        const modal = await this.modalController.create({
            component: DepositRefundComponent,
            componentProps: {
                deposits,
                paymentCharge: deposit.paymentCharge,
                cashAudit: deposit.cashAudit
            }
        });

        modal.onDidDismiss().then(() => {
            this.refresh();
        });

        await modal.present();
    }
}
