import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, CardSummary, LoadingService, Membership, MembershipService} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'bb-request-membership-card-save',
    templateUrl: './request-membership-card-save.component.html',
    styleUrls: ['./request-membership-card-save.component.scss'],
})
export class RequestMembershipCardSaveComponent extends BaseModalComponent implements OnInit {
    @Input() member: Membership;
    @Input() cardSummary: CardSummary;

    formGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private membershipService: MembershipService,
        private loadingService: LoadingService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            denyCardRequest: false
        });
    }

    async save(save: boolean) {
        if (save) {
            const member = new Membership(this.member);
            member.cardSummary = this.cardSummary;
            this.loadingService.present();
            const saved = await this.membershipService.addPersonInfo(member)
                .toPromise() as Membership;
            this.loadingService.dismiss();
            await this.close(saved);
        } else if (this.formGroup.controls.denyCardRequest.value) {
            const member = new Membership(this.member);
            this.loadingService.present();
            member.denyCardRequest = true;
            const saved = await this.membershipService.update(member)
                .toPromise() as Membership;
            this.loadingService.dismiss();
            await this.close(saved);
        } else {
            await this.close();
        }
    }
}
