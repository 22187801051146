import {Component, Input, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    EpsonPrinterService,
    Organization,
    OrganizationTerminal,
    OrganizationTerminalService,
    PrinterService,
    StarPrinterService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-select-printer',
    templateUrl: './select-printer.component.html',
    styleUrls: ['./select-printer.component.scss'],
})
export class SelectPrinterComponent extends BaseModalComponent implements OnInit {
    @Input() organization: Organization;
    terminal: OrganizationTerminal;
    connected = false;
    loaded = false;
    type: string;

    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private organizationTerminalService: OrganizationTerminalService,
        private epsonPrinterService: EpsonPrinterService,
        private starPrinterService: StarPrinterService,
        private printerService: PrinterService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        const ipAddress = this.epsonPrinterService.ipAddress;
        const columns = this.printerService.columns;

        this.printerService.connectViaBlueTooth();

        this.formGroup = this.formBuilder.group({
            ipAddress: [ipAddress, Validators.required],
            columns: [columns, [Validators.required, Validators.min(0)]]
        });

        this.subscribe(this.organizationTerminalService.current.subscribe(t => this.terminal = t));
        this.subscribe(this.printerService.connected.subscribe(c => {
            if (this.loaded && !this.connected && c) {
                this.close();
            }
            this.connected = c;
            this.type = this.printerService.type;

            if (this.type === 'STAR') {
                this.formGroup.get('ipAddress').setValidators([]);
            } else {
                this.formGroup.get('ipAddress').setValidators([Validators.required]);
            }
            this.formGroup.controls.ipAddress.updateValueAndValidity();

            this.loaded = true;
        }));
    }

    disconnect() {
        this.epsonPrinterService.disconnect();
    }

    connect() {
        this.epsonPrinterService.connect(this.formGroup.controls.ipAddress.value, false,
            parseInt(this.formGroup.controls.columns.value, 10));
    }

    save() {
        this.starPrinterService.setColumns(this.formGroup.controls.columns.value);
        this.close();
    }
}
