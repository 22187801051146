import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {
    BaseStepperFormComponent,
    LoadingService,
    MembershipTokenGroup,
    MembershipTokenGroupService,
    MenuItemPriceSummary,
    Organization,
    ToastService
} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-membership-token-group',
    templateUrl: './edit-membership-token-group.component.html',
    styleUrls: ['./edit-membership-token-group.component.scss'],
})
export class EditMembershipTokenGroupComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organization: Organization;
    @Input() tokenGroup: MembershipTokenGroup;
    formGroup: FormGroup;
    partial = true;

    priceSummaries: MenuItemPriceSummary[];
    itemFilter = '';
    filteredItems = [];

    constructor(
        modalController: ModalController,
        private membershipTokenGroupService: MembershipTokenGroupService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        const roles = [];
        this.formGroup = this.formBuilder.group({
            id: !!this.tokenGroup ? this.tokenGroup.id : null,
            parentId: this.organization.id,
            name: [
                !!this.tokenGroup ? this.tokenGroup.name : '',
                Validators.required
            ],
            description: !!this.tokenGroup ? this.tokenGroup.description : '',
            tokenCount: [!!this.tokenGroup ? this.tokenGroup.tokenCount : '', Validators.required],
            limitDurationPart: !!this.tokenGroup ? this.tokenGroup.limitDurationPart : 'TAB',
            limitDurationCount: !!this.tokenGroup ? this.tokenGroup.limitDurationCount : '',
            limitCount: !!this.tokenGroup ? this.tokenGroup.limitCount : '',
        });

        this.priceSummaries = this.priceSummaries.map(item => {
            const container = new MenuItemPriceSummary({...item});
            container.qualified = !!this.tokenGroup && !!this.tokenGroup.items
                && this.tokenGroup.items.some(q => q.itemId === item.itemId && q.priceId === item.priceId);

            return container;
        });
        this.filteredItems = [...this.priceSummaries];
    }

    filterItems() {
        this.filteredItems = !!this.itemFilter ? this.priceSummaries.filter((p: any) =>
                (!!p.itemName && p.itemName.toLowerCase().includes(this.itemFilter.toLowerCase()))
                || (!!p.priceName && p.priceName.toLowerCase().includes(this.itemFilter.toLowerCase())))
            : [...this.priceSummaries];
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const tokenGroup = new MembershipTokenGroup(this.formGroup.value);

            tokenGroup.items = this.priceSummaries.filter(item => item.qualified);

            this.loadingService.present();
            if (!tokenGroup.id) {
                this.membershipTokenGroupService.create(tokenGroup).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Token group created.');
                    this.close(new MembershipTokenGroup(c));
                });
            } else {
                this.membershipTokenGroupService.update(tokenGroup).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Token group updated.');
                    this.close(new MembershipTokenGroup(c));
                });
            }
        }
    }

    toggle() {
        if (!this.allSelected()) {
            this.filteredItems.forEach(i => i.qualified = true);
        } else {
            this.filteredItems.forEach(i => i.qualified = false);
        }
    }

    partialSelected() {
        return !!this.filteredItems && this.filteredItems.some(i => i.qualified) && this.filteredItems.some(i => !i.qualified);
    }

    allSelected() {
        return !!this.filteredItems && !this.filteredItems.some(i => !i.qualified);
    }

    durationChanged() {
        if (this.formGroup.get('limitDurationPart').value === 'TAB') {
            this.formGroup.get('limitDurationCount').setValue(null);
        }
    }
}
