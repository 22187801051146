import {Component} from '@angular/core';
import {BaseOrganizationComponent} from '../base-organization.component';
import {
    CashDrawerService,
    EditAddressModalComponent,
    EditOrganizationModalComponent,
    LoadingService,
    OrganizationService,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-organization-details',
    templateUrl: './organization-details.component.html',
    styleUrls: ['./organization-details.component.scss'],
})
export class OrganizationDetailsComponent extends BaseOrganizationComponent {

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private alertController: AlertController,
        private modalController: ModalController,
        private cashDrawerService: CashDrawerService
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    async openChangeOrgInformation() {
        this.loadingService.present();
        this.cashDrawerService.safes(this.organization.id).subscribe(async safes => {
            this.loadingService.dismiss();
            const modal = await this.modalController.create({
                component: EditOrganizationModalComponent,
                cssClass: 'large-modal',
                componentProps: {
                    organization: this.organization,
                    safes
                }
            });

            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.organization = dataReturned.data;
                    this.refreshOrganization();
                }
            });
        });
    }

    async openChangeAddress() {
        const modal = await this.modalController.create({
            component: EditAddressModalComponent,
            componentProps: {
                address: this.organization.address,
                endpoint: this.organizationService.destination,
                parentId: this.organization.id
            }
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshOrganization();
            }
        });
    }
}
