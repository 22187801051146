import {Component, Input, OnInit} from '@angular/core';
import {Availabilities, BaseModalComponent, MenuItemPrice} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-menu-item-price',
    templateUrl: './edit-menu-item-price.component.html',
    styleUrls: ['./edit-menu-item-price.component.scss'],
})
export class EditMenuItemPriceComponent extends BaseModalComponent implements OnInit {
    @Input() menuItemPrice: MenuItemPrice;
    @Input() defaultAvailability = Availabilities.ALL;
    formGroup: FormGroup;
    availability: string;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.availability = this.menuItemPrice ? this.menuItemPrice.availability : this.defaultAvailability;
        this.menuItemPrice = this.menuItemPrice == null ? new MenuItemPrice() : this.menuItemPrice;

        this.formGroup = this.formBuilder.group({
            id: this.menuItemPrice.id,
            ancestorId: this.menuItemPrice.ancestorId,
            availability: this.menuItemPrice.availability == null ? Availabilities.ALL : this.menuItemPrice.availability,
            name: [this.menuItemPrice.name, Validators.required],
            sku: this.menuItemPrice.sku,
            price: [this.menuItemPrice.price == null ? '' : this.menuItemPrice.price.toString(), Validators.required],
            volume: [this.menuItemPrice.volume, Validators.min(0)],
            defaultPrice: this.menuItemPrice.defaultPrice,
            terminalOnly: this.menuItemPrice.terminalOnly,
            variable: this.menuItemPrice.variable,
            sortOrder: this.menuItemPrice.sortOrder,
            percentageOfParent: this.menuItemPrice.percentageOfParent,
            tipPool: this.menuItemPrice.tipPool,
            deposit: this.menuItemPrice.deposit
        });
    }

    async onSubmit() {
        const mip = new MenuItemPrice(this.formGroup.value);
        mip.availability = this.availability;
        await this.close(mip);
    }

    selected(event) {
        this.availability = event.value;
    }
}
