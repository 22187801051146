import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    BaseModalComponent,
    LoadingService,
    Menu,
    MenuItem,
    MenuItemInventory,
    MenuItemInventoryPriceAdjustment,
    MenuItemInventoryService,
    Organization,
    PickupLocation,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-menu-item-inventory',
    templateUrl: './edit-menu-item-inventory.component.html',
    styleUrls: ['./edit-menu-item-inventory.component.scss'],
})
export class EditMenuItemInventoryComponent extends BaseModalComponent implements OnInit, OnDestroy {
    @ViewChild('selectMenuItemInput') set selectMenuItemInput(element) {
        this.input = element.el;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    this.popoverWidth = this.input.offsetWidth;
                });
            });

            this.resizeObserver.observe(this.input);
        }
    }

    @Input() menuItemInventory: MenuItemInventory;
    @Input() pickupLocation: PickupLocation;
    @Input() menu: Menu;
    @Input() organization: Organization;
    @Input() existingInventory: MenuItemInventory[];
    @Input() existingOrganizationInventory: MenuItemInventory[];

    availableItems: MenuItem[];
    availableOrganizationItems: MenuItem[];
    filteredItems: MenuItem[];
    formGroup: FormGroup;
    menuItem: MenuItem;
    existingKegs: MenuItemInventory[];

    allowBackOfHouse = false;

    showOptions = false;
    input: any;
    popoverWidth = 0;
    resizeObserver: ResizeObserver;

    constructor(
        modalController: ModalController,
        private menuItemInventoryService: MenuItemInventoryService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnDestroy(): void {
        if (!!this.resizeObserver) {
            this.resizeObserver.unobserve(this.input);
            this.resizeObserver.disconnect();
        }
    }

    ngOnInit() {
        this.availableItems = !this.menuItemInventory || !this.menuItemInventory.menuItem
            ? this.filterAvailable(this.existingInventory)
            : [];
        this.availableOrganizationItems = !this.menuItemInventory ? this.filterAvailable(this.existingOrganizationInventory) : [];

        if (!!this.pickupLocation) {
            this.existingKegs = this.existingInventory.filter(k => !!k.containerQuantity);
            if (this.menuItemInventory && !!this.menuItemInventory.fillFrom) {
                this.menuItemInventory.fillFrom = this.existingKegs.find(k => k.id === this.menuItemInventory.fillFrom.id);
            }
        }

        this.filteredItems = !!this.menu ? [...this.availableItems] : [];

        if (!!this.menuItemInventory && !!this.menuItemInventory.menuItem) {
            this.menuItem = !!this.menu ? this.menu.menuItems.find(m => m.id === this.menuItemInventory.menuItem.id) :
                this.menuItemInventory.menuItem;
            this.menuItem.allPrices = !this.menuItemInventory.menuItemPrice;
        }

        const selectedRole = this.menuItemInventory && this.menuItemInventory.alertRole ?
            this.organization.roles.find(o => o.id === this.menuItemInventory.alertRole.id) : null;

        this.formGroup = this.formBuilder.group({
            id: !!this.menuItemInventory ? this.menuItemInventory.id : null,
            pickupLocation: this.pickupLocation,
            includeBackOfHouse: false,
            menuItem: [
                !!this.menuItemInventory && !!this.menuItemInventory.menuItem ? this.menuItemInventory.menuItem : null, Validators.required
            ],
            menuItemPrice: [
                !!this.menuItemInventory && !!this.menuItemInventory.menuItemPrice ? this.menuItemInventory.menuItemPrice : null
            ],
            alertRole: selectedRole,
            alertCount: !!this.menuItemInventory ? this.menuItemInventory.alertCount : null,
            byPriceCount: !!this.menuItemInventory ? this.menuItemInventory.byPriceCount : 1,
            isKeg: !!this.menuItemInventory && this.menuItemInventory.containerLabel === 'Keg',
            containerLabel: !!this.menuItemInventory ? this.menuItemInventory.containerLabel : null,
            containerQuantity: !!this.menuItemInventory ? this.menuItemInventory.containerQuantity : null,
            expectedPercentageLost: !!this.menuItemInventory ? this.menuItemInventory.expectedPercentageLost : 0,
            fillFrom: !!this.menuItemInventory ? this.menuItemInventory.fillFrom : '',
            fillQuantity: !!this.menuItemInventory ? this.menuItemInventory.fillQuantity : null,
            productionCost: !!this.menuItemInventory ? this.menuItemInventory.productionCost : null,
            currentCount: [
                !!this.menuItemInventory && !!this.menuItemInventory.menuItem ? this.menuItemInventory.currentCount : null, [
                    Validators.required, Validators.min(0)
                ]
            ],
            mobileCountdownStart: [
                !!this.menuItemInventory ? this.menuItemInventory.mobileCountdownStart : null, [
                    Validators.min(0)
                ]
            ],
            currentCountBackOfHouse: null,
            alertCountBackOfHouse: null,
            label: !!this.menuItemInventory ? this.menuItemInventory.label : null
        });

        if (!!this.menuItemInventory && !!this.menuItemInventory.priceAdjustments && this.menuItemInventory.priceAdjustments.length > 0) {
            this.menuItemInventory.priceAdjustments.forEach(p => {
                this.formGroup.addControl(`price_${p.menuItemPrice.id}`, new FormControl(p.count, [Validators.min(1)]));
                this.formGroup.controls[`price_${p.menuItemPrice.id}`].updateValueAndValidity();
            });
        }

        if (!!this.menuItemInventory && !this.menuItemInventory.id) {
            // this is a copy, treat it as new
            this.menuItemInventory = null;
        }
    }

    filterAvailable(existing: MenuItemInventory[]) {
        const menuItems = !!this.menu ? [...this.menu.menuItems] : [];
        menuItems.forEach(m => m.prices = [...m.prices]);

        const flatUsedPrices = !!existing ? existing.reduce((acc, i) => {
            if (!!i.menuItemPrice) {
                acc.push(i.menuItemPrice);
            }
            return acc;
        }, []) : [];

        menuItems.forEach(mi => mi.prices = mi.prices.filter(p => !flatUsedPrices.some(f => f.id === p.id)));

        return !!this.menu && !!existing ?
            this.menu.menuItems.filter(i => !existing.find(e => e.menuItem.id === i.id
                && (!e.menuItemPrice || i.prices.length === 0)))
            : this.menu.menuItems;
    }

    toggleBackOfHouse() {
        if (this.formGroup.get('includeBackOfHouse').value) {
            this.formGroup.get('currentCountBackOfHouse').setValidators([Validators.required, Validators.min(0)]);
        } else {
            this.formGroup.get('currentCountBackOfHouse').setValidators([]);
        }
        this.formGroup.controls.includeBackOfHouse.updateValueAndValidity();
    }

    async presentPopover() {
        this.showOptions = true;
    }

    async closed(item: MenuItem) {
        this.formGroup.controls.menuItem.setValue(item);

        if (!!this.menuItem && !!this.menuItem.prices && item !== this.menuItem) {
            this.menuItem.prices.forEach(p => {
                this.formGroup.removeControl(`price_${p.id}`);
            });
        }

        this.menuItem = item;
        this.showOptions = false;

        if (!!this.menuItem && !!this.menuItem.prices && this.menuItem.prices.length === 1) {
            this.formGroup.controls.menuItemPrice.setValue(this.menuItem.prices[0]);
            this.menuItem.allPrices = false;
        }

        if (!!item) {
            if (!!this.pickupLocation && !this.menuItemInventory) {
                this.allowBackOfHouse = !!item && !this.existingOrganizationInventory.find(e => e.menuItem.id === item.id);

                if (this.allowBackOfHouse) {
                    this.formGroup.controls.includeBackOfHouse.setValue(false);
                    this.formGroup.controls.currentCountBackOfHouse.setValue(null);
                    this.formGroup.controls.alertCountBackOfHouse.setValue(null);
                }
            }

            if (!!this.pickupLocation) {
                if (this.existingInventory.some(e => e.menuItem.id === item.id && !!e.menuItemPrice)) {
                    this.menuItem.allPrices = false;
                    this.formGroup.get('menuItemPrice').setValidators([Validators.required]);
                } else {
                    this.menuItem.allPrices = true;
                    this.formGroup.get('menuItemPrice').setValidators([]);
                }
            } else {
                if (this.existingOrganizationInventory.some(e => e.menuItem.id === item.id && !!e.menuItemPrice)) {
                    this.menuItem.allPrices = false;
                    this.formGroup.get('menuItemPrice').setValidators([Validators.required]);
                } else {
                    this.menuItem.allPrices = true;
                    this.formGroup.get('menuItemPrice').setValidators([]);
                }
            }

            this.formGroup.get('byPriceCount').setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.formGroup.get('byPriceCount').setValidators([]);
        }

        this.formGroup.controls.alertRole.updateValueAndValidity();

        if (!!this.menuItem && !!this.menuItem.prices) {
            this.menuItem.prices.forEach(p => {
                const units = this.formGroup.controls.isKeg.value ? p.volume : 1;
                this.formGroup.addControl(`price_${p.id}`, new FormControl(units, [Validators.min(1)]));
                this.formGroup.controls[`price_${p.id}`].updateValueAndValidity();
            });
        }
    }

    getMenuItemName() {
        let name = !!this.menuItem ? this.menuItem.name : '';
        if (!!this.menuItem && !!this.menuItem.labels && this.menuItem.labels.length > 0) {
            name += ' (';
            let first = true;
            this.menuItem.labels.forEach(l => {
                if (!first) {
                    name += ', ';
                }
                name += l.name;
                first = false;
            });
            name += ')';
        }
        return name;
    }

    filter(s: string) {
        this.filteredItems = !!this.menu ?
            this.availableItems.filter((item: any) => item.name.toLowerCase().includes(s.toLowerCase())
                || item.labels.some(l => l.name.toLowerCase().includes(s.toLowerCase())))
            : [...this.menu.menuItems];
    }

    fillFromChange() {
        if (!!this.formGroup.controls.fillFrom.value) {
            this.formGroup.controls.fillQuantity.setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.controls.fillQuantity.updateValueAndValidity();

            this.formGroup.controls.byPriceCount.setValue(1);
            this.formGroup.controls.byPriceCount.updateValueAndValidity();
        } else {
            this.formGroup.controls.fillQuantity.setValidators([]);
            this.formGroup.controls.fillQuantity.updateValueAndValidity();
        }
    }

    kegToggle() {
        if (this.formGroup.controls.isKeg.value) {
            this.formGroup.controls.containerLabel.setValue('Keg');
            this.formGroup.controls.containerLabel.updateValueAndValidity();

            this.formGroup.controls.label.setValue('Ounce');
            this.formGroup.controls.label.updateValueAndValidity();

            this.formGroup.controls.menuItemPrice.setValue(null);
            this.formGroup.controls.menuItemPrice.updateValueAndValidity();

            this.formGroup.controls.containerQuantity.setValue(1984);
            this.formGroup.controls.containerQuantity.updateValueAndValidity();

            if (!!this.formGroup.controls.pickupLocation.value) {
                this.formGroup.controls.currentCount.setValue(1984);
                this.formGroup.controls.currentCount.updateValueAndValidity();
            }

            if (!!this.menuItem && this.menuItem.prices) {
                this.menuItem.prices.forEach(p => {
                    const control = this.formGroup.get(`price_${p.id}`);
                    const ounces = p.volume;
                    control.setValue(ounces);
                    control.updateValueAndValidity();
                });
            }
        } else {
            this.formGroup.controls.containerLabel.setValue(null);
            this.formGroup.controls.containerLabel.updateValueAndValidity();

            this.formGroup.controls.containerQuantity.setValue(null);
            this.formGroup.controls.containerQuantity.updateValueAndValidity();
        }
    }

    onSubmit() {
        const menuItemInventory = new MenuItemInventory(this.formGroup.value);
        menuItemInventory.priceAdjustments = [];

        if (!menuItemInventory.fillFrom) {
            menuItemInventory.fillFrom = null;
        }

        if (!menuItemInventory.menuItemPrice && !!this.menuItem && !!this.menuItem.prices) {
            this.menuItem.prices.forEach(p => {
                const adjustment = new MenuItemInventoryPriceAdjustment();
                adjustment.menuItemPrice = p;
                adjustment.count = this.formGroup.controls[`price_${p.id}`].value;

                if (!!this.menuItemInventory) {
                    const existing = this.menuItemInventory.priceAdjustments.find(a => a.menuItemPrice.id === p.id);
                    if (!!existing) {
                        adjustment.id = existing.id;
                    }
                }
                menuItemInventory.priceAdjustments.push(adjustment);
            });
        }

        if (!menuItemInventory.id && !!menuItemInventory.pickupLocation && !!menuItemInventory.containerQuantity) {
            menuItemInventory.currentCount = menuItemInventory.containerQuantity;
        }

        this.loadingService.present();
        if (!!menuItemInventory.id) {
            this.menuItemInventoryService.update(menuItemInventory).subscribe(m => {
                this.loadingService.dismiss();
                this.close(m);
            });
        } else {
            this.menuItemInventoryService.create(menuItemInventory).subscribe(m => {
                this.loadingService.dismiss();
                this.close(m);
            }, (error) => {
                if (error.status === 409) {
                    this.toastService.error('Inventory already exists for this item.');
                }
            });
        }
    }
}
