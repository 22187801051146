import {Component, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
    Address,
    AddressService,
    BaseStepperFormComponent,
    LoadingService,
    Organization,
    OrganizationService,
    ToastService,
    Venue,
    VenueMapComponent
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
    selector: 'app-register-organization',
    templateUrl: './register-organization.component.html',
    styleUrls: ['./register-organization.component.scss'],
})
export class RegisterOrganizationComponent extends BaseStepperFormComponent {
    @Input() venue: Venue;
    @ViewChild('venueMap') venueMap: VenueMapComponent;

    orgFormGroup: FormGroup = new FormGroup({});
    addressFormGroup: FormGroup = new FormGroup({});
    personFormGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private addressService: AddressService,
        private loadingService: LoadingService,
        private organizationService: OrganizationService,
        private alertController: AlertController,
        private router: Router,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    orgFormInitialized(form: FormGroup) {
        this.orgFormGroup = form;
    }

    addressFormInitialized(form: FormGroup) {
        this.addressFormGroup = form;
    }

    personFormInitialized(form: FormGroup) {
        this.personFormGroup = form;
    }

    valid() {
        return this.orgFormGroup.valid
            && this.personFormGroup.valid
            && ((!this.venue && this.addressFormGroup.valid)
                || !!this.venue);
    }

    async onSubmit() {
        if (!this.valid()) {
            return;
        }

        const org: Organization = this.orgFormGroup.value;

        org.owner = this.personFormGroup.value;

        if (!this.venue) {
            org.address = this.addressFormGroup.value;
            this.addressService.getGeocoder().geocode({
                address: this.addressService.fullAddress(org.address)
            }, async (results, status) => {
                // @ts-ignore
                if (status === google.maps.GeocoderStatus.OK && results[0].geometry.location_type === 'ROOFTOP') {
                    org.address.latitude = results[0].geometry.location.lat();
                    org.address.longitude = results[0].geometry.location.lng();
                    await this.register(org);
                } else {
                    const alert = await this.alertController.create({
                        cssClass: 'brewbill-alert',
                        header: 'Confirm',
                        message: 'The address was not found. Would you like to proceed anyway?',
                        buttons: [
                            {
                                text: 'No',
                                role: 'cancel',
                                cssClass: 'secondary',
                            }, {
                                text: 'Yes',
                                handler: () => {
                                    org.address.latitude = null;
                                    org.address.longitude = null;
                                    this.register(org);
                                }
                            }
                        ]
                    });

                    await alert.present();
                }
            });
        } else {
            org.venue = this.venue;
            org.address = new Address({...this.venue.address});
            org.address.id = null;
            org.description = '';

            await this.register(org);
        }
    }

    async register(org: Organization) {
        this.loadingService.present();

        this.organizationService.create(org).subscribe(async (o: Organization) => {
            this.toastService.success('Organization created.');
            this.close(o);
            this.loadingService.dismiss();
        });
    }
}
