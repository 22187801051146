import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    Feature,
    Features,
    LoadingService,
    Organization,
    OrganizationRole,
    OrganizationRoleService,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-organization-role',
    templateUrl: './edit-organization-role.component.html',
    styleUrls: ['./edit-organization-role.component.scss'],
})
export class EditOrganizationRoleComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organizationRole: OrganizationRole;
    @Input() organization: Organization;

    features = [];
    itemFilter = '';
    filteredItems: { feature: Feature, selected: boolean }[];
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private organizationRoleService: OrganizationRoleService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        const tmpFeatures = Features.filter(f => !f.global).sort((a, b) => a.description.localeCompare(b.description));
        tmpFeatures.forEach(feature => {
            this.features.push({
                feature,
                selected: !!this.organizationRole.features && this.organizationRole.features.some(f => f.key === feature.key)
            });
        });

        this.filterItems();
        this.formGroup = this.formBuilder.group({
            id: this.organizationRole.id,
            parentId: this.organization.id,
            name: [this.organizationRole.name, [Validators.required, Validators.maxLength(250)]],
            description: [this.organizationRole.description, Validators.maxLength(2000)]
        });
    }

    filterItems() {
        if (!!this.features) {
            this.filteredItems = !!this.itemFilter ? this.features.filter((item: any) => item.feature.description.toLowerCase()
                    .includes(this.itemFilter.toLowerCase()))
                : [...this.features];
        }
    }

    onSubmit() {
        const role = new OrganizationRole(this.formGroup.value);
        role.features = this.features.filter(f => f.selected).map(f => f.feature);

        this.loadingService.present();

        if (role.id != null) {
            this.organizationRoleService.update(role).subscribe((p) => {
                    this.toastService.success('Role updated.');
                    this.loadingService.dismiss();
                    this.close(role);
                }
            );
        } else {
            this.organizationRoleService.create(role).subscribe((p) => {
                    this.toastService.success('Role added.');
                    this.loadingService.dismiss();
                    this.close(role);
                }
            );
        }
    }

    valid() {
        return this.formGroup.valid;
    }

    toggle() {
        if (!this.allSelected()) {
            this.filteredItems.forEach(i => i.selected = true);
        } else {
            this.filteredItems.forEach(i => i.selected = false);
        }
    }

    partialSelected() {
        return !!this.filteredItems
            && this.filteredItems.some(i => i.selected)
            && this.filteredItems.some(i => !i.selected);
    }

    allSelected() {
        return !!this.filteredItems && !this.filteredItems.some(i => !i.selected);
    }
}
