import {Component, OnInit} from '@angular/core';
import {BaseOrganizationComponent} from '../base-organization.component';
import {
    LoadingService,
    MerchantAccount,
    MerchantAccountService,
    Organization,
    OrganizationPreference,
    OrganizationPreferenceService,
    OrganizationService,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {OrganizationPreferenceComponent} from '../organization-preference/organization-preference.component';
import {EditMerchantAccountComponent} from '../edit-merchant-account/edit-merchant-account.component';

@Component({
    selector: 'bb-organization-settings',
    templateUrl: './organization-settings.component.html',
    styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent extends BaseOrganizationComponent implements OnInit {
    organizationPreference: OrganizationPreference;
    merchantAccount: MerchantAccount;

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private alertController: AlertController,
        private modalController: ModalController,
        private merchantAccountService: MerchantAccountService,
        private organizationPreferenceService: OrganizationPreferenceService,
        private navController: NavController
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.referesh();
    }

    referesh() {
        this.organizationPreferenceService.findByOrganizationId(this.organization.id)
            .subscribe(async (op: OrganizationPreference) => {
                this.organizationPreference = op;
            });

        this.merchantAccountService.findByOrganizationId(this.organization.id)
            .subscribe(async (ma: MerchantAccount) => {
                this.merchantAccount = ma;
            });
    }

    async confirmDeleteOrganization() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: 'Are you sure you want to delete this organization? This action cannot be undone.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.deleteOrganization();
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmActivateOrganization() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Activate',
            message: 'Are you sure you want to make this organization active?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.updateOrganizationStatus('ACTIVE');
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmDeactivateOrganization() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Deactivate',
            message: 'Are you sure you want to make this organization inactive?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.updateOrganizationStatus('INACTIVE');
                    }
                }
            ]
        });

        await alert.present();
    }

    updateOrganizationStatus(status: string) {
        this.loadingService.present();
        this.organizationService.updateStatus(this.organization.id, status).subscribe(organization => {
            this.organizationService.setCurrent(new Organization(organization));
            this.loadingService.dismiss();
        });
    }

    deleteOrganization() {
        this.loadingService.present();
        this.organizationService.delete(this.organization.id).subscribe(async () => {
            if (!!this.organization.venueId) {
                await this.navController.navigateBack(`/venue/${this.organization.venueId}`);
            } else {
                await this.navController.navigateBack('/organizations');
            }
            this.loadingService.dismiss();
        });
    }

    async changePreferences() {
        this.loadingService.present();
        this.organizationPreferenceService.findByOrganizationId(this.organization.id)
            .subscribe(async (organizationPreference: OrganizationPreference) => {
                const modal = await this.modalController.create({
                    component: OrganizationPreferenceComponent,
                    componentProps: {
                        organizationPreference
                    }
                });

                this.loadingService.dismiss();

                modal.onDidDismiss().then(dataReturned => {
                    if (!!dataReturned && !!dataReturned.data) {
                        this.referesh();
                    }
                });

                await modal.present();
            });
    }

    async openEditMerchantAccount() {
        const modal = await this.modalController.create({
            component: EditMerchantAccountComponent,
            componentProps: {
                merchantAccount: this.merchantAccount,
                organization: this.organization
            }
        });

        this.loadingService.dismiss();
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.merchantAccount = dataReturned.data;
            }
        });
    }
}
