import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
    addCurrency,
    BaseModalComponent,
    CashAudit,
    CashAuditActions,
    CashAuditEvents,
    CashAuditService,
    CashDrawer,
    CashDrawerService,
    LoadingService,
    Order,
    OrderService,
    PickupLocation,
    PickupLocationService,
    PrinterService,
    subtractCurrency,
    Tab,
    TabService,
    ToastService
} from 'brewbill-lib';
import {CurrencyPipe} from '@angular/common';
import {AssignCashDrawerComponent} from '../assign-cash-drawer/assign-cash-drawer.component';

@Component({
    selector: 'bb-cash-payment',
    templateUrl: './cash-payment.component.html',
    styleUrls: ['./cash-payment.component.scss'],
})
export class CashPaymentComponent extends BaseModalComponent implements OnInit {
    @Input() tab: Tab;
    @Input() silent = false;

    order: Order;
    formGroup: FormGroup = new FormGroup({});
    cashDrawer: CashDrawer;
    pickupLocation: PickupLocation;
    due: number;
    totalDue: number;
    closing = false;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private pickupLocationService: PickupLocationService,
        private toastService: ToastService,
        private cashDrawerService: CashDrawerService,
        private orderService: OrderService,
        private tabService: TabService,
        private cashAuditService: CashAuditService,
        private currencyPipe: CurrencyPipe,
        private printerService: PrinterService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.order = this.orderService.currentValue;
        this.cashDrawer = this.cashDrawerService.currentValue;
        this.adjustIncludedTaxes();

        const amount = !!this.totalDue ? this.totalDue : this.due;

        this.formGroup = this.formBuilder.group(
            {
                tabId: this.tab.id,
                amount: [
                    Number.parseFloat(amount.toFixed(2)),
                    [Validators.max(amount), Validators.min(0)]
                ],
                received: ['', [Validators.required, Validators.min(0)]],
                tip: '',
                action: [CashAuditActions.IN, Validators.required],
                event: [CashAuditEvents.PAYMENT, Validators.required]
            }
        );

        this.subscribe(this.orderService.current.subscribe(o => {
            if (!this.closing) {
                this.order = !!o ? new Order(o) : null;
            }
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => this.pickupLocation = !!p ? new PickupLocation(p) : null));

        this.subscribe(this.cashDrawerService.current.subscribe(c => {
            this.cashDrawer = !!c ? new CashDrawer(c) : null;
            if (!this.cashDrawer && !this.closing) {
                this.close();
            } else {
                if (!this.cashDrawer.trackCashTips) {
                    this.formGroup.controls.tip.setValue(0);
                }
            }
        }));
    }

    adjustIncludedTaxes() {
        let d = this.tab.paymentInformation.remaining;
        if (!!this.tab.taxes && !this.tab.taxExemptionId) {
            this.tab.orders.forEach(o => {
                o.items.forEach(oi => {
                    if (!!oi.menuItem && !!oi.taxes && oi.taxes.length > 0) {
                        oi.taxes.filter(t => !t.taxIncluded).forEach(tax => {
                            if (!oi.menuItem.taxOverride && tax.taxIncludedCash &&
                                (this.tab.paymentInformation.paymentType === 'NONE'
                                    || this.tab.paymentInformation.paymentType === 'CASH'
                                    || (this.tab.paymentInformation.paymentType === 'CHARGE'
                                        && this.tab.paymentInformation.chargePaymentsTotal === 0))) {
                                d = subtractCurrency(d, tax.tax);
                            }
                        });
                    }
                });
            });
        }
        this.due = d;

        if (!!this.tab.paymentInformation.depositsRemaining) {
            this.totalDue = addCurrency(this.due, this.tab.paymentInformation.depositsRemaining);
        }
    }

    exact() {
        this.quickCash();
        this.addAction();
    }

    addAction() {
        this.loadingService.present();
        const audit = new CashAudit(this.formGroup.value);
        audit.parentId = this.cashDrawer.id;

        const principal = subtractCurrency(audit.received, audit.tip);

        if (principal >= audit.amount && !!this.tab.paymentInformation.depositsRemaining) {
            audit.deposit = this.tab.paymentInformation.depositsRemaining;
            audit.amount = subtractCurrency(audit.amount, audit.deposit);
        } else if (principal < audit.amount) {
            audit.amount = principal;
        }

        audit.fullPayment = audit.amount === this.due;
        audit.change = this.change();

        if (this.printerService.isConnected()) {
            this.printerService.openDrawer();
        }

        this.close(audit);
    }

    change() {
        const change =
            subtractCurrency(this.formGroup.controls.received.value,
                addCurrency(this.formGroup.controls.amount.value, this.formGroup.controls.tip.value));
        return !!this.formGroup.controls.received.value && change > 0 ? change : 0;
    }

    changeAsTip() {
        this.formGroup.controls.tip.setValue(addCurrency(this.formGroup.controls.tip.value, this.change()).toFixed(2));
    }

    quickCash() {
        this.formGroup.controls.received.setValue(this.formGroup.controls.amount.value);
    }

    async changeDrawer() {
        const modal = await this.modalController.create({
            component: AssignCashDrawerComponent,
            componentProps: {
                pickupLocation: this.pickupLocation
            }
        });

        await modal.present();
    }
}
