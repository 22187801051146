import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, CashDrawer, CashDrawerService, CashSession, LoadingService, Organization, ToastService} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-edit-cash-drawer',
    templateUrl: './edit-cash-drawer.component.html',
    styleUrls: ['./edit-cash-drawer.component.scss'],
})
export class EditCashDrawerComponent extends BaseModalComponent implements OnInit {
    @Input() cashDrawer: CashDrawer;
    @Input() cashSession: CashSession;
    @Input() organization: Organization;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private cashDrawerService: CashDrawerService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        let name = !!this.cashDrawer && !!this.cashDrawer.name ? this.cashDrawer.name : null;
        if (!name) {
            name = !!this.cashSession ? `Drawer ${this.cashSession.cashDrawers.length + 1}` : 'Safe';
        }

        this.formGroup = this.formBuilder.group({
            id: !!this.cashDrawer && !!this.cashDrawer.id ? this.cashDrawer.id : null,
            parentId: !!this.cashSession ? this.cashSession.id : null,
            organizationId: !this.cashSession ? this.organization.id : null,
            name: [name, Validators.required],
            safeAccess: !this.cashSession && !!this.cashDrawer ? this.cashDrawer.safeAccess : 'ALL',
            trackCashTips: !!this.cashDrawer ? this.cashDrawer.trackCashTips : false,
            pickupLocation: [
                !!this.cashDrawer ? this.cashDrawer.pickupLocation : '', Validators.required
            ],
            startAmount: ['', !this.cashDrawer ? Validators.required : null]
        });

        if (!!this.cashSession || (!!this.cashDrawer && !!this.cashDrawer.pickupLocation)) {
            this.formGroup.get('pickupLocation').setValidators([Validators.required]);
            if (!!this.cashDrawer) {
                this.formGroup.controls.pickupLocation.setValue(
                    this.organization.pickupLocations.find(p => p.id === this.cashDrawer.pickupLocation.id));
            } else {
                this.formGroup.controls.pickupLocation.setValue(
                    this.organization.pickupLocations.length === 1 ? this.organization.pickupLocations[0] : '');
            }
        } else {
            this.formGroup.get('pickupLocation').setValidators([]);
            this.formGroup.controls.pickupLocation.setValue(null);
        }
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const cashDrawer = new CashDrawer(this.formGroup.value);
            this.loadingService.present();
            if (!cashDrawer.id) {
                this.cashDrawerService.create(cashDrawer).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success(!!this.cashSession ? 'Cash drawer created.' : 'Safe created.');
                    this.close(new CashDrawer(c));
                }, async (error) => {
                    if (error.status === 412) {
                        await this.toastService.error('The cash session is no longer active.');
                        await this.close();
                    }
                });
            } else {
                this.cashDrawerService.update(cashDrawer).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success(!!this.cashDrawer.parentId ? 'Cash drawer updated.' : 'Safe updated.');
                    this.close(new CashDrawer(c));
                }, async (error) => {
                    if (error.status === 412) {
                        await this.toastService.error('The cash session is no longer active.');
                        await this.close();
                    }
                });
            }
        }
    }
}
