import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    LoadingService,
    Menu,
    MenuItem,
    MenuSelection,
    MenuSelectionItem,
    MenuSelectionPrice,
    MenuSelectionService,
    reorder,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {SelectMenuSelectionItemsComponent} from '../select-menu-selection-items/select-menu-selection-items.component';

@Component({
    selector: 'bb-edit-menu-selection',
    templateUrl: './edit-menu-selection.component.html',
    styleUrls: ['./edit-menu-selection.component.scss'],
})
export class EditMenuSelectionComponent extends BaseStepperFormComponent implements OnInit {
    @Input() menuSelection: MenuSelection;
    @Input() menu: Menu;
    @Input() addMenuItem: any;

    prices: MenuSelectionPrice[] = [];
    selectedItems: MenuSelectionItem[] = [];
    filteredItems: any[] = [];
    informationFormGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private menuSelectionService: MenuSelectionService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuSelection = this.menuSelection == null ? new MenuSelection() : this.menuSelection;
        this.selectedItems = this.menuSelection.options == null ? [] : [...this.menuSelection.options];
        this.prices = this.menuSelection.prices == null ? [] : [...this.menuSelection.prices];

        if (this.prices.length === 0) {
            const price = new MenuSelectionPrice();
            price.price = 0;
            price.countMin = 1;
            this.prices = [price];
        }

        this.informationFormGroup = this.formBuilder.group(
            {
                id: this.menuSelection.id,
                parentId: this.menu.id,
                name: [this.menuSelection.name, Validators.required],
                minCount: this.menuSelection.minCount,
                maxCount: this.menuSelection.maxCount,
                required: this.menuSelection.required,
                description: this.menuSelection.description
            }
        );
    }

    changeMin(menuSelectionPrice: MenuSelectionPrice) {
        const index = this.prices.indexOf(menuSelectionPrice);
        if (index === 0) {
            menuSelectionPrice.countMin = 1;
        }

        if (menuSelectionPrice.countMin > menuSelectionPrice.countMax) {
            menuSelectionPrice.countMax = menuSelectionPrice.countMin;
            this.changeMax(menuSelectionPrice);
        }

        if (index - 1 >= 0) {
            const previous = this.prices[index - 1];
            if (previous.countMin >= menuSelectionPrice.countMin) {
                menuSelectionPrice.countMin = previous.countMin;
                this.removePrice(previous);
            } else {
                previous.countMax = menuSelectionPrice.countMin - 1;
                if (previous.countMax < previous.countMin) {
                    previous.countMin = previous.countMax;
                    this.changeMin(previous);
                }
            }
        }
    }

    changeMax(menuSelectionPrice: MenuSelectionPrice) {
        const index = this.prices.indexOf(menuSelectionPrice);
        if (menuSelectionPrice.countMax == null) {
            this.prices.splice(index + 1, this.prices.length - index);
        } else {
            if (menuSelectionPrice.countMax < menuSelectionPrice.countMin) {
                menuSelectionPrice.countMin = menuSelectionPrice.countMax;
                this.changeMin(menuSelectionPrice);
            }

            if (index + 1 < this.prices.length) {
                this.prices[index + 1].countMin = menuSelectionPrice.countMax + 1;
                if (this.prices[index + 1].countMin > this.prices[index + 1].countMax) {
                    this.prices[index + 1].countMax = this.prices[index + 1].countMin;
                    this.changeMax(this.prices[index + 1]);
                }
            }
        }
    }

    createPrice() {
        const lastPrice = this.prices[this.prices.length - 1];
        const price = new MenuSelectionPrice();
        price.countMin = lastPrice.countMax != null ? lastPrice.countMax + 1 : lastPrice.countMin + 1;
        if (lastPrice.countMax == null) {
            lastPrice.countMax = lastPrice.countMin;
        }

        price.countMax = null;
        this.prices.push(price);
    }

    removePrice(menuSelectionPrice: MenuSelectionPrice) {
        if (this.prices.length > 1) {
            const index = this.prices.indexOf(menuSelectionPrice);
            if (index === 0) {
                this.prices[index + 1].countMin = menuSelectionPrice.countMin;
            } else {
                this.prices[index - 1].countMax = menuSelectionPrice.countMax;
            }
            this.prices.splice(index, 1);
        }
    }

    selectItem(item: MenuItem) {
        if (this.selectedItems.find(obj => obj.menuItem.id === item.id) == null) {
            const menuSelectionItem = new MenuSelectionItem();
            menuSelectionItem.menuItem = item;
            if (!!item.prices && item.prices.length > 0) {
                menuSelectionItem.menuItemPrice = item.prices.find(p => p.defaultPrice);
                if (!menuSelectionItem.menuItemPrice) {
                    menuSelectionItem.menuItemPrice = item.prices[0];
                }
            }
            menuSelectionItem.sortOrder = this.selectedItems.length + 1;
            this.selectedItems = [...this.selectedItems, menuSelectionItem];
        }
    }

    updateSortOrder(list: MenuSelectionItem[]) {
        this.selectedItems = list;
    }

    async openSelectMenuItems() {
        const modal = await this.modalController.create({
            component: SelectMenuSelectionItemsComponent,
            componentProps: {
                menu: this.menu,
                selected: this.selectedItems
            },
            cssClass: 'menu-modal'
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.selectedItems = dataReturned.data;
            }
        });
    }

    async openAddMenuItem() {
        const l = await this.addMenuItem(null);
        if (l != null) {
            this.selectItem(l);
        }
    }

    remove(item: any) {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    }

    reorder(event) {
        reorder(this.selectedItems, event);
    }

    valid() {
        return this.informationFormGroup.valid && this.validPrices() && this.selectedItems.length > 0;
    }

    validPrices() {
        return this.prices.length > 0
            && this.prices.filter(s => !!s.price || s.price === 0).length === this.prices.length;
    }

    onSubmit() {
        this.loadingService.present();
        const selection: MenuSelection = new MenuSelection(this.informationFormGroup.value);
        selection.options = this.selectedItems;
        selection.prices = this.prices;

        if (selection.id != null) {
            this.menuSelectionService.update(selection).subscribe(
                (sel: MenuSelection) => {
                    this.toastService.success(`${selection.name} updated.`);
                    this.loadingService.dismiss();
                    this.close(sel);
                }, (error) => this.errorHandler(error)
            );
        } else {
            this.menuSelectionService.create(selection).subscribe(
                (sel: MenuSelection) => {
                    this.toastService.success(`${selection.name} added.`);
                    this.loadingService.dismiss();
                    this.close(sel);
                }, (error) => this.errorHandler(error)
            );
        }
    }

    errorHandler(error) {
        this.loadingService.dismiss();
        if (error.status === 424) {
            this.toastService.error('Failed dependency.');
        } else if (error.status === 409) {
            this.toastService.error('The selection cannot contain any items that also include this selection.');
        } else {
            this.toastService.error('A server error occurred.');
        }
    }
}
