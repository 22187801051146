import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {BaseModalComponent, LoadingService, Membership, MembershipRenewal, MembershipRenewalService, ToastService} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-membership-renewal',
    templateUrl: './edit-membership-renewal.component.html',
    styleUrls: ['./edit-membership-renewal.component.scss'],
})
export class EditMembershipRenewalComponent extends BaseModalComponent implements OnInit {
    @Input() renewal: MembershipRenewal;
    @Input() member: Membership;

    formGroup: FormGroup;

    constructor(
        private membershipRenewalService: MembershipRenewalService,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            id: !!this.renewal ? this.renewal.id : null,
            parentId: this.member.id,
            startDate: [!!this.renewal ? this.renewal.startDate : null, Validators.required],
            endDate: [!!this.renewal ? this.renewal.endDate : null, Validators.required]
        });
    }

    async onSubmit() {
        const m = new MembershipRenewal(this.formGroup.value);

        this.loadingService.present();
        if (!!m.id) {
            this.membershipRenewalService.update(m).subscribe(
                async (renewal: MembershipRenewal) => {
                    this.loadingService.dismiss();
                    await this.toastService.success('Renewal information updated.');
                    await this.close(renewal);
                }
            );
        } else {
            this.membershipRenewalService.create(m).subscribe(
                async (renewal: MembershipRenewal) => {
                    this.loadingService.dismiss();
                    await this.toastService.success('Renewal information created.');
                    await this.close(renewal);
                }
            );
        }
    }

    setValue(field, val) {
        this.formGroup.get(field).setValue(val);
    }
}
