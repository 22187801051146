import {Component, Inject, OnInit, Renderer2} from '@angular/core';

import {AlertController, MenuController, NavController, Platform} from '@ionic/angular';
import {
    AuthenticationService,
    BaseAppComponent,
    CashSession,
    CashSessionService,
    Constants,
    DeviceService,
    FeatureKeys,
    GlobalPayService,
    LibConfig,
    LibConfigService,
    LoadingService,
    Order,
    OrderService,
    Organization,
    OrganizationService,
    OrganizationTerminalService,
    PickupLocation,
    PickupLocationService,
    PrinterService,
    ScriptService,
    Tab,
    TabService,
    Timecard,
    TimecardService,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';
import {Router} from '@angular/router';
import {EventService} from './_services/event.service';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {Capacitor} from '@capacitor/core';
import {VersionService} from './_services/version-service';
import {DOCUMENT} from '@angular/common';
import {Network} from '@capacitor/network';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseAppComponent implements OnInit {
    public appMenu = [
        {title: 'Account', url: '/account', icon: 'person'},
        {title: 'Privacy Policy', url: '/privacy-policy', icon: 'clipboard'},
    ];

    // @ViewChild('imageSource') set imageSource(image: HTMLImageElement) {
    //     if (!!image) {
    //         this.loadImage();
    //     }
    // }

    public currentOrganization: Organization;
    public currentPickupLocation: PickupLocation;

    appVersion: string;
    pushTok: string;
    trainingModeMove = false;
    checkVersionTimeout;
    networkConnected = true;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private toastService: ToastService,
        private alertController: AlertController,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private cashSessionService: CashSessionService,
        private orderService: OrderService,
        private tabService: TabService,
        private eventService: EventService,
        private organizationTerminalService: OrganizationTerminalService,
        private printerService: PrinterService,
        private timecardService: TimecardService,
        private versionService: VersionService,
        @Inject(DOCUMENT) document: Document,
        platform: Platform,
        authenticationService: AuthenticationService,
        userService: UserService,
        router: Router,
        navController: NavController,
        loadingService: LoadingService,
        deviceService: DeviceService,
        menu: MenuController,
        globalPayService: GlobalPayService,
        renderer: Renderer2,
        scriptService: ScriptService
    ) {
        super(
            document,
            platform,
            authenticationService,
            userService,
            router,
            navController,
            loadingService,
            deviceService,
            menu,
            globalPayService,
            renderer,
            scriptService
        );

        this.appVersion = config.appVersion;
        this.tabService.saveToLocalStorage = false;
        this.orderService.saveToLocalStorage = false;
        this.organizationTerminalService.isTerminal = true;
    }

    ngOnInit() {
        this.subscribe(this.organizationService.current.subscribe(async o => {
            if (!!o) {
                if (!this.currentOrganization || this.currentOrganization.id !== o.id || this.currentOrganization.testMode !== o.testMode) {
                    this.organizationService.getToken(o.id).subscribe((t: any) => {
                        this.organizationService.setOrganizationToken(t.response);
                    });

                    this.currentOrganization = new Organization(o);

                    if (!!this.currentUser && this.currentUser.authorizedTo(FeatureKeys.TERMINAL, this.currentOrganization.id)) {
                        this.organizationService.getOpenTabs(o.id)
                            .subscribe((tabs: Tab[]) => this.tabService.setOpenTabs(tabs));
                    }

                    this.getActiveTimeCard();

                    this.cashSessionService.findOpen(o.id)
                        .subscribe((c: CashSession) => {
                            this.cashSessionService.load(c);
                        });

                } else {
                    this.currentOrganization = new Organization(o);
                }
            }
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(async p => {
            if (!!p) {
                if (!this.currentPickupLocation || this.currentPickupLocation.id !== p.id) {
                    if (!!this.pushTok) {
                        this.pickupLocationService.saveDevice(p.id, this.pushTok);
                    }

                    if (!!this.currentUser
                        && !!this.currentOrganization
                        && this.currentUser.authorizedTo(FeatureKeys.TERMINAL, this.currentOrganization.id)) {
                        this.pickupLocationService.getActiveOrders(p.id).subscribe((ao: Order[]) => {
                            this.orderService.setActiveOrders(ao);
                        });
                    }

                    this.currentPickupLocation = p;
                }
            } else {
                this.orderService.setActiveOrders([]);
                this.tabService.setOpenTabs([]);
            }
        }));

        this.platform.pause.subscribe(() => {
            if (!!this.checkVersionTimeout) {
                clearTimeout(this.checkVersionTimeout);
            }
        });

        this.platform.resume.subscribe(() => {
            this.checkVersion(false);
        });

        this.checkVersion(false);
    }

    async logout() {
        await this.authenticationService.logout();
    }

    async init(user: User) {
        if (user && user.token) {
            let hash = location.hash;
            if (hash.indexOf('?returnUrl=') > -1) {
                hash = hash.substring(0, hash.indexOf('?'));
            }
            this.platform.ready().then(async () => {
                this.printerService.connectViaBlueTooth();

                Network.addListener('networkStatusChange', status => {
                    if (!!status && !this.networkConnected && status.connected) {
                        console.log('Network status changed', status);
                        this.eventService.refreshApp();
                    }
                    this.networkConnected = status.connected;
                });

                if (Capacitor.isPluginAvailable('PushNotifications')) {
                    PushNotifications.requestPermissions().then(result => {
                        if (result.receive === 'granted') {
                            // Register with Apple / Google to receive push via APNS/FCM
                            PushNotifications.register();
                        }
                    });

                    PushNotifications.addListener(
                        'pushNotificationReceived',
                        (notification: PushNotificationSchema) => {
                            console.log('Push received', notification);
                            // if (!!notification.data && !!notification.data.orderId) {
                            //     this.toastService.message('Order In', 'warning');
                            // }
                        });

                    PushNotifications.addListener(
                        'pushNotificationActionPerformed',
                        async (action: ActionPerformed) => {
                            console.log('Push action performed: ' + JSON.stringify(action));
                            if (!!action.notification.data && !!action.notification.data.orderId) {
                                if (this.router.routerState.snapshot.url.indexOf(`/manager/${this.currentPickupLocation.id}`) === -1) {
                                    await this.router.navigateByUrl(`/manager/${this.currentPickupLocation.id}`);
                                }
                            }
                        });

                    PushNotifications.addListener('registration', (token: Token) => {
                        this.pushTok = token.value;
                        if (!!this.currentPickupLocation) {
                            this.pickupLocationService.saveDevice(this.currentPickupLocation.id, token.value);
                        }
                    });
                }

                await this.eventService.connect();

                this.getActiveTimeCard();

                if (!user.modalLogin) {
                    if (user.siteAdmin) {
                        await this.authenticationService.loginNavigation(user, null, hash);
                    } else if (!user.organizations || user.organizations.length < 1) {
                        await this.navController.navigateRoot('/login');
                        await this.toastService.error(Constants.NOT_AUTHORIZED_ERROR);
                    } else {
                        let organization = null;
                        const organizationId = !!user.organizations && user.organizations.length > 0
                            ? user.organizations[0].orgId : null;
                        if (!!organizationId) {
                            this.loadingService.present();
                            organization = await this.organizationService.get(organizationId).toPromise() as Organization;
                            this.loadingService.dismiss();
                        }

                        await this.authenticationService.loginNavigation(user, organization, hash);
                    }
                }
            });
        }
        this.dismiss();
        this.ready = true;
    }

    getActiveTimeCard() {
        if (!!this.currentOrganization && !!this.currentUser) {
            this.timecardService.activeUserTimecard(this.currentOrganization.id)
                .subscribe((tc: Timecard) => {
                    this.currentUser.timecard = tc;
                    this.userService.setCurrent(this.currentUser);
                    if (!this.currentUser.authorizedTo(FeatureKeys.ORG_ADMIN, this.currentOrganization.id) && !tc) {
                        this.timecardService.confirmClockIn(this.currentUser, this.currentPickupLocation, this.currentOrganization);
                    }
                });
        }
    }

    async checkVersion(longWait: boolean) {
        if (this.platform.is('ios') || this.platform.is('android')) {
            const currentVersion = await this.versionService.getCurrentAppVersion();
            const availableVersion = await this.versionService.getAvailableAppVersion();
            if (currentVersion !== availableVersion) {
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: `New Version`,
                    message: `There is a newer version available. Would you like to upgrade to the latest version?`,
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary'
                        }, {
                            text: 'Yes',
                            handler: async () => {
                                if (this.platform.is('ios')) {
                                    await this.versionService.openAppStore();
                                } else if (this.platform.is('android')) {
                                    await this.versionService.performImmediateUpdate();
                                }
                            }
                        }
                    ]
                });

                await alert.present();
                await alert.onDidDismiss();
                await this.scheduleVersionCheck(true);
            } else {
                await this.scheduleVersionCheck(false);
            }
        }
    }

    async scheduleVersionCheck(longWait: boolean) {
        if (!!this.checkVersionTimeout) {
            clearTimeout(this.checkVersionTimeout);
        }

        this.checkVersionTimeout = setTimeout(async () => {
            await this.checkVersion(longWait);
        }, longWait ? 900000 : 4.32e+7);
    }
}
