import {Injectable} from '@angular/core';
import {AppUpdate, AppUpdateAvailability} from '@capawesome/capacitor-app-update';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    constructor() {
    }

    async getCurrentAppVersion() {
        const result = await AppUpdate.getAppUpdateInfo();
        return result.currentVersion;
    }

    async getAvailableAppVersion() {
        const result = await AppUpdate.getAppUpdateInfo();
        return result.availableVersion;
    }

    async openAppStore() {
        await AppUpdate.openAppStore();
    }

    async performImmediateUpdate() {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            return;
        }
        if (result.immediateUpdateAllowed) {
            await AppUpdate.performImmediateUpdate();
        }
    }
}
