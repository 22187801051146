import {Component, Input} from '@angular/core';
import {BaseModalComponent, CashAudit, CashAuditActions, CashAuditEvents, CashDrawer, CashSession, CashSessionStatuses} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {AddCashActionComponent} from '../add-cash-action/add-cash-action.component';

@Component({
    selector: 'bb-display-cash-audit',
    templateUrl: './display-cash-audit.component.html',
    styleUrls: ['./display-cash-audit.component.scss'],
})
export class DisplayCashAuditComponent extends BaseModalComponent {
    @Input() cashAudit: CashAudit;
    @Input() cashDrawer: CashDrawer;
    @Input() cashSession: CashSession;

    correction: CashAudit;

    cashAuditActions = CashAuditActions;
    cashAuditEvents = CashAuditEvents;
    cashSessionStatuses = CashSessionStatuses;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    async createCorrection() {
        const modal = await this.modalController.create({
            component: AddCashActionComponent,
            componentProps: {
                cashDrawer: this.cashDrawer,
                cashSession: this.cashSession,
                correcting: this.cashAudit
            }
        });

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                this.correction = dataReturned.data;
                this.close(this.correction);
            }
        });

        await modal.present();
    }
}
