import {Component, OnInit, ViewChild} from '@angular/core';
import {
    CashSession,
    CashSessionService,
    CashSessionSummary,
    FeatureKeys,
    LoadingService,
    Organization,
    OrganizationService,
    SubscriberComponent,
    TipOutTypes,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';
import {AlertController, NavController} from '@ionic/angular';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'bb-cash-sessions',
    templateUrl: './cash-sessions.component.html',
    styleUrls: ['./cash-sessions.component.scss'],
})
export class CashSessionsComponent extends SubscriberComponent implements OnInit {
    searchCriteria: any;
    organization: Organization;
    currentSession: CashSession;
    currentUser: User;
    startRoute = false;

    dataSource: MatTableDataSource<CashSessionSummary>;
    displayedColumns: string[] = ['startTime', 'startedByName', 'status', 'drawerCount'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    featureKeys = FeatureKeys;
    tipOutTypes = TipOutTypes;

    constructor(
        private cashSessionService: CashSessionService,
        private organizationService: OrganizationService,
        private userService: UserService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private navController: NavController,
        private alertController: AlertController,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        if (this.organization.tipOutType !== TipOutTypes.PAY_PERIOD_POOLED) {
            this.displayedColumns.push('remainingTips');
        }

        if (!!this.route.queryParams) {
            this.startRoute = this.route.snapshot.queryParams.start;
        }

        this.searchCriteria = this.cashSessionService.getSearchCriteria();
        this.dataSource = new MatTableDataSource([]);

        this.subscribe(this.userService.current.subscribe(u => this.currentUser = !!u ? new User(u) : null));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            if (!!this.organization) {
                this.cashSessionService.findOpen(this.organization.id).subscribe(c => this.currentSession = !!c
                    ? new CashSession(c)
                    : null);

                this.refreshSummaries();
            }
        }));

        this.subscribe(this.cashSessionService.current.subscribe(c => this.currentSession = !!c ? new CashSession(c) : null));

        this.subscribe(this.cashSessionService.event.subscribe(c => this.refreshSummaries()));
    }

    refreshSummaries() {
        if (!!this.currentUser && !!this.organization
            && this.currentUser.authorizedTo(FeatureKeys.CASH_SESSION_VIEW_KEYS, this.organization.id)) {
            this.loadingService.present();
            this.cashSessionService.setSearchCriteria(this.searchCriteria);
            this.cashSessionService.getDateRangeSummaries(this.organization.id, this.searchCriteria.startDate, this.searchCriteria.endDate)
                .subscribe(async (c: CashSessionSummary[]) => {
                    this.dataSource = new MatTableDataSource(c);
                    this.dataSource.sort = this.sort;
                    if (this.startRoute) {
                        this.startRoute = false;
                        await this.checkPrevious();
                    }
                    this.loadingService.dismiss();
                });
        }
    }

    async checkPrevious() {
        this.loadingService.present();
        this.cashSessionService.findLast(this.organization.id).subscribe(async (c: CashSession) => {
            const lastSession = !!c ? new CashSession(c) : null;
            this.loadingService.dismiss();

            if (!!lastSession && !this.organization.preventDrawerCopy) {
                if (lastSession.status !== 'COMPLETE') {
                    const alert = await this.alertController.create({
                        cssClass: 'brewbill-alert',
                        header: 'Skip Drawer Copy',
                        message: 'The previous session has not been completed. Cash drawers cannot be copied if the previous session is not completed. Are you sure you want to continue?',
                        buttons: [
                            {
                                text: 'No',
                                role: 'cancel'
                            }, {
                                text: 'Yes',
                                handler: () => {
                                    this.startSession();
                                }
                            }
                        ]
                    });

                    await alert.present();
                } else {
                    const alert = await this.alertController.create({
                        cssClass: 'brewbill-alert',
                        header: 'Copy Drawers',
                        message: 'Would you like to copy the cash drawers from the previous session starting with their final counts?',
                        buttons: [
                            {
                                text: 'No',
                                handler: () => {
                                    this.startSession();
                                }
                            }, {
                                text: 'Yes',
                                handler: () => {
                                    this.startSession(lastSession.id);
                                }
                            }
                        ]
                    });

                    await alert.present();
                }
            } else {
                this.startSession();
            }
        });
    }

    startSession(originId: number = null) {
        this.loadingService.present();
        this.cashSessionService.create(new CashSession({parentId: this.organization.id, originId})).subscribe(c => {
            this.currentSession = new CashSession(c);
            this.navController.navigateForward(`/${this.organization.id}/cash-session/${this.currentSession.id}`);
            this.loadingService.dismiss();
        }, async error => {
            console.log(error);
            this.loadingService.dismiss();
            if (error.status === 409) {
                await this.toastService.error('Complete or pend the existing cash session before creating a new one.');
            } else if (error.status === 412) {
                await this.toastService.error('Invalid session for copying.');
            }
        });
    }

    async displayCashSession(cashSession: CashSession) {
        await this.navController.navigateForward(`/${this.organization.id}/cash-session/${cashSession.id}`);
    }

    async displayReport() {
        await this.navController.navigateForward(`/${this.organization.id}/employee-tip-report`);
    }
}
