import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
    BaseModalComponent,
    CashDrawer,
    CashDrawerService,
    CashDrawerStatuses,
    CashSession,
    CashSessionService,
    PickupLocation
} from 'brewbill-lib';

@Component({
    selector: 'bb-assign-cash-drawer',
    templateUrl: './assign-cash-drawer.component.html',
    styleUrls: ['./assign-cash-drawer.component.scss'],
})
export class AssignCashDrawerComponent extends BaseModalComponent implements OnInit {
    @Input() pickupLocation: PickupLocation;
    @Input() working = false;
    currentCashDrawer: CashDrawer;
    selected: CashDrawer;
    cashSession: CashSession;
    activeDrawers: CashDrawer[];
    drawers: CashDrawer[];

    constructor(
        modalController: ModalController,
        private cashSessionService: CashSessionService,
        private cashDrawerService: CashDrawerService,
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.subscribe(this.cashSessionService.current.subscribe(c => {
            if (!this.working) {
                this.cashSession = !!c ? new CashSession(c) : null;
                this.drawers = !!this.cashSession ? this.cashSession.cashDrawers
                    .filter(d => (!this.pickupLocation || d.pickupLocation.id === this.pickupLocation.id)
                        && d.status === CashDrawerStatuses.ACTIVE) : [];
            } else {
                const activeSession = !!c ? new CashSession(c) : null;
                this.activeDrawers = !!activeSession ? activeSession.cashDrawers
                    .filter(d => (!this.pickupLocation || d.pickupLocation.id === this.pickupLocation.id)
                        && d.status === CashDrawerStatuses.ACTIVE) : [];
            }

            this.initDrawer();
        }));

        if (this.working) {
            this.subscribe(this.cashSessionService.working.subscribe(c => {
                this.cashSession = !!c ? new CashSession(c) : null;

                this.drawers = [...this.cashSession.cashDrawers];

                this.initDrawer();
            }));
        }

        this.subscribe(this.cashDrawerService.current.subscribe(c => {
            this.currentCashDrawer = !!c ? new CashDrawer(c) : null;
            this.initDrawer();
        }));
    }

    initDrawer() {
        if (!!this.cashSession && !!this.currentCashDrawer) {
            this.selected = this.cashSession.cashDrawers.find(d => d.id === this.currentCashDrawer.id);
        }

        if (!this.selected && !!this.activeDrawers) {
            this.selected = this.activeDrawers.find(d => d.id === this.currentCashDrawer.id);
        }
    }

    selectDrawer(drawer) {
        this.cashDrawerService.setCurrent(drawer);
        this.close(drawer);
    }
}
