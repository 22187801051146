import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

import {addCurrency, BaseModalComponent, CashDrawer, CashDrawerService, LoadingService, multiplyCurrency, ToastService} from 'brewbill-lib';

@Component({
    selector: 'bb-add-cash-count',
    templateUrl: './add-cash-count.component.html',
    styleUrls: ['./add-cash-count.component.scss'],
})
export class AddCashCountComponent extends BaseModalComponent implements OnInit {
    @Input() cashDrawer: CashDrawer;
    formGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private cashDrawerService: CashDrawerService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group(
            {
                amount: ['', Validators.required],
                ones: '',
                fives: '',
                tens: '',
                twenties: '',
                fifties: '',
                hundreds: '',
                pennies: '',
                nickels: '',
                dimes: '',
                quarters: ''
            }
        );
    }

    addCount() {
        this.loadingService.present();
        const cashDrawer = new CashDrawer(this.cashDrawer);
        cashDrawer.endAmount = this.formGroup.controls.amount.value;
        cashDrawer.ones = this.formGroup.controls.ones.value;
        cashDrawer.fives = this.formGroup.controls.fives.value;
        cashDrawer.tens = this.formGroup.controls.tens.value;
        cashDrawer.twenties = this.formGroup.controls.twenties.value;
        cashDrawer.fifties = this.formGroup.controls.fifties.value;
        cashDrawer.hundreds = this.formGroup.controls.hundreds.value;
        cashDrawer.pennies = this.formGroup.controls.pennies.value;
        cashDrawer.nickels = this.formGroup.controls.nickels.value;
        cashDrawer.dimes = this.formGroup.controls.dimes.value;
        cashDrawer.quarters = this.formGroup.controls.quarters.value;

        this.cashDrawerService.complete(cashDrawer).subscribe(c => {
            this.loadingService.dismiss();
            this.toastService.success('The drawer has been updated.');
            this.close(new CashDrawer(c));
        }, async error => {
            if (error.status === 412) {
                await this.toastService.error('The session must be pending or active to set totals for a drawer.');
            }
        });
    }

    calculateTotal() {
        let total = 0;
        if (!!this.formGroup.controls.ones.value) {
            total = addCurrency(total, this.formGroup.controls.ones.value);
        }
        if (!!this.formGroup.controls.fives.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.fives.value, 5));
        }
        if (!!this.formGroup.controls.tens.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.tens.value, 10));
        }
        if (!!this.formGroup.controls.twenties.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.twenties.value, 20));
        }
        if (!!this.formGroup.controls.fifties.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.fifties.value, 50));
        }
        if (!!this.formGroup.controls.hundreds.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.hundreds.value, 100));
        }
        if (!!this.formGroup.controls.pennies.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.pennies.value, .01));
        }
        if (!!this.formGroup.controls.nickels.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.nickels.value, .05));
        }
        if (!!this.formGroup.controls.dimes.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.dimes.value, .10));
        }
        if (!!this.formGroup.controls.quarters.value) {
            total = addCurrency(total, multiplyCurrency(this.formGroup.controls.quarters.value, .25));
        }
        if (!!total || total === 0) {
            this.formGroup.controls.amount.setValue(total);
            this.formGroup.get('amount').updateValueAndValidity();
        }
    }
}
