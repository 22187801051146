import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {
    BaseModalComponent,
    CashAudit,
    CashAuditService,
    CashDrawerService,
    CashDrawerSummary,
    FeatureKeys,
    LoadingService,
    Organization,
    OrganizationService,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';

@Component({
    selector: 'bb-transfer-cash',
    templateUrl: './transfer-cash.component.html',
    styleUrls: ['./transfer-cash.component.scss'],
})
export class TransferCashComponent extends BaseModalComponent implements OnInit {
    @Input() cashDrawerSummary: CashDrawerSummary;
    @Input() availableDrawers: CashDrawerSummary[];
    formGroup: FormGroup;
    user: User;
    admin = false;
    organization: Organization;

    from: CashDrawerSummary;
    to: CashDrawerSummary;

    constructor(
        modalController: ModalController,
        private cashAuditService: CashAuditService,
        private cashDrawerService: CashDrawerService,
        private organizationService: OrganizationService,
        private userService: UserService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.user = this.userService.currentValue;
        this.organization = this.organizationService.currentValue;
        this.admin = this.user.authorizedTo(FeatureKeys.SAFE_ADMIN, this.organization.id);

        if (!this.admin) {
            this.availableDrawers = this.availableDrawers.filter(d => d.safeAccess !== 'ADMIN_ONLY');
        }

        this.formGroup = this.formBuilder.group({
            fromId: ['', Validators.required],
            toId: ['', Validators.required],
            amount: ['', Validators.required],
            comment: ''
        });
    }

    fromChanged() {
        if (!!this.from) {
            this.formGroup.controls.fromId.setValue(this.from.id);
            if (this.from.id !== this.cashDrawerSummary.id) {
                this.to = this.availableDrawers.find(d => d.id === this.cashDrawerSummary.id);
                this.formGroup.controls.toId.setValue(this.to.id);
            }

            this.formGroup.controls.amount.setValidators([
                Validators.required, Validators.min(.01), Validators.max(this.from.expectedTotal)
            ]);
        }

        this.formGroup.get('amount').updateValueAndValidity();
    }

    toChanged() {
        if (!!this.to) {
            this.formGroup.controls.toId.setValue(this.to.id);
            if (this.to.id !== this.cashDrawerSummary.id) {
                this.from = this.availableDrawers.find(d => d.id === this.cashDrawerSummary.id);
                this.formGroup.controls.fromId.setValue(this.from.id);
            }
        }
    }

    onSubmit() {
        if (this.formGroup.valid) {
            this.loadingService.present();
            this.cashAuditService.transfer(this.from.id, this.to.id, new CashAudit(this.formGroup.value)).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Transfer successful.');
                this.close(c);
            }, async (error) => {
                if (error.status === 406) {
                    await this.toastService.error('The transfer is not valid.');
                    await this.close();
                }
            });
        }
    }
}
