import {Component, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    CardTransactionResponse,
    CardTransactionResponseService,
    LoadingService,
    Organization,
    OrganizationTerminal,
    OrganizationTerminalService,
    ToastService
} from 'brewbill-lib';
import {v4 as uuidv4} from 'uuid';
import {ModalController} from '@ionic/angular';

@Component({
    template: ''
})
export abstract class BaseCardTransactionComponent extends BaseModalComponent implements OnInit {
    organization: Organization;
    loading: HTMLIonLoadingElement;
    referenceId: string;
    terminal: OrganizationTerminal;

    polling;
    pollingCount = 0;

    abstract cardPaymentSuccess(response: CardTransactionResponse);

    abstract cardPaymentFail(response: CardTransactionResponse);

    abstract cardPaymentCancel();

    protected constructor(
        protected cardTransactionResponseService: CardTransactionResponseService,
        protected organizationTerminalService: OrganizationTerminalService,
        protected toastService: ToastService,
        protected loadingService: LoadingService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.terminal = this.organizationTerminalService.currentValue;

        this.subscribe(this.cardTransactionResponseService.event.subscribe(async c => {
            if (!!c && c.referenceId === this.referenceId) {
                this.referenceId = null;
                if (!!this.polling) {
                    clearTimeout(this.polling);
                }
                if (c.code === 200) {
                    this.cardPaymentSuccess(c);
                } else {
                    if (c.code === 410) {
                        console.log('Payment canceled.');
                    } else if (c.code === 406) {
                        if (!!c.message) {
                            await this.toastService
                                .error(c.message);
                        } else {
                            await this.toastService
                                .error('The card was declined.');
                        }
                    } else if (c.code === 405) {
                        await this.toastService
                            .error('A dip is required to tokenize this card.');
                    } else if (c.code === 408) {
                        await this.toastService.error('Request timed out.');
                    } else if (c.code === 413) {
                        await this.toastService
                            .error('Payment request exceeds remaining tab amount.');
                    } else if (c.code === 423) {
                        await this.toastService
                            .error('The card terminal is unavailable.');
                    } else {
                        await this.toastService.error('Server error.');
                    }

                    if (!!this.loading) {
                        await this.loading.dismiss(true);
                    }

                    this.cardPaymentFail(c);
                }

                if (!!this.loading) {
                    await this.loading.dismiss(true);
                }
            }
        }));

        this.subscribe(this.organizationTerminalService.current.subscribe(r => this.terminal = r));
    }

    pollReferenceId() {
        if (!!this.organization && !!this.referenceId) {
            this.cardTransactionResponseService.getByOrganizationIdAndReferenceId(this.organization.id, this.referenceId)
                .subscribe(c => {
                    if (!!c) {
                        this.cardTransactionResponseService.setEvent(new CardTransactionResponse(c));
                    } else {
                        if (this.pollingCount < 36) {
                            this.polling = setTimeout(() => {
                                this.pollReferenceId();
                                this.pollingCount++;
                            }, 5000);
                        } else {
                            this.cardPaymentCancel();
                        }
                    }
                });
        }
    }

    beginPolling() {
        this.pollingCount = 0;
        this.polling = setTimeout(() => {
            this.pollReferenceId();
        }, 3000);
    }

    setReferenceId() {
        this.referenceId = 'MER-' + uuidv4();
    }
}
