import {Component, Input, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    LoadingService,
    MenuItemInventory,
    MenuItemInventoryAction,
    MenuItemInventoryActionService,
    Organization,
    PickupLocationService,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bb-menu-item-inventory-transfer',
    templateUrl: './menu-item-inventory-transfer.component.html',
    styleUrls: ['./menu-item-inventory-transfer.component.scss'],
})
export class MenuItemInventoryTransferComponent extends BaseModalComponent implements OnInit {
    @Input() from: MenuItemInventory;
    @Input() to: MenuItemInventory;
    @Input() organization: Organization;
    @Input() available: MenuItemInventory[];
    formGroup: FormGroup;
    toCount = 0;
    closing = false;
    kegUpdated: MenuItemInventory;

    constructor(
        modalController: ModalController,
        private pickupLocationService: PickupLocationService,
        private loadingService: LoadingService,
        private menuItemInventoryActionService: MenuItemInventoryActionService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            amount: [
                !this.from.pickupLocation && !!this.from.containerQuantity ? 1 : '',
                [Validators.required, Validators.min(0), Validators.max(this.from.currentCount)]
            ],
            comment: ''
        });

        const pickupLocation = this.pickupLocationService.currentValue;
        if (!this.from.pickupLocation) {
            this.formGroup.addControl('pickupLocation', new FormControl(!!pickupLocation ? this.organization.pickupLocations
                .find(p => p.id === pickupLocation.id) : this.organization.pickupLocations[0], [Validators.required]));
            this.formGroup.controls.pickupLocation.updateValueAndValidity();
            this.findAvailable();
        }

        if (!!this.to) {
            this.kegUpdated = new MenuItemInventory({...this.from});
            this.toCount = this.to.currentCount;
        }
    }

    calculatedFromCount() {
        return !this.to || !this.to.fillFrom ? this.from.currentCount - Number.parseInt(this.formGroup.controls.amount.value, 10)
            : this.from.currentCount - (Number.parseInt(this.formGroup.controls.amount.value, 10) * this.to.fillQuantity);
    }

    calculatedToCount() {
        return this.toCount + Number.parseInt(this.formGroup.controls.amount.value, 10);
    }

    updateToValue() {
        if (!!this.kegUpdated) {
            this.kegUpdated.currentCount = this.from.currentCount - (Number.parseInt(this.formGroup.controls.amount.value, 10) * this.to.fillQuantity);
        }
    }

    findAvailable() {
        let toCount = 0;
        if (this.available) {
            if (!this.from.pickupLocation && !!this.formGroup.controls.pickupLocation.value) {
                const found = this.available.find(a => !!a.pickupLocation && a.pickupLocation.id === this.formGroup.controls.pickupLocation.value.id);
                if (!!found) {
                    toCount = found.currentCount;
                }
            } else if (!!this.from.pickupLocation) {
                const found = this.available.find(a => !a.pickupLocation);
                if (!!found) {
                    toCount = found.currentCount;
                }
            }
        }
        this.toCount = toCount;
    }

    transfer() {
        this.loadingService.present();
        const action = new MenuItemInventoryAction(this.formGroup.value);

        if (!!this.to && this.to.fillFrom) {
            action.parentId = this.to.id;
        }

        this.menuItemInventoryActionService.transfer(this.from.id, action).subscribe(f => {
            this.closing = true;
            this.loadingService.dismiss();
            this.toastService.success('Transfer successful.');
            this.close(f);
        });
    }
}
