import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, TabNoteDefinition} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-select-tab-note',
    templateUrl: './select-tab-note.component.html',
    styleUrls: ['./select-tab-note.component.scss'],
})
export class SelectTabNoteComponent extends BaseModalComponent implements OnInit {
    @Input() availableDefinitions: TabNoteDefinition[];

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit() {
    }
}
