import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {
    ActiveCashSessionResolver,
    AllOrganizationsResolver,
    AllVenuesResolver,
    CardSummaryResolver,
    CashDrawerResolver,
    CashDrawerSummariesResolver,
    CashDrawerSummaryResolver,
    CashSessionResolver,
    CashSessionSummaryResolver,
    CustomerAreaResolver,
    FeatureKeys,
    LoggedInUserResolver,
    MembershipProgramCompDefinitionResolver,
    MembershipProgramResolver,
    MembershipResolver,
    MembershipTokenAuditsResolver,
    MembershipTokenPurseResolver,
    MenuCategoryListResolver,
    MenuItemInventoryResolver,
    MenuItemKickedKegResolver,
    MenuItemKickedKegsSummaryResolver,
    OrganizationPickupLocationsResolver,
    OrganizationResolver,
    PickupLocationMenuResolver,
    PickupLocationResolver,
    ReportResolver,
    RoleGuard,
    SafesResolver,
    TabResolver,
    UserResolver,
    VenueResolver
} from 'brewbill-lib';

const routes: Routes = [
    {
        path: '', redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./_pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./_pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./_pages/home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'account',
        loadChildren: () => import('./_pages/account/account.module').then(m => m.AccountPageModule),
        resolve: {user: LoggedInUserResolver}
    },
    {
        path: 'organizations',
        loadChildren: () => import('./_pages/organizations/organizations.module').then(m => m.OrganizationsPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'users',
        loadChildren: () => import('./_pages/users/users.module').then(m => m.UsersPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'organization/:orgId',
        loadChildren: () => import('./_pages/organization/organization.module').then(m => m.OrganizationPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: ':orgId/dashboard',
        loadChildren: () => import('./_pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [RoleGuard],
        data: {feature: FeatureKeys.DASHBOARD_FEATURE_KEYS},
        resolve: {
            categories: MenuCategoryListResolver
        }
    },
    {
        path: 'manager/:pickupLocationId',
        loadChildren: () => import('./_pages/manager/manager.module').then(m => m.ManagerPageModule),
        canActivate: [RoleGuard],
        data: {feature: FeatureKeys.TERMINAL}
    },
    {
        path: 'user/:userId/:orgId',
        loadChildren: () => import('./_pages/user/user.module').then(m => m.UserPageModule),
        canActivate: [RoleGuard],
        resolve: {
            user: UserResolver
        }
    },
    {
        path: 'venues',
        loadChildren: () => import('./_pages/venues/venues.module').then(m => m.VenuesPageModule),
        canActivate: [RoleGuard],
        resolve: {
            venues: AllVenuesResolver
        }
    },
    {
        path: 'venue/:venueId',
        loadChildren: () => import('./_pages/venue/venue.module').then(m => m.VenuePageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'venue-boundary/:venueId',
        loadChildren: () => import('./_pages/venue-boundary/venue-boundary.module').then(m => m.VenueBoundaryPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'pickup-location/:pickupLocationId',
        loadChildren: () => import('./_pages/pickup-location/pickup-location.module').then(m => m.PickupLocationPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'pickup-location-menu/:pickupLocationId',
        loadChildren: () => import('./_pages/pickup-location-menu/pickup-location-menu.module').then(m => m.PickupLocationMenuPageModule),
        canActivate: [RoleGuard],
        resolve: {
            pickupLocationMenu: PickupLocationMenuResolver
        }
    },
    {
        path: 'confirm-tab/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/confirm-tab/confirm-tab.module').then(m => m.ConfirmTabPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: ':orgId/report/:reportId',
        loadChildren: () => import('./_pages/report/report.module').then(m => m.ReportPageModule),
        canActivate: [RoleGuard],
        resolve: {
            categories: MenuCategoryListResolver,
            report: ReportResolver
        }
    },
    {
        path: ':orgId/create-report',
        loadChildren: () => import('./_pages/report/report.module').then(m => m.ReportPageModule),
        canActivate: [RoleGuard],
        resolve: {
            categories: MenuCategoryListResolver
        }
    },
    {
        path: 'reports/:orgId',
        loadChildren: () => import('./_pages/reports/reports.module').then(m => m.ReportsPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'gift-card/:orgId/:giftCardId/tab/:tabId',
        loadChildren: () => import('./_pages/tab/tab.module').then(m => m.TabPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver
        }
    },
    {
        path: 'cash-session/:orgId/:cashSessionId/tab/:tabId',
        loadChildren: () => import('./_pages/tab/tab.module').then(m => m.TabPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver,
            cashSession: CashSessionResolver
        }
    },
    {
        path: 'report/:orgId/:reportId/tab/:tabId',
        loadChildren: () => import('./_pages/tab/tab.module').then(m => m.TabPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver,
            report: ReportResolver
        }
    },
    {
        path: 'report/:orgId/:reportId/order/:orderId/tab',
        loadChildren: () => import('./_pages/tab/tab.module').then(m => m.TabPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver,
            report: ReportResolver
        }
    },
    {
        path: 'report/:orgId/:reportId/order-item/:orderItemId/tab',
        loadChildren: () => import('./_pages/tab/tab.module').then(m => m.TabPageModule),
        canActivate: [RoleGuard],
        resolve: {
            tab: TabResolver,
            report: ReportResolver
        }
    }, {
        path: 'start-tab/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/start-tab/start-tab.module').then(m => m.StartTabPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'pay-with-card/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/pay-with-card/pay-with-card.module').then(m => m.PayWithCardPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'thank-you/:pickupLocationId/:tabId',
        loadChildren: () => import('./_pages/thank-you/thank-you.module').then(m => m.ThankYouPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'customer-area/:customerAreaId/:orgId',
        loadChildren: () => import('./_pages/customer-area/customer-area.module').then(m => m.CustomerAreaPageModule),
        canActivate: [RoleGuard],
        resolve: {
            customerArea: CustomerAreaResolver
        }
    },
    {
        path: ':orgId/report-landing/:reportId',
        loadChildren: () => import('./_pages/report-landing/report-landing.module').then(m => m.ReportLandingPageModule)
    },
    {
        path: 'operations/:orgId',
        loadChildren: () => import('./_pages/operations/operations.module').then(m => m.OperationsPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: ':orgId/cash-session/:cashSessionId',
        loadChildren: () => import('./_pages/cash-session/cash-session.module').then(m => m.CashSessionPageModule),
        canActivate: [RoleGuard],
        resolve: {
            cashSession: CashSessionResolver,
            cashDrawerSummaries: CashDrawerSummariesResolver,
            summary: CashSessionSummaryResolver,
            safes: SafesResolver
        }
    },
    {
        path: ':orgId/:cashSessionId/cash-drawer/:cashDrawerId',
        loadChildren: () => import('./_pages/cash-drawer/cash-drawer.module').then(m => m.CashDrawerPageModule),
        canActivate: [RoleGuard],
        resolve: {
            cashDrawer: CashDrawerResolver,
            cashDrawerSummary: CashDrawerSummaryResolver,
            cashSession: CashSessionResolver,
            cashSessionSummary: CashSessionSummaryResolver,
            safes: SafesResolver
        }
    },
    {
        path: ':orgId/safe/:cashDrawerId',
        loadChildren: () => import('./_pages/cash-drawer/cash-drawer.module').then(m => m.CashDrawerPageModule),
        canActivate: [RoleGuard],
        resolve: {
            cashSession: ActiveCashSessionResolver,
            cashDrawer: CashDrawerResolver,
            cashDrawerSummary: CashDrawerSummaryResolver,
            safes: SafesResolver
        }
    },
    {
        path: ':orgId/employee-tip-report',
        loadChildren: () => import('./_pages/employee-tip-report/employee-tip-report.module').then(m => m.EmployeeTipReportPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: 'organization/blank/:orgId',
        loadChildren: () => import('./_pages/blank/blank.module').then(m => m.BlankPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: ':orgId/card-history/:cardSummaryId',
        loadChildren: () => import('./_pages/card-history/card-history.module').then(m => m.CardHistoryPageModule),
        canActivate: [RoleGuard],
        resolve: {
            cardSummary: CardSummaryResolver
        }
    },
    {
        path: ':pickupLocationId/keg-summary/:menuItemId',
        loadChildren: () => import('./_pages/keg-summary/keg-summary.module').then(m => m.KegSummaryPageModule),
        canActivate: [RoleGuard],
        resolve: {
            kickedKegs: MenuItemKickedKegResolver,
            summary: MenuItemKickedKegsSummaryResolver,
            menuItemInventory: MenuItemInventoryResolver
        }
    },
    {
        path: ':orgId/daily-payment-summaries',
        loadChildren: () => import('./_pages/daily-payment-summaries/daily-payment-summaries.module').then(m => m.DailyPaymentSummariesPageModule),
        canActivate: [RoleGuard]
    },
    {
        path: ':orgId/membership-program/:id',
        loadChildren: () => import('./_pages/membership-program/membership-program.module').then(m => m.MembershipProgramPageModule),
        canActivate: [RoleGuard],
        resolve: {
            membershipProgram: MembershipProgramResolver,
            compDefinitions: MembershipProgramCompDefinitionResolver
        }
    },
    {
        path: ':orgId/member/:memberId',
        loadChildren: () => import('./_pages/member/member.module').then(m => m.MemberPageModule),
        canActivate: [RoleGuard],
        resolve: {
            member: MembershipResolver
        }
    },
    {
        path: ':orgId/:memberId/member-token-purse/:membershipTokenPurseId',
        loadChildren: () => import('./_pages/member-token-purse/member-token-purse.module').then(m => m.MemberTokenPursePageModule),
        resolve: {
            member: MembershipResolver,
            audits: MembershipTokenAuditsResolver,
            purse: MembershipTokenPurseResolver,
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
    ],
    exports: [RouterModule],
    providers: [
        ActiveCashSessionResolver,
        AllOrganizationsResolver,
        AllVenuesResolver,
        CardSummaryResolver,
        CashDrawerResolver,
        CashDrawerSummaryResolver,
        CashDrawerSummariesResolver,
        CashSessionResolver,
        CashSessionSummaryResolver,
        CustomerAreaResolver,
        LoggedInUserResolver,
        MembershipResolver,
        MembershipTokenAuditsResolver,
        MembershipTokenPurseResolver,
        MembershipProgramResolver,
        MembershipProgramCompDefinitionResolver,
        MenuCategoryListResolver,
        MenuItemInventoryResolver,
        MenuItemKickedKegResolver,
        MenuItemKickedKegsSummaryResolver,
        OrganizationResolver,
        OrganizationPickupLocationsResolver,
        PickupLocationResolver,
        PickupLocationMenuResolver,
        ReportResolver,
        SafesResolver,
        TabResolver,
        UserResolver,
        VenueResolver
    ]
})
export class AppRoutingModule {
}
