import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, LoadingService, MerchantAccount, MerchantAccountService, Organization, ToastService} from 'brewbill-lib';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-merchant-account',
    templateUrl: './edit-merchant-account.component.html',
    styleUrls: ['./edit-merchant-account.component.scss'],
})
export class EditMerchantAccountComponent extends BaseModalComponent implements OnInit {
    @Input() merchantAccount: MerchantAccount;
    @Input() organization: Organization;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private merchantAccountService: MerchantAccountService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.merchantAccount ? this.merchantAccount.id : null,
            parentId: this.organization.id,
            cardNotPresentAccountCredential: [
                !!this.merchantAccount ? this.merchantAccount.cardNotPresentAccountCredential : ''
            ],
            externalId: [!!this.merchantAccount ? this.merchantAccount.externalId : ''],
            terminalAccountCredential: [!!this.merchantAccount ? this.merchantAccount.terminalAccountCredential : ''],
            achAccountCredential: [!!this.merchantAccount ? this.merchantAccount.achAccountCredential : '']
        });
    }

    onSubmit() {
        const ma = new MerchantAccount(this.formGroup.value);
        this.loadingService.present();
        if (!!ma.id) {
            this.merchantAccountService.update(ma).subscribe(m => {
                this.loadingService.dismiss();
                this.toastService.success('Merchant account saved');
                this.close(m);
            });
        } else {
            this.merchantAccountService.create(ma).subscribe(m => {
                this.loadingService.dismiss();
                this.toastService.success('Merchant account saved');
                this.close(m);
            });
        }
    }
}
