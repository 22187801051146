import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {CurrencyPipe} from '@angular/common';
import {
    addCurrency,
    Availabilities,
    buildReceipt,
    CardSummary,
    CardTransactionResponseService,
    CashAudit,
    CashAuditActions,
    CashAuditService,
    CashSessionService,
    ChangeCustomerPositionComponent,
    CompDefinitionTypes,
    Constants,
    CustomDollarAmountComponent,
    CustomerAreaPosition,
    Deposit,
    DepositService,
    EditRatingsComponent,
    FeatureKeys,
    GiftCardAction,
    isCashPayment,
    isCreditCardPayment,
    isGiftCardPayment,
    LoadingService,
    Membership,
    MembershipService,
    MembershipSummary,
    MembershipVisitSummary,
    Menu,
    MenuItem,
    MenuItemPrice,
    multiplyCurrency,
    Order,
    OrderItem,
    OrderItemCredit,
    OrderItemCreditService,
    OrderItemService,
    OrderService,
    OrderStatuses,
    OrganizationRatingService,
    OrganizationService,
    OrganizationTerminalService,
    PaymentCharge,
    PaymentChargeRefundComponent,
    PaymentChargeService,
    PaymentStatuses,
    PickupLocationMenu,
    PickupLocationMenuService,
    PickupLocationService,
    PrinterService,
    SelectExistingCardComponent,
    SelectServerComponent,
    sortPayments,
    Tab,
    TabCredit,
    TabCreditService,
    TabNoteService,
    TabService,
    TabStatuses,
    Tax,
    ToastService,
    UserService
} from 'brewbill-lib';
import {ActivatedRoute} from '@angular/router';
import {CashPaymentComponent} from '../cash-payment/cash-payment.component';
import {CashRefundComponent} from '../cash-refund/cash-refund.component';
import {ApplyGiftCardComponent} from '../apply-gift-card/apply-gift-card.component';
import {BaseTabComponent} from '../base-tab.component';
import {DepositRefundComponent} from '../deposit-refund/deposit-refund.component';
import {SelectMemberComponent} from '../select-member/select-member.component';
import {v4 as uuidv4} from 'uuid';
import {MembershipOrderItemHistory} from 'brewbill-lib/lib/_models/membership-order-item-history';

@Component({
    selector: 'bb-tab-details',
    templateUrl: './tab-details.component.html',
    styleUrls: ['./tab-details.component.scss']
})
export class TabDetailsComponent extends BaseTabComponent implements OnInit {
    @Input() tab: Tab;

    activeMembership: Membership;
    orders: Order[];
    pendingOrders: Order[];
    payments: Array<CashAudit | PaymentCharge | GiftCardAction>;
    loadingVisitSummary = false;
    membershipVisitSummary: MembershipVisitSummary;

    orderStatuses = OrderStatuses;
    tabStatuses = TabStatuses;
    availabilities = Availabilities;
    paymentStatuses = PaymentStatuses;
    cashAuditActions = CashAuditActions;
    editTaxExemption = false;
    taxes: Tax[];
    addedTaxes: Tax[];
    closing = false;
    canPrint = false;
    itemHistories = [];

    menu: Menu;
    pickupLocationMenu: PickupLocationMenu;

    isCashPayment = isCashPayment;
    isGiftCardPayment = isGiftCardPayment;
    isCreditCardPayment = isCreditCardPayment;

    constructor(
        private currencyPipe: CurrencyPipe,
        private paymentChargeService: PaymentChargeService,
        private organizationRatingService: OrganizationRatingService,
        private route: ActivatedRoute,
        private navController: NavController,
        private alertController: AlertController,
        private changeDetectorRef: ChangeDetectorRef,
        private cashAuditService: CashAuditService,
        private depositService: DepositService,
        private printerService: PrinterService,
        private membershipService: MembershipService,
        private orderItemService: OrderItemService,
        private pickupLocationMenuService: PickupLocationMenuService,
        orderService: OrderService,
        orderItemCreditService: OrderItemCreditService,
        tabService: TabService,
        tabCreditService: TabCreditService,
        tabNoteService: TabNoteService,
        modalController: ModalController,
        cashSessionService: CashSessionService,
        pickupLocationService: PickupLocationService,
        userService: UserService,
        organizationService: OrganizationService,
        cardTransactionResponseService: CardTransactionResponseService,
        organizationTerminalService: OrganizationTerminalService,
        toastService: ToastService,
        loadingService: LoadingService
    ) {
        super(cashSessionService,
            pickupLocationService,
            orderItemCreditService,
            orderService,
            organizationService,
            tabService,
            tabCreditService,
            tabNoteService,
            userService,
            modalController,
            cardTransactionResponseService,
            organizationTerminalService,
            toastService,
            loadingService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.init(this.route.snapshot.data.tab);

        this.subscribe(this.printerService.connected.subscribe(c => this.canPrint = c));

        this.subscribe(this.orderService.event.subscribe((o: Order) => {
            if (!!o && !!this.tab && o.parentId === this.tab.id && !!this.tab.orders) {
                const t = [...this.tab.orders];
                const existingIndex = t.findIndex((i) => i.id === o.id);
                if (existingIndex > -1) {
                    t[existingIndex] = o;
                    this.orderService.setEvent(null);
                } else {
                    t.push(o);
                }
                this.tab.orders = t;
            }
        }));

        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t && !!this.tab && t.id === this.tab.id) {
                this.init(t);
            }
        }));

        this.subscribe(this.pickupLocationMenuService.current.subscribe(p => {
            this.pickupLocationMenu = p;
            this.menu = !!p ? p.menu : null;
            this.filterMenuItems();
        }));

        this.subscribe(this.tabService.deletedTab.subscribe(id => {
            if (!!id && !!this.tab && this.tab.id === id) {
                this.toastService.message('This tab has been deleted.');
                this.tabService.setCurrent(null);
                this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tabs`);
            }
        }));

        this.subscribe(this.membershipService.current.subscribe(m => {
            if (!!m && !!this.tab && !!this.tab.member && m.id === this.tab.member.id) {
                this.activeMembership = new Membership(m);
                this.tab.member = new Membership(m);
            }
        }));
    }

    async init(t: Tab) {
        this.tab = new Tab(t);
        this.tabService.setCurrent(this.tab);

        if (this.tab.status === TabStatuses.OPEN) {
            const openTabs = this.tabService.getCurrentOpenTabs();
            if (!!openTabs && !openTabs.some(o => o.id === this.tab.id)) {
                openTabs.push(this.tab);
                this.tabService.setOpenTabs(openTabs);
            }
        }

        if (!!this.tab.member) {
            this.activeMembership = new Membership(this.tab.member);
            this.membershipService.loadMembership(this.tab.member.id);

            if (this.tab.status === TabStatuses.OPEN) {
                if (!this.membershipVisitSummary) {
                    this.loadingVisitSummary = true;
                }

                this.membershipService.getVisitSummary(this.tab.member.id).subscribe(v => {
                    this.membershipVisitSummary = new MembershipVisitSummary(v);
                    this.filterMenuItems();
                    this.loadingVisitSummary = false;
                }, e => this.loadingVisitSummary = false);
            }
        }

        const taxes = [];
        if (!!this.tab.taxes) {
            this.tab.taxes.forEach(tax => {
                const found = taxes.find(tabTax => tabTax.name === tax.name
                    && tabTax.taxRate === tax.taxRate
                    && tabTax.taxIncluded === tax.taxIncluded);
                if (!found) {
                    taxes.push(tax);
                } else {
                    found.tax = addCurrency(tax.tax, found.tax);
                }
            });
        }
        this.taxes = taxes;

        const openOrders = this.tab.orders.filter(o => o.status !== OrderStatuses.PENDING);
        this.orders = !!openOrders ? openOrders.sort((a, b) => a.date > b.date ? -1 : 1) : [];
        this.pendingOrders = this.tab.orders.filter(o => o.status === OrderStatuses.PENDING && o.terminalOrder);

        this.payments = sortPayments(this.tab.paymentCharges, this.tab.cashAudits, this.tab.giftCardPayments);

        this.tabService.addToIdCache(this.tab.orgId, this.tab.id);

        if (!this.tab.fromCache) {
            this.tabService.addToCache(this.tab);
        }

        this.orderItemCreditIndex = this.tab.orders.reduce((max, order) => max = (max > order.maxCreditIndex())
            ? max
            : order.maxCreditIndex() + 1, 0);

        this.orderService.setCurrent(null);

        if (this.tab.fromCache) {
            this.tabService.getSilent(this.tab.id).subscribe((tab: Tab) => {
                this.init(tab);
            });
        }
    }

    async ionViewWillLeave() {
        if (!!this.polling) {
            await this.cardPaymentCancel();
        }
    }

    filterMenuItems() {
        const histories = [];
        if (!!this.pickupLocationMenu && !!this.membershipVisitSummary) {
            for (const item of this.membershipVisitSummary.orderItemHistories) {
                const menuItem = this.menu.menuItems.find(m => m.id === item.menuItemId);
                if (!!menuItem) {
                    item.menuItem = new MenuItem(menuItem);
                    item.menuItem.setPickupLocationMenuItemPrices(this.pickupLocationMenu.menuItemPrices);
                    const menuItemPrice = item.menuItem.prices.find(mi => mi.id === item.menuItemPriceId);
                    if (!!menuItemPrice) {
                        item.menuItemPrice = new MenuItemPrice(menuItemPrice);
                        if ((this.pickupLocationMenu.allowInHouse && item.menuItem.allowInHouse() && item.menuItemPrice.allowInHouse())
                            || (this.pickupLocationMenu.allowTogo && item.menuItem.allowToGo() && item.menuItemPrice.allowToGo())) {
                            histories.push(item);
                            if (histories.length === 3) {
                                break;
                            }
                        }
                    }
                }
            }
        }
        this.itemHistories = histories;
    }

    cardPaymentSuccess() {
        this.refresh();
    }

    cardPaymentFail() {
        this.closing = false;
    }

    async cardPaymentCancel() {
        if (!!this.loading) {
            await this.loading.dismiss();
        }
        this.closing = false;
    }

    async presentOptions() {
        await this.navController.navigateForward(`/thank-you/${this.pickupLocation.id}/${this.tab.id}`);
    }

    async delete() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to delete this tab?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.tabService.delete(this.tab.id).subscribe(() => {
                            this.loadingService.dismiss();
                            this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tabs`);
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async removeCredit(credit: TabCredit) {
        if (this.authorizedTo(FeatureKeys.CREDIT_APPLY)) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Confirm Remove Credit',
                message: `Are you sure you would like to remove the credit for ${this.currencyPipe.transform(credit.amount)}?`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.loadingService.present();
                            this.tabService.deleteTabCredit(credit.id).subscribe((t) => {
                                this.tab = new Tab(t);
                                this.tabService.setEvent(this.tab);
                                this.loadingService.dismiss();
                            });
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    refresh() {
        this.tabService.getSilent(this.tab.id).subscribe(async (t: Tab) => {
            this.tabService.setEvent(new Tab(t), true);
            this.loadingService.dismiss();
        });
    }

    async refundCash() {
        const modal = await this.modalController.create({
            component: CashRefundComponent,
            componentProps: {
                tab: this.tab,
                pickupLocation: this.pickupLocation
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                this.refresh();
            }
        });
    }

    async applyGiftCard() {
        const modal = await this.modalController.create({
            component: ApplyGiftCardComponent,
            componentProps: {
                tab: this.tab,
                organization: this.organization
            }
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.loadingService.present();
                if (dataReturned.data.balance <= this.tab.paymentInformation.remaining) {
                    this.loadingService.present();
                    this.tabService.payWithGiftCard(this.tab, dataReturned.data.id, this.tab.paymentInformation.remaining, 0)
                        .subscribe((tab: Tab) => {
                            this.tabService.setEvent(new Tab(tab));
                            this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${this.tab.id}`);
                            this.loadingService.dismiss();
                        }, async error => {
                            if (error.status === 402) {
                                await this.toastService.error('Insufficient funds.');
                            } else if (error.status === 409) {
                                await this.toastService.error('This gift card has already been applied to this tab.');
                            } else if (error.status === 424) {
                                await this.toastService.error('The gift card could not be applied.');
                            }
                        });
                } else {
                    this.navController.navigateForward(`/pay-with-card/${this.pickupLocation.id}/${this.tab.id}?giftCardId=${dataReturned.data.id}`);
                }
            }
        });

        await modal.present();

    }

    async refundGiftCardPayment(giftCardPayment: GiftCardAction) {
        const modal = await this.modalController.create({
            component: ApplyGiftCardComponent,
            componentProps: {
                tab: this.tab,
                organization: this.organization,
                refund: true
            }
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.loadingService.present();
                this.tabService.refundGiftCardAction(this.tab, giftCardPayment.id, dataReturned.data.cardNumber)
                    .subscribe(() => {
                        this.tabService.get(this.tab.id).subscribe(tab => {
                            this.tab = new Tab(tab);
                            this.tabService.setEvent(this.tab);
                            this.loadingService.dismiss();
                        });
                    }, async error => {
                        if (error.status === 409) {
                            await this.toastService.error('The gift card number does not match.');
                        } else if (error.status === 424) {
                            await this.toastService.error('The gift card could not be deleted.');
                        }
                    });
            }
        });

        await modal.present();
    }

    async refundPayment(paymentCharge: PaymentCharge) {
        if ((paymentCharge.totalPrincipalRefunds() < paymentCharge.principal || paymentCharge.totalTipRefunds() < paymentCharge.tip)
            && this.currentUser.authorizedTo(FeatureKeys.REFUND_PAYMENT, this.tab.orgId) != null) {

            this.loadingService.present('Confirming charge details.');
            this.paymentChargeService.get(paymentCharge.id).subscribe(async (details: PaymentCharge) => {
                this.loadingService.dismiss();
                if (!!details.registerId && !this.terminal) {
                    await this.toastService.error('This device must be connected to a terminal to refund this transaction.');
                } else {
                    const modal = await this.modalController.create({
                        component: PaymentChargeRefundComponent,
                        componentProps: {
                            paymentCharge: new PaymentCharge(details)
                        }
                    });

                    await modal.present();
                    await modal.onDidDismiss().then(dataReturned => {
                        if (dataReturned != null && dataReturned.data != null) {
                            this.refresh();
                        }
                    });
                }
            });
        }
    }

    async ratingResponse() {
        if (this.currentUser.isOrgAdmin(this.tab.orgId)) {

            this.loadingService.present();
            this.organizationRatingService.get(this.tab.rating.id).subscribe(async rating => {
                const modal = await this.modalController.create({
                    component: EditRatingsComponent,
                    componentProps: {
                        rating,
                        tabTotal: this.tab.paymentInformation.invoiced
                    }
                });

                await modal.present();

                this.loadingService.dismiss();

                await modal.onDidDismiss().then((dataReturned) => {
                    if (dataReturned && dataReturned.data != null) {
                        this.tab.rating = dataReturned.data;
                    }
                });
            });
        }
    }

    async payWithCash() {
        const modal = await this.modalController.create({
            component: CashPaymentComponent,
            componentProps: {
                tab: this.tab
            }
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.closing = true;
                const audit = new CashAudit(dataReturned.data);
                this.cashAuditService.payTab(audit).subscribe(async (t: Tab) => {
                    const tab = new Tab(t);
                    this.closing = false;
                    this.tabService.setEvent(tab, true);
                    this.loadingService.dismiss();
                }, async error => {
                    this.closing = false;
                    this.loadingService.dismiss();
                    if (error.status === 424) {
                        this.orderService.handlePendingError(error);
                    } else if (error.status === 406 && error.error.message) {
                        await this.toastService.error(error.error.message);
                    }
                });
            }
        });

        await modal.present();
    }

    async createInvoice() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Invoice',
            message: 'Are you sure you want to invoice this tab? The tab will behave as a normal tab and additional orders can be added to it until the tab is closed.',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.tab.parentId = this.organization.id;
                        this.tabService.startInvoice(this.tab)
                            .subscribe(async t => {
                                const tab = new Tab(t);
                                this.tabService.setCurrent(tab);
                                this.tabService.setEvent(tab);
                                await this.navController.navigateRoot(`/thank-you/${this.pickupLocation.id}/${this.tab.id}`);
                                this.loadingService.dismiss();
                            }, async error => {
                                if (error.status === 406) {
                                    await this.toastService.error('This organization does not accept invoices.');
                                }
                            });
                    }
                }
            ]
        });

        await alert.present();
    }

    async payWithCardSplit() {
        const modal = await this.modalController.create({
            component: CustomDollarAmountComponent,
            componentProps: {
                amount: '',
                max: this.tab.paymentInformation.remaining,
                label: 'Split',
                splitMax: this.tab.invoiced
            },
            breakpoints: [0.0, 1],
            initialBreakpoint: 1,
            cssClass: 'quick-input-modal'
        });
        await modal.present();

        modal.onDidDismiss().then(async (returnData) => {
            if (!!returnData && !!returnData.data) {
                this.payWithCard(Number.parseFloat(returnData.data));
            }
        });
    }

    async payWithCard(amount: number) {
        if (!this.closing) {
            if (!!this.terminal && this.terminal.promptTipOnReader) {
                this.closing = true;

                this.setReferenceId();

                this.loading = await this.organizationTerminalService.loading(this.terminal.terminalId,
                    this.organization.id, !!this.tab.paymentInformation.depositsRemaining, this.referenceId);

                let gratuityEligibleAmount = 0;
                if (this.terminal.promptTipOnReader) {
                    gratuityEligibleAmount = amount < this.tab.invoiced ? amount : this.tab.subtotal;
                }

                try {
                    await this.organizationTerminalService.payTab(this.terminal.terminalId, this.tab.id,
                        amount, 0, gratuityEligibleAmount, this.referenceId).toPromise();

                    this.beginPolling();
                } catch (error) {
                    console.log(error);
                    this.closing = false;
                    await this.loading.dismiss(true);
                }
            } else {
                await this.navController.navigateForward(`/pay-with-card/${this.pickupLocation.id}/${this.tab.id}`);
            }
        }
    }

    async presentChangeCard() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Change Card',
            message: 'Changing the card on file will detach the claimed mobile session if it already exists. The cardholder may resume the session by scanning the QR code after the card is updated. Would you still like to continue?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.navController.navigateForward(`/start-tab/${this.pickupLocation.id}/${this.tab.id}`,
                            {queryParams: {changeCardOnFile: true}});
                    }
                }
            ]
        });

        await alert.present();
    }

    async addCard() {
        await this.navController.navigateForward(`/start-tab/${this.pickupLocation.id}/${this.tab.id}`,
            {queryParams: {changeCardOnFile: true}});
    }

    async openMenu(pendingOrder?) {
        this.tabService.setCurrent(this.tab);
        this.membershipService.setCurrent(this.activeMembership);

        if (!!pendingOrder) {
            this.orderService.setCurrent(pendingOrder);
        } else {
            this.orderService.setCurrent(!!this.pendingOrders && this.pendingOrders.length > 0 ? this.pendingOrders[0] : null);
        }
        await this.navController.navigateForward(`/manager/${this.pickupLocation.id}/manual-order`);
    }

    async checkPending() {
        const pendingItems = !!this.pendingOrders && this.pendingOrders.length > 0 ?
            this.pendingOrders.reduce((items, o) => {
                return items.concat(o.items);
            }, []) : null;
        if (!!pendingItems && pendingItems.length > 0) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Pending Orders',
                message: 'A pending order has not been submitted for this tab. Continuing will cancel any pending orders. Do you wish to continue?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.closeTab();
                        }
                    }
                ]
            });

            await alert.present();
        } else {
            await this.closeTab();
        }
    }

    isCardAvailable() {
        return !!this.tab.paymentCharges
            && this.tab.paymentCharges.length > 0
            && this.tab.paymentCharges.some(p => p.cardSummary.tokenized);
    }

    async selectExistingCard() {
        const modal = await this.modalController.create({
            component: SelectExistingCardComponent,
            componentProps: {
                tab: this.tab
            },
            breakpoints: [0.0, 1],
            initialBreakpoint: 1,
            cssClass: 'select-card-modal'
        });
        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refresh();
            }
        });
    }

    async closeTab() {
        if (this.tab.paymentInformation.invoiceCreated) {
            this.loadingService.present();
            this.tabService.closeTab(this.tab, false).subscribe((t: Tab) => {
                this.tab = new Tab(t);
                this.tabService.setCurrent(this.tab);
                this.tabService.setEvent(this.tab);
                this.loadingService.dismiss();
                this.toastService.success('The tab has been closed.');
                if (t.paymentInformation.paymentStatus === PaymentStatuses.PAID) {
                    this.navController.navigateRoot(`/manager/${this.pickupLocation.id}/open`);
                }
            }, error => {
                console.log(error);
                this.loadingService.dismiss();
                this.toastService.error(Constants.SERVER_ERROR);
            });
        } else if (this.tab.paymentInformation.remaining > 0 || this.tab.paymentInformation.tokenPaymentsTotal > 0) {
            this.orderService.setCurrent(null);
            this.tabService.setCurrent(null);
            await this.navController.navigateForward(`/confirm-tab/${this.pickupLocation.id}/${this.tab.id}`);
        } else {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Close Tab',
                message: 'The remaining amount on this tab is $0. Closing the tab will prevent future orders without charging the card. Do you wish to continue?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.loadingService.present();
                            this.tab.pendingTip = 0;
                            this.tabService.closeTab(this.tab, false).subscribe(() => {
                                this.loadingService.dismiss();
                                this.toastService.success('The tab has been closed.');
                                this.navController.navigateRoot(`/manager/${this.pickupLocation.id}/open`);
                            }, error => {
                                console.log(error);
                                this.loadingService.dismiss();
                                this.toastService.error(Constants.SERVER_ERROR);
                            });
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    async selectMember() {
        this.loadingService.present();
        this.membershipService.findActiveByOrganizationId(this.organization.id).subscribe(async (members: MembershipSummary[]) => {
            this.loadingService.dismiss();

            const modal = await this.modalController.create({
                component: SelectMemberComponent,
                componentProps: {
                    members,
                    currentMember: this.tab.member
                },
                cssClass: 'menu-modal'
            });

            await modal.present();

            await modal.onDidDismiss().then(async dataReturned => {
                const membership = !!dataReturned && !!dataReturned.data ? new Membership(dataReturned.data) : null;
                if (!!membership) {
                    this.loadingService.present();
                    this.tabService.updateMember(this.tab.id, membership).subscribe(t => {
                        const tab = new Tab(t);
                        this.tabService.setEvent(tab);
                        this.tabService.setCurrent(tab);
                        this.loadingService.dismiss();
                    });
                }
            });
        });
    }

    async assignServer() {
        const modal = await this.modalController.create({
            component: SelectServerComponent,
            componentProps: {
                tabId: this.tab.id,
                serverId: !!this.tab.server ? this.tab.server.id : null
            }
        });

        await modal.present();

    }

    async claimServer() {
        if (!!this.tab.id) {
            this.loadingService.present();
            this.tabService.updateServer(this.tab.id, this.currentUser.person).subscribe((t: Tab) => {
                this.tabService.setEvent(new Tab(t));
                this.tab.server = t.server;
                this.loadingService.dismiss();
            }, (error) => {
                this.loadingService.dismiss();
                if (error.status === 417) {
                    this.toastService.error('The server is not valid for this tab.');
                }
            });
        }
    }

    async changeCustomerPosition() {
        if (!!this.organization.customerAreas && this.organization.customerAreas.length > 0) {
            const modal = await this.modalController.create({
                component: ChangeCustomerPositionComponent,
                componentProps: {
                    customerAreas: this.organization.customerAreas,
                    position: this.tab.customerAreaPosition
                }
            });

            await modal.present();

            await modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.loadingService.present();
                    this.tabService.updateCustomerPosition(this.tab.id, !!dataReturned.data ? dataReturned.data.id : null)
                        .subscribe((t: Tab) => {
                            this.tab.customerAreaPosition = !!t.customerAreaPosition
                                ? new CustomerAreaPosition(t.customerAreaPosition)
                                : null;
                            this.loadingService.dismiss();
                        }, error => {
                            if (error.status === 424) {
                                this.toastService.error('The selected position is invalid.');
                            } else if (error.status === 417) {
                                this.toastService.error('The tab is in an invalid status.');
                            }
                            this.loadingService.dismiss();
                        });

                }
            });
        }
    }

    async quickClose() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Quick Close',
            message: `Are you sure you would like to close this tab with a 20% tip and apply the mobile fee of ${this.currencyPipe.transform(this.tab.potentialFee)}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.tab.pendingTip = multiplyCurrency(this.tab.subtotal, .2);
                        this.tabService.closeTab(this.tab, true).subscribe(async (t: Tab) => {
                            this.tab = new Tab(t);
                            this.tabService.setEvent(this.tab);
                            this.changeDetectorRef.detectChanges();
                            this.loadingService.dismiss();
                        }, async error => {
                            if (error.status === 424) {
                                await this.toastService.error('The payment could not be processed.');
                            } else if (!!error.error && !!error.error.message) {
                                await this.toastService.error(error.error.message);
                            }
                            this.loadingService.dismiss();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async removeOrderCredit(data: any) {
        const credit: OrderItemCredit = data.credit;
        this.loadingService.present();
        this.tabService.deleteItemCredit(credit.id).subscribe((t) => {
            const tab = new Tab(t);
            this.tabService.setCurrent(tab);
            this.tabService.setEvent(tab);
            this.loadingService.dismiss();
        });
    }

    addOrderCredit(orderItem: OrderItem, credit: OrderItemCredit) {
        this.loadingService.present();
        this.tabService.addItemCredit(this.tab.id, credit).subscribe((t: Tab) => {
            const tab = new Tab(t);
            const order = tab.orders.find(o => o.id === orderItem.parentId);

            this.tabService.setEvent(tab);
            this.tabService.setCurrent(tab);

            if (!!this.order && this.order.id === order.id) {
                this.orderService.setCurrent(order);
            }
            this.loadingService.dismiss();
        });
    }

    addTabCredit(credit: TabCredit) {
        const found = !!this.tab.credits ? this.tab.credits.find(c => c.compDefinition.id === credit.compDefinition.id) : null;
        if (!found || credit.compDefinition.type === CompDefinitionTypes.MANUAL) {
            this.loadingService.present();
            this.tabService.addTabCredit(this.tab.id, credit).subscribe(async (t: Tab) => {
                const tab = new Tab(t);
                this.loadingService.dismiss();
                this.tabService.setCurrent(tab);
                this.tabService.setEvent(tab);
            }, async (error) => {
                if (error.status === 400) {
                    await this.toastService.error('Credit amount is invalid.');
                    this.loadingService.dismiss();
                }
            });
        } else {
            this.toastService.message(`${credit.compDefinition.name} already exists on this tab.`);
        }
    }

    async removeItemTokens(data: any) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Token Removal',
            message: `Are you sure you want to remove this token payment?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        const item: OrderItem = data;
                        this.loadingService.present();
                        this.tabService.updateTokens(this.tab.id, item, null).subscribe((t) => {
                            const tab = new Tab(t);
                            this.tabService.setCurrent(tab);
                            this.tabService.setEvent(tab);
                            this.loadingService.dismiss();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    openEditTaxExemption() {
        this.editTaxExemption = true;
    }

    closeEditTaxExemption(taxExemptionId: string) {
        if ((!!taxExemptionId || !!this.tab.taxExemptionId) && taxExemptionId !== this.tab.taxExemptionId) {
            this.loadingService.present();
            const t = new Tab({
                id: this.tab.id,
                taxExemptionId: !!taxExemptionId ? taxExemptionId : null
            });

            this.tabService.taxExemptionId(t).subscribe((tab: Tab) => {
                this.loadingService.dismiss();
                this.tab = new Tab(tab);
                this.tabService.setEvent(this.tab);
                this.tabService.setCurrent(this.tab);
            }, error => {
                if (error.status === 409) {
                    this.toastService.error('Tax exemptions are not configured.');
                }
                this.loadingService.dismiss();
            });
        }
        this.editTaxExemption = false;
    }

    cashPayment(payment) {
        return payment as CashAudit;
    }

    cardPayment(payment) {
        return payment as PaymentCharge;
    }

    giftCardPayment(payment) {
        return payment as GiftCardAction;
    }

    async displayDeposit(deposit: Deposit) {
        this.loadingService.present();
        const deposits = !!deposit.paymentCharge ?
            await this.depositService.findByPaymentCharge(deposit.paymentCharge.id).toPromise() :
            await this.depositService.findByCashAudit(deposit.cashAudit.id).toPromise();
        this.loadingService.dismiss();
        const modal = await this.modalController.create({
            component: DepositRefundComponent,
            componentProps: {
                deposits,
                paymentCharge: deposit.paymentCharge,
                cashAudit: deposit.cashAudit
            }
        });

        modal.onDidDismiss().then(() => {
            this.loadingService.present();
            this.refresh();
        });

        await modal.present();
    }

    viewCardHistory(cardSummary: CardSummary) {
        if (!!this.currentUser && this.currentUser.authorizedTo(FeatureKeys.CARD_SUMMARY, this.organization.id)) {
            this.navController.navigateForward(`/${this.organization.id}/card-history/${cardSummary.id}`);
        }
    }

    async printReceipt() {
        let deposits: Deposit[] = null;
        if (this.tab.deposits > 0) {
            deposits = await this.depositService.findByTab(this.tab.id).toPromise() as Deposit[];
        }

        const output = buildReceipt(this.organization, this.tab, this.printerService.columns, deposits);
        if (this.printerService.isConnected()) {
            this.printerService.print(output);
        } else {
            await this.toastService.error('The receipt could not be printed.');
        }
    }

    async printClaim() {
        this.printerService.printClaimTab(this.tab);
    }

    quickAdd(historyItem: MembershipOrderItemHistory) {
        const order = this.orderService.initialize(this.menu, this.pickupLocation);
        order.parentId = this.tab.id;

        const orderItem = this.orderItemService.initializeOrderItem(historyItem.menuItem);
        orderItem.key = uuidv4();
        orderItem.selectedPrice = historyItem.menuItemPrice;
        orderItem.addIndex = 1;
        order.items.push(new OrderItem(orderItem));
        order.status = this.organization.autoDeliver ? OrderStatuses.DELIVERED : OrderStatuses.RECEIVED;
        order.terminalOrder = true;

        this.applyComps(order);
        this.orderService.applyMembershipTokens(order, this.activeMembership, this.activeMembership.availableTokens);

        this.loadingService.present();
        this.orderService.activateOrder(order).subscribe((t: Tab) => {
            const saved = new Tab(t);
            this.tabService.setEvent(saved);
            this.tabService.setCurrent(saved);
            this.loadingService.dismiss();
        });
    }

    getMenuItemId(index: number, obj: any) {
        return obj.menuItemId;
    }
}
