import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, ColumnField, ColumnFormats, Filter, FilterValue, Menu, Organization, ReportColumns} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-tab-report-filter',
    templateUrl: './edit-tab-report-filter.component.html',
    styleUrls: ['./edit-tab-report-filter.component.scss'],
})
export class EditTabReportFilterComponent extends BaseModalComponent implements OnInit {
    @Input() filter: Filter;
    @Input() menu: Menu;
    @Input() organization: Organization;

    loaded = false;
    allColumns = [...ReportColumns];
    options = [];
    type = 'LONG';

    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.allColumns = this.allColumns.filter(c => !!c.filterType
            || c.format === ColumnFormats.BOOLEAN
            || c.format === ColumnFormats.CURRENCY);

        this.allColumns = this.allColumns.sort((a, b) => {
            if (a.display < b.display) {
                return -1;
            } else if (a.display > b.display) {
                return 1;
            }
            return 0;
        });

        const column = !!this.filter ? this.allColumns.find(c => c.key === this.filter.field) : null;

        let selected = [];
        let initComparisonValue = '';
        if (!!column && !!column.filterType) {
            this.type = column.filterType;
            selected = this.filter.values.map(s => !!column && column.filterType === 'LONG' ? Number(s.value) : s.value);
        } else if (!!column && column.format === ColumnFormats.BOOLEAN) {
            this.type = ColumnFormats.BOOLEAN;
            initComparisonValue = this.filter.values[0].value;
        } else {
            this.type = 'BIG_DECIMAL';
            initComparisonValue = !!this.filter && !!this.filter.values && this.filter.values.length === 1
                ? this.filter.values[0].value
                : '';
        }

        this.formGroup = this.formBuilder.group({
            id: !!this.filter ? this.filter.id : null,
            field: [!!this.filter ? this.filter.field : null, Validators.required],
            operator: [!!this.filter ? this.filter.operator : null, Validators.required],
            selectedValues: [selected],
            comparisonValue: initComparisonValue
        });

        this.load();
    }

    clear() {
        this.formGroup.controls.selectedValues.setValue(null);
        this.formGroup.controls.comparisonValue.setValue(null);
        this.formGroup.controls.operator.setValue(null);
        this.load();
    }

    load() {
        const field = this.formGroup.controls.field.value;
        if (field) {
            const column = this.allColumns.find(f => f.key === field);

            if (!!column && !!column.filterType) {
                this.type = column.filterType;
            } else if (!!column && column.format === ColumnFormats.BOOLEAN) {
                this.type = ColumnFormats.BOOLEAN;
            } else {
                this.type = 'BIG_DECIMAL';
            }
            if (!!column && !!column.filterType) {
                this.type = column.filterType;
                this.formGroup.get('selectedValues').setValidators([Validators.required]);
                this.formGroup.get('comparisonValue').setValidators([]);
            } else {
                this.type = column.format === 'CURRENCY' ? 'BIG_DECIMAL' : column.format;
                if (this.type === ColumnFormats.BOOLEAN) {
                    this.formGroup.controls.operator.setValue('EQUALS');
                    if (!this.formGroup.controls.comparisonValue.value) {
                        this.formGroup.controls.comparisonValue.setValue('false');
                    }
                }

                this.formGroup.get('comparisonValue').setValidators([Validators.required]);
                this.formGroup.get('selectedValues').setValidators([]);
            }
            this.formGroup.get('selectedValues').updateValueAndValidity();
            this.formGroup.get('comparisonValue').updateValueAndValidity();

            switch (field) {
            case 'MENU_LABEL_ID':
                this.options = this.menu.menuLabels.map(l => {
                    return {id: l.id, label: l.name};
                });
                break;

            case 'MENU_CATEGORY_ID':
                this.options = this.menu.menuCategories.map(cat => {
                    return {id: cat.id, label: cat.name};
                });
                this.options.push({id: -1, label: 'Uncategorized'});
                break;

            case 'MENU_ITEM_ID':
                this.options = this.menu.menuItems.map(i => {
                    return {id: i.id, label: i.name};
                });
                this.options.push({id: -1, label: 'Uncategorized'});
                break;

            case 'PICKUP_LOCATION_ID':
                this.options = this.organization.pickupLocations.map(p => {
                    return {id: p.id, label: p.name};
                });
                break;

            case 'ORDER_EVENT':
                this.options = this.organization.events.map(e => {
                    return {id: e.name, label: e.name};
                });
                break;

            case 'ORDER_ITEM_SELECTED_PRICE_NAME':
                this.options = this.menu.menuItems.reduce((acc, i) => {
                    i.prices.forEach(p => {
                        if (!acc.some(a => a.id === p.name)) {
                            acc.push({id: p.name, label: p.name});
                        }
                    });
                    return acc;
                }, []);
                break;

            case 'TAB_NOTE':
                this.options = this.organization.tabNoteDefinitions.reduce((acc, i) => {
                    acc.push({id: i.name, label: i.name});
                    return acc;
                }, []);
                break;

            case 'ORDER_ITEM_CREDIT_TYPE':
                this.options = [
                    {id: 'UNSATISFACTORY', label: 'Unsatisfactory'},
                    {id: 'POOR_SERVICE', label: 'Poor Service'},
                    {id: 'LATE', label: 'Late'},
                    {id: 'NEVER_RECEIVED', label: 'Never Received'},
                    {id: 'INCORRECT_ITEM', label: 'Incorrect Order'},
                    {id: 'FRAUDULENT', label: 'Fraudulent'},
                    {id: 'DUPLICATE', label: 'Duplicate'},
                    {id: 'REWARD', label: 'Reward'},
                    {id: 'OTHER', label: 'Other'}
                ];
                break;

            case 'ORDER_STATUS':
                this.options = [
                    {id: 'DELIVERED', label: 'Delivered'},
                    {id: 'CANCELED', label: 'Canceled'}
                ];
                break;

            case 'PAYMENT_INFORMATION_PAYMENT_STATUS':
                this.options = [
                    {id: 'PRE_AUTH', label: 'Card on File'},
                    {id: 'PAID', label: 'Paid'},
                    {id: 'PARTIAL_PAID', label: 'Partial Paid'},
                    {id: 'UNPAID', label: 'Unpaid'},
                    {id: 'OVER_PAID', label: 'Over Paid'},
                    {id: 'CANCELED', label: 'Canceled'},
                    {id: 'UNKNOWN', label: 'Unknown'}
                ];
                break;

            case 'PAYMENT_INFORMATION_PAYMENT_TYPE':
                this.options = [
                    {id: 'NONE', label: 'None'},
                    {id: 'CASH', label: 'Cash'},
                    {id: 'CHARGE', label: 'Charge'},
                    {id: 'MIX', label: 'Mix'},
                    {id: 'UNKNOWN', label: 'Unknown'}
                ];
                break;

            default:
                this.options = [];
            }
        } else {
            this.options = [];
        }

        this.options = this.options.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        this.loaded = true;
    }

    fieldChange(columnField: ColumnField) {
        if (!!columnField) {
            this.formGroup.controls.field.setValue(columnField.key);
            this.clear();
        }
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const filter = new Filter();
            filter.id = this.formGroup.controls.id.value;
            filter.field = this.formGroup.controls.field.value;
            filter.operator = this.formGroup.controls.operator.value;
            const filterValues = [];
            if (this.type === 'BIG_DECIMAL') {
                const filterValue = new FilterValue();
                filterValue.value = this.formGroup.controls.comparisonValue.value;
                filterValues.push(filterValue);
            } else if (this.type === 'BOOLEAN') {
                const filterValue = new FilterValue();
                filterValue.value = this.formGroup.controls.comparisonValue.value;
                filterValues.push(filterValue);
            } else {
                this.formGroup.controls.selectedValues.value.forEach(v => {
                    const filterValue = new FilterValue();
                    filterValue.value = v;
                    filterValues.push(filterValue);
                });
            }
            filter.values = filterValues;
            filter.type = this.type;
            this.close(filter);
        }
    }
}
