import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    Column,
    Filter,
    getReportFieldDisplayName,
    Menu,
    MenuService,
    Organization,
    reorder,
    Sort,
    TabReport
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertController, ModalController} from '@ionic/angular';
import {EditTabReportColumnComponent} from '../edit-tab-report-column/edit-tab-report-column.component';
import {EditTabReportFilterComponent} from '../edit-tab-report-filter/edit-tab-report-filter.component';

@Component({
    selector: 'bb-edit-tab-report',
    templateUrl: './edit-tab-report.component.html',
    styleUrls: ['./edit-tab-report.component.scss'],
})
export class EditTabReportComponent extends BaseStepperFormComponent implements OnInit {
    @Input() tabReport: TabReport;
    @Input() type: string;
    @Input() organization: Organization;
    menu: Menu;
    formGroup: FormGroup;
    columns: Column[];
    filters: Filter[];

    getReportFieldDisplayName = getReportFieldDisplayName;

    constructor(
        modalController: ModalController,
        private menuService: MenuService,
        private alertController: AlertController,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuService.current.subscribe(m => this.menu = m);

        this.columns = !!this.tabReport && !!this.tabReport.columns ? [...this.tabReport.columns] : [];
        this.filters = !!this.tabReport && !!this.tabReport.baseFilters ? [...this.tabReport.baseFilters] : [];
        const sort = !!this.tabReport && !!this.tabReport.sort && this.tabReport.sort.length > 0 ? this.tabReport.sort[0] : null;

        const sortColumn = !!sort
            ? this.columns.find(c => c.id === sort.column.id)
            : null;

        this.formGroup = this.formBuilder.group(
            {
                id: !!this.tabReport ? this.tabReport.id : null,
                title: [!!this.tabReport ? this.tabReport.title : '', Validators.required],
                description: !!this.tabReport ? this.tabReport.description : '',
                sortColumn,
                sortDirection: !!sort ? sort.sortDirection : 'ASC',
                durationPart: [!!this.tabReport ? this.tabReport.durationPart : '', Validators.required],
                durationCount: [!!this.tabReport ? this.tabReport.durationCount : '', Validators.required]
            }
        );
    }

    async editColumn(column: Column) {
        const modal = await this.modalController.create({
            component: EditTabReportColumnComponent,
            componentProps: {
                column,
                menu: this.menu,
                showSummaryAggregate: true
            },
            cssClass: 'report-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                if (!!column) {
                    this.columns[this.columns.indexOf(column)] = dataReturned.data;
                } else {
                    this.columns.push(dataReturned.data);
                }
            }
        });
    }

    async editFilter(filter: Filter) {
        const modal = await this.modalController.create({
            component: EditTabReportFilterComponent,
            componentProps: {
                filter,
                menu: this.menu,
                organization: this.organization
            },
            cssClass: 'report-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                if (!!filter) {
                    this.filters[this.filters.indexOf(filter)] = dataReturned.data;
                } else {
                    this.filters.push(dataReturned.data);
                }
            }
        });
    }

    removeFilter(event, filter) {
        event.stopPropagation();
        this.filters.splice(this.filters.indexOf(filter), 1);
    }

    valid() {
        return this.formGroup.valid && !!this.columns && this.columns.length > 0;
    }

    removeColumn(column: Column) {
        this.columns.splice(this.columns.indexOf(column), 1);
    }

    reorderColumns(event) {
        reorder(this.columns, event);
    }

    onSubmit() {
        const t = new TabReport(this.formGroup.value);
        t.columns = this.columns;
        t.baseFilters = this.filters;
        t.parentId = this.menu.orgId;
        if (!!this.formGroup.controls.sortColumn.value) {
            const sort = new Sort();
            sort.sortDirection = this.formGroup.controls.sortDirection.value;
            sort.column = this.formGroup.controls.sortColumn.value;
            t.sort = [sort];
        } else {
            t.sort = [];
        }
        this.close(t);
    }
}
