import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BaseModalComponent, CashSession, CashSessionNote, CashSessionNoteService, LoadingService, ToastService} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-cash-session-note',
    templateUrl: './edit-cash-session-note.component.html',
    styleUrls: ['./edit-cash-session-note.component.scss'],
})
export class EditCashSessionNoteComponent extends BaseModalComponent implements OnInit {
    @Input() cashSessionNote: CashSessionNote;
    @Input() cashSession: CashSession;

    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private cashSessionNoteService: CashSessionNoteService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.cashSessionNote ? this.cashSessionNote.id : null,
            parentId: this.cashSession.id,
            note: !!this.cashSessionNote ? this.cashSessionNote.note : ''
        });
    }

    onSubmit() {
        this.loadingService.present();
        const cashSessionNote = new CashSessionNote(this.formGroup.value);

        if (!cashSessionNote.id) {
            this.cashSessionNoteService.create(cashSessionNote).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Note created.');
                this.close(new CashSessionNote(c));
            });
        } else {
            this.cashSessionNoteService.update(cashSessionNote).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Note updated.');
                this.close(new CashSessionNote(c));
            });
        }
    }
}
