import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'bb-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
