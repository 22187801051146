import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {BaseModalComponent, GiftCard, GiftCardService, LoadingService, Organization, Tab} from 'brewbill-lib';
import {IonInput, ModalController} from '@ionic/angular';
import {debounce} from 'lodash';

@Component({
    selector: 'bb-apply-gift-card',
    templateUrl: './apply-gift-card.component.html',
    styleUrls: ['./apply-gift-card.component.scss'],
})
export class ApplyGiftCardComponent extends BaseModalComponent implements OnInit {
    @Input() organization: Organization;
    @Input() tab: Tab;
    @Input() refund = false;

    searchString = '';
    giftCard: GiftCard;
    last: GiftCard;
    prefix: string;

    @ViewChild('cardNumber') set content(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 50);
        }
    }

    constructor(
        modalController: ModalController,
        private giftCardService: GiftCardService,
        private loadingService: LoadingService
    ) {
        super(modalController);
        this.search = debounce(this.search, 250);
    }

    ngOnInit() {
        this.loadingService.present();
        this.giftCardService.last(this.organization.id).subscribe((g: GiftCard) => {
            if (!!g && !!g.cardNumber && g.cardNumber.length > 6) {
                this.prefix = g.cardNumber.substring(0, g.cardNumber.length - 6);
                this.last = new GiftCard(g);
            }
            this.loadingService.dismiss();
        });
    }

    search() {
        let searchString = this.searchString;
        if (!!searchString && searchString.toString().length >= 6) {
            if (searchString.length === 6 && !!this.prefix) {
                searchString = this.prefix + searchString;
            }
            this.giftCardService.findByCardNumber(this.organization.id, searchString)
                .subscribe(c => this.giftCard = !!c ? new GiftCard(c) : null);
        } else {
            this.giftCard = null;
        }
    }

    apply() {
        this.close(this.giftCard);
    }
}
