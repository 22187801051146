import {Component, OnInit} from '@angular/core';
import {BaseOrganizationComponent} from '../base-organization.component';
import {
    FeatureKeys,
    LoadingService,
    OrganizationRole,
    OrganizationRoleService,
    OrganizationService,
    ToastService,
    User,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';
import {EditOrganizationRoleComponent} from '../edit-organization-role/edit-organization-role.component';

@Component({
    selector: 'bb-organization-users',
    templateUrl: './organization-users.component.html',
    styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent extends BaseOrganizationComponent implements OnInit {

    users: User[];

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private modalController: ModalController,
        private alertController: AlertController,
        private organizationRoleService: OrganizationRoleService,
        private toastService: ToastService
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.refreshUsers();
    }

    refreshUsers() {
        this.userService.getOrgUsers(this.organization.id).subscribe((users: User[]) => {
            const sanitizedUsers = [];
            users.forEach((u: User) => {
                if (u.organizations && u.organizations.length > 0) {
                    u.organizations = u.organizations.filter(r => r.orgId === this.organization.id);
                }
                sanitizedUsers.push(new User(u));
            });
            if (!!sanitizedUsers) {
                sanitizedUsers.sort((a, b) => a.person.lastName.localeCompare(b.person.lastName));
            }
            this.users = sanitizedUsers;
        });
    }

    async editRole(role = null) {
        if (this.currentUser.authorizedTo(FeatureKeys.ORG_ADMIN, this.organization.id)) {
            const organizationRole = new OrganizationRole(!!role ? role : {});
            const modal = await this.modalController.create({
                component: EditOrganizationRoleComponent,
                cssClass: 'large-modal',
                componentProps: {
                    organizationRole,
                    organization: this.organization
                }
            });

            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.refreshOrganization();
                }
            });
        }
    }

    async confirmDeleteRole(event, role: OrganizationRole) {
        event.stopPropagation();
        if (this.currentUser.authorizedTo(FeatureKeys.ORG_ADMIN, this.organization.id)) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Confirm Delete',
                message: `Are you sure you want to remove ${role.name}? This will remove the role from all associated users.`,
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.loadingService.present();
                            this.organizationRoleService.delete(role.id).subscribe(() => {
                                this.loadingService.dismiss();
                                this.toastService.success('The role has been removed.');
                                this.refreshOrganization();
                            });
                        }
                    }
                ]
            });

            await alert.present();
        }
    }
}
