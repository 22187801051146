import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import {IonicModule} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
    BrewBillLibModule,
    DateAgoPipe,
    LeftPadPipe,
    MaterialModule,
    NegativeCurrencyPipe,
    OrderStatusColorPipe,
    OrderStatusPipe,
    UnderscoreConversionPipe
} from 'brewbill-lib';

import {environment} from '../../../../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditMenuCategoryComponent} from './_components/edit-menu-category/edit-menu-category.component';
import {EditMenuLabelComponent} from './_components/edit-menu-label/edit-menu-label.component';
import {RegisterOrganizationComponent} from './_components/register-organization/register-organization.component';
import {EditMenuItemComponent} from './_components/edit-menu-item/edit-menu-item.component';
import {LabelHelpComponent} from './_components/label-help/label-help.component';
import {EditMenuItemPriceComponent} from './_components/edit-menu-item-price/edit-menu-item-price.component';
import {EditMenuToppingComponent} from './_components/edit-menu-topping/edit-menu-topping.component';
import {EditMenuItemToppingComponent} from './_components/edit-menu-item-topping/edit-menu-item-topping.component';
import {EditMenuSelectionComponent} from './_components/edit-menu-selection/edit-menu-selection.component';
import {EditMenuSelectionItemComponent} from './_components/edit-menu-selection-item/edit-menu-selection-item.component';
import {EditMenuTaxLabelComponent} from './_components/edit-menu-tax-label/edit-menu-tax-label.component';
import {EditPickupLocationComponent} from './_components/edit-pickup-location/edit-pickup-location.component';
import {AssignPinCodeComponent} from './_components/assign-pin-code/assign-pin-code.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {ImageCropperModule} from 'ngx-image-cropper';
import {RegisterVenueComponent} from './_components/register-venue/register-venue.component';
import {EditOrganizationLocationComponent} from './_components/edit-organization-location/edit-organization-location.component';
import {EditMenuMarqueeItemComponent} from './_components/edit-menu-marquee-item/edit-menu-marquee-item.component';
import {ManagerEmptyComponent} from './_components/manager-empty/manager-empty.component';
import {OpenOrdersComponent} from './_components/open-orders/open-orders.component';
import {OrderHistoryComponent} from './_components/order-history/order-history.component';
import {CustomersComponent} from './_components/customers/customers.component';
import {EditTabReportComponent} from './_components/edit-tab-report/edit-tab-report.component';
import {EditTabReportColumnComponent} from './_components/edit-tab-report-column/edit-tab-report-column.component';
import {EditDashboardWidgetComponent} from './_components/edit-dashboard-widget/edit-dashboard-widget.component';
import {ManualOrderComponent} from './_components/manual-order/manual-order.component';
import {EditTabReportFilterComponent} from './_components/edit-tab-report-filter/edit-tab-report-filter.component';
import {QRCodeModule} from 'angularx-qrcode';
import {TabsComponent} from './_components/tabs/tabs.component';
import {EditCustomerAreaComponent} from './_components/edit-customer-area/edit-customer-area.component';
import {
    EditOrganizationRewardProgramComponent
} from './_components/edit-organization-reward-program/edit-organization-reward-program.component';
import {OrganizationPreferenceComponent} from './_components/organization-preference/organization-preference.component';
import {EditCustomerAreaPositionComponent} from './_components/edit-customer-area-position/edit-customer-area-position.component';
import {EditTabReportEmailComponent} from './_components/edit-tab-report-email/edit-tab-report-email.component';
import {OrganizationDetailsComponent} from './_components/organization-details/organization-details.component';
import {OrganizationLocationsComponent} from './_components/organization-locations/organization-locations.component';
import {OrganizationMenuComponent} from './_components/organization-menu/organization-menu.component';
import {OrganizationUsersComponent} from './_components/organization-users/organization-users.component';
import {OrganizationSettingsComponent} from './_components/organization-settings/organization-settings.component';
import {EndOfDayComponent} from './_components/end-of-day/end-of-day.component';
import {TimecardsComponent} from './_components/timecards/timecards.component';
import {MarketingComponent} from './_components/marketing/marketing.component';
import {CashSessionsComponent} from './_components/cash-sessions/cash-sessions.component';
import {EditCashDrawerComponent} from './_components/edit-cash-drawer/edit-cash-drawer.component';
import {AssignCashDrawerComponent} from './_components/assign-cash-drawer/assign-cash-drawer.component';
import {AddCashActionComponent} from './_components/add-cash-action/add-cash-action.component';
import {AddCashCountComponent} from './_components/add-cash-count/add-cash-count.component';
import {CashPaymentComponent} from './_components/cash-payment/cash-payment.component';
import {CashRefundComponent} from './_components/cash-refund/cash-refund.component';
import {EditTipAdjustmentComponent} from './_components/edit-tip-adjustment/edit-tip-adjustment.component';
import {EditCashSessionEmployeeComponent} from './_components/edit-cash-session-employee/edit-cash-session-employee.component';
import {DisplayCashAuditComponent} from './_components/display-cash-audit/display-cash-audit.component';
import {ManagerModule} from './_module/manager.module';
import {GiftCardsComponent} from './_components/gift-cards/gift-cards.component';
import {ApplyGiftCardComponent} from './_components/apply-gift-card/apply-gift-card.component';
import {GiftCardComponent} from './_components/gift-card/gift-card.component';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {SelectPrinterComponent} from './_components/select-printer/select-printer.component';
import {DepositsComponent} from './_components/deposits/deposits.component';
import {DepositRefundComponent} from './_components/deposit-refund/deposit-refund.component';
import {OrganizationOtherComponent} from './_components/organization-other/organization-other.component';
import {EditOrganizationRoleComponent} from './_components/edit-organization-role/edit-organization-role.component';
import {EditCompDefinitionComponent} from './_components/edit-comp-definition/edit-comp-definition.component';
import {ColorSketchModule} from 'ngx-color/sketch';
import {SelectCompComponent} from './_components/select-comp/select-comp.component';
import {SafesComponent} from './_components/safes/safes.component';
import {TransferCashComponent} from './_components/transfer-cash/transfer-cash.component';
import {EditMerchantAccountComponent} from './_components/edit-merchant-account/edit-merchant-account.component';
import {SwiperModule} from 'swiper/angular';
import {InventoryComponent} from './_components/inventory/inventory.component';
import {EditMenuItemInventoryComponent} from './_components/edit-menu-item-inventory/edit-menu-item-inventory.component';
import {MenuItemInventoryTransferComponent} from './_components/menu-item-inventory-transfer/menu-item-inventory-transfer.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {EditTabNoteDefinitionComponent} from './_components/edit-tab-note-definition/edit-tab-note-definition.component';
import {SelectTabNoteComponent} from './_components/select-tab-note/select-tab-note.component';
import {TipOutComponent} from './_components/tip-out/tip-out.component';
import {StarPRNT} from '@awesome-cordova-plugins/star-prnt/ngx';
import {EditOrganizationEventComponent} from './_components/edit-organization-event/edit-organization-event.component';
import {SelectOrganizationEventComponent} from './_components/select-organization-event/select-organization-event.component';
import {OrganizationEventComponent} from './_components/organization-event/organization-event.component';
import {UserTimecardsComponent} from './_components/user-timecards/user-timecards.component';
import {OrganizationConnectionsComponent} from './_components/organization-connections/organization-connections.component';
import {PresentCardForTabComponent} from './_components/present-card-for-tab/present-card-for-tab.component';
import {EditQuickbooksDefinitionComponent} from './_components/edit-quickbooks-definition/edit-quickbooks-definition.component';
import {EditMenuItemGroupComponent} from './_components/edit-menu-item-group/edit-menu-item-group.component';
import {EditCashSessionNoteComponent} from './_components/edit-cash-session-note/edit-cash-session-note.component';
import {MembershipProgramsComponent} from './_components/membership-programs/membership-programs.component';
import {EditMembershipProgramComponent} from './_components/edit-membership-program/edit-membership-program.component';
import {SelectMenuSelectionItemsComponent} from './_components/select-menu-selection-items/select-menu-selection-items.component';
import {EditMembershipTokenGroupComponent} from './_components/edit-membership-token-group/edit-membership-token-group.component';
import {SelectMemberComponent} from './_components/select-member/select-member.component';
import {AddTokenActionComponent} from './_components/add-token-action/add-token-action.component';
import {RenewMembershipComponent} from './_components/renew-membership/renew-membership.component';
import {EditMembershipComponent} from './_components/edit-membership/edit-membership.component';
import {EditMembershipRenewalComponent} from './_components/edit-membership-renewal/edit-membership-renewal.component';
import {SelectCloseoutComponent} from './_components/select-closeout/select-closeout.component';
import {CompleteCashTransferComponent} from './_components/complete-cash-transfer/complete-cash-transfer.component';
import {RequestMembershipCardSaveComponent} from './_components/request-membership-card-save/request-membership-card-save.component';

@NgModule({
    declarations: [
        AppComponent,
        AddCashActionComponent,
        AddCashCountComponent,
        AddTokenActionComponent,
        ApplyGiftCardComponent,
        AssignCashDrawerComponent,
        AssignPinCodeComponent,
        CashPaymentComponent,
        CashRefundComponent,
        CompleteCashTransferComponent,
        CustomersComponent,
        CashSessionsComponent,
        DisplayCashAuditComponent,
        EditCashDrawerComponent,
        EditCashSessionEmployeeComponent,
        EditCashSessionNoteComponent,
        EditCompDefinitionComponent,
        EditCustomerAreaComponent,
        EditCustomerAreaPositionComponent,
        EditDashboardWidgetComponent,
        EditMembershipComponent,
        EditMembershipRenewalComponent,
        EditMembershipProgramComponent,
        EditMembershipTokenGroupComponent,
        EditMenuCategoryComponent,
        EditMenuItemComponent,
        EditMenuItemGroupComponent,
        EditMenuItemInventoryComponent,
        EditMenuItemPriceComponent,
        EditMenuItemToppingComponent,
        EditMenuLabelComponent,
        EditMenuMarqueeItemComponent,
        EditMenuSelectionComponent,
        EditMenuSelectionItemComponent,
        EditMenuTaxLabelComponent,
        EditMenuToppingComponent,
        EditMerchantAccountComponent,
        EditOrganizationEventComponent,
        EditOrganizationLocationComponent,
        EditOrganizationRoleComponent,
        EditOrganizationRewardProgramComponent,
        EditPickupLocationComponent,
        EditQuickbooksDefinitionComponent,
        EditTabNoteDefinitionComponent,
        EditTabReportComponent,
        EditTabReportColumnComponent,
        EditTabReportEmailComponent,
        EditTabReportFilterComponent,
        EditTipAdjustmentComponent,
        EndOfDayComponent,
        DepositsComponent,
        DepositRefundComponent,
        GiftCardComponent,
        GiftCardsComponent,
        InventoryComponent,
        LabelHelpComponent,
        ManagerEmptyComponent,
        ManualOrderComponent,
        MarketingComponent,
        MembershipProgramsComponent,
        MenuItemInventoryTransferComponent,
        OpenOrdersComponent,
        OrderHistoryComponent,
        OrganizationConnectionsComponent,
        OrganizationDetailsComponent,
        OrganizationLocationsComponent,
        OrganizationMenuComponent,
        OrganizationOtherComponent,
        OrganizationPreferenceComponent,
        OrganizationSettingsComponent,
        OrganizationUsersComponent,
        PresentCardForTabComponent,
        RegisterOrganizationComponent,
        RegisterVenueComponent,
        RenewMembershipComponent,
        RequestMembershipCardSaveComponent,
        SafesComponent,
        SelectMemberComponent,
        SelectMenuSelectionItemsComponent,
        SelectPrinterComponent,
        SelectCompComponent,
        SelectCloseoutComponent,
        SelectOrganizationEventComponent,
        SelectTabNoteComponent,
        TabsComponent,
        TimecardsComponent,
        TipOutComponent,
        TransferCashComponent,
        UserTimecardsComponent
    ],
    imports: [
        BrowserModule,
        ClipboardModule,
        ColorSketchModule,
        GoogleMapsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        ManagerModule,
        InfiniteScrollModule,
        BrewBillLibModule.forRoot({
            terminal: true,
            apiUrl: environment.apiUrl,
            appVersion: environment.version,
            mobileUrl: environment.mobileUrl,
            testMode: environment.testMode
        }),
        ImageCropperModule,
        QRCodeModule,
        SwiperModule,
        BrewBillLibModule,
        BrewBillLibModule,
        BrewBillLibModule,
        BrewBillLibModule,
        NgOptimizedImage
    ],
    providers: [
        AndroidPermissions,
        CurrencyPipe,
        DateAgoPipe,
        OrderStatusPipe,
        OrderStatusColorPipe,
        LeftPadPipe,
        NegativeCurrencyPipe,
        UnderscoreConversionPipe,
        FileOpener,
        StarPRNT
    ],
    exports: [
        OrganizationEventComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
