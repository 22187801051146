import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CashSessionEmployeesComponent} from '../_components/cash-session-employees/cash-session-employees.component';
import {EditNameOverrideComponent} from '../_components/edit-name-override/edit-name-override.component';
import {TabDetailsComponent} from '../_components/tab-details/tab-details.component';
import {TabCardHeaderComponent} from '../_components/tab-card-header/tab-card-header.component';
import {BrewBillLibModule} from 'brewbill-lib';
import {IonicModule} from '@ionic/angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectColumnComponent} from '../_components/select-column/select-column.component';
import {PaymentButtonComponent} from '../_components/payment-button/payment-button.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NoteDefinitionComponent} from '../_components/note-definition/note-definition.component';
import {OrganizationEventComponent} from '../_components/organization-event/organization-event.component';
import {SelectQuickbooksComponent} from '../_components/select-quickbooks/select-quickbooks.component';
import {CompDefinitionComponent} from '../_components/comp-definition/comp-definition.component';

@NgModule({
    declarations: [
        CashSessionEmployeesComponent, CompDefinitionComponent, EditNameOverrideComponent, NoteDefinitionComponent,
        OrganizationEventComponent,
        PaymentButtonComponent, SelectColumnComponent, SelectQuickbooksComponent, TabDetailsComponent, TabCardHeaderComponent
    ],
    exports: [
        CashSessionEmployeesComponent, CompDefinitionComponent, EditNameOverrideComponent, NoteDefinitionComponent,
        OrganizationEventComponent,
        PaymentButtonComponent, SelectColumnComponent, SelectQuickbooksComponent, TabDetailsComponent, TabCardHeaderComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BrewBillLibModule,
        NgCircleProgressModule,
    ]
})
export class ManagerModule {
}
