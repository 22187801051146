import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, CompDefinition} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-select-comp',
    templateUrl: './select-comp.component.html',
    styleUrls: ['./select-comp.component.scss'],
})
export class SelectCompComponent extends BaseModalComponent implements OnInit {
    @Input() availableComps: CompDefinition[];

    generalComps = [];
    membershipComps = [];
    comps = [];

    compTypes = 'GENERAL';

    constructor(
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.generalComps = this.availableComps.filter(c => !c.membershipProgram);
        this.membershipComps = this.availableComps.filter(c => !!c.membershipProgram);
        this.loadComps();
    }

    loadComps() {
        if (this.compTypes === 'GENERAL') {
            this.comps = this.generalComps;
        } else {
            this.comps = this.membershipComps;
        }
    }
}
