import {Component, OnInit} from '@angular/core';
import {BaseOrganizationComponent} from '../base-organization.component';
import {
    ConnectBankAccountModalComponent,
    LoadingService,
    OrganizationService,
    QuickbooksDefinition,
    QuickbooksDefinitionService,
    QuickbooksService,
    ToastService,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {EditQuickbooksDefinitionComponent} from '../edit-quickbooks-definition/edit-quickbooks-definition.component';

@Component({
    selector: 'bb-organization-connections',
    templateUrl: './organization-connections.component.html',
    styleUrls: ['./organization-connections.component.scss'],
})
export class OrganizationConnectionsComponent extends BaseOrganizationComponent implements OnInit {
    testDate: string;
    quickbooksDefinition: QuickbooksDefinition;

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private quickbooksService: QuickbooksService,
        private quickbooksDefinitionService: QuickbooksDefinitionService,
        private alertController: AlertController,
        private modalController: ModalController,
        private toastService: ToastService,
        private route: ActivatedRoute
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    ngOnInit() {
        super.ngOnInit();
        if (!!this.route.snapshot.queryParams) {
            const activated = this.route.snapshot.queryParams.activated;
            if (activated === 'quickbooks') {
                this.openEditQuickbooksDefinition();
            }
        }

        this.quickbooksDefinitionService.findByOrganizationId(this.organization.id).subscribe(d => this.quickbooksDefinition = !!d
            ? new QuickbooksDefinition(d)
            : null);
    }

    async openConnectBankAccount() {
        const modal = await this.modalController.create({
            component: ConnectBankAccountModalComponent,
            componentProps: {
                organization: this.organization
            }
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshOrganization();
            }
        });
    }

    openEditQuickbooksDefinition() {
        this.loadingService.present();

        this.quickbooksDefinitionService.findByOrganizationId(this.organization.id).subscribe(async (definition: QuickbooksDefinition) => {
            const modal = await this.modalController.create({
                component: EditQuickbooksDefinitionComponent,
                cssClass: 'menu-item-modal',
                componentProps: {
                    definition
                }
            });

            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.quickbooksDefinition = new QuickbooksDefinition(dataReturned.data);
                }
            });
        });
    }

    async connectToQuickbooks() {
        this.quickbooksService.getConnectionAuthUrl(this.organization.id).subscribe((authUrl: any) => {
            if (!!authUrl) {
                window.location = authUrl;
            }
        });
    }

    async revokeQuickbooksAccess() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Disconnect',
            message: 'Disconnecting your account will stop BrewBill from sharing data with QuickBooks. Are you sure you want to proceed?',
            buttons: [
                {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.quickbooksService.revoke(this.organization.id).subscribe(() => {
                            console.log('success');
                            this.refreshOrganization();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    toggleQuickbooksStatus() {
        this.loadingService.present();
        this.quickbooksDefinitionService.updateStatus(this.organization.id, !this.quickbooksDefinition.active).subscribe(r => {
            this.quickbooksDefinition = new QuickbooksDefinition(r);
            this.toastService.success('The Quickbooks integration has been saved.');
            this.loadingService.dismiss();
        });
    }

    fireJob() {
        this.loadingService.present('This may take a bit...');
        this.quickbooksService.createDailySalesReceiptJob(this.organization.id).subscribe(() => {
            this.toastService.success('Done');
            this.loadingService.dismiss();
        });
    }

    runForDate() {
        this.loadingService.present('This may take a bit...');
        this.quickbooksService.createDailySalesReceipt(this.organization.id, this.testDate).subscribe(() => {
            this.toastService.success('Done');
            this.loadingService.dismiss();
        });
    }
}
