import {Component, Input} from '@angular/core';
import {BaseModalComponent, OrganizationEvent} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-select-organization-event',
    templateUrl: './select-organization-event.component.html',
    styleUrls: ['./select-organization-event.component.scss'],
})
export class SelectOrganizationEventComponent extends BaseModalComponent {
    @Input() availableEvents: OrganizationEvent[];

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
