import {Component, Input, OnInit} from '@angular/core';
import {
    addCurrency,
    BaseModalComponent,
    CashAudit,
    CashDrawer,
    CashDrawerService,
    Deposit,
    DepositService,
    LoadingService,
    PaymentCharge,
    PickupLocation,
    PickupLocationService,
    ToastService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {AssignCashDrawerComponent} from '../assign-cash-drawer/assign-cash-drawer.component';

@Component({
    selector: 'bb-deposit-refund',
    templateUrl: './deposit-refund.component.html',
    styleUrls: ['./deposit-refund.component.scss'],
})
export class DepositRefundComponent extends BaseModalComponent implements OnInit {
    @Input() deposits: Deposit[];
    @Input() paymentCharge: PaymentCharge;
    @Input() cashAudit: CashAudit;

    clonedDeposits: Deposit[];
    cashDrawer: CashDrawer;
    pickupLocation: PickupLocation;

    constructor(
        modalController: ModalController,
        private depositService: DepositService,
        private loadingService: LoadingService,
        private cashDrawerService: CashDrawerService,
        private pickupLocationService: PickupLocationService,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.clonedDeposits = [...this.deposits];
        this.clonedDeposits.forEach(d => d.selected = false);

        this.subscribe(this.cashDrawerService.current.subscribe(c => {
            this.cashDrawer = !!c ? new CashDrawer(c) : null;
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => {
            this.pickupLocation = !!p ? new PickupLocation(p) : null;
        }));
    }

    valid() {
        return this.clonedDeposits.some(d => d.selected);
    }

    total() {
        return this.clonedDeposits.filter(d => d.selected)
            .reduce((total, d) => addCurrency(total, d.amount), 0);
    }

    submit() {
        this.loadingService.present();
        const ids: number[] = this.clonedDeposits.filter(d => d.selected)
            .reduce((i, d) => {
                i.push(d.id);
                return i;
            }, []);
        this.depositService.refund(ids.join(','), (this.cashDrawer ? this.cashDrawer.id : null)).subscribe(d => {
            this.loadingService.dismiss();
            this.toastService.success('The deposit has been refunded');
            this.close();
        });
    }

    async changeDrawer() {
        const modal = await this.modalController.create({
            component: AssignCashDrawerComponent,
            componentProps: {
                pickupLocation: this.pickupLocation
            }
        });

        await modal.present();
    }
}
