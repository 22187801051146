import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
    CardTransactionResponse,
    CardTransactionResponseService,
    LoadingService,
    Membership,
    MembershipService,
    MembershipSummary,
    Organization,
    OrganizationService,
    OrganizationTerminal,
    OrganizationTerminalService,
    ToastService
} from 'brewbill-lib';
import {debounce} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {BaseCardTransactionComponent} from '../base-card-transaction.component';

@Component({
    selector: 'bb-select-member',
    templateUrl: './select-member.component.html',
    styleUrls: ['./select-member.component.scss'],
})
export class SelectMemberComponent extends BaseCardTransactionComponent implements OnInit, OnDestroy {
    @Input() members: MembershipSummary[];
    @Input() currentMember: MembershipSummary;

    organization: Organization;
    terminal: OrganizationTerminal;
    filter = '';
    options: MembershipSummary[];
    filteredOptions: MembershipSummary[];
    selectedMember: MembershipSummary;

    constructor(
        private membershipService: MembershipService,
        private organizationService: OrganizationService,
        modalController: ModalController,
        cardTransactionResponseService: CardTransactionResponseService,
        organizationTerminalService: OrganizationTerminalService,
        toastService: ToastService,
        loadingService: LoadingService
    ) {
        super(
            cardTransactionResponseService,
            organizationTerminalService,
            toastService,
            loadingService,
            modalController);
        this.filterMembers = debounce(this.filterMembers, 250);
    }

    ngOnInit() {
        super.ngOnInit();
        this.options = [];
        this.organization = this.organizationService.currentValue;
        this.terminal = this.organizationTerminalService.currentValue;
        this.members.forEach(m => this.options.push(new MembershipSummary(m)));
        if (!!this.currentMember) {
            this.selectedMember = this.options.find(m => m.id === this.currentMember.id);
        }
        this.filterMembers();
        this.findByPresentedCard();
    }

    ngOnDestroy() {
        if (!!this.polling) {
            this.organizationTerminalService.cancel(this.organization.id, this.terminal.terminalId, this.referenceId)
                .subscribe(() => console.log('Transaction canceled.'));
        }
    }

    cardPaymentCancel() {
    }

    cardPaymentFail(response: CardTransactionResponse) {
    }

    cardPaymentSuccess(response: CardTransactionResponse) {
        if (!!response && !!response.message) {
            const ids = response.message.split(',');
            this.filteredOptions = this.options.filter(o => ids.some(i => o.id === Number.parseInt(i, 10)));
            if (this.filteredOptions.length === 1) {
                this.select(this.filteredOptions[0]);
            }
        } else {
            this.toastService.message('No member found.');
            this.findByPresentedCard();
        }
    }

    filterMembers() {
        if (!!this.filter) {
            this.filteredOptions = this.members.filter(m => {
                if (/^[0-9]*$/.test(this.filter)) {
                    return m.memberNumber === Number.parseInt(this.filter, 10);
                } else {
                    return m.name.toLowerCase().includes(this.filter.toLowerCase());
                }
            });
        } else {
            this.filteredOptions = [...this.options];
        }
    }

    select(summary: MembershipSummary) {
        if (!!summary) {
            this.loadingService.present();
            this.membershipService.get(summary.id).subscribe(m => {
                this.loadingService.dismiss();
                this.close(new Membership(m));
            });
        } else {
            this.close();
        }
    }

    async findByPresentedCard() {
        try {
            this.referenceId = 'MER-' + uuidv4();
            await this.membershipService.findByTerminalCard(this.organization.id, this.terminal.terminalId, this.referenceId).toPromise();
            this.beginPolling();
        } catch (error) {
            console.log(error);
            await this.loading.dismiss(true);
        }
    }
}
