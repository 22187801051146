import {Component, Input, OnInit} from '@angular/core';
import {MenuItemTopping} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-menu-item-topping',
    templateUrl: './edit-menu-item-topping.component.html',
    styleUrls: ['./edit-menu-item-topping.component.scss'],
})
export class EditMenuItemToppingComponent implements OnInit {
    @Input() menuItemTopping: MenuItemTopping;

    constructor() {
    }

    ngOnInit() {
    }

}
