import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'bb-select-quickbooks',
    templateUrl: './select-quickbooks.component.html',
    styleUrls: ['./select-quickbooks.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectQuickbooksComponent),
            multi: true
        }
    ]
})
export class SelectQuickbooksComponent implements OnDestroy, ControlValueAccessor {
    @ViewChild('selectInput') set selectColumnInput(element) {
        this.input = element.el;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    this.popoverWidth = this.input.offsetWidth;
                });
            });

            this.resizeObserver.observe(this.input);
        }
    }

    @Input() title: string;
    @Input() items: string[];
    @Output() selected: EventEmitter<string> = new EventEmitter();

    input: any;
    selectedName: string;
    value: string;

    popoverWidth = 0;
    resizeObserver: ResizeObserver;
    showOptions = false;
    closing = false;

    constructor() {
    }

    ngOnDestroy(): void {
        if (!!this.resizeObserver) {
            this.resizeObserver.unobserve(this.input);
            this.resizeObserver.disconnect();
        }
    }

    async presentPopover() {
        this.showOptions = true;
    }

    async closed(v: string) {
        this.fireSelected(v);
        this.showOptions = false;
    }

    onChange = (value: any) => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    fireSelected(value) {
        if (!!value) {
            this.selected.emit(value);
        }
    }
}
