import {Component, Input, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    CompDefinition,
    LoadingService,
    Organization,
    TabNoteDefinition,
    TabNoteDefinitionService,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-tab-note-definition',
    templateUrl: './edit-tab-note-definition.component.html',
    styleUrls: ['./edit-tab-note-definition.component.scss'],
})
export class EditTabNoteDefinitionComponent extends BaseModalComponent implements OnInit {
    @Input() tabNoteDefinition: TabNoteDefinition;
    @Input() organization: Organization;
    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private tabNoteDefinitionService: TabNoteDefinitionService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.tabNoteDefinition ? this.tabNoteDefinition.id : null,
            parentId: this.organization.id,
            name: [!!this.tabNoteDefinition ? this.tabNoteDefinition.name : '', Validators.required],
            color: [!!this.tabNoteDefinition ? this.tabNoteDefinition.color : '#fddb31', Validators.required],
            contrastColor: [!!this.tabNoteDefinition ? this.tabNoteDefinition.contrastColor : '#000000', Validators.required],
            description: [!!this.tabNoteDefinition ? this.tabNoteDefinition.description : '', Validators.required]
        });
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const tabNoteDefinition = new TabNoteDefinition(this.formGroup.value);

            this.loadingService.present();
            if (!tabNoteDefinition.id) {
                this.tabNoteDefinitionService.create(tabNoteDefinition).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Tab Note definition created.');
                    this.close(new CompDefinition(c));
                });
            } else {
                this.tabNoteDefinitionService.update(tabNoteDefinition).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Tab note definition updated.');
                    this.close(new CompDefinition(c));
                });
            }
        }
    }

    setValue(field, val) {
        this.formGroup.get(field).setValue(val);
    }
}
