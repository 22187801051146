import {Component, Input} from '@angular/core';
import {BaseModalComponent, CashDrawer} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-complete-cash-transfer',
    templateUrl: './complete-cash-transfer.component.html',
    styleUrls: ['./complete-cash-transfer.component.scss'],
})
export class CompleteCashTransferComponent extends BaseModalComponent {
    @Input() from: CashDrawer;
    @Input() to: CashDrawer;
    @Input() amount: number;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
