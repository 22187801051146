import {Component, OnInit} from '@angular/core';
import {
    AuthenticationService,
    BaseManagerComponent,
    getTabIdCache,
    LoadingService,
    MembershipService,
    OrganizationService,
    OrganizationTerminal,
    OrganizationTerminalService,
    PickupLocationService,
    Tab,
    TabSearchResult,
    TabService,
    ToastService,
    UserService
} from 'brewbill-lib';
import {debounce} from 'lodash';
import {NavController} from '@ionic/angular';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';

@Component({
    selector: 'bb-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent extends BaseManagerComponent implements OnInit {
    searchCriteria: any;
    deviceTabs: TabSearchResult[];
    searchTabs: TabSearchResult[];
    cardTabs: TabSearchResult[];
    totalTabs = 0;
    totalPages = 0;
    currentPage = 0;
    loaded = false;
    type = 'device';
    terminal: OrganizationTerminal = null;

    constructor(
        private tabService: TabService,
        private organizationTerminalService: OrganizationTerminalService,
        private toastService: ToastService,
        private navController: NavController,
        private loadingService: LoadingService,
        authenticationService: AuthenticationService,
        organizationService: OrganizationService,
        pickupLocationService: PickupLocationService,
        userService: UserService,
        membershipService: MembershipService
    ) {
        super(authenticationService,
            organizationService,
            pickupLocationService,
            userService,
            membershipService);

        this.searchCriteria = this.tabService.getHistorySearchCriteria();
        this.refreshTabs = debounce(this.refreshTabs, 250);
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.organizationTerminalService.current.subscribe(t => this.terminal = t));
        this.subscribe(this.tabService.current.subscribe(t => {
            if (!!t && !!t.id) {
                const searchResult = this.tabService.convertToTabSearchResult(new Tab(t));
                if (!this.deviceTabs) {
                    this.deviceTabs = [];
                }

                const index = this.deviceTabs.findIndex(d => d.id === searchResult.id);
                if (index > -1) {
                    this.deviceTabs.splice(index, 1);
                }

                this.deviceTabs.unshift(searchResult);
            }
        }));

        this.subscribe(this.tabService.event.subscribe(t => {
            if (!!t) {
                const tab = new Tab(t);
                const searchResult = this.tabService.convertToTabSearchResult(tab);
                if (!!this.deviceTabs) {
                    const index = this.deviceTabs.findIndex(d => d.id === searchResult.id);
                    if (index > -1) {
                        this.deviceTabs[index] = searchResult;
                    }
                }
                if (!!this.searchTabs) {
                    const index = this.searchTabs.findIndex(d => d.id === searchResult.id);
                    if (index > -1) {
                        this.searchTabs[index] = searchResult;
                    } else if (!this.searchCriteria.searchString) {
                        const start = moment(this.searchCriteria.startDate);
                        const end = moment(this.searchCriteria.endDate);
                        const tabStart = moment(tab.startTime);
                        if (tabStart > start && tabStart < end) {
                            this.searchTabs.unshift(searchResult);
                        }
                    }
                }
            }
        }));
    }

    init() {
        this.refreshTabs();
    }

    clearHistory() {
        localStorage.removeItem(`TAB_ID_CACHE_${this.organization.id}`);
        this.deviceTabs = [];
        this.loaded = true;
    }

    refreshTabs(clearSearch = false) {
        if (this.type === 'all') {
            if (clearSearch) {
                this.searchTabs = null;
            }
            this.loadNextTabPage();
        } else if (this.type === 'device') {
            this.loadDeviceHistory();
        } else {
            this.searchByLastFour();
        }
    }

    loadNextTabPage(event?) {
        this.tabService.setHistorySearchCriteria(this.searchCriteria);
        if (!this.searchTabs) {
            this.loadingService.present();
        }
        this.organizationService
            .getDateRangeTabsSearch(this.organization.id,
                this.currentPage,
                50,
                this.searchCriteria.startDate,
                this.searchCriteria.endDate,
                this.searchCriteria.searchString,
                this.searchCriteria.tabNumber)
            .subscribe(async (page: any) => {
                if (!this.searchTabs) {
                    this.loadingService.dismiss();
                }
                await this.loadTabPage(page);
            }, async () => {
                await this.toastService.error('Could not retrieve tabs.');
            }, () => {
                this.loaded = true;
                if (!!event) {
                    event.target.complete();
                }
            });
    }

    searchByLastFour() {
        if (!!this.searchCriteria.lastFour && this.searchCriteria.lastFour.length === 4) {
            this.loadingService.present();
            this.tabService.findByLastFour(this.organization.id, this.searchCriteria.lastFour).subscribe((t: TabSearchResult[]) => {
                this.cardTabs = t;
                this.loadingService.dismiss();
            });
        } else {
            this.cardTabs = [];
        }
    }

    loadDeviceHistory() {
        const cached = getTabIdCache(this.organization.id);
        if (!this.loaded || !this.deviceTabs) {
            this.loadingService.present();
        }

        this.tabService.findByIdIn(this.organization.id, cached.map(t => t.id))
            .subscribe(async (tabs: any) => {
                const sanitizedTabs = [];
                if (!!tabs) {
                    const tabMap = {};
                    tabs.forEach(t => {
                        tabMap[t.id] = new TabSearchResult(t);
                    });
                    cached.forEach(t => {
                        const tab = tabMap[t.id];
                        if (!!tab) {
                            sanitizedTabs.push(tab);
                        }
                    });
                }

                if (!this.loaded || !this.deviceTabs) {
                    this.loadingService.dismiss();
                }

                this.deviceTabs = sanitizedTabs;
                this.loaded = true;
            });
    }

    async loadTabPage(page) {
        const sanitizedTabs = [];
        if (!!page.content) {
            page.content.forEach(t => {
                sanitizedTabs.push(new TabSearchResult(t));
            });
        }

        if (this.currentPage === 0) {
            this.searchTabs = sanitizedTabs;
        } else {
            sanitizedTabs.forEach(tab => this.searchTabs.push(new TabSearchResult(tab)));
        }
        this.totalTabs = page.totalElements;
        this.totalPages = page.totalPages;
    }

    async viewDetails(tab: TabSearchResult) {
        this.loadingService.present();
        await this.navController.navigateForward(`/manager/${this.pickupLocation.id}/tab/${tab.id}`);
    }

    async doInfinite(event) {
        if (this.currentPage < this.totalPages - 1) {
            this.currentPage++;
            await this.loadNextTabPage(event);
        }
        event.target.complete();
    }

    async findByPresentedCard() {
        const referenceId = 'MER-' + uuidv4();
        const loading = await this.organizationTerminalService.loading(this.terminal.terminalId,
            this.organization.id, false, referenceId);

        this.tabService.findByTerminalCard(this.organization.id, this.terminal.terminalId, referenceId, 'CLOSED')
            .subscribe((t: TabSearchResult[]) => {
                loading.dismiss(true);
                if (t.length === 1) {
                    this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${t[0].id}`);
                } else {
                    this.searchTabs = t;
                }
            });
    }
}
