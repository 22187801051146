import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, Menu, MenuItem, MenuSelectionItem} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-select-menu-selection-items',
    templateUrl: './select-menu-selection-items.component.html',
    styleUrls: ['./select-menu-selection-items.component.scss'],
})
export class SelectMenuSelectionItemsComponent extends BaseModalComponent implements OnInit {
    @Input() menu: Menu;
    @Input() selected: MenuSelectionItem[];
    @Input() forItem: MenuItem;

    filteredItems: any[];
    options: any[];
    itemFilter: string;

    constructor(modalController: ModalController) {
        super(modalController);
    }

    ngOnInit() {
        this.options = this.menu.menuItems.reduce((acc: any[], item: MenuItem) => {
            const combo = [];
            if (!!item.prices && item.prices.length > 0) {
                item.prices.forEach(price => combo.push({
                    item, price,
                    selected: this.selected.some(msi =>
                            msi.menuItem.id === item.id
                            && (
                                (!!msi.menuItemPrice && msi.menuItemPrice.id === price.id)
                                || (!msi.menuItemPrice && price.defaultPrice)
                            )
                    )
                }));
            } else {
                combo.push({item, price: null, selected: this.selected.some(msi => msi.menuItem.id === item.id && !msi.menuItemPrice)});
            }
            acc = acc.concat(combo);
            return acc;
        }, []);

        this.filterItems();
    }

    filterItems() {
        this.filteredItems = !!this.itemFilter ? this.options.filter((o: any) =>
                (!!o.item && !!o.item.name && o.item.name.toLowerCase().includes(this.itemFilter.toLowerCase()))
                || (!!o.price && !!o.price.name && o.price.name.toLowerCase().includes(this.itemFilter.toLowerCase())))
            : [...this.options];
    }

    partialSelected() {
        return !!this.filteredItems
            && this.filteredItems.some(i => i.selected)
            && this.filteredItems.some(i => !i.selected);
    }

    allSelected() {
        return !!this.filteredItems && !this.filteredItems.some(i => !i.selected);
    }

    toggle() {
        if (!this.allSelected()) {
            this.filteredItems.forEach(i => i.selected = true);
        } else {
            this.filteredItems.forEach(i => i.selected = false);
        }
    }

    submit() {
        const updated: MenuSelectionItem[] = this.options.filter(o => o.selected)
            .reduce((acc: MenuSelectionItem[], o) => {
                // find existing and update
                const found = this.selected.find(msi => msi.menuItem.id === o.item.id
                    && !!msi.menuItemPrice
                    && msi.menuItemPrice.id === o.price.id);

                if (!!found) {
                    acc.push(found);
                } else {
                    const menuSelectionItem = new MenuSelectionItem();
                    menuSelectionItem.menuItem = o.item;
                    menuSelectionItem.menuItemPrice = o.price;
                    acc.push(menuSelectionItem);
                }
                return acc;
            }, []);

        let nextSort = updated.reduce((sort: number, item: MenuSelectionItem) =>
            !!item.sortOrder && item.sortOrder > sort ? item.sortOrder : sort, 0) + 1;

        updated.forEach(i => i.sortOrder = !!i.sortOrder || i.sortOrder === 0 ? i.sortOrder : nextSort++);

        this.close(updated.sort((a, b) => a.sortOrder - b.sortOrder));
    }
}
