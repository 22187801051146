import {Component, OnInit} from '@angular/core';
import {
    addCurrency,
    CardTransactionResponseService,
    CashSession,
    CashSessionService,
    CompDefinition,
    CompDefinitionAvailabilities,
    CompDefinitionTypes,
    CreateOrderItemCreditComponent,
    CreateTabCreditComponent,
    divideCurrency,
    FeatureKeys,
    LoadingService,
    Membership,
    multiplyCurrency,
    Order,
    OrderItem,
    OrderItemCredit,
    OrderItemCreditService,
    OrderService,
    Organization,
    OrganizationService,
    OrganizationTerminalService,
    PickupLocation,
    PickupLocationService,
    subtractCurrency,
    Tab,
    TabCredit,
    TabCreditService,
    TabNote,
    TabNoteDefinition,
    TabNoteService,
    TabService,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';
import {SelectCompComponent} from './select-comp/select-comp.component';
import {ModalController} from '@ionic/angular';
import {SelectTabNoteComponent} from './select-tab-note/select-tab-note.component';
import {BaseCardTransactionComponent} from './base-card-transaction.component';

@Component({
    template: ''
})
export abstract class BaseTabComponent extends BaseCardTransactionComponent implements OnInit {
    abstract tab: Tab;
    abstract activeMembership: Membership;

    order: Order;
    organization: Organization;
    currentUser: User;
    pickupLocation: PickupLocation;
    cashSession: CashSession;
    availableTabComps = [];
    availableOrderComps: CompDefinition[] = [];
    orderItemCreditIndex = 1;
    tabCreditIndex = 1;
    testMode = false;

    featureKeys = FeatureKeys;
    compDefinitionTypes = CompDefinitionTypes;

    abstract refresh();

    abstract addOrderCredit(orderItem: OrderItem, credit: OrderItemCredit);

    abstract addTabCredit(credit: TabCredit);

    protected constructor(
        protected cashSessionService: CashSessionService,
        protected pickupLocationService: PickupLocationService,
        protected orderItemCreditService: OrderItemCreditService,
        protected orderService: OrderService,
        protected organizationService: OrganizationService,
        protected tabService: TabService,
        protected tabCreditService: TabCreditService,
        protected tabNoteService: TabNoteService,
        protected userService: UserService,
        modalController: ModalController,
        cardTransactionResponseService: CardTransactionResponseService,
        organizationTerminalService: OrganizationTerminalService,
        toastService: ToastService,
        loadingService: LoadingService
    ) {
        super(
            cardTransactionResponseService,
            organizationTerminalService,
            toastService,
            loadingService,
            modalController);
    }

    ngOnInit() {
        super.ngOnInit();
        this.disableBackDismiss = true;
        this.subscribe(this.organizationService.current.subscribe(p => {
            this.organization = !!p ? new Organization(p) : null;
            this.testMode = !!this.organization && this.organization.testMode;
            this.checkAvailableComps();
        }));

        this.subscribe(this.userService.current.subscribe(u => {
            this.currentUser = !!u ? new User(u) : null;
            this.checkAvailableComps();
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(p => this.pickupLocation = !!p ? new PickupLocation(p) : null));
        this.subscribe(this.cashSessionService.current.subscribe(c => this.cashSession = !!c ? new CashSession(c) : null));
    }

    checkAvailableComps() {
        if (!!this.currentUser && !!this.organization && !!this.organization.compDefinitions) {
            const tabComps = this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.TAB);
            const orderComps = this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.ORDER_ITEM);
            if (this.currentUser.siteAdmin) {
                this.availableTabComps = tabComps;
                this.availableOrderComps = orderComps;
            } else {
                const organizationUser = this.currentUser.organizations.find(o => o.orgId === this.organization.id);
                if (organizationUser.admin || organizationUser.owner) {
                    this.availableTabComps = tabComps;
                    this.availableOrderComps = orderComps;
                } else {
                    this.availableTabComps = tabComps
                        .filter(c => !c.roles
                            || c.roles.length === 0
                            || c.roles.some(r => organizationUser.roles.some(orgRole => orgRole.id === r.id)));

                    this.availableOrderComps = orderComps
                        .filter(c => !c.roles
                            || c.roles.length === 0
                            || c.roles.some(r => organizationUser.roles.some(orgRole => orgRole.id === r.id)));
                }
            }
        } else {
            this.availableTabComps = [];
            this.availableOrderComps = [];
        }
    }

    authorizedTo(feature: string | string[]) {
        return !!this.currentUser && this.currentUser.authorizedTo(feature, this.organization.id);
    }

    async selectTabCredit() {
        const modal = await this.modalController.create({
            component: SelectCompComponent,
            componentProps: {
                tab: this.tab,
                availableComps: this.availableTabComps
            },
            cssClass: this.availableTabComps.length > 4 ? 'menu-modal' : ''
        });

        await modal.present();

        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const compDefinition = new CompDefinition(dataReturned.data);
                if (compDefinition.type === CompDefinitionTypes.MANUAL) {
                    this.manualTabCredit(dataReturned.data);
                } else {
                    const tabCredit = new TabCredit({
                        parentId: this.tab.id,
                        addIndex: this.tabCreditIndex++,
                        compDefinition
                    });

                    this.addTabCredit(tabCredit);
                }
            }
        });
    }

    async manualTabCredit(compDefinition: CompDefinition) {
        const modal = await this.modalController.create({
            component: CreateTabCreditComponent,
            componentProps: {
                tab: this.tab,
                compDefinition
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const credit = new TabCredit(dataReturned.data);
                credit.addIndex = this.tabCreditIndex++;
                this.addTabCredit(credit);
            }
        });
    }

    async manualOrderItemCredit(orderItem: OrderItem, compDefinition: CompDefinition) {
        const modal = await this.modalController.create({
            component: CreateOrderItemCreditComponent,
            componentProps: {
                orderItem,
                compDefinition
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const credit = new OrderItemCredit(dataReturned.data);

                if (credit.amount > 0) {
                    this.addOrderCredit(orderItem, credit);
                }
            }
        });
    }

    async selectOrderItemCredit(orderItem: OrderItem) {
        const modal = await this.modalController.create({
            component: SelectCompComponent,
            componentProps: {
                availableComps: this.availableOrderComps
                    .filter(c => !c.items || c.items.length === 0 || c.items.some(p => !!orderItem.selectedPrice && p.priceId === orderItem.selectedPrice.id))
            },
            cssClass: this.availableOrderComps.length > 4 ? 'menu-modal' : ''
        });

        await modal.present();

        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const compDefinition = new CompDefinition(dataReturned.data);

                if (compDefinition.type === CompDefinitionTypes.MANUAL) {
                    this.manualOrderItemCredit(orderItem, compDefinition);
                } else {
                    const credit = new OrderItemCredit({compDefinition, parentId: orderItem.id});

                    credit.addIndex = this.orderItemCreditIndex++;

                    if (compDefinition.type === CompDefinitionTypes.DEFINED) {
                        credit.amount = compDefinition.amount;
                    } else if (compDefinition.type === CompDefinitionTypes.PERCENT) {
                        credit.amount = multiplyCurrency(orderItem.calcTotal(), divideCurrency(compDefinition.amount, 100));
                    }

                    this.addOrderCredit(orderItem, credit);
                    this.applyComps(this.order);
                }
            }
        });
    }

    async removeNote(note: TabNote) {
        this.loadingService.present();
        this.tabNoteService.delete(note.id).subscribe(async () => {
            this.loadingService.dismiss();
            const index = this.tab.notes.findIndex(n => n.id === note.id);
            if (index > 0) {
                this.tab.notes.splice(index, 1);
            }
            this.refresh();
        });
    }

    async addNote() {
        const modal = await this.modalController.create({
            component: SelectTabNoteComponent,
            componentProps: {
                availableDefinitions: this.organization.tabNoteDefinitions
            },
            cssClass: this.availableTabComps.length > 4 ? 'menu-modal' : ''
        });

        await modal.present();

        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                this.loadingService.present();
                const definition = new TabNoteDefinition(dataReturned.data);
                this.tabNoteService.create(new TabNote({definition, parentId: this.tab.id})).subscribe(async (note: TabNote) => {
                    const index = this.tab.notes.findIndex(n => n.id === note.id);
                    if (index === -1) {
                        this.tab.notes.push(note);
                    }

                    this.loadingService.dismiss();
                    this.refresh();
                });
            }
        });
    }

    adjustTokenPayments() {
        if (!!this.tab && !this.tab.id && !this.tab.paymentInformation.tokenPaymentsTotal) {
            this.tab.paymentInformation.tokenPaymentsTotal = this.tab.orders.reduce((oTotal, order) => addCurrency(oTotal, order.items
                .reduce((total, i) => addCurrency(total, i.tokenPayment > 0 ? i.total : 0), 0)), 0);

            this.tab.paymentInformation.tokensUsed = this.tab.orders.reduce((oTotal, order) => addCurrency(oTotal, order.items
                .reduce((total, i) => addCurrency(total, i.tokenPayment > 0 ? i.tokenPayment : 0), 0)), 0);

            this.tab.paymentInformation.remaining =
                subtractCurrency(this.tab.paymentInformation.remaining, this.tab.paymentInformation.tokenPaymentsTotal);
        }
    }

    applyComps(order: Order) {
        this.orderService.applyComps(order, this.tab, this.pickupLocation.activeEvent, this.activeMembership);
        this.order = order;
        this.orderService.setCurrent(order);
    }
}
