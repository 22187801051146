import {Inject, Injectable} from '@angular/core';
import {
    AudioService,
    BaseEventService,
    CardTransactionResponse,
    CardTransactionResponseService,
    CashDrawer,
    CashDrawerService,
    CashDrawerStatuses,
    CashSession,
    CashSessionService,
    CashSessionStatuses,
    DeviceService,
    FeatureKeys,
    LibConfig,
    LibConfigService,
    Membership,
    MembershipService,
    Menu,
    MenuItemInventoryService,
    MenuService,
    Order,
    OrderService,
    OrderStatuses,
    Organization,
    OrganizationRating,
    OrganizationRatingService,
    OrganizationService,
    OrganizationSummary,
    OrganizationTerminalService,
    PickupLocationMenuService,
    PickupLocationService,
    Tab,
    TabService,
    Timecard,
    TimecardService,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class EventService extends BaseEventService {
    pickupLocationChannelPrefix = 'PICKUP_LOCATION_EVENT';
    organizationChannelPrefix = 'ORG_EVENT';

    orderIn: Order;
    orderInSound = new Audio('assets/tones/correct-answer.wav');
    deviceId: string;
    audioOption: string;
    currentUser: User;

    constructor(
        private toastService: ToastService,
        private audioService: AudioService,
        private cashDrawerService: CashDrawerService,
        private cashSessionService: CashSessionService,
        private userService: UserService,
        private timecardService: TimecardService,
        private organizationRatingService: OrganizationRatingService,
        private organizationTerminalService: OrganizationTerminalService,
        private cardTransactionResponseService: CardTransactionResponseService,
        private deviceService: DeviceService,
        private membershipService: MembershipService,
        pickupLocationService: PickupLocationService,
        pickupLocationMenuService: PickupLocationMenuService,
        menuService: MenuService,
        menuItemInventoryService: MenuItemInventoryService,
        orderService: OrderService,
        tabService: TabService,
        organizationService: OrganizationService,
        platform: Platform,
        @Inject(LibConfigService) public config: LibConfig
    ) {
        super(pickupLocationService, pickupLocationMenuService, menuService, menuItemInventoryService,
            orderService, tabService, organizationService, platform, config);

        this.userService.current.subscribe(u => this.currentUser = !!u ? new User(u) : null);

        this.deviceService.getDeviceId().then(d => this.deviceId = d);

        this.audioService.current.subscribe(a => this.audioOption = a);

        const parent = this;
        window.addEventListener('focus', () => {
            parent.refresh();
        });
    }

    async connect() {
        const token = !!this.currentUser ? this.currentUser.token : null;
        this.connectAbly(token);

        this.subscribeToOrganization();
        this.subscribeToPickupLocation();
    }

    refreshApp() {
        if (!!this.organization) {
            if (!!this.currentUser && this.currentUser.authorizedTo(FeatureKeys.TERMINAL, this.organization.id)) {
                this.organizationService.getOpenTabs(this.organization.id)
                    .subscribe((tabs: Tab[]) => this.tabService.setOpenTabs(tabs));
            }

            if (!!this.currentUser && this.currentUser.authorizedTo([
                ...FeatureKeys.CASH_SESSION_VIEW_KEYS, FeatureKeys.TERMINAL
            ], this.organization.id)) {
                this.cashSessionService.findOpen(this.organization.id)
                    .subscribe((c: CashSession) => {
                        this.cashSessionService.load(c);
                    });
            }

            if (!!this.currentUser) {
                this.timecardService.activeUserTimecard(this.organization.id)
                    .subscribe((tc: Timecard) => {
                        this.currentUser.timecard = tc;
                        this.userService.setCurrent(this.currentUser);
                    });
            }
        }

        if (!!this.pickupLocationService.currentValue
            && (!this.currentUser || this.currentUser.authorizedTo(FeatureKeys.TERMINAL, this.organization.id))) {
            this.pickupLocationService.getActiveOrders(this.pickupLocationService.currentValue.id).subscribe((ao: Order[]) => {
                this.orderService.setActiveOrders(ao);
            });
        }
    }

    subscribeToOrganizationChannels(channel) {
        channel.subscribe(async (message) => {
            switch (message.name) {
            case 'ORG_TAB':
                this.tabEventHandler(message.data);
                break;

            case 'ORG_TAB_DELETE':
                this.tabDeleteEventHandler(parseInt(message.data, 10));
                break;

            case 'ORG_RATING':
                this.organizationRatingService.setCurrent(new OrganizationRating(JSON.parse(message.data)));
                break;

            case 'ORG_RATING_SUMMARY':
                this.organizationRatingService.setOrganizationRatingSummary(new OrganizationSummary(JSON.parse(message.data)));
                break;

            case 'ORG_UPDATE':
                await this.orgEventHandler(parseInt(message.data, 10));
                break;

            case 'CASH_SESSION_UPDATE':
                await this.cashSessionEventHandler(parseInt(message.data, 10));
                break;

            case 'MENU_UPDATE':
                await this.menuEventHandler(parseInt(message.data, 10));
                break;

            case 'INVENTORY_UPDATE':
                await this.inventoryEventHandler(parseInt(message.data, 10));
                break;

            case 'TIMECARD':
                await this.timecardEventHandler(parseInt(message.data, 10));
                break;

            case 'TIMECARD_DELETE':
                await this.timecardDeleteEventHandler(parseInt(message.data, 10));
                break;

            case 'ORG_MEMBERSHIP':
                this.membershipEventHandler(parseInt(message.data, 10));
                break;

            case 'ORG_CARD_TRANSACTION':
                if (!!message && !!message.data) {
                    this.cardTransactionResponseService.setEvent(new CardTransactionResponse(JSON.parse(message.data)));
                }
                break;
            }
        });
    }

    checkReceivedOrders() {
        return !!this.orderService.getCurrentActiveOrders()
            && this.orderService.getCurrentActiveOrders().some(o => o.status === OrderStatuses.RECEIVED);
    }

    private async orgEventHandler(id: number) {
        const current = this.organizationService.currentValue;
        if (!current || (current.id === id)) {
            this.organizationService.get(id).subscribe(async (organization: Organization) => {
                this.organizationService.setCurrent(organization);
            });
        }
    }

    private async timecardDeleteEventHandler(id: number) {
        await this.timecardService.setEvent(null);

        if (!!this.currentUser && !!this.currentUser.timecard && this.currentUser.timecard.id === id) {
            this.currentUser.timecard = null;
        }
    }

    private async timecardEventHandler(id: number) {
        if (this.currentUser.authorizedTo(FeatureKeys.TIMECARD_FEATURE_KEYS, this.organization.id)
            || (!!this.currentUser.timecard && this.currentUser.timecard.id === id)) {
            this.timecardService.get(id).subscribe(async (timecard: Timecard) => {
                this.timecardService.setEvent(timecard);

                if (!!this.currentUser && !!this.currentUser.timecard && this.currentUser.timecard.id === id) {
                    if (!timecard || !!timecard.clockOutTime) {
                        this.currentUser.timecard = null;
                    } else {
                        this.currentUser.timecard = timecard;
                    }
                    this.userService.setCurrent(this.currentUser);
                } else if (!!this.currentUser && !!timecard && timecard.person.id === this.currentUser.person.id && !timecard.clockOutTime) {
                    this.currentUser.timecard = timecard;
                    this.userService.setCurrent(this.currentUser);
                }
            });
        }
    }

    private async cashSessionEventHandler(id: number) {
        const current = this.cashSessionService.currentValue;
        const working = this.cashSessionService.workingValue;

        this.cashSessionService.get(id).subscribe(async (cs: CashSession) => {
            const next = new CashSession(cs);
            this.cashSessionService.setEvent(next);

            if (!!working && next.id === working.id) {
                if (next.status === CashSessionStatuses.COMPLETE) {
                    await this.cashSessionService.setWorking(null);
                } else {
                    await this.cashSessionService.setWorking(next);
                }
            }

            if (!!current && next.id === current.id
                && current.status === CashSessionStatuses.ACTIVE && next.status !== CashSessionStatuses.ACTIVE) {
                this.toastService.message('The cash session is no longer active.');
                await this.cashSessionService.setCurrent(null);
            }

            if (!!next && next.status === CashSessionStatuses.ACTIVE) {
                this.cashSessionService.setCurrent(next);
                const cashDrawer = this.cashDrawerService.currentValue;
                if (!!cashDrawer) {
                    const found = next.cashDrawers.find(c => c.id === cashDrawer.id && c.status === CashDrawerStatuses.ACTIVE);
                    this.cashDrawerService.setCurrent(!!found ? new CashDrawer(found) : null);

                    if (!found) {
                        this.toastService.message('The associated cash drawer is no longer available.');
                    }
                } else {
                    this.cashDrawerService.setCurrent(null);
                }
            } else {
                this.cashDrawerService.setCurrent(null);
            }
        });
    }

    private async menuEventHandler(orgId: number) {
        const currentMenu = this.menuService.currentValue;
        if (!currentMenu || (currentMenu.orgId === orgId)) {
            this.menuService.findByOrganizationId(orgId).subscribe(async (menu: Menu) => {
                await this.menuService.setCurrent(menu);
            });
        }
    }

    tabDeleteEventHandler(id: number) {
        const openTabs = this.tabService.getCurrentOpenTabs();
        this.tabService.setOpenTabs(openTabs.filter(t => t.id !== id));
        this.tabService.removeFromCache(id);
        this.tabService.setDeletedTab(id);
    }

    tabEventHandler(json: string) {
        const tabInfo = JSON.parse(json);
        const id = Number.parseInt(tabInfo.first, 10);
        if (!id || isNaN(id)) {
            return;
        }

        const version = Number.parseInt(tabInfo.second, 10);
        const found = this.tabService.fromCache(id);
        if (!found || found.version < version) {
            if (!this.tabService.touched || this.tabService.touched.id !== id) {
                this.tabService.get(id).subscribe(jt => {
                    const tab = new Tab(jt);
                    this.tabService.setEvent(tab);

                    const currentTab = this.tabService.currentValue;
                    if (!!currentTab && currentTab.id === tab.id) {
                        this.tabService.setCurrent(tab);
                    }

                    if (!!tab.orders) {
                        tab.orders.forEach(o => {
                            if (!!tab.nameOverride) {
                                o.tabPersonName = tab.nameOverride;
                            }
                            this.orderEventHandler(o);
                        });
                    }
                });
            }
            this.tabService.touched = null;
        }
    }

    orderEventHandler(order: Order) {
        if (!!this.pickupLocationService.currentValue && order.pickupLocation.id === this.pickupLocationService.currentValue.id) {
            this.orderService.setEvent(order);

            const currentActiveOrders = this.orderService.getCurrentActiveOrders().filter(o => o.id === order.id);
            const activeOrders = [...this.orderService.getCurrentActiveOrders()];

            if (currentActiveOrders && currentActiveOrders.length === 1) {
                activeOrders.splice(activeOrders.indexOf(currentActiveOrders[0]), 1);
            }

            if (order.isActive()) {
                activeOrders.push(order);
            }

            const currentOrder = this.orderService.currentValue;
            if (!!currentOrder && currentOrder.id === order.id) {
                this.orderService.setCurrent(order);
            }

            if (!order.modifiedBy
                || !this.userService.currentValue
                || order.modifiedBy.id !== this.userService.currentValue.id) {
                if (currentActiveOrders.length < 1 && order.status === OrderStatuses.RECEIVED) {
                    if (order.deviceId !== this.deviceId) {
                        if ((order.terminalOrder && this.audioOption === 'ALL')
                            || (!order.terminalOrder && !!this.audioOption)) {
                            this.orderInSound.play();
                        }
                    }
                    this.orderIn = order;
                }
            }

            this.orderService.setActiveOrders(activeOrders);
        }
    }

    membershipEventHandler(id: number) {
        const cur = this.membershipService.currentValue;
        if (!!cur && cur.id === id) {
            this.membershipService.getSilent(id).subscribe(m => {
                const membership = new Membership(m);
                this.membershipService.setCurrent(membership);
                this.membershipService.setEvent(membership);
            });
        } else {
            this.membershipService.setEvent(new Membership({id}));
        }
    }
}
