import {Component} from '@angular/core';
import {BaseModalComponent} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-select-closeout',
    templateUrl: './select-closeout.component.html',
    styleUrls: ['./select-closeout.component.scss'],
})
export class SelectCloseoutComponent extends BaseModalComponent {

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
