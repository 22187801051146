import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Column, ColumnField, ColumnFormats, Menu, ReportColumns, TaxColumns} from 'brewbill-lib';

@Component({
    selector: 'bb-select-column',
    templateUrl: './select-column.component.html',
    styleUrls: ['./select-column.component.scss']
})
export class SelectColumnComponent implements OnInit, OnDestroy {
    @ViewChild('selectColumnInput') set selectColumnInput(element) {
        this.input = element.el;
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(async () => {
                    this.popoverWidth = this.input.offsetWidth;
                });
            });

            this.resizeObserver.observe(this.input);
        }
    }

    @Input() requireAggregate = false;
    @Input() menu: Menu;
    @Input() column: Column;
    @Input() key = '';
    @Input() columns = [...ReportColumns];
    @Output() selected: EventEmitter<ColumnField> = new EventEmitter();

    input: any;
    selectedColumnField: ColumnField;
    keys = [];

    popoverWidth = 0;
    resizeObserver: ResizeObserver;
    showOptions = false;
    closing = false;

    constructor() {
    }

    ngOnDestroy(): void {
        if (!!this.resizeObserver) {
            this.resizeObserver.unobserve(this.input);
            this.resizeObserver.disconnect();
        }
    }

    ngOnInit() {
        if (this.requireAggregate) {
            this.columns = this.columns.filter(c => (c.format === ColumnFormats.NUMBER
                    || c.format === ColumnFormats.CURRENCY || c.format === ColumnFormats.DURATION || c.format === ColumnFormats.ID)
                && c.key !== 'HOUR' && c.key !== 'DAY' && c.key !== 'WEEK' && c.key !== 'YEAR');
        }

        this.menu.menuTaxLabels.forEach(t => {
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_${TaxColumns.TAX}`, ColumnFormats.CURRENCY, `Tab ${t.name} Tax`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_${TaxColumns.TAX_RATE}`, ColumnFormats.NUMBER, `Tab ${t.name} Tax Rate`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_${TaxColumns.TAXED_TOTAL}`, ColumnFormats.CURRENCY, `Tab ${t.name} Total Amount Taxed`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_${TaxColumns.TAX_INCLUDED}`, ColumnFormats.BOOLEAN, `Tab ${t.name} Tax Included`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_ORDER_ITEM_${TaxColumns.TAX}`, ColumnFormats.CURRENCY, `Order Item ${t.name} Tax`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_ORDER_ITEM_${TaxColumns.TAX_RATE}`, ColumnFormats.NUMBER, `Order Item ${t.name} Tax Rate`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_ORDER_ITEM_${TaxColumns.TAXED_TOTAL}`, ColumnFormats.CURRENCY, `Order Item ${t.name} Total Amount Taxed`));
            this.columns.push(new ColumnField(`${t.name.toUpperCase().replace(/ /g, '_')}_ORDER_ITEM_${TaxColumns.TAX_INCLUDED}`, ColumnFormats.BOOLEAN, `Order Item ${t.name} Tax Included`));
        });

        this.columns = this.columns.sort((a, b) => {
            if (a.display < b.display) {
                return -1;
            } else if (a.display > b.display) {
                return 1;
            }
            return 0;
        });

        let key = !!this.column ? this.column.key : this.key;
        if (!!this.column && !this.columns.some(a => a.key === key)) {
            key = this.column.accessKey;
        }

        if (!!this.column && !!this.column.aggregation && !this.columns.some(c => c.key === key)) {
            key = this.column.accessKey.substring(0, this.column.accessKey.indexOf(`_${this.column.aggregation.toUpperCase()}`));
        }

        this.selectedColumnField = this.columns.find(c => c.key === key);

        if (!this.key) {
            this.selected.emit(this.selectedColumnField);
        }
    }

    async presentPopover() {
        this.showOptions = true;
    }

    async closed(item) {
        this.selectedColumnField = item;
        this.showOptions = false;
        this.selected.emit(this.selectedColumnField);
    }
}
