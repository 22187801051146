import {Component, Input} from '@angular/core';
import {BaseModalComponent} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-present-card-for-tab',
    templateUrl: './present-card-for-tab.component.html',
    styleUrls: ['./present-card-for-tab.component.scss'],
})
export class PresentCardForTabComponent extends BaseModalComponent {
    @Input() hideTap = false;
    @Input() forTab = true;

    constructor(modalController: ModalController) {
        super(modalController);
    }
}
