import {Component, Input, OnInit} from '@angular/core';
import {
    BaseModalComponent,
    LoadingService,
    MembershipProgram,
    MembershipProgramFeeStructure,
    MembershipProgramService,
    Organization,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-membership-program',
    templateUrl: './edit-membership-program.component.html',
    styleUrls: ['./edit-membership-program.component.scss'],
})
export class EditMembershipProgramComponent extends BaseModalComponent implements OnInit {
    @Input() membershipProgram: MembershipProgram;
    @Input() organization: Organization;
    formGroup: FormGroup;

    membershipProgramFeeStructure = MembershipProgramFeeStructure;

    constructor(
        modalController: ModalController,
        private membershipProgramService: MembershipProgramService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.membershipProgram && !!this.membershipProgram.id ? this.membershipProgram.id : null,
            parentId: !!this.organization ? this.organization.id : null,
            name: [!!this.membershipProgram ? this.membershipProgram.name : null, Validators.required],
            description: !!this.membershipProgram ? this.membershipProgram.description : null,
            amount: [!!this.membershipProgram ? this.membershipProgram.amount : null, Validators.required],
            durationPart: [!!this.membershipProgram ? this.membershipProgram.durationPart : 'YEAR', Validators.required],
            durationCount: [!!this.membershipProgram ? this.membershipProgram.durationCount : 1, Validators.required],
            autoRenewal: !!this.membershipProgram && this.membershipProgram.autoRenewal,
            lifetime: !!this.membershipProgram && this.membershipProgram.lifetime,
            status: !!this.membershipProgram ? this.membershipProgram.status : 'ACTIVE',
            tokens: !!this.membershipProgram ? this.membershipProgram.tokens : 0,
            tokensExpire: !this.membershipProgram || this.membershipProgram.tokensExpire,
            feeStructure: !!this.membershipProgram ? this.membershipProgram.feeStructure : MembershipProgramFeeStructure.NO_ORG
        });
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const membershipProgram = new MembershipProgram(this.formGroup.value);
            this.loadingService.present();
            if (!membershipProgram.id) {
                this.membershipProgramService.create(membershipProgram).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Program created.');
                    this.close(new MembershipProgram(c));
                });
            } else {
                this.membershipProgramService.update(membershipProgram).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Program updated.');
                    this.close(new MembershipProgram(c));
                });
            }
        }
    }
}
