import {Component} from '@angular/core';
import {BaseOrganizationComponent} from '../base-organization.component';
import {
    LoadingService,
    MenuItemPriceSummaryService,
    OrganizationEvent,
    OrganizationService,
    ToastService,
    UserService,
    VenueService
} from 'brewbill-lib';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {EditPickupLocationComponent} from '../edit-pickup-location/edit-pickup-location.component';
import {EditCustomerAreaComponent} from '../edit-customer-area/edit-customer-area.component';
import {EditOrganizationEventComponent} from '../edit-organization-event/edit-organization-event.component';

@Component({
    selector: 'bb-organization-locations',
    templateUrl: './organization-locations.component.html',
    styleUrls: ['./organization-locations.component.scss'],
})
export class OrganizationLocationsComponent extends BaseOrganizationComponent {

    constructor(
        organizationService: OrganizationService,
        pickupLocationService: VenueService,
        userService: UserService,
        venueService: VenueService,
        loadingService: LoadingService,
        private menuItemPriceSummaryService: MenuItemPriceSummaryService,
        private alertController: AlertController,
        private modalController: ModalController,
        private toastService: ToastService,
        private navController: NavController
    ) {
        super(organizationService,
            pickupLocationService,
            userService,
            venueService,
            loadingService);
    }

    async openEditPickupLocation() {
        const modal = await this.modalController.create({
            component: EditPickupLocationComponent,
            cssClass: 'large-modal',
            componentProps: {
                organization: this.organization,
                venue: this.venue,
                pickupLocation: null
            }
        });

        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshOrganization();
            }
        });
    }

    async addCustomerArea() {
        const modal = await this.modalController.create({
            component: EditCustomerAreaComponent,
            componentProps: {
                organization: this.organization
            }
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.navController.navigateForward(`/customer-area/${dataReturned.data.id}/${this.organization.id}`);
            }
        });
    }

    async editOrganizationEvent(organizationEvent: OrganizationEvent) {
        this.loadingService.present();
        const modal = await this.modalController.create({
            component: EditOrganizationEventComponent,
            cssClass: 'menu-item-modal',
            componentProps: {
                organizationEvent,
                organization: this.organization
            }
        });

        this.loadingService.dismiss();
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.refreshOrganization();
            }
        });
    }
}
