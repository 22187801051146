import {Component, Input, OnInit} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {CurrencyPipe} from '@angular/common';

import {
    BaseModalComponent,
    CashAudit,
    CashAuditActions,
    CashAuditEvents,
    CashAuditService,
    CashDrawer,
    CashDrawerService,
    CashDrawerStatuses,
    CashDrawerSummary,
    CashSession,
    CashSessionService,
    FeatureKeys,
    LoadingService,
    Person,
    PickupLocation,
    PrinterService,
    ToastService,
    User,
    UserService,
} from 'brewbill-lib';
import {AssignCashDrawerComponent} from '../assign-cash-drawer/assign-cash-drawer.component';
import {forkJoin} from 'rxjs';
import {TransferCashComponent} from '../transfer-cash/transfer-cash.component';

@Component({
    selector: 'bb-add-cash-action',
    templateUrl: './add-cash-action.component.html',
    styleUrls: ['./add-cash-action.component.scss'],
})
export class AddCashActionComponent extends BaseModalComponent implements OnInit {
    @Input() pickupLocation: PickupLocation;
    @Input() cashDrawer: CashDrawer;
    @Input() cashSession: CashSession;
    @Input() correcting: CashAudit;

    @Input() tippedPerson: Person;
    @Input() initialAmount: number;
    @Input() correctsAll = false;

    formGroup: FormGroup = new FormGroup({});

    cashAuditActions = CashAuditActions;
    cashAuditEvents = CashAuditEvents;

    users: User[];
    drawers: CashDrawer[];
    organizationId: number;
    user: User;
    canPrint = false;

    featureKeys = FeatureKeys;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private userService: UserService,
        private cashDrawerService: CashDrawerService,
        private cashSessionService: CashSessionService,
        private cashAuditService: CashAuditService,
        private currencyPipe: CurrencyPipe,
        private printerService: PrinterService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.organizationId = !!this.pickupLocation ? this.pickupLocation.orgId : this.cashDrawer.orgId;

        this.subscribe(this.userService.current.subscribe(u => this.user = !!u ? new User(u) : null));
        this.subscribe(this.printerService.connected.subscribe(c => this.canPrint = c));

        let tippedPerson: Person;
        let action: string;
        let event: string;
        if (!!this.correcting) {
            tippedPerson = this.correcting.tippedPerson;
            action = this.correcting.action === CashAuditActions.IN
                ? CashAuditActions.OUT : CashAuditActions.IN;
            event = this.correcting.event;
        } else {
            tippedPerson = !!this.tippedPerson ? this.tippedPerson : null;
            action = !!this.tippedPerson ? CashAuditActions.OUT : CashAuditActions.IN;
            event = !!this.tippedPerson ? CashAuditEvents.TIP_OUT : CashAuditEvents.ADD;
        }

        this.formGroup = this.formBuilder.group(
            {
                amount: [!!this.initialAmount ? this.initialAmount : '', Validators.required],
                action: [action, Validators.required],
                applyToCashSessionId: !!this.cashSession ? this.cashSession.id : null,
                event: [event, Validators.required],
                tippedPerson,
                correctsAll: this.correctsAll,
                comment: ''
            }
        );

        this.formGroup.get('action').valueChanges.subscribe(value => {
            if (!this.correcting) {
                this.formGroup.get('event').setValue(value === CashAuditActions.IN ? CashAuditEvents.ADD : null);
                this.formGroup.controls.event.updateValueAndValidity();
            }
        });

        this.formGroup.get('event').valueChanges.subscribe(value => {
            this.formGroup.get('tippedPerson').setValidators(value === CashAuditEvents.TIP_OUT ? [Validators.required] : null);
            this.formGroup.get('tippedPerson').setValue(null);
            this.formGroup.controls.tippedPerson.updateValueAndValidity();
        });

        this.userService.getOrgUsers(this.organizationId).subscribe((users: User[]) => {
            const sanitizedUsers = [];
            users.forEach((u: User) => {
                sanitizedUsers.push(new User(u));
            });
            this.users = sanitizedUsers;
        });

        if (!this.cashDrawer) {
            this.subscribe(this.cashSessionService.current.subscribe(c => {
                this.cashSession = !!c ? new CashSession(c) : null;

                if (!!this.pickupLocation) {
                    this.drawers = !!this.cashSession ? this.cashSession.cashDrawers
                        .filter(d => d.pickupLocation.id === this.pickupLocation.id && d.status === CashDrawerStatuses.ACTIVE) : [];
                }
            }));

            this.subscribe(this.cashDrawerService.current.subscribe(c => {
                this.cashDrawer = !!c ? new CashDrawer(c) : null;
            }));
        } else {
            this.subscribe(this.cashSessionService.event.subscribe(c => {
                if (!!c && c.id === this.cashSession.id) {
                    this.cashSession = new CashSession(c);
                    if (this.cashSession.status !== 'PENDING' && this.cashSession.status !== 'ACTIVE') {
                        this.close();
                    } else {
                        const found = c.cashDrawers.find(d => d.id === this.cashDrawer.id);
                        if (!!found) {
                            this.cashDrawer = new CashDrawer(found);
                            if (this.cashDrawer.status !== CashDrawerStatuses.ACTIVE) {
                                this.close();
                            }
                        }
                    }
                }
            }));
        }
    }

    addAction() {
        this.loadingService.present();
        const audit = new CashAudit(this.formGroup.value);
        audit.parentId = this.cashDrawer.id;
        audit.corrects = this.correcting;

        if (this.canPrint) {
            this.printerService.openDrawer();
        }

        this.cashAuditService.create(audit).subscribe(async (c: CashAudit) => {
            this.loadingService.dismiss();
            await this.toastService.success(`${this.currencyPipe.transform(c.amount)} has been ${c.action === CashAuditActions.IN
                ? 'added'
                : 'removed'}.`);
            await this.close(c);
        }, async error => {
            this.loadingService.dismiss();
            if (error.status === 417) {
                await this.toastService.error('The amount received cannot be less than the tip and payment.');
            }
        });
    }

    noSale() {
        if (this.canPrint) {
            this.printerService.openDrawer();
        }

        const audit = new CashAudit(this.formGroup.value);
        audit.parentId = this.cashDrawer.id;
        audit.corrects = null;
        audit.amount = 0;
        audit.comment = null;

        this.cashAuditService.create(audit, true).subscribe(async (c: CashAudit) => {
            await this.close(c);
        });
    }

    async changeDrawer() {
        if (!this.cashSession) {
            const cs = await this.cashSessionService.findOpen(this.organizationId).toPromise() as CashSession;
            if (!!cs) {
                this.cashSession = new CashSession(cs);
                this.cashSessionService.setCurrent(this.cashSession);
            }
        }

        const modal = await this.modalController.create({
            component: AssignCashDrawerComponent,
            componentProps: {
                pickupLocation: this.pickupLocation
            }
        });

        await modal.present();
    }

    async openTransferModal() {
        const queries = [
            this.cashDrawerService.safes(this.organizationId)
        ];

        if (!!this.cashSession) {
            queries.push(this.cashDrawerService.summaries(this.cashSession.id));
        }

        forkJoin(queries).subscribe(async ([safes, cashSessionDrawers]) => {
            this.loadingService.dismiss();
            const availableDrawers = [];
            if (!!safes) {
                availableDrawers.concat();
            }

            const modal = await this.modalController.create({
                component: TransferCashComponent,
                componentProps: {
                    cashDrawerSummary: (cashSessionDrawers as CashDrawerSummary[]).find(c => c.id === this.cashDrawer.id),
                    availableDrawers: (!!safes ? safes as CashDrawerSummary[] : []).concat((!!cashSessionDrawers
                        ? cashSessionDrawers as CashDrawerSummary[]
                        : []))
                }
            });

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.close(dataReturned.data);
                } else {
                    this.formGroup.controls.action.setValue(CashAuditActions.IN);
                }
            });

            await modal.present();
        });
    }

    authorizedToCashDrawerAssign() {
        return !!this.user && this.user.authorizedTo(FeatureKeys.CASH_DRAWER_ASSIGN_KEYS, this.organizationId);
    }
}
