import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Address, AddressService, BaseModalComponent, Organization, OrganizationService, Venue, VenueMapComponent} from 'brewbill-lib';
import clone from 'clone';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-edit-organization-location',
    templateUrl: './edit-organization-location.component.html',
    styleUrls: ['./edit-organization-location.component.css'],
})
export class EditOrganizationLocationComponent extends BaseModalComponent implements AfterViewInit {
    @Input() venue: Venue;
    @Input() organization: Organization;
    @ViewChild('venueMap') venueMap: VenueMapComponent;
    location: google.maps.Marker;

    constructor(
        public modalController: ModalController,
        private addressService: AddressService,
        private organizationService: OrganizationService
    ) {
        super(modalController);
    }

    ngAfterViewInit(): void {
        this.location = new google.maps.Marker({
            position: new google.maps.LatLng(this.organization.address.latitude, this.organization.address.longitude),
            map: this.venueMap.map.googleMap,
        });
    }

    setLocation(event) {
        if (!this.location) {
            this.location = new google.maps.Marker({
                position: event.latLng,
                map: this.venueMap.map.googleMap,
            });
        } else {
            this.location.setPosition(event.latLng);
        }
    }


    onSubmit() {
        const address: Address = clone<Address>(this.organization.address);
        address.latitude = this.location.getPosition().lat();
        address.longitude = this.location.getPosition().lng();

        this.addressService.update(address, this.organizationService.destination, this.organization.id).subscribe(a => {
            this.close(a);
        });
    }
}
