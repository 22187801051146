import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent, LoadingService, Menu, MenuTaxLabel, MenuTaxLabelService, ToastService} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-menu-tax-label',
    templateUrl: './edit-menu-tax-label.component.html',
    styleUrls: ['./edit-menu-tax-label.component.scss'],
})
export class EditMenuTaxLabelComponent extends BaseModalComponent implements OnInit {
    formGroup: FormGroup = new FormGroup({});
    @Input() menuTaxLabel: MenuTaxLabel;
    @Input() menu: Menu;

    constructor(
        modalController: ModalController,
        private menuTaxLabelService: MenuTaxLabelService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuTaxLabel = this.menuTaxLabel == null ? new MenuTaxLabel() : this.menuTaxLabel;
        this.formGroup = this.formBuilder.group({
            id: this.menuTaxLabel.id,
            parentId: this.menu.id,
            name: [this.menuTaxLabel.name, Validators.required],
            description: this.menuTaxLabel.description,
            taxRate: [this.menuTaxLabel.taxRate, Validators.required],
            taxIncludedCash: !!this.menuTaxLabel ? this.menuTaxLabel.taxIncludedCash : false,
            taxIncludedCharge: !!this.menuTaxLabel ? this.menuTaxLabel.taxIncludedCharge : false,
            taxIncludedGiftCard: !!this.menuTaxLabel ? this.menuTaxLabel.taxIncludedGiftCard : false,
            fee: !!this.menuTaxLabel ? this.menuTaxLabel.fee : false
        });
    }

    onSubmit() {
        this.loadingService.present();
        const ml: MenuTaxLabel = new MenuTaxLabel(this.formGroup.value);

        if (ml.id != null) {
            this.menuTaxLabelService.update(ml).subscribe(
                (menuTaxLabel: MenuTaxLabel) => {
                    this.toastService.success('Tax label updated.');
                    this.loadingService.dismiss();
                    this.close(menuTaxLabel);
                }
            );
        } else {
            this.menuTaxLabelService.create(ml).subscribe(
                (menuTaxLabel: MenuTaxLabel) => {
                    this.toastService.success('Tax label added.');
                    this.loadingService.dismiss();
                    this.close(menuTaxLabel);
                }
            );
        }
    }
}
