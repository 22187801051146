import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AddressService, BaseStepperFormComponent, LoadingService, ToastService, User, Venue, VenueService} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
    selector: 'app-register-venue',
    templateUrl: './register-venue.component.html',
    styleUrls: ['./register-venue.component.scss'],
})
export class RegisterVenueComponent extends BaseStepperFormComponent {
    venueFormGroup: FormGroup = new FormGroup({});
    addressFormGroup: FormGroup = new FormGroup({});
    personFormGroup: FormGroup = new FormGroup({});

    constructor(
        modalController: ModalController,
        private addressService: AddressService,
        private loadingService: LoadingService,
        private venueService: VenueService,
        private alertController: AlertController,
        private router: Router,
        private toastService: ToastService
    ) {
        super(modalController);
    }

    venueFormInitialized(form: FormGroup) {
        this.venueFormGroup = form;
    }

    addressFormInitialized(form: FormGroup) {
        this.addressFormGroup = form;
    }

    personFormInitialized(form: FormGroup) {
        this.personFormGroup = form;
    }

    valid() {
        return this.venueFormGroup.valid
            && this.addressFormGroup.valid
            && this.personFormGroup.valid;
    }

    onSubmit() {
        if (!this.valid()) {
            return;
        }

        const venue: Venue = this.venueFormGroup.value;
        venue.address = this.addressFormGroup.value;

        const owner: User = new User();
        owner.person = this.personFormGroup.value;

        venue.owner = owner;

        this.addressService.getGeocoder().geocode({
            address: this.addressService.fullAddress(venue.address)
        }, async (results, status) => {
            // @ts-ignore
            if (status === google.maps.GeocoderStatus.OK && results[0].geometry.location_type === 'ROOFTOP') {
                venue.address.latitude = results[0].geometry.location.lat();
                venue.address.longitude = results[0].geometry.location.lng();
                await this.register(venue);
            } else {
                const alert = await this.alertController.create({
                    cssClass: 'brewbill-alert',
                    header: 'Confirm',
                    message: 'The address was not found. Would you like to proceed anyway?',
                    buttons: [
                        {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: 'Yes',
                            handler: () => {
                                venue.address.latitude = null;
                                venue.address.longitude = null;
                                this.register(venue);
                            }
                        }
                    ]
                });

                await alert.present();
            }
        });
    }

    async register(venue: Venue) {
        this.loadingService.present();

        this.venueService.create(venue).subscribe((v: Venue) => {
            this.toastService.success('Venue created.');
            this.close();
            this.loadingService.dismiss();
            this.router.navigateByUrl(`/venue/${v.id}`);
        });
    }
}
