import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompDefinition, CompDefinitionTypes} from 'brewbill-lib';

@Component({
    selector: 'bb-comp-definition',
    templateUrl: './comp-definition.component.html',
    styleUrls: ['./comp-definition.component.scss'],
})
export class CompDefinitionComponent implements OnInit {
    @Input() compDefinition: CompDefinition;
    @Input() selectOption = false;
    @Output() compClick = new EventEmitter();

    altColor = '#ffffff';

    compDefinitionTypes = CompDefinitionTypes;

    ngOnInit(): void {
        const color = this.compDefinition.color.startsWith('#') ? this.compDefinition.color.substring(1) : this.compDefinition.color;

        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        R = (R > 125) ? R * (100 - 50) / 100 : R * (100 + 50) / 100;
        G = (G > 125) ? G * (100 - 50) / 100 : G * (100 + 50) / 100;
        B = (B > 125) ? B * (100 - 50) / 100 : B * (100 + 50) / 100;

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
        const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
        const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

        this.altColor = '#' + RR + GG + BB;
    }
}
