import {Component, Input} from '@angular/core';
import {BaseModalComponent, LoadingService, OrganizationUser, OrganizationUserService, ToastService} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-assign-pin-code',
    templateUrl: './assign-pin-code.component.html',
    styleUrls: ['./assign-pin-code.component.scss'],
})
export class AssignPinCodeComponent extends BaseModalComponent {
    @Input() organizationUser: OrganizationUser;
    formGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private organizationUserService: OrganizationUserService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        modalController: ModalController
    ) {
        super(modalController);
        this.formGroup = this.formBuilder.group({
            pinCode: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])]
        });
    }

    checkGenerate() {
        return this.formGroup.controls.pinCode.value.length === 0;
    }

    async submit() {
        this.loadingService.present();
        this.organizationUserService.assignPin(this.organizationUser.id, this.formGroup.controls.pinCode.value).subscribe((uo: OrganizationUser) => {
                this.toastService.success(`The pin code has been set to ${uo.pinCode}.`);
                this.close();
                this.loadingService.dismiss();
            },
            error => {
                if (error.status === 409) {
                    this.toastService.error('This pin code is already in use. Please select a new pin code.');
                }
                this.loadingService.dismiss();
            });
    }
}
