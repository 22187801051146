import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    CustomerArea,
    CustomerAreaPosition,
    CustomerAreaService,
    LoadingService,
    Organization,
    PickupLocation,
    reorder,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {EditCustomerAreaPositionComponent} from '../edit-customer-area-position/edit-customer-area-position.component';

@Component({
    selector: 'bb-edit-customer-area',
    templateUrl: './edit-customer-area.component.html',
    styleUrls: ['./edit-customer-area.component.scss'],
})
export class EditCustomerAreaComponent extends BaseStepperFormComponent implements OnInit {
    @Input() customerArea: CustomerArea;
    @Input() organization: Organization;
    formGroup: FormGroup = new FormGroup({});
    positions: CustomerAreaPosition[] = [];

    constructor(
        modalController: ModalController,
        private customerAreaService: CustomerAreaService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.positions = !!this.customerArea && !!this.customerArea.positions ? [...this.customerArea.positions] : [];
        this.formGroup = this.formBuilder.group({
            id: !!this.customerArea ? this.customerArea.id : null,
            parentId: this.organization.id,
            name: [!!this.customerArea ? this.customerArea.name : '', Validators.required],
            positionLabel: !!this.customerArea ? this.customerArea.positionLabel : '',
            pickupLocationIds: [
                !!this.customerArea && !!this.customerArea.pickupLocations && this.customerArea.pickupLocations.length > 0
                    ? this.customerArea.pickupLocations.map(pl => pl.id) : []
            ]
        });
    }

    async editPosition(position: CustomerAreaPosition) {
        const modal = await this.modalController.create({
            component: EditCustomerAreaPositionComponent,
            componentProps: {
                customerAreaPosition: position,
                positions: [...this.positions]
            }
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.positions = dataReturned.data;
            }
        });
    }

    remove(position: CustomerAreaPosition) {
        this.positions.splice(this.positions.indexOf(position), 1);
    }

    onSubmit() {
        const ca = new CustomerArea(this.formGroup.value);
        const pickupLocationIds = this.formGroup.controls.pickupLocationIds.value;

        ca.pickupLocations = pickupLocationIds.reduce((accumulator: PickupLocation[], id) => {
            accumulator.push(new PickupLocation({id}));
            return accumulator;
        }, []);
        ca.positions = this.positions;
        this.loadingService.present();
        if (!this.customerArea) {
            this.customerAreaService.create(ca).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('The customer area has been saved.');
                this.close(c);
            });
        } else {
            this.customerAreaService.update(ca).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('The customer area has been saved.');
                this.close(c);
            });
        }
    }

    reorderPositions(event) {
        reorder(this.positions, event);
    }
}
