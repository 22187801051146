import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    BaseStepperFormComponent,
    dependentRequired,
    LoadingService,
    MenuItemPriceSummary,
    OrganizationRewardProgram,
    OrganizationRewardProgramService,
    RewardTypes,
    ToastService
} from 'brewbill-lib';
import {AlertController, ModalController} from '@ionic/angular';

@Component({
    selector: 'app-edit-organization-reward-program',
    templateUrl: './edit-organization-reward-program.component.html',
    styleUrls: ['./edit-organization-reward-program.component.scss'],
})
export class EditOrganizationRewardProgramComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organizationRewardProgram: OrganizationRewardProgram;
    @Input() organizationId: number;
    @Input() priceSummaries: MenuItemPriceSummary[];
    copyQualified = false;
    formGroup: FormGroup = new FormGroup({});

    itemFilter = '';
    filteredItems = [];

    constructor(
        modalController: ModalController,
        private alertController: AlertController,
        private organizationRewardProgramService: OrganizationRewardProgramService,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.organizationRewardProgram = this.organizationRewardProgram == null
            ? new OrganizationRewardProgram()
            : this.organizationRewardProgram;

        this.priceSummaries = this.priceSummaries.map(item => {
            const container = new MenuItemPriceSummary({...item});
            container.qualified = !!this.organizationRewardProgram.qualifiedItems
                && this.organizationRewardProgram.qualifiedItems.some(q => q.itemId === item.itemId && q.priceId === item.priceId);

            container.reward = !!this.organizationRewardProgram.rewardItems
                && this.organizationRewardProgram.rewardItems.some(r => r.itemId === item.itemId && r.priceId === item.priceId);

            return container;
        });
        this.filteredItems = [...this.priceSummaries];

        this.initializeCopyQualified();

        const fg = this.formBuilder.group(
            {
                id: !!this.organizationRewardProgram ? this.organizationRewardProgram.id : null,
                parentId: this.organizationId,
                type: [!!this.organizationRewardProgram ? this.organizationRewardProgram.type : null, Validators.required],
                rewardType: [!!this.organizationRewardProgram ? this.organizationRewardProgram.rewardType : null, Validators.required],
                reward: [!!this.organizationRewardProgram ? this.organizationRewardProgram.reward : null, Validators.required],
                goal: [!!this.organizationRewardProgram ? this.organizationRewardProgram.goal : null, Validators.required],
                rewardName: this.organizationRewardProgram.rewardName
            }
        );

        fg.setValidators(dependentRequired('rewardName', 'rewardType', RewardTypes.ITEM));
        this.formGroup = fg;
    }

    filterItems() {
        this.filteredItems = !!this.itemFilter ? this.priceSummaries.filter((p: any) =>
                (!!p.itemName && p.itemName.toLowerCase().includes(this.itemFilter.toLowerCase()))
                || (!!p.priceName && p.priceName.toLowerCase().includes(this.itemFilter.toLowerCase())))
            : [...this.priceSummaries];
    }

    initializeCopyQualified() {
        this.copyQualified = this.priceSummaries.every(item => item.qualified === item.reward);
    }

    copyRewards() {
        if (this.copyQualified) {
            this.priceSummaries.forEach(item => item.reward = item.qualified);
        }
    }

    valid() {
        return this.formGroup.valid;
    }

    description() {
        return this.formGroup.valid ? this.organizationRewardProgramService.description(this.formGroup.value) : '';
    }

    async confirmRewardGrandfather() {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Grandfather',
            message: 'Would you like to grandfather in existing rewards users or have this change take affect for all users immediately?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }, {
                    text: 'Delete Existing',
                    handler: () => {
                        this.save(false);
                    }
                }, {
                    text: 'Grandfather Existing',
                    handler: () => {
                        this.save(true);
                    }
                }
            ]
        });

        await alert.present();
    }

    async onSubmit() {
        const program: OrganizationRewardProgram = new OrganizationRewardProgram(this.formGroup.value);
        if (!this.organizationRewardProgram
            || (!!this.organizationRewardProgram
                && this.organizationRewardProgram.rewardType === program.rewardType
                && this.organizationRewardProgram.reward === program.reward
                && this.organizationRewardProgram.goal === program.goal
                && this.organizationRewardProgram.type === program.type)
        ) {
            await this.save(true);
        } else {
            await this.confirmRewardGrandfather();
        }
    }

    async save(grandfather) {
        this.loadingService.present();
        const program: OrganizationRewardProgram = new OrganizationRewardProgram(this.formGroup.value);
        program.parentId = this.organizationId;
        program.qualifiedItems = this.priceSummaries.filter(item => item.qualified);
        program.rewardItems = this.priceSummaries.filter(item => item.reward);

        this.organizationRewardProgramService.create(program, {grandfather}).subscribe(p => {
            this.loadingService.dismiss();
            this.toastService.success('The rewards program has been saved.');
            this.close(p);
        });
    }
}
