import {Component, OnInit} from '@angular/core';
import {
    LoadingService,
    Menu,
    MenuItemInventory,
    MenuItemInventoryActionComponent,
    MenuItemInventoryService,
    MenuService,
    Organization,
    OrganizationService,
    PickupLocationService,
    SubscriberComponent,
    ToastService
} from 'brewbill-lib';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {EditMenuItemInventoryComponent} from '../edit-menu-item-inventory/edit-menu-item-inventory.component';
import {MenuItemInventoryTransferComponent} from '../menu-item-inventory-transfer/menu-item-inventory-transfer.component';

@Component({
    selector: 'bb-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    pickupLocationId: number;
    items: MenuItemInventory[];
    menu: Menu;

    constructor(
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private menuItemInventoryService: MenuItemInventoryService,
        private menuService: MenuService,
        private navController: NavController,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private modalController: ModalController,
        private alertController: AlertController
    ) {
        super();
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        const pickupLocation = this.pickupLocationService.currentValue;
        this.pickupLocationId = !!pickupLocation ? pickupLocation.id : -1;
        this.menu = this.menuService.currentValue;

        if (!!this.organization) {
            this.refresh();
        }

        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
        this.subscribe(this.menuService.current.subscribe(m => this.menu = !!m ? new Menu(m) : null));

        this.subscribe(this.menuItemInventoryService.event.subscribe(i => {
            if (!!i) {
                const inventory = new MenuItemInventory(i);

                if (!!this.items && ((!inventory.pickupLocation && this.pickupLocationId < 1)
                    || (!!inventory.pickupLocation && inventory.pickupLocation.id === this.pickupLocationId))) {
                    const index = this.items.findIndex(item => item.id === inventory.id);
                    if (index > -1) {
                        if (!inventory.deleted) {
                            this.items[index] = inventory;
                        } else {
                            this.items.splice(index, 1);
                        }
                    } else {
                        this.items.push(inventory);
                    }
                }
            }
        }));
    }

    refresh() {
        this.loadingService.present();

        if (this.pickupLocationId > 0) {
            this.menuItemInventoryService.findByPickupLocationId(this.pickupLocationId).subscribe((items: MenuItemInventory[]) => {
                this.items = items;
                this.loadingService.dismiss();
            });
        } else {
            this.menuItemInventoryService.findByOrganizationId(this.organization.id).subscribe((items: MenuItemInventory[]) => {
                this.items = items;
                this.loadingService.dismiss();
            });
        }
    }

    async openInventoryAction(menuItemInventory: MenuItemInventory) {
        if (!menuItemInventory.pickupLocation || !menuItemInventory.containerQuantity) {
            const modal = await this.modalController.create({
                component: MenuItemInventoryActionComponent,
                componentProps: {
                    menuItemInventory
                }
            });

            modal.onDidDismiss().then(dataReturned => {
                if (!!dataReturned && !!dataReturned.data) {
                    this.refresh();
                }
            });

            await modal.present();
        } else {
            await this.navController.navigateForward(`/${this.pickupLocationId}/keg-summary/${menuItemInventory.menuItem.id}`);
        }
    }

    openTransfer(event, menuItemInventory: MenuItemInventory) {
        event.stopPropagation();
        this.loadingService.present();
        if (!!menuItemInventory.fillFrom) {
            this.menuItemInventoryService.get(menuItemInventory.fillFrom.id).subscribe(async (from: MenuItemInventory) => {
                this.loadingService.dismiss();

                const modal = await this.modalController.create({
                    component: MenuItemInventoryTransferComponent,
                    componentProps: {
                        from,
                        organization: this.organization,
                        to: {...menuItemInventory}
                    }
                });

                modal.onDidDismiss().then(dataReturned => {
                    if (!!dataReturned && !!dataReturned.data) {
                        this.refresh();
                    }
                });

                await modal.present();
            });
        } else {
            this.menuItemInventoryService.findByMenuItemId(menuItemInventory.menuItem.id, !!menuItemInventory.menuItemPrice
                ? menuItemInventory.menuItemPrice.id
                : null).subscribe(async (available: MenuItemInventory[]) => {
                this.loadingService.dismiss();

                const modal = await this.modalController.create({
                    component: MenuItemInventoryTransferComponent,
                    componentProps: {
                        from: menuItemInventory,
                        organization: this.organization,
                        available
                    }
                });

                modal.onDidDismiss().then(dataReturned => {
                    if (!!dataReturned && !!dataReturned.data) {
                        this.refresh();
                    }
                });

                await modal.present();
            });
        }
    }

    async openEditInventory(event, menuItemInventory: MenuItemInventory) {
        event.stopPropagation();
        const existingOrganizationInventory = (this.pickupLocationId < 1) ? this.items :
            await this.menuItemInventoryService.findByOrganizationId(this.organization.id).toPromise();

        const modal = await this.modalController.create({
            component: EditMenuItemInventoryComponent,
            componentProps: {
                menuItemInventory,
                menu: this.menu,
                pickupLocation: (this.pickupLocationId > 0)
                    ? this.organization.pickupLocations.find(p => p.id === this.pickupLocationId)
                    : null,
                organization: this.organization,
                existingInventory: this.items,
                existingOrganizationInventory
            },
            cssClass: 'inventory-modal'
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.refresh();
            }
        });

        await modal.present();
    }

    async copyInventory(event, menuItemInventory: MenuItemInventory) {
        event.stopPropagation();
        const existingOrganizationInventory = this.pickupLocationId < 1 ? this.items :
            await this.menuItemInventoryService.findByOrganizationId(this.organization.id).toPromise();

        const item = {...menuItemInventory};
        item.id = null;
        item.menuItem = null;
        const modal = await this.modalController.create({
            component: EditMenuItemInventoryComponent,
            componentProps: {
                menuItemInventory: item,
                menu: this.menu,
                pickupLocation: (this.pickupLocationId > 0)
                    ? this.organization.pickupLocations.find(p => p.id === this.pickupLocationId)
                    : null,
                organization: this.organization,
                existingInventory: this.items,
                existingOrganizationInventory
            },
            cssClass: 'inventory-modal'
        });

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                this.refresh();
            }
        });

        await modal.present();
    }

    getMenuLabels(menuItem) {
        let labels = '';
        if (!!menuItem && !!menuItem.labels && menuItem.labels.length > 0) {
            let first = true;
            menuItem.labels.forEach(l => {
                if (!first) {
                    labels += ', ';
                }
                labels += l.name;
                first = false;
            });
        }
        return labels;
    }

    async confirmDelete(event, item: MenuItemInventory) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Inventory Delete',
            message: `Are you sure you want to delete inventory for ${item.menuItem.name} - ${item.pickupLocation
                ? item.pickupLocation.name
                : 'Back of House'}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.menuItemInventoryService.delete(item.id).subscribe(() => {
                            this.toastService.success(`${item.menuItem.name} - ${item.pickupLocation
                                ? item.pickupLocation.name
                                : 'Back of House'} has been deleted.`);
                            this.loadingService.dismiss();
                            this.refresh();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    protected readonly Math = Math;
}
