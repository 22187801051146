import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    Organization,
    OrganizationService,
    OrganizationTerminal,
    OrganizationTerminalService,
    SelectCardReaderComponent,
    SubscriberComponent
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
    selector: 'bb-payment-button',
    templateUrl: './payment-button.component.html',
    styleUrls: ['./payment-button.component.scss'],
})
export class PaymentButtonComponent extends SubscriberComponent implements OnInit {
    @Input() cssClass: string;
    @Input() label = 'Proceed to Payment';
    @Output() pay = new EventEmitter();
    @Output() ready = new EventEmitter();

    organization: Organization;
    terminal: OrganizationTerminal;

    constructor(
        private organizationService: OrganizationService,
        private organizationTerminalService: OrganizationTerminalService,
        private modalController: ModalController,
        protected router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.organizationTerminalService.current.subscribe(t => {
            this.terminal = t;
            if (!!this.terminal) {
                this.ready.emit();
            }
        }));

        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
        }));
    }

    async connectReader() {
        const modal = await this.modalController.create({
            component: SelectCardReaderComponent,
            componentProps: {
                organization: this.organization
            }
        });

        await modal.present();
    }
}
