import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganizationEvent} from 'brewbill-lib';

@Component({
    selector: 'bb-organization-event',
    templateUrl: './organization-event.component.html',
    styleUrls: ['./organization-event.component.scss'],
})
export class OrganizationEventComponent implements OnInit {
    @Input() organizationEvent: OrganizationEvent;
    @Input() selectOption = false;
    @Output() eventClick = new EventEmitter();

    altColor = '#ffffff';

    ngOnInit(): void {
        const color = this.organizationEvent.color.startsWith('#')
            ? this.organizationEvent.color.substring(1)
            : this.organizationEvent.color;

        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        R = (R > 125) ? R * (100 - 50) / 100 : R * (100 + 50) / 100;
        G = (G > 125) ? G * (100 - 50) / 100 : G * (100 + 50) / 100;
        B = (B > 125) ? B * (100 - 50) / 100 : B * (100 + 50) / 100;

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
        const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
        const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

        this.altColor = '#' + RR + GG + BB;
    }
}
