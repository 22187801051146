import {Component, Inject, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
    BaseModalComponent,
    LibConfig,
    LibConfigService,
    Membership,
    MembershipProgram,
    MembershipRenewal,
    Organization
} from 'brewbill-lib';

@Component({
    selector: 'bb-renew-membership',
    templateUrl: './renew-membership.component.html',
    styleUrls: ['./renew-membership.component.scss'],
})
export class RenewMembershipComponent extends BaseModalComponent implements OnInit {
    @Input() member = new Membership();
    @Input() membershipProgram: MembershipProgram;
    @Input() organization: Organization;

    expirationDate: Date;
    testMode = false;
    qrUrl: string;

    modal: HTMLIonModalElement;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit(): void {
        this.disableBackDismiss = true;
        this.testMode = this.config.testMode;
        this.qrUrl = `${this.config.mobileUrl}/#/membership-signup/${this.organization.id}/${this.organization.pickupLocations[0].id}`;
    }

    async selectProgram(membershipProgram: MembershipProgram) {
        await this.close(new MembershipRenewal({
            membershipProgram
        }));
    }
}
