import {Component, OnInit} from '@angular/core';
import {
    LoadingService,
    MembershipProgram,
    MembershipProgramService,
    MembershipService,
    MembershipSummary,
    MembershipTokenGroup,
    MembershipTokenGroupService,
    MenuItemPriceSummaryService,
    Organization,
    OrganizationService,
    reorder,
    SubscriberComponent
} from 'brewbill-lib';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {EditMembershipProgramComponent} from '../edit-membership-program/edit-membership-program.component';
import {EditMembershipTokenGroupComponent} from '../edit-membership-token-group/edit-membership-token-group.component';

@Component({
    selector: 'bb-membership-programs',
    templateUrl: './membership-programs.component.html',
    styleUrls: ['./membership-programs.component.scss'],
})
export class MembershipProgramsComponent extends SubscriberComponent implements OnInit {
    organization: Organization;
    programs = [];
    members: MembershipSummary[];
    includeInactive = false;

    filter = '';
    filteredOptions: MembershipSummary[];

    constructor(
        private organizationService: OrganizationService,
        private membershipProgramService: MembershipProgramService,
        private membershipService: MembershipService,
        private menuItemPriceSummaryService: MenuItemPriceSummaryService,
        private membershipTokenGroupService: MembershipTokenGroupService,
        private loadingService: LoadingService,
        private navController: NavController,
        private alertController: AlertController,
        private modalController: ModalController
    ) {
        super();
    }

    ngOnInit() {
        this.organization = this.organizationService.currentValue;
        if (!!this.organization) {
            this.refreshSummaries();
        }

        this.subscribe(this.organizationService.current.subscribe(o => this.organization = !!o ? new Organization(o) : null));
    }

    refresh() {
        this.loadingService.present();
        this.organizationService.get(this.organization.id).subscribe(o => {
            this.organization = new Organization(o);
            this.loadingService.dismiss();
        });
    }

    loadAll() {
        if (this.includeInactive) {
            this.membershipService.findAllByOrganizationId(this.organization.id).subscribe((m: MembershipSummary[]) => {
                const temp = [];
                if (!!m && m.length > 0) {
                    m.forEach(i => temp.push(new MembershipSummary(i)));
                }
                this.members = temp;
                this.filterMembers();
            });
        } else {
            this.membershipService.findActiveByOrganizationId(this.organization.id).subscribe((m: MembershipSummary[]) => {
                const temp = [];
                if (!!m && m.length > 0) {
                    m.forEach(i => temp.push(new MembershipSummary(i)));
                }
                this.members = temp;
                this.filterMembers();
            });
        }
    }

    async openCreateProgram(membershipProgram = null) {
        const modal = await this.modalController.create({
            component: EditMembershipProgramComponent,
            componentProps: {
                membershipProgram,
                organization: this.organization
            },
            cssClass: 'menu-item-modal'
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.navController.navigateForward(`/${this.organization.id}/membership-program/${dataReturned.data.id}`);
            }
        });
    }

    refreshSummaries() {
        this.loadingService.present();
        this.membershipProgramService.findByOrganizationId(this.organization.id).subscribe((s: MembershipProgram[]) => {
            this.programs = s;
            this.loadingService.dismiss();
        });
        this.loadAll();
    }

    async openEditTokenGroup(tokenGroup: MembershipTokenGroup = null) {
        this.loadingService.present();
        this.menuItemPriceSummaryService.get(this.organization.menuId).subscribe(async (priceSummaries) => {
            const modal = await this.modalController.create({
                component: EditMembershipTokenGroupComponent,
                cssClass: 'menu-item-modal',
                componentProps: {
                    tokenGroup,
                    organization: this.organization,
                    priceSummaries
                }
            });

            this.loadingService.dismiss();
            await modal.present();

            modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null && dataReturned.data != null) {
                    this.refresh();
                }
            });
        });
    }

    async removeTokenGroup(event, tokenGroup: MembershipTokenGroup) {
        event.stopPropagation();
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Confirm Delete',
            message: `Are you sure you want to remove ${tokenGroup.name}?`,
            buttons: [
                {
                    text: 'No',
                    role: 'cancel'
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.membershipTokenGroupService.delete(tokenGroup.id).subscribe(() => {
                            this.loadingService.dismiss();
                            this.refresh();
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    filterMembers() {
        if (!!this.filter) {
            this.filteredOptions = this.members.filter(m => m.name.toLowerCase().includes(this.filter.toLowerCase()));
        } else {
            this.filteredOptions = [...this.members];
        }
    }

    navigate(program: MembershipProgram) {
        this.navController.navigateForward(`/${this.organization.id}/membership-program/${program.id}`);
    }

    reorderMembershipPrograms(event) {
        event.stopPropagation();
        reorder(this.programs, event);
        this.membershipProgramService.sort(this.programs).subscribe(async (o) => {
            this.organizationService.setEvent(new Organization(o));
        });
    }
}
