import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ModalController, NavController} from '@ionic/angular';
import {
    GiftCard,
    GiftCardService,
    LoadingService,
    Organization,
    OrganizationGiftCardSummary,
    OrganizationService,
    PickupLocation,
    PickupLocationService,
    SubscriberComponent,
    ToastService
} from 'brewbill-lib';
import {forkJoin} from 'rxjs';
import {GiftCardComponent} from '../gift-card/gift-card.component';

@Component({
    selector: 'bb-gift-cards',
    templateUrl: './gift-cards.component.html',
    styleUrls: ['./gift-cards.component.scss'],
})
export class GiftCardsComponent extends SubscriberComponent implements OnInit {
    searchCriteria: any;
    organization: Organization;
    pickupLocation: PickupLocation;
    dataSource: MatTableDataSource<GiftCard>;
    filterString = '';
    organizationSummary: OrganizationGiftCardSummary;
    status = ['ACTIVE'];
    displayedColumns: string[] = ['cardNumber', 'status', 'createDate', 'createdBy', 'lastActive', 'balance'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private giftCardService: GiftCardService,
        private organizationService: OrganizationService,
        private pickupLocationService: PickupLocationService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private modalController: ModalController,
        private navController: NavController
    ) {
        super();
    }

    ngOnInit() {
        this.pickupLocation = this.pickupLocationService.currentValue;
        this.organization = this.organizationService.currentValue;
        if (!this.pickupLocation && !!this.organization) {
            this.pickupLocation = this.organization.pickupLocations[0];
        }

        this.searchCriteria = this.giftCardService.getSearchCriteria();
        if (!!this.searchCriteria) {
            this.filterString = this.searchCriteria.filter;
        }

        this.dataSource = new MatTableDataSource([]);
        this.subscribe(this.organizationService.current.subscribe(o => {
            this.organization = !!o ? new Organization(o) : null;
            this.refresh();
        }));
    }

    ionViewWillEnter() {
        if (!!this.organization) {
            this.refresh();
        }
    }

    refresh() {
        this.loadingService.present();

        let query = this.giftCardService.findActiveByOrganization(this.organization.id);
        if (this.status.length === 2 || this.status.length === 0) {
            query = this.giftCardService.findAllByOrganization(this.organization.id);
        } else if (this.status[0] === 'COMPLETE') {
            query = this.giftCardService.findCompletedByOrganization(this.organization.id);
        }

        forkJoin([
            query,
            this.giftCardService.findSummaryByOrganization(this.organization.id, (!!this.status && this.status.length === 1)
                ? this.status[0]
                : null)
        ]).subscribe(([giftCards, summary]) => {
            this.dataSource = new MatTableDataSource(giftCards as GiftCard[]);
            this.dataSource.sort = this.sort;
            this.organizationSummary = summary as OrganizationGiftCardSummary;
            this.applyFilter(this.filterString);
            this.loadingService.dismiss();
        });
    }

    applyFilter(filterValue: string) {
        this.giftCardService.setSearchCriteria({filter: filterValue});
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    displayGiftCard(giftCard) {
        this.loadingService.present();
        this.giftCardService.get(giftCard.id).subscribe(async g => {
            const modal = await this.modalController.create({
                component: GiftCardComponent,
                componentProps: {
                    giftCard: new GiftCard(g),
                    organization: this.organization
                }
            });

            modal.onDidDismiss().then((dataReturned) => {
                if (!!dataReturned && !!dataReturned.data) {
                    this.loadGiftCard(new GiftCard(dataReturned.data));
                }
            });

            await modal.present();
            this.loadingService.dismiss();
        });
    }

    async loadGiftCard(giftCard = new GiftCard()) {
        this.giftCardService.setLoadingGiftCard(giftCard);
        await this.navController.navigateBack(`/manager/${this.pickupLocation.id}/manual-order`);
    }
}
