import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {
    BaseMenuPageComponent,
    CompDefinition,
    CompDefinitionAvailabilities,
    CompDefinitionTypes,
    CreateOrderItemCreditComponent,
    DeviceService,
    divideCurrency,
    LibConfig,
    LibConfigService,
    LoadingService,
    Membership,
    MembershipProgramFeeStructure,
    MembershipRenewal,
    MembershipService,
    MembershipSummary,
    MenuService,
    multiplyCurrency,
    OfflineOrderService,
    OfflineTabService,
    OrderItem,
    OrderItemCredit,
    OrderItemService,
    OrderService,
    OrderStatuses,
    OrganizationService,
    OrganizationTerminalService,
    PickupLocation,
    PickupLocationMenuService,
    PickupLocationService,
    subtractCurrency,
    Tab,
    TabNoteDefinition,
    TabService,
    TabStatuses,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';

import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {SelectCompComponent} from '../select-comp/select-comp.component';
import {clone} from 'chart.js/helpers';
import {SelectMemberComponent} from '../select-member/select-member.component';
import {RenewMembershipComponent} from '../renew-membership/renew-membership.component';

@Component({
    selector: 'bb-manual-order',
    templateUrl: './manual-order.component.html',
    styleUrls: ['./manual-order.component.scss'],
})
export class ManualOrderComponent extends BaseMenuPageComponent implements OnInit {
    isTerminal = true;
    availableTabComps: CompDefinition[] = [];
    availableOrderComps: CompDefinition[] = [];
    openTabs: Tab[];
    currentUser: User;
    deviceId: string;
    active = false;

    testMode = false;

    constructor(
        @Inject(LibConfigService) private config: LibConfig,
        private modalController: ModalController,
        private offlineTabService: OfflineTabService,
        private offlineOrderService: OfflineOrderService,
        private deviceService: DeviceService,
        membershipService: MembershipService,
        pickupLocationService: PickupLocationService,
        toastService: ToastService,
        changeDetectorRef: ChangeDetectorRef,
        pickupLocationMenuService: PickupLocationMenuService,
        orderService: OrderService,
        orderItemService: OrderItemService,
        loadingService: LoadingService,
        menuService: MenuService,
        tabService: TabService,
        organizationTerminalService: OrganizationTerminalService,
        userService: UserService,
        organizationService: OrganizationService,
        route: ActivatedRoute,
        alertController: AlertController,
        router: Router,
        navController: NavController
    ) {
        super(membershipService,
            pickupLocationService,
            toastService,
            changeDetectorRef,
            pickupLocationMenuService,
            orderService,
            orderItemService,
            loadingService,
            menuService,
            tabService,
            organizationTerminalService,
            userService,
            organizationService,
            route,
            alertController,
            router,
            navController);
    }

    ngOnInit() {
        super.ngOnInit();

        this.subscribe(this.membershipService.current.subscribe(m => {
            if (!!m && m.renewing) {
                this.renewMembership(null);
            }
        }));

        this.subscribe(this.userService.current.subscribe(u => {
            this.currentUser = !!u ? new User(u) : null;
            this.checkAvailableComps();
        }));

        this.subscribe(this.tabService.openTabs.subscribe(ot => {
            this.openTabs = ot;
        }));

        this.subscribe(this.tabService.deletedTab.subscribe(id => {
            if (!!id && !!this.tab && this.tab.id === id) {
                this.toastService.message('This tab has been deleted.');
                this.tabService.setCurrent(null);
            }
        }));

        this.deviceService.getDeviceId().then(d => this.deviceId = d);
    }

    ionViewDidEnter() {
        this.active = true;
    }

    ionViewWillLeave() {
        this.active = false;
    }

    checkAvailableComps() {
        if (!!this.currentUser && !!this.organization && !!this.organization.compDefinitions) {
            const tabComps = this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.TAB);
            const orderComps = this.organization.compDefinitions.filter(c => c.availability === CompDefinitionAvailabilities.ORDER_ITEM);

            if (this.currentUser.siteAdmin) {
                this.availableTabComps = tabComps;
                this.availableOrderComps = orderComps;
            } else {
                const organizationUser = this.currentUser.organizations.find(o => o.orgId === this.organization.id);
                if (organizationUser.admin || organizationUser.owner) {
                    this.availableTabComps = tabComps;
                    this.availableOrderComps = orderComps;
                } else {
                    this.availableTabComps = tabComps
                        .filter(c => !c.roles
                            || c.roles.length === 0
                            || c.roles.some(r => organizationUser.roles.some(orgRole => orgRole.id === r.id)));

                    this.availableOrderComps = orderComps
                        .filter(c => !c.roles
                            || c.roles.length === 0
                            || c.roles.some(r => organizationUser.roles.some(orgRole => orgRole.id === r.id)));
                }
            }
        } else {
            this.availableTabComps = [];
            this.availableOrderComps = [];
        }
    }

    organizationChanged() {
        this.checkAvailableComps();
    }

    pickupLocationChanged(p: PickupLocation) {
        this.pickupLocation = !!p ? new PickupLocation(p) : null;
    }

    async tabChanged(t: Tab) {
        const tab = !!t ? new Tab(t) : null;

        if (this.active && !!tab && !!this.tab && this.tab.id === tab.id && tab.status === TabStatuses.CLOSED && this.tab.status === TabStatuses.OPEN) {
            await this.toastService.message('This tab has been closed.');
            await this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${this.tab.id}`);
            this.tab = null;
        } else {
            this.tab = tab;
        }
    }

    async activateOrder(silent = false) {
        this.order.terminalOrder = true;
        this.order.parentId = this.tab.id;

        if (this.organization.autoDeliver && !silent) {
            this.loadingService.present();
        }

        this.orderService.activateOrder(this.order).subscribe(async (t: Tab) => {
            const saved = new Tab(t);
            this.tabService.setEvent(saved);
            this.tabService.setCurrent(saved);
            if (this.organization.autoDeliver) {
                this.loadingService.dismiss();
                await this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${saved.id}`);
                this.orderService.setCurrent(null);
            }
        }, error => {
            console.log(error);
            this.tab.orders.splice(0, 1);
            this.tabService.addToCache(this.tab);
            const current = this.tabService.currentValue;
            if (!!current && current.id === tab.id) {
                this.tabService.setCurrent(tab);
            }
            this.toastService.error('Failed to save order');
        });

        const tab = this.tab;
        const order = this.order;
        order.status = this.organization.autoDeliver ? OrderStatuses.DELIVERED : OrderStatuses.RECEIVED;
        tab.orders.unshift(order);
        this.tabService.addToCache(tab);
        this.tabService.setCurrent(tab);

        if (!this.organization.autoDeliver) {
            await this.navController.navigateRoot(`/thank-you/${this.pickupLocation.id}/${this.tab.id}`,
                {queryParams: {paymentType: 'CASH'}});
        }
    }

    async navigate(paymentType: string = null) {
        if (paymentType === 'START_BLANK_TAB') {
            this.loadingService.present();
            const blankTab = new Tab({
                orders: !!this.order ? [this.order] : null,
                member: this.activeMembership,
                membershipProgram: !!this.activeMembership ? this.activeMembership.activeMembershipProgram : null
            });
            this.tabService.createBlank(this.pickupLocation.id, blankTab).subscribe(async (t: Tab) => {
                const tab = new Tab(t);
                this.tabService.setCurrent(tab);
                this.loadingService.dismiss();
                await this.navController.navigateForward(`/manager/${this.pickupLocation.id}/tab/${t.id}`);

            });
        } else if (!!this.order && this.order.items.length > 0) {
            try {
                const order = this.offlineOrderService.initialize(this.order, this.deviceId);
                order.terminalOrder = this.organizationTerminalService.isTerminal;

                const tab: Tab = this.offlineTabService.initialize(order, this.applyNotes);
                this.tabService.setCurrent(tab);

                if (paymentType === 'START_TAB') {
                    if (!!this.activeMembership && !!this.activeMembership.cardOnFile) {
                        this.memberStartTab(tab);
                    } else {
                        await this.navController.navigateForward(`/start-tab/${this.pickupLocation.id}/${tab.key}`);
                    }
                } else {
                    await this.navController.navigateForward(`/confirm-tab/${this.pickupLocation.id}/${tab.key}`,
                        {queryParams: {paymentType}});
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    async memberStartTab(tab: Tab) {
        const alert = await this.alertController.create({
            cssClass: 'brewbill-alert',
            header: 'Card on File',
            message: `Would you like to use the card ending in ${this.activeMembership.cardOnFile.lastFour}?`,
            buttons: [
                {
                    text: 'No',
                    handler: async () => {
                        await this.navController.navigateForward(`/start-tab/${this.pickupLocation.id}/${tab.key}`);
                    }
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.loadingService.present();
                        this.tabService.startForMember(tab, this.activeMembership.id).subscribe(t => {
                            this.loadingService.dismiss();
                            this.membershipService.setCurrent(null);
                            this.tabService.setCurrent(null);
                            this.orderService.setCurrent(null);
                            this.navController.navigateBack(`manager/${this.pickupLocation.id}/manual-order`);
                        }, error => {
                            this.loadingService.dismiss();
                            this.tabService.startTabErrorHandler(error);
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

    async nextAction(paymentType: string = null) {
        if (!!this.tab && this.tab.status === TabStatuses.OPEN) {
            await this.activateOrder();
        } else {
            await this.navigate(paymentType);
        }
    }

    async manualOrderItemCredit(orderItem: OrderItem, compDefinition: CompDefinition) {
        const modal = await this.modalController.create({
            component: CreateOrderItemCreditComponent,
            componentProps: {
                orderItem,
                compDefinition
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const credit = new OrderItemCredit(dataReturned.data);
                const updated = clone<OrderItem>(orderItem);
                if (credit.amount > 0) {
                    if (!orderItem.credits) {
                        updated.credits = [];
                    }
                    updated.tokenPayment = null;
                    updated.membershipTokenGroupId = null;
                    updated.credits.push(credit);
                }

                this.removeOrderItems([orderItem]);
                this.addOrderItems([updated]);
            }
        });
    }

    async addCredit(orderItem: OrderItem) {
        const modal = await this.modalController.create({
            component: SelectCompComponent,
            componentProps: {
                availableComps: this.availableOrderComps
                    .filter(c => !c.items || c.items.length === 0 || c.items.some(p => p.priceId === orderItem.selectedPrice.id))
            },
            cssClass: this.availableOrderComps.length > 4 ? 'menu-modal' : ''
        });

        await modal.present();

        await modal.onDidDismiss().then(async dataReturned => {
            if (dataReturned != null && dataReturned.data != null) {
                const compDefinition = new CompDefinition(dataReturned.data);

                if (compDefinition.type === CompDefinitionTypes.MANUAL) {
                    await this.manualOrderItemCredit(orderItem, compDefinition);
                } else {
                    const updated = clone<OrderItem>(orderItem);
                    const credit = new OrderItemCredit({compDefinition});

                    credit.addIndex = this.orderItemCreditIndex++;

                    if (compDefinition.type === CompDefinitionTypes.DEFINED) {
                        credit.amount = compDefinition.amount;
                    } else if (compDefinition.type === CompDefinitionTypes.PERCENT) {
                        credit.amount = multiplyCurrency(orderItem.calcTotal(), divideCurrency(compDefinition.amount, 100));
                    }

                    if (credit.amount > 0) {
                        if (!updated.credits) {
                            updated.credits = [];
                        }
                        updated.credits.push(credit);
                        updated.tokenPayment = null;
                        updated.membershipTokenGroupId = null;
                    }

                    this.removeOrderItems([orderItem]);
                    this.addOrderItems([updated]);
                }
            }
        });
    }

    async removeCredit(data: any) {
        const orderItem: OrderItem = data.orderItem;
        const updated = clone<OrderItem>(orderItem);
        const credit: OrderItemCredit = data.credit;
        updated.preventAutoCredit = true;

        if (!!updated.credits && updated.credits.length > 0) {
            updated.credits = updated.credits.filter(c => credit.addIndex !== c.addIndex);
            this.removeOrderItems([orderItem]);
            this.addOrderItems([updated]);
        }
        this.applyMembershipProgram();
    }

    removeTokens(data: any) {
        const orderItem: OrderItem = data;
        const updated = clone<OrderItem>(orderItem);

        if (!!updated.tokenPayment) {
            updated.tokenPaymentRefunded = updated.tokenPayment;
            updated.tokenPayment = null;
            updated.membershipTokenGroupId = null;
            this.removeOrderItems([orderItem]);
            this.addOrderItems([updated]);
        }
    }

    removeMember() {
        this.membershipService.setCurrent(null);
    }

    notesChanged(noteDefinitions: TabNoteDefinition[]) {
        this.applyNotes = noteDefinitions;
        this.applyComps(this.order);
    }

    async selectMember() {
        this.loadingService.present();

        this.membershipService.findActiveByOrganizationId(this.organization.id).subscribe(async (members: MembershipSummary[]) => {
            this.loadingService.dismiss();

            const modal = await this.modalController.create({
                component: SelectMemberComponent,
                componentProps: {
                    members,
                    currentMember: this.activeMembership
                },
                cssClass: 'menu-modal'
            });

            await modal.present();

            await modal.onDidDismiss().then(async dataReturned => {
                const membership = !!dataReturned && !!dataReturned.data ? new Membership(dataReturned.data) : null;
                this.membershipService.setCurrent(membership);
                if (!!membership && !!this.openTabs && this.openTabs.length > 0) {
                    const memberTabs = this.openTabs.filter(t => !!t.person && t.person.id === membership.person.id);
                    if (memberTabs.length === 1) {
                        this.tabService.setCurrent(new Tab(memberTabs[0]));
                    }
                }
            });
        });
    }

    async renewMembership(orderItem: OrderItem) {
        const modal = await this.modalController.create({
            component: RenewMembershipComponent,
            componentProps: {
                organization: this.organization
            },
            cssClass: 'present-card-modal'
        });

        await modal.present();

        modal.onDidDismiss().then(dataReturned => {
            if (!!dataReturned && !!dataReturned.data) {
                let orderItemIndex = !!orderItem ? orderItem.addIndex : null;
                if (!orderItemIndex) {
                    orderItemIndex = (!!this.order && !!this.order.items && this.order.items.length > 0) ?
                        this.order.items.reduce((max, item) => max = (max > item.addIndex) ? max : item.addIndex + 1, 0)
                        : 1;
                }

                const updated = this.orderItemService.initializeOrderItem(null, null);
                updated.membershipRenewal = new MembershipRenewal(dataReturned.data);

                if (!!this.activeMembership) {
                    updated.membershipRenewal.membershipId = this.activeMembership.id;
                    updated.membershipRenewal.membershipName = !!this.activeMembership.person
                        ? this.activeMembership.person.name : 'No Person Information';
                }
                updated.membershipRenewal.quantity = !!orderItem && !!orderItem.membershipRenewal.quantity
                    ? orderItem.membershipRenewal.quantity : 1;
                updated.total = multiplyCurrency(updated.membershipRenewal.membershipProgram.amount, updated.membershipRenewal.quantity);

                updated.menuItem = null;
                updated.selectedPrice = null;
                updated.addIndex = orderItemIndex;

                updated.membershipHostFee = updated.membershipRenewal.months;
                updated.membershipOrganizationFee = updated.membershipRenewal.membershipProgram.feeStructure === MembershipProgramFeeStructure.DUAL
                    ? updated.membershipHostFee : 0;

                if (updated.membershipRenewal.membershipProgram.feeStructure === MembershipProgramFeeStructure.ABSORBED) {
                    // for now just apply $1/month make this configurable in the future
                    updated.total = subtractCurrency(updated.total, updated.membershipHostFee);
                    updated.membershipFeeIncluded = true;
                }

                if (!!this.activeMembership && !!this.order && !!this.order.items) {
                    const foundItems = this.order.items.filter(i => !!i.membershipRenewal);
                    if (!!foundItems && foundItems.length > 0) {
                        this.removeOrderItems(foundItems);
                    }
                } else if (!!orderItem) {
                    this.removeOrderItems([orderItem]);
                }
                this.addOrderItems([updated]);
            }
        });
    }
}
