import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    CompDefinition,
    CompDefinitionAvailabilities,
    CompDefinitionService,
    CompDefinitionTypes,
    LoadingService,
    MembershipProgram,
    MenuItemPriceSummary,
    Organization,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-comp-definition',
    templateUrl: './edit-comp-definition.component.html',
    styleUrls: ['./edit-comp-definition.component.scss'],
})
export class EditCompDefinitionComponent extends BaseStepperFormComponent implements OnInit {
    @Input() membershipProgram: MembershipProgram;
    @Input() compDefinition: CompDefinition;
    @Input() organization: Organization;
    formGroup: FormGroup;
    selectAll = false;
    partial = true;

    priceSummaries: MenuItemPriceSummary[];
    itemFilter = '';
    filteredItems = [];

    compDefinitionTypes = CompDefinitionTypes;
    compDefinitionAvailabilities = CompDefinitionAvailabilities;

    constructor(
        modalController: ModalController,
        private compDefinitionService: CompDefinitionService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        const roles = [];
        if (!!this.compDefinition && !!this.compDefinition.roles) {
            this.compDefinition.roles.forEach(r => {
                const found = this.organization.roles.find(orgRole => orgRole.id === r.id);
                if (!!found) {
                    roles.push(found);
                }
            });
        }

        const requiredTabNote = !!this.compDefinition && !!this.compDefinition.requiredTabNote
            ? this.organization.tabNoteDefinitions.find(n => n.id === this.compDefinition.requiredTabNote.id)
            : '';

        this.formGroup = this.formBuilder.group({
            id: !!this.compDefinition ? this.compDefinition.id : null,
            parentId: this.organization.id,
            name: [
                !!this.compDefinition ? this.compDefinition.name : (!!this.membershipProgram ? this.membershipProgram.name : ''),
                Validators.required
            ],
            color: [!!this.compDefinition ? this.compDefinition.color : '#fddb31', Validators.required],
            contrastColor: [!!this.compDefinition ? this.compDefinition.contrastColor : '#000000', Validators.required],
            description: !!this.compDefinition ? this.compDefinition.description : '',
            type: [!!this.compDefinition ? this.compDefinition.type : CompDefinitionTypes.PERCENT, Validators.required],
            availability: [
                !!this.compDefinition ? this.compDefinition.availability : (!this.membershipProgram
                    ? CompDefinitionAvailabilities.TAB
                    : CompDefinitionAvailabilities.ORDER_ITEM), Validators.required
            ],
            membershipProgram: this.membershipProgram,
            amount: [!!this.compDefinition ? this.compDefinition.amount : '', Validators.required],
            max: !!this.compDefinition ? this.compDefinition.max : '',
            roles: [roles],
            autoApply: !!this.compDefinition ? this.compDefinition.autoApply : false,
            requiredCount: !!this.compDefinition ? this.compDefinition.requiredCount : '',
            rewardCount: !!this.compDefinition ? this.compDefinition.rewardCount : '',
            priority: !!this.compDefinition ? this.compDefinition.priority : 'FIRST',
            limitDurationPart: !!this.compDefinition ? this.compDefinition.limitDurationPart : 'TAB',
            limitDurationCount: !!this.compDefinition ? this.compDefinition.limitDurationCount : '',
            limitCount: !!this.compDefinition ? this.compDefinition.limitCount : '',
            requiredTabNote
        });

        this.typeChanged();
        this.autoApplyChanged();
        this.durationChanged();
        this.rewardCountChanged();
        this.requiredCountChanged();

        this.priceSummaries = this.priceSummaries.map(item => {
            const container = new MenuItemPriceSummary({...item});
            container.qualified = !!this.compDefinition && !!this.compDefinition.items
                && this.compDefinition.items.some(q => q.itemId === item.itemId && q.priceId === item.priceId);

            container.condition = !!this.compDefinition && !!this.compDefinition.items
                && this.compDefinition.items.some(q => q.itemId === item.itemId && q.priceId === item.priceId);

            return container;
        });
        this.filteredItems = [...this.priceSummaries];
    }

    filterItems() {
        this.filteredItems = !!this.itemFilter ? this.priceSummaries.filter((p: any) =>
                (!!p.itemName && p.itemName.toLowerCase().includes(this.itemFilter.toLowerCase()))
                || (!!p.priceName && p.priceName.toLowerCase().includes(this.itemFilter.toLowerCase())))
            : [...this.priceSummaries];
    }

    typeChanged() {
        if (this.formGroup.get('type').value === CompDefinitionTypes.MANUAL) {
            this.formGroup.get('amount').setValidators([]);
            this.formGroup.get('autoApply').setValue(false);
        } else {
            this.formGroup.get('max').setValidators([]);
            if (this.formGroup.get('type').value === CompDefinitionTypes.PERCENT) {
                this.formGroup.get('amount').setValidators([Validators.required, Validators.max(100)]);
            } else {
                this.formGroup.get('amount').setValidators([Validators.required, Validators.max(99999)]);
            }
        }
        this.formGroup.controls.max.updateValueAndValidity();
        this.formGroup.controls.amount.updateValueAndValidity();
    }

    autoApplyChanged() {
        if (this.formGroup.get('autoApply').value) {
            this.formGroup.get('roles').setValue([]);
        }
    }

    durationChanged() {
        if (this.formGroup.get('limitDurationPart').value === 'TAB') {
            this.formGroup.get('limitDurationCount').setValue(null);
        }
    }

    requiredCountChanged() {
        if (this.formGroup.get('requiredCount').value > 0) {
            this.formGroup.get('rewardCount').setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.formGroup.get('rewardCount').setValidators([]);
        }
        this.formGroup.controls.rewardCount.updateValueAndValidity();
    }

    rewardCountChanged() {
        if (this.formGroup.get('rewardCount').value > 0) {
            this.formGroup.get('requiredCount').setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.formGroup.get('requiredCount').setValidators([]);
        }
        this.formGroup.controls.requiredCount.updateValueAndValidity();
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const compDefinition = new CompDefinition(this.formGroup.value);
            if (!compDefinition.requiredTabNote) {
                compDefinition.requiredTabNote = null;
            }

            compDefinition.items = compDefinition.availability === CompDefinitionAvailabilities.ORDER_ITEM ?
                this.priceSummaries.filter(item => item.qualified) : [];

            compDefinition.conditionItems = compDefinition.availability === CompDefinitionAvailabilities.ORDER_ITEM ?
                this.priceSummaries.filter(item => item.condition) : [];

            this.loadingService.present();
            if (!compDefinition.id) {
                this.compDefinitionService.create(compDefinition).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Comp definition created.');
                    this.close(new CompDefinition(c));
                });
            } else {
                this.compDefinitionService.update(compDefinition).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Comp definition updated.');
                    this.close(new CompDefinition(c));
                });
            }
        }
    }

    setValue(field, val) {
        this.formGroup.get(field).setValue(val);
    }

    compClick(event) {
        console.log(event);
    }

    toggleQualifiedItems() {
        if (!this.allSelected()) {
            this.filteredItems.forEach(i => i.qualified = true);
        } else {
            this.filteredItems.forEach(i => i.qualified = false);
        }
    }

    toggleConditionItems() {
        if (!this.allSelected()) {
            this.filteredItems.forEach(i => i.condition = true);
        } else {
            this.filteredItems.forEach(i => i.condition = false);
        }
    }

    partialSelected() {
        return !!this.filteredItems && this.filteredItems.some(i => i.qualified) && this.filteredItems.some(i => !i.qualified);
    }

    allSelected() {
        return !!this.filteredItems && !this.filteredItems.some(i => !i.qualified);
    }
}
