import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'bb-label-help',
    templateUrl: './label-help.component.html',
    styleUrls: ['./label-help.component.scss'],
})
export class LabelHelpComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
