import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

import {
    BaseModalComponent,
    CashAudit,
    CashAuditService,
    CashSession,
    CashSessionEmployee,
    CashSessionEmployeeService,
    LoadingService,
    Person,
    ToastService
} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-cash-session-employee',
    templateUrl: './edit-cash-session-employee.component.html',
    styleUrls: ['./edit-cash-session-employee.component.scss'],
})
export class EditCashSessionEmployeeComponent extends BaseModalComponent implements OnInit {
    @Input() employee: CashSessionEmployee;
    @Input() cashSession: CashSession;
    @Input() employees: Person[];
    @Input() includePayroll = true;

    formGroup: FormGroup;
    cashAudits: CashAudit[];
    loading = true;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private cashSessionEmployeeService: CashSessionEmployeeService,
        private cashAuditService: CashAuditService
    ) {
        super(modalController);
    }

    ngOnInit() {
        const found = !!this.employee ? this.employees.find(o => o.id === this.employee.person.id) : null;

        this.formGroup = this.formBuilder.group({
            id: !!this.employee ? this.employee.id : null,
            parentId: this.cashSession.id,
            person: [!found && !!this.employee ? this.employee.person : found, Validators.required],
            hours: !!this.employee ? this.employee.hours : '',
            payrollTips: !!this.employee ? this.employee.payrollTips : ''
        });

        if (!!this.employee) {
            this.loadTips();
        }
    }

    loadTips() {
        this.loadingService.present();
        this.cashAuditService.getByCashSessionAndPerson(this.cashSession.id, this.employee.person.id)
            .subscribe((a: CashAudit[]) => {
                this.cashAudits = a;
                this.loadingService.dismiss();
            });
    }

    onSubmit() {
        this.loadingService.present();
        const emp = new CashSessionEmployee(this.formGroup.value);
        emp.payrollTips = !!emp.payrollTips ? emp.payrollTips : 0;

        if (!emp.id) {
            this.cashSessionEmployeeService.create(emp).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Employee added.');
                this.close(new CashSessionEmployee(c));
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot add employees if it is not in active or pending status.');
                    await this.close();
                } else if (error.status === 409) {
                    await this.toastService
                        .error('This employee already exists in the session.');
                }
            });
        } else {
            this.cashSessionEmployeeService.update(emp).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Employee updated.');
                this.close(new CashSessionEmployee(c));
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot update employees if it is not in active or pending status.');
                    await this.close();
                }
            });
        }
    }
}
