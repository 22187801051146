import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

import {
    BaseModalComponent,
    CashDrawer,
    CashSession,
    CashSessionTipAdjustment,
    CashSessionTipAdjustmentService,
    LoadingService,
    multiplyCurrency,
    ToastService
} from 'brewbill-lib';

@Component({
    selector: 'bb-edit-tip-adjustment',
    templateUrl: './edit-tip-adjustment.component.html',
    styleUrls: ['./edit-tip-adjustment.component.scss'],
})
export class EditTipAdjustmentComponent extends BaseModalComponent implements OnInit {
    @Input() tipAdjustment: CashSessionTipAdjustment;
    @Input() cashSession: CashSession;
    @Input() cashDrawer: CashDrawer;

    formGroup: FormGroup;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private cashSessionTipAdjustmentService: CashSessionTipAdjustmentService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            id: !!this.tipAdjustment ? this.tipAdjustment.id : null,
            direction: !this.tipAdjustment || this.tipAdjustment.amount > 0 ? 'ADD' : 'REMOVE',
            parentId: this.cashSession.id,
            cashDrawerId: !!this.tipAdjustment && !!this.tipAdjustment.cashAudit
                ? this.tipAdjustment.cashAudit.parentId
                : (!!this.cashDrawer ? this.cashDrawer.id : ''),
            amount: [
                !!this.tipAdjustment ? (this.tipAdjustment.amount > 0
                    ? this.tipAdjustment.amount
                    : multiplyCurrency(-1, this.tipAdjustment.amount)) : '', Validators.required
            ],
            comment: !!this.tipAdjustment ? this.tipAdjustment.comment : ''
        });
    }

    onSubmit() {
        this.loadingService.present();
        const tipAdjustment = new CashSessionTipAdjustment(this.formGroup.value);

        if (this.formGroup.controls.direction.value === 'REMOVE') {
            tipAdjustment.amount = multiplyCurrency(-1, tipAdjustment.amount);
        }

        if (!tipAdjustment.id) {
            this.cashSessionTipAdjustmentService.create(tipAdjustment).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Tip adjustment created.');
                this.close(new CashSessionTipAdjustment(c));
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot accept tip adjustments if it is not in active or pending status.');
                    await this.close();
                }
            });
        } else {
            this.cashSessionTipAdjustmentService.update(tipAdjustment).subscribe(c => {
                this.loadingService.dismiss();
                this.toastService.success('Tip adjustment updated.');
                this.close(new CashSessionTipAdjustment(c));
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot accept tip adjustment changes if it is not in active or pending status.');
                    await this.close();
                }
            });
        }
    }
}
