import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {
    BaseModalComponent,
    CashAudit,
    CashAuditActions,
    CashAuditEvents,
    CashAuditService,
    CashDrawer,
    CashDrawerService,
    LoadingService,
    PickupLocation,
    PickupLocationService,
    PrinterService,
    Tab,
    TabService,
    ToastService
} from 'brewbill-lib';
import {AssignCashDrawerComponent} from '../assign-cash-drawer/assign-cash-drawer.component';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'bb-cash-refund',
    templateUrl: './cash-refund.component.html',
    styleUrls: ['./cash-refund.component.scss'],
})
export class CashRefundComponent extends BaseModalComponent implements OnInit {
    @Input() tab: Tab;
    formGroup: FormGroup;
    pickupLocation: PickupLocation;
    cashDrawer: CashDrawer;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private tabService: TabService,
        private pickupLocationService: PickupLocationService,
        private cashAuditService: CashAuditService,
        private currencyPipe: CurrencyPipe,
        private cashDrawerService: CashDrawerService,
        private printerService: PrinterService,
        modalController: ModalController
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.init();

        this.subscribe(this.pickupLocationService.current.subscribe(p => !!p ? this.pickupLocation : null));

        this.subscribe(this.cashDrawerService.current.subscribe(c => {
            this.cashDrawer = !!c ? new CashDrawer(c) : null;
            if (!this.cashDrawer) {
                this.close();
            } else {
                if (!this.cashDrawer.trackCashTips) {
                    this.formGroup.controls.tip.setValue(0);
                }
            }
        }));
    }

    init() {
        this.formGroup = this.formBuilder.group(
            {
                amount: [
                    this.tab.paymentInformation.appliedCashPrincipal,
                    [Validators.required, Validators.max(this.tab.paymentInformation.appliedCashPrincipal), Validators.min(0)]
                ],
                tip: [
                    this.tab.paymentInformation.appliedCashTips,
                    [Validators.max(this.tab.paymentInformation.appliedCashTips), Validators.min(0)]
                ],
                action: [CashAuditActions.OUT, Validators.required],
                event: [CashAuditEvents.REFUND, Validators.required],
                tabId: [this.tab.id, Validators.required],
                creditType: null,
                creditTab: false,
                comment: ''
            }
        );
    }

    creditChanged() {
        if (this.formGroup.get('creditTab').value) {
            this.formGroup.get('creditType').setValidators([Validators.required]);
        } else {
            this.formGroup.get('creditType').setValidators([]);
        }
        this.formGroup.controls.creditType.updateValueAndValidity();
    }

    async onSubmit() {
        this.loadingService.present();
        const audit = new CashAudit(this.formGroup.value);
        audit.parentId = this.cashDrawer.id;

        if (this.printerService.isConnected()) {
            this.printerService.openDrawer();
        }

        this.cashAuditService.create(audit).subscribe(async (c: CashAudit) => {
            this.loadingService.dismiss();
            await this.close(c);
        }, async error => {
            this.loadingService.dismiss();
            if (error.status === 417) {
                await this.toastService.error('The amount received cannot be less than the tip and payment.');
            }
        });
    }

    maxRefund() {
        this.formGroup.controls.amount.setValue(this.tab.paymentInformation.appliedCashPrincipal);
    }

    maxTip() {
        this.formGroup.controls.tip.setValue(this.tab.paymentInformation.appliedCashTips);
    }

    async changeDrawer() {
        const modal = await this.modalController.create({
            component: AssignCashDrawerComponent,
            componentProps: {
                pickupLocation: this.pickupLocation
            }
        });

        await modal.present();
    }
}
