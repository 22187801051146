import {Component, OnInit} from '@angular/core';
import {
    AuthenticationService,
    BaseManagerComponent,
    Constants,
    LoadingService,
    Membership,
    MembershipService,
    OrderService,
    OrganizationService,
    OrganizationTerminal,
    OrganizationTerminalService,
    PickupLocationService,
    Tab,
    TabSearchResult,
    TabService,
    ToastService,
    UserService
} from 'brewbill-lib';
import {debounce} from 'lodash';
import {AlertController, NavController} from '@ionic/angular';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'bb-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends BaseManagerComponent implements OnInit {
    searchCriteria: any;
    tabs: Tab[] = [];
    filteredTabs: Tab[] = [];
    initialized = false;
    terminal: OrganizationTerminal = null;

    constructor(
        private tabService: TabService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private navController: NavController,
        private orderService: OrderService,
        private organizationTerminalService: OrganizationTerminalService,
        private alertController: AlertController,
        authenticationService: AuthenticationService,
        organizationService: OrganizationService,
        pickupLocationService: PickupLocationService,
        userService: UserService,
        membershipService: MembershipService
    ) {
        super(authenticationService,
            organizationService,
            pickupLocationService,
            userService,
            membershipService);

        this.searchCriteria = this.tabService.getOpenTabSearchCriteria();

        this.debouncedFilterTabs = debounce(this.debouncedFilterTabs, 250);
    }

    ngOnInit() {
        this.tabs = this.tabService.getCurrentOpenTabs();
        this.filterTabs();

        super.ngOnInit();

        this.subscribe(this.tabService.openTabs.subscribe((t) => {
            if (this.initialized) {
                this.tabs = t;
                this.filterTabs();
            }
            this.initialized = true;
        }));

        this.subscribe(this.organizationTerminalService.current.subscribe(t => this.terminal = t));
    }

    init() {
        this.loadTabs();
    }

    debouncedFilterTabs() {
        this.filterTabs();
    }

    filterTabs() {
        this.tabService.setOpenTabSearchCriteria(this.searchCriteria);
        this.filteredTabs = this.tabs.filter(t =>
                !this.searchCriteria
                || !this.searchCriteria.searchString
                || (
                    (!!t.personName && t.personName.toLowerCase().includes(this.searchCriteria.searchString))
                    || (!!t.tabNumber && t.tabNumber.includes(this.searchCriteria.searchString))
                    || (!!t.person && !!t.person.email && t.person.email.includes(this.searchCriteria.searchString))
                )
        );
    }

    loadTabs() {
        this.organizationService
            .getOpenTabs(this.organization.id, !!this.tabs && this.tabs.length > 0)
            .subscribe(async (t: any) => {
                this.tabService.setOpenTabs(t);
            }, async () => {
                await this.toastService.error('Could not retrieve open tabs.');
            });
    }

    async viewDetails(tab: Tab) {
        await this.navController.navigateForward(`/manager/${this.pickupLocation.id}/tab/${tab.id}`);
    }

    createTab() {
        this.loadingService.present();
        this.tabService.createBlank(this.pickupLocation.id, new Tab({})).subscribe(async (t: Tab) => {
            this.loadingService.dismiss();
            await this.viewDetails(new Tab(t));
        });
    }

    async findByPresentedCard() {
        const referenceId = 'MER-' + uuidv4();
        const loading = await this.organizationTerminalService.loading(this.terminal.terminalId,
            this.organization.id, false, referenceId);

        this.tabService.findByTerminalCard(this.organization.id, this.terminal.terminalId, referenceId, 'OPEN')
            .subscribe((t: TabSearchResult[]) => {
                loading.dismiss(true);
                if (t.length === 1) {
                    this.navController.navigateBack(`/manager/${this.pickupLocation.id}/tab/${t[0].id}`);
                } else {
                    this.filteredTabs = this.tabs.filter(f => t.some(s => s.id === f.id));
                }
            });
    }

    async openMenu(event, tabSearchResult: TabSearchResult) {
        event.stopPropagation();
        this.membershipService.setCurrent(null);
        let tab = this.tabService.fromCache(tabSearchResult.id);
        if (!tab) {
            this.loadingService.present();
            tab = await this.tabService.get(tabSearchResult.id).toPromise() as Tab;
            this.loadingService.dismiss();
        }
        if (!!tab.member) {
            this.loadingService.present('Loading member.');
            this.membershipService.setCurrent(new Membership(await this.membershipService.get(tab.member.id).toPromise() as Membership));
            this.loadingService.dismiss();
        }
        this.tabService.setCurrent(new Tab(tab));
        await this.navController.navigateForward(`/manager/${this.pickupLocation.id}/manual-order`);
    }

    async closeTab(event, tabSearchResult: TabSearchResult) {
        event.stopPropagation();

        let tab = this.tabService.fromCache(tabSearchResult.id);
        if (!tab) {
            this.loadingService.present();
            tab = await this.tabService.get(tabSearchResult.id).toPromise() as Tab;
            this.loadingService.dismiss();
        }

        if (tab.paymentInformation.invoiceCreated) {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Close Tab',
                message: 'Closing the invoiced tab will prevent future orders without charging a card. Do you wish to continue?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.loadingService.present();
                            this.tabService.closeTab(tab, false).subscribe((t: Tab) => {
                                this.tabService.setEvent(new Tab(t));
                                this.loadingService.dismiss();
                                this.toastService.success('The tab has been closed.');
                                this.navController.navigateRoot(`/manager/${this.pickupLocation.id}/open`);
                            }, error => {
                                console.log(error);
                                this.loadingService.dismiss();
                                this.toastService.error(Constants.SERVER_ERROR);
                            });
                        }
                    }
                ]
            });

            await alert.present();
        } else if (tab.paymentInformation.remaining > 0 || tab.paymentInformation.tokenPaymentsTotal > 0) {
            await this.navController.navigateForward(`/confirm-tab/${this.pickupLocation.id}/${tab.id}`);
        } else {
            const alert = await this.alertController.create({
                cssClass: 'brewbill-alert',
                header: 'Close Tab',
                message: 'The remaining amount on this tab is $0. Closing the tab will prevent future orders without charging the card. Do you wish to continue?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel'
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.loadingService.present();
                            tab.pendingTip = 0;
                            if (!!tab.orders && tab.orders.length > 0) {
                                this.tabService.closeTab(tab, false).subscribe((t) => {
                                    this.loadingService.dismiss();
                                    this.tabService.setEvent(new Tab(t));
                                    this.toastService.success('The tab has been closed.');
                                    this.navController.navigateRoot(`/manager/${this.pickupLocation.id}/open`);
                                }, error => {
                                    console.log(error);
                                    this.loadingService.dismiss();
                                    this.toastService.error(Constants.SERVER_ERROR);
                                });
                            } else {
                                this.tabService.delete(tab.id).subscribe(() => {
                                    this.loadingService.dismiss();
                                    this.toastService.success('The tab has been closed.');
                                    this.tabService.setDeletedTab(tab.id);
                                });
                            }
                        }
                    }
                ]
            });

            await alert.present();
        }
    }
}
