import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LoadingService, Tab, TabService} from 'brewbill-lib';
import {IonInput} from '@ionic/angular';

@Component({
    selector: 'bb-edit-name-override',
    templateUrl: './edit-name-override.component.html',
    styleUrls: ['./edit-name-override.component.scss'],
})
export class EditNameOverrideComponent implements OnInit {
    @Input() tab: Tab;
    @Input() requireSave = false;
    @Input() required = false;
    @Output() close = new EventEmitter();

    nameOverrideValue: string;

    @ViewChild('nameOverride') set content(input: IonInput) {
        if (!!input) {
            setTimeout(async () => {
                await input.setFocus();
            }, 50);
        }
    }

    constructor(
        private tabService: TabService,
        private loadingService: LoadingService
    ) {
    }

    ngOnInit() {
        this.nameOverrideValue = !!this.tab.nameOverride ? this.tab.nameOverride : '';
    }

    saveNameOverride() {
        if (!this.required || !!this.nameOverrideValue) {
            if ((!!this.nameOverrideValue || !!this.tab.nameOverride) && this.nameOverrideValue !== this.tab.nameOverride) {
                this.loadingService.present();
                const t = new Tab({
                    id: this.tab.id,
                    nameOverride: !!this.nameOverrideValue ? this.nameOverrideValue : null
                });

                this.tabService.nameOverride(t).subscribe((tab: Tab) => {
                    this.loadingService.dismiss();
                    this.tabService.setEvent(new Tab(tab));
                    this.close.emit(tab);
                });
            } else {
                this.close.emit();
            }
        }
    }

    handleBlur() {
        if (!this.requireSave) {
            this.saveNameOverride();
        }
    }

    async handleKeyUp(e) {
        if (e.keyCode === 13) {
            await this.saveNameOverride();
        }
    }
}
