import {Component, Input, OnInit} from '@angular/core';
import {addCurrency, BaseModalComponent, formatToHoursPartial, LoadingService, Organization, Timecard, TimecardService} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import moment from 'moment/moment';

@Component({
    selector: 'bb-user-timecards',
    templateUrl: './user-timecards.component.html',
    styleUrls: ['./user-timecards.component.scss'],
})
export class UserTimecardsComponent extends BaseModalComponent implements OnInit {
    @Input() organization: Organization;
    loaded = false;
    timecards: Timecard[];
    startTime: string;
    endTime: string;
    display = 'WEEKLY';
    weeks = [];

    constructor(
        modalController: ModalController,
        private loadingService: LoadingService,
        private timeCardService: TimecardService
    ) {
        super(modalController);
    }

    ngOnInit() {
        const dateSetter = moment();
        dateSetter.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        dateSetter.add(1, 'days');

        this.endTime = dateSetter.format();
        dateSetter.subtract(90, 'days');
        this.startTime = dateSetter.format();

        this.load();
    }

    load() {
        this.loadingService.present();
        this.timeCardService.getUserDateRangeTimecardsSearch(this.organization.id, this.startTime, this.endTime)
            .subscribe((t: Timecard[]) => {
                this.timecards = t;

                this.weeks = [];
                let id = 0;
                this.timecards.forEach(tc => {
                    const startTime = moment(tc.clockInTime);
                    const startOfWeek = moment(tc.clockInTime).startOf('week');
                    startOfWeek.add(1, 'days');
                    const endOfWeek = moment(tc.clockInTime).endOf('week');
                    endOfWeek.add(1, 'days');

                    const duration = !!tc.shiftDurationSecs
                        ? tc.shiftDurationSecs
                        : moment.duration(+new Date() - +new Date(tc.clockInTime)).asSeconds();

                    const week = this.weeks.find(w => w.start < startTime && w.end > startTime);
                    if (!!week) {
                        week.duration = addCurrency(week.duration, duration);
                        week.count++;
                    } else {
                        this.weeks.push({
                            start: startOfWeek,
                            end: endOfWeek,
                            duration,
                            count: 1
                        });
                    }
                });

                this.loaded = true;
                this.loadingService.dismiss();
            });
    }

    formatToHours(timeInSec: number) {
        return formatToHoursPartial(moment.duration(timeInSec * 1000));
    }

    getDuration(timecard: Timecard) {
        if (!!timecard.clockOutTime) {
            return this.formatToHours(timecard.shiftDurationSecs);
        } else {
            return formatToHoursPartial(moment.duration(+new Date() - +new Date(timecard.clockInTime)));
        }
    }
}
