import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    CompDefinition,
    LoadingService,
    Organization,
    OrganizationEvent,
    OrganizationEventService,
    ToastService
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'bb-edit-organization-event',
    templateUrl: './edit-organization-event.component.html',
    styleUrls: ['./edit-organization-event.component.scss'],
})
export class EditOrganizationEventComponent extends BaseStepperFormComponent implements OnInit {
    @Input() organizationEvent: OrganizationEvent;
    @Input() organization: Organization;
    formGroup: FormGroup;
    availableComps = [];
    selectingComps = false;

    constructor(
        modalController: ModalController,
        private organizationEventService: OrganizationEventService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.availableComps = [...this.organization.compDefinitions];
        if (!!this.organizationEvent) {
            this.availableComps.forEach(c => {
                if (this.organizationEvent.compDefinitions.some(d => d.id === c.id)) {
                    c.selected = true;
                }
            });
        }
        this.formGroup = this.formBuilder.group({
            id: !!this.organizationEvent ? this.organizationEvent.id : null,
            parentId: this.organization.id,
            name: [!!this.organizationEvent ? this.organizationEvent.name : '', Validators.required],
            color: [!!this.organizationEvent ? this.organizationEvent.color : '#fddb31', Validators.required],
            contrastColor: [!!this.organizationEvent ? this.organizationEvent.contrastColor : '#000000', Validators.required],
            description: [!!this.organizationEvent ? this.organizationEvent.description : '', Validators.required]
        });
    }

    onSubmit() {
        if (this.formGroup.valid) {
            const organizationEvent = new OrganizationEvent(this.formGroup.value);
            organizationEvent.compDefinitions = this.availableComps.filter(c => c.selected);

            this.loadingService.present();
            if (!organizationEvent.id) {
                this.organizationEventService.create(organizationEvent).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Event created.');
                    this.close(new CompDefinition(c));
                });
            } else {
                this.organizationEventService.update(organizationEvent).subscribe(c => {
                    this.loadingService.dismiss();
                    this.toastService.success('Event updated.');
                    this.close(new CompDefinition(c));
                });
            }
        }
    }

    setValue(field, val) {
        this.formGroup.get(field).setValue(val);
    }
}
