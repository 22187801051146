import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'bb-end-of-day',
  templateUrl: './end-of-day.component.html',
  styleUrls: ['./end-of-day.component.scss'],
})
export class EndOfDayComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
