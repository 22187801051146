import {Component, Input, OnInit} from '@angular/core';
import {
    addCurrency,
    BaseModalComponent,
    CashAudit,
    CashAuditActions,
    CashAuditEvents,
    CashAuditService,
    CashDrawer,
    CashDrawerService,
    CashDrawerStatuses,
    CashSession,
    CashSessionEmployee,
    CashSessionEmployeeService,
    CashSessionService,
    CashSessionSummary,
    divideCurrency,
    FeatureKeys,
    LoadingService,
    multiplyCurrency,
    Organization,
    PickupLocation,
    PickupLocationService,
    PrinterService,
    ToastService,
    User,
    UserService
} from 'brewbill-lib';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AssignCashDrawerComponent} from '../assign-cash-drawer/assign-cash-drawer.component';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'bb-tip-out',
    templateUrl: './tip-out.component.html',
    styleUrls: ['./tip-out.component.scss'],
})
export class TipOutComponent extends BaseModalComponent implements OnInit {
    @Input() tippedPerson: CashSessionEmployee;
    @Input() organization: Organization;
    @Input() cashSession: CashSession;
    @Input() cashDrawer: CashDrawer;
    @Input() cashSessionSummary: CashSessionSummary;

    formGroup: FormGroup = new FormGroup({});
    loaded = false;
    users: User[];
    currentUser: User;
    pickupLocation: PickupLocation;
    drawers: CashDrawer[];
    existing: CashSessionEmployee;

    featureKeys = FeatureKeys;
    multiplyCurrency = multiplyCurrency;
    addCurrency = addCurrency;
    divideCurrency = divideCurrency;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private cashDrawerService: CashDrawerService,
        private cashSessionService: CashSessionService,
        private userService: UserService,
        private pickupLocationService: PickupLocationService,
        private cashSessionEmployeeService: CashSessionEmployeeService,
        private cashAuditService: CashAuditService,
        private printerService: PrinterService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.currentUser = this.userService.currentValue;
        this.pickupLocation = this.pickupLocationService.currentValue;
        this.existing = this.tippedPerson;

        this.subscribe(this.cashDrawerService.current.subscribe(c => {
            if (!!c) {
                this.cashDrawer = new CashDrawer(c);
            }
        }));

        this.subscribe(this.pickupLocationService.current.subscribe(c => {
            this.pickupLocation = !!c ? new PickupLocation(c) : null;
        }));

        this.drawers = !!this.cashSession
            ? this.cashSession.cashDrawers
                .filter(d => (!this.pickupLocation || d.pickupLocation.id === this.pickupLocation.id)
                    && d.status === CashDrawerStatuses.ACTIVE)
            : [];

        this.formGroup = this.formBuilder.group(
            {
                id: null,
                parentId: this.cashSession.id,
                applyToCashSessionId: this.cashSession.id,
                payrollTips: '',
                cashTips: '',
                hours: [!!this.tippedPerson ? this.tippedPerson.hours : '', Validators.required],
                person: [!!this.tippedPerson ? this.tippedPerson.person : null, Validators.required],
                payoutType: 'CASH',
                action: CashAuditActions.OUT,
                event: CashAuditEvents.TIP_OUT,
                correctsAll: true
            }
        );

        this.loadingService.present();
        if (!this.tippedPerson) {
            forkJoin([
                this.userService.getOrgUsers(this.organization.id),
                this.cashSessionService.roundedHours(this.cashSession.id, this.currentUser.person.id),
                this.cashSessionEmployeeService.findByCashSessionAndPerson(this.cashSession.id, this.currentUser.person.id)
            ]).subscribe(([users, hours, cashSessionEmployee]) => {
                this.sanitizeUsers(users as User[]);
                if (!!hours) {
                    this.formGroup.controls.hours.setValue((hours as any).response);
                    this.formGroup.controls.hours.updateValueAndValidity();
                }

                if (!!cashSessionEmployee) {
                    const e = cashSessionEmployee as CashSessionEmployee;
                    this.formGroup.controls.id.setValue(e.id);
                    this.formGroup.controls.hours.setValue(e.hours);
                    this.formGroup.updateValueAndValidity();
                }

                this.loadingService.dismiss();
                this.loaded = true;
            });
        } else {
            this.formGroup.controls.id.setValue(this.tippedPerson.id);
            this.loadingService.dismiss();
            this.loaded = true;
        }
    }

    sanitizeUsers(users: User[]) {
        const sanitizedUsers = [];
        users.forEach((u: User) => {
            sanitizedUsers.push(new User(u));
        });
        this.users = sanitizedUsers;

        if (!this.tippedPerson) {
            const user = this.users.find(u => u.id === this.currentUser.id);
            this.formGroup.controls.person.setValue(!!user ? user.person : null);
        }
    }

    loadEmployee() {
        if (!!this.formGroup.controls.person.value) {
            this.loadingService.present();
            this.cashSessionEmployeeService.findByCashSessionAndPerson(this.cashSession.id, this.formGroup.controls.person.value.id)
                .subscribe(async (e: CashSessionEmployee) => {
                    if (!!e) {
                        this.existing = new CashSessionEmployee(e);
                        this.formGroup.controls.id.setValue(e.id);
                        this.formGroup.controls.hours.setValue(e.hours);
                    } else {
                        this.existing = null;
                        const hours: any = await this.cashSessionService
                            .roundedHours(this.cashSession.id, this.formGroup.controls.person.value.id).toPromise();
                        this.formGroup.controls.id.setValue('');
                        this.formGroup.controls.hours.setValue(!!hours && !!hours.response ? hours.response : '');
                        this.formGroup.controls.payrollTips.setValue('');
                        this.formGroup.controls.cashTips.setValue('');
                    }
                    this.formGroup.updateValueAndValidity();
                    this.loadingService.dismiss();
                });
        }
    }

    async changeDrawer() {
        const modal = await this.modalController.create({
            component: AssignCashDrawerComponent,
            componentProps: {
                pickupLocation: this.pickupLocation,
                working: true
            }
        });

        await modal.present();

        await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.cashDrawer = new CashDrawer(dataReturned.data);
            }
        });
    }

    tipOut() {
        this.loadingService.present();
        const emp = new CashSessionEmployee(this.formGroup.value);
        emp.payrollTips = !!emp.payrollTips ? emp.payrollTips : 0;
        emp.payrollTips = addCurrency(emp.payrollTips, !!this.existing ? this.existing.payrollTips : 0);

        if (!emp.id) {
            this.cashSessionEmployeeService.create(emp).subscribe(c => {
                if (!!this.cashDrawer && (!!emp.cashTips || emp.cashTips === 0)) {
                    this.cashAction(emp);
                } else {
                    this.loadingService.dismiss();
                    this.close(new CashSessionEmployee(c));
                }
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot add employees if it is not in active or pending status.');
                    await this.close();
                } else if (error.status === 409) {
                    await this.toastService
                        .error('This employee already exists in the session.');
                }
            });
        } else {
            this.cashSessionEmployeeService.update(emp).subscribe(c => {
                if (!!this.cashDrawer && (!!emp.cashTips || emp.cashTips === 0)) {
                    this.cashAction(emp);
                } else {
                    this.loadingService.dismiss();
                    this.close(new CashSessionEmployee(c));
                }
            }, async (error) => {
                if (error.status === 412) {
                    await this.toastService
                        .error('The cash session cannot update employees if it is not in active or pending status.');
                    await this.close();
                }
            });
        }
    }

    async cashAction(employee: CashSessionEmployee) {
        const amount = Number.parseInt(this.formGroup.controls.cashTips.value, 10);

        const audit = new CashAudit({
            parentId: this.cashDrawer.id,
            applyToCashSessionId: !!this.cashSession ? this.cashSession.id : this.cashDrawer.parentId,
            correctsAll: false,
            tippedPerson: employee.person,
            action: amount >= 0 ? CashAuditActions.OUT : CashAuditActions.IN,
            event: CashAuditEvents.TIP_OUT,
            amount: amount >= 0 ? amount : (-1 * amount)
        });

        if (this.printerService.isConnected()) {
            this.printerService.openDrawer();
        }

        this.cashAuditService.create(audit).subscribe(() => {
            this.loadingService.dismiss();
            this.close(new CashSessionEmployee(employee));
        }, async error => {
            this.loadingService.dismiss();
            if (error.status === 417) {
                await this.toastService.error('The amount received cannot be less than the tip and payment.');
            }
        });
    }
}
