import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    Column,
    DashboardWidget,
    DashboardWidgetService,
    DashboardWidgetTab,
    Filter,
    getReportFieldDisplayName,
    Menu,
    MenuService,
    Organization,
    OrganizationService,
    Sort,
    TabReport
} from 'brewbill-lib';
import {InputCustomEvent, ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EditTabReportComponent} from '../edit-tab-report/edit-tab-report.component';
import {EditTabReportColumnComponent} from '../edit-tab-report-column/edit-tab-report-column.component';
import {EditTabReportFilterComponent} from '../edit-tab-report-filter/edit-tab-report-filter.component';

@Component({
    selector: 'bb-edit-dashboard-widget',
    templateUrl: './edit-dashboard-widget.component.html',
    styleUrls: ['./edit-dashboard-widget.component.scss'],
})
export class EditDashboardWidgetComponent extends BaseStepperFormComponent implements OnInit {
    @Input() widget: DashboardWidget;
    formGroup: FormGroup;
    tabFormGroups: FormGroup[] = [];
    currentTabForm: FormGroup;
    organization: Organization;
    menu: Menu;

    listColumns: Column[] = [];
    groupingColumn: Column;
    labelColumn: Column;
    valueColumn: Column;
    formChange = false;

    getReportFieldDisplayName = getReportFieldDisplayName;

    constructor(
        modalController: ModalController,
        private menuService: MenuService,
        private organizationService: OrganizationService,
        private dashboardWidgetService: DashboardWidgetService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
                id: !!this.widget ? this.widget.id : null,
                title: [!!this.widget ? this.widget.title : '', Validators.required],
                size: [!!this.widget ? this.widget.size : 'MD', Validators.required],
                sortOrder: !!this.widget ? this.widget.sortOrder : null
            }
        );

        this.subscribe(this.menuService.current.subscribe(m => this.menu = m));

        this.subscribe(this.organizationService.current.subscribe(o => {
            const init = !this.organization;
            this.organization = !!o ? new Organization(o) : null;

            if (init) {
                if (!!this.widget && !!this.widget.tabs && this.widget.tabs.length > 0) {
                    this.widget.tabs.forEach(t => this.setupTabFormGroup(t));
                } else {
                    this.setupTabFormGroup(null);
                }
            }
        }));

        this.setCurrentTabForm(this.tabFormGroups[0]);
    }

    typeChange(inputEvent: InputCustomEvent) {
        if (!this.formChange) {
            this.currentTabForm.controls.report.setValue(null);
            this.labelColumn = null;
            this.valueColumn = null;
        }

        this.setValidators(this.currentTabForm);
    }

    onStepChange(event: any): void {
        if (event.selectedIndex === 1 && !!this.formGroup.controls.title.value
            && (!this.tabFormGroups[0].controls.title.value || this.tabFormGroups[0].controls.title.pristine)) {
            this.tabFormGroups[0].controls.title.setValue(this.formGroup.controls.title.value);
        }
    }

    setValidators(formGroup) {

        if (formGroup.controls.type.value === 'LIST' || formGroup.controls.type.value === 'TABLE') {
            formGroup.get('maxRows').setValidators([Validators.required]);
        } else {
            formGroup.get('maxRows').setValidators([]);
            formGroup.get('maxRows').setValue(null);
        }
        formGroup.get('maxRows').updateValueAndValidity();

        if (formGroup.controls.type.value === 'ACTIVE_TIMECARDS') {
            formGroup.get('durationPart').setValidators([]);
            formGroup.get('durationCount').setValidators([]);
        } else {
            formGroup.get('durationPart').setValidators([Validators.required]);
            formGroup.get('durationCount').setValidators([Validators.required]);
        }
        formGroup.get('durationPart').updateValueAndValidity();
        formGroup.get('durationCount').updateValueAndValidity();

        if (formGroup.controls.type.value === 'EMPLOYEE_HOURS'
            || formGroup.controls.type.value === 'ACTIVE_TIMECARDS') {
            formGroup.get('report').setValidators([]);
        } else {
            formGroup.get('report').setValidators([Validators.required]);
        }
        formGroup.get('report').updateValueAndValidity();
    }

    async sizeChange() {
        const size = this.formGroup.controls.size.value;
        if (size === 'xs') {
            if (this.tabFormGroups.length > 1) {
                this.tabFormGroups = [];
                this.setupTabFormGroup(null);
                this.setCurrentTabForm(this.currentTabForm = this.tabFormGroups[0]);
            }
        }
    }

    async tabFormChange() {
        if (!this.currentTabForm) {
            this.setupTabFormGroup(null);
            await this.changeDetectorRef.detectChanges();
            setTimeout(() => this.currentTabForm = this.tabFormGroups[this.tabFormGroups.length - 1]);
            await this.changeDetectorRef.detectChanges();
        }
        this.formChange = true;
        await this.changeDetectorRef.detectChanges();
        this.formChange = false;

        this.setupReportInformation();
    }

    setCurrentTabForm(formGroup: FormGroup) {
        this.currentTabForm = formGroup;
        this.setupReportInformation();
    }

    setupReportInformation() {
        const report = !!this.currentTabForm ? this.currentTabForm.controls.report.value : null;
        if (!report) {
            this.labelColumn = null;
            this.valueColumn = null;
            this.groupingColumn = null;
            this.listColumns = [];
        } else if (this.currentTabForm.controls.type.value === 'SINGLE_VALUE') {
            this.valueColumn = report.columns[0];
        } else if (this.currentTabForm.controls.type.value === 'PIE_CHART') {
            this.labelColumn = report.columns[0];
            this.valueColumn = report.columns[1];
        } else if (this.currentTabForm.controls.type.value === 'BAR_CHART' || this.currentTabForm.controls.type.value === 'LINE_CHART') {
            this.labelColumn = report.columns[0];
            this.valueColumn = report.columns[1];
            if (report.columns.length > 2) {
                this.groupingColumn = report.columns[2];
            }
        } else if (this.currentTabForm.controls.type.value === 'SUMMARY_LIST') {
            this.listColumns = [...report.columns];
        } else if (this.currentTabForm.controls.type.value === 'LIST') {
            this.listColumns = [...report.columns];
            this.valueColumn = this.listColumns.pop();
        }
    }

    removeTab(tabForm: FormGroup) {
        const index = this.tabFormGroups.indexOf(tabForm);
        this.setCurrentTabForm(this.tabFormGroups[index !== 0 ? 0 : 1]);
        this.tabFormChange();
        this.changeDetectorRef.detectChanges();
        this.tabFormGroups.splice(index, 1);
    }

    setupTabFormGroup(tab: DashboardWidgetTab) {
        const sort = !!tab && !!tab.report && !!tab.report.sort && tab.report.sort.length > 0 ? tab.report.sort[0] : null;
        const columns = !!tab && !!tab.report ? tab.report.columns : [];
        const filters = !!tab && !!tab.report && !!tab.report.baseFilters
            ? [...tab.report.baseFilters] :
            [
                {
                    id: null,
                    field: 'ORGANIZATION_ID',
                    operator: 'EQUALS',
                    values: [{value: this.organization.id}],
                    type: 'LONG'
                }
            ];

        const sortColumn = !!sort
            ? columns.find(c => c.id === sort.column.id)
            : null;

        const tabFormGroup = this.formBuilder.group(
            {
                id: !!tab ? tab.id : null,
                title: [!!tab ? tab.title : '', Validators.required],
                type: [!!tab ? tab.type : '', Validators.required],
                durationPart: [!!tab ? tab.durationPart : null, Validators.required],
                durationCount: [!!tab ? tab.durationCount : null, Validators.required],
                comparisonOffsetPart: !!tab ? tab.comparisonOffsetPart : null,
                comparisonOffsetCount: !!tab ? tab.comparisonOffsetCount : null,
                maxRows: !!tab ? tab.maxRows : '',
                report: [!!tab ? tab.report : null, Validators.required],
                sortDirection: !!sort ? sort.sortDirection : 'DESC',
                filters: [filters],
                sortColumn
            }
        );

        if (!!tab && !!tab.title) {
            tabFormGroup.controls.title.markAsDirty();
        }

        this.setValidators(tabFormGroup);
        this.tabFormGroups.push(tabFormGroup);
    }

    async editReport(form: FormGroup) {
        const modal = await this.modalController.create({
            component: EditTabReportComponent,
            componentProps: {
                tabReport: form.controls.report.value,
                type: form.controls.type.value,
                organization: this.organization
            },
            cssClass: 'report-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                const report = new TabReport(dataReturned.data);
                report.baseFilters = [
                    {
                        id: null,
                        field: 'ORGANIZATION_ID',
                        operator: 'EQUALS',
                        values: [{id: null, value: this.organization.id}],
                        type: 'LONG'
                    }
                ];
                form.controls.report.setValue(report);
            }
        });
    }

    async selectValueColumn(form: FormGroup) {
        const modal = await this.modalController.create({
            component: EditTabReportColumnComponent,
            componentProps: {
                column: this.valueColumn,
                menu: this.menu,
                requireAggregate: true,
                hideRowSummary: true
            },
            cssClass: 'report-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                this.valueColumn = dataReturned.data;
                this.createReport(form);
            }
        });
    }

    async selectLabelColumn(form: FormGroup, grouping = false) {
        const modal = await this.modalController.create({
            component: EditTabReportColumnComponent,
            componentProps: {
                column: grouping ? this.groupingColumn : this.labelColumn,
                menu: this.menu,
                restrictAggregate: true,
                hidePercent: true,
                hideRowSummary: true
            },
            cssClass: 'report-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                if (grouping) {
                    this.groupingColumn = dataReturned.data;
                    this.groupingColumn.groupingColumn = true;
                } else {
                    this.labelColumn = dataReturned.data;
                    if (this.labelColumn.key === 'DAY'
                        || this.labelColumn.key === 'WEEK'
                        || this.labelColumn.key === 'MONTH') {
                        this.labelColumn.includeParent = true;
                    }
                }

                this.createReport(form);
            }
        });
    }

    createReport(form: FormGroup) {
        let report = form.controls.report.value;
        if (!report) {
            report = new TabReport({});
        }

        const filters = form.controls.filters.value;
        if (!!filters && filters.length > 0) {
            report.baseFilters = filters;
        }

        if (form.controls.type.value === 'SINGLE_VALUE' && !!this.valueColumn) {
            report.columns = [this.valueColumn];
            form.controls.report.setValue(report);
        } else if (form.controls.type.value === 'PIE_CHART' && !!this.labelColumn && !!this.valueColumn) {
            report.columns = [this.labelColumn, this.valueColumn];
            form.controls.report.setValue(report);
        } else if ((form.controls.type.value === 'BAR_CHART' || form.controls.type.value === 'LINE_CHART')
            && !!this.valueColumn && !!this.labelColumn) {
            report.columns = [this.labelColumn, this.valueColumn];
            if (!!this.groupingColumn) {
                report.columns.push(this.groupingColumn);
            }
            form.controls.report.setValue(report);
        } else if (form.controls.type.value === 'SUMMARY_LIST' && !!this.listColumns && this.listColumns.length > 0) {
            report.columns = this.listColumns;
            form.controls.report.setValue(report);
        } else if (form.controls.type.value === 'LIST' && !!this.listColumns && this.listColumns.length > 0 && !!this.valueColumn) {
            report.columns = [...this.listColumns, this.valueColumn];
            const sort = new Sort();
            sort.sortDirection = form.controls.sortDirection.value;
            sort.column = this.valueColumn;
            report.sort = [sort];
            form.controls.report.setValue(report);
        }
    }

    valid() {
        return this.formGroup.valid && !this.tabFormGroups.some(t => t.invalid);
    }

    tabAvailable() {
        let maxTabs = 1;
        const size = this.formGroup.controls.size.value;
        if (size === 'MD') {
            maxTabs = 2;
        } else if (size === 'LG') {
            maxTabs = 4;
        }
        return maxTabs > this.tabFormGroups.length;
    }

    onSubmit() {
        const w = new DashboardWidget(this.formGroup.value);
        w.parentId = this.organization.id;
        w.tabs = [];
        let sortOrder = 0;
        this.tabFormGroups.forEach(tfg => {
            const tab = new DashboardWidgetTab(tfg.value);
            tab.sortOrder = sortOrder++;
            w.tabs.push(tab);
        });

        if (!w.id) {
            this.dashboardWidgetService.create(w).subscribe(async (dw: DashboardWidget) => {
                this.close(dw);
            });
        } else {
            this.dashboardWidgetService.update(w).subscribe(async (dw: DashboardWidget) => {
                this.close(dw);
            });
        }
    }

    async selectListColumn(form: FormGroup, column) {
        let requireAggregate = true;
        let hidePercent = true;

        if (this.currentTabForm.controls.type.value === 'LIST') {
            requireAggregate = false;
            hidePercent = false;
        }

        const modal = await this.modalController.create({
            component: EditTabReportColumnComponent,
            componentProps: {
                column,
                menu: this.menu,
                requireAggregate,
                hidePercent,
                hideRowSummary: true
            },
            cssClass: 'report-modal'
        });

        await modal.present();

        return await modal.onDidDismiss().then((dataReturned) => {
            if (dataReturned !== null && dataReturned.data != null) {
                if (!!column) {
                    this.listColumns[this.listColumns.indexOf(column)] = dataReturned.data;
                } else {
                    this.listColumns.push(dataReturned.data);
                }
                this.createReport(form);
            }
        });
    }

    reorderListColumns(form: FormGroup, event) {
        const itemMove = this.listColumns.splice(event.detail.from, 1)[0];
        this.listColumns.splice(event.detail.to, 0, itemMove);
        this.createReport(form);
        event.detail.complete();
    }

    removeListColumn(form: FormGroup, column: Column) {
        this.listColumns.splice(this.listColumns.indexOf(column), 1);
        this.createReport(form);
    }

    async editFilter(filter: Filter) {
        const modal = await this.modalController.create({
            component: EditTabReportFilterComponent,
            componentProps: {
                filter,
                menu: this.menu,
                organization: this.organization
            },
            cssClass: 'report-modal'
        });
        await modal.present();

        modal.onDidDismiss().then((dataReturned) => {
            if (!!dataReturned && !!dataReturned.data) {
                const filters = this.currentTabForm.controls.filters.value;
                if (!!filter) {
                    filters[filters.indexOf(filter)] = dataReturned.data;
                } else {
                    filters.push(dataReturned.data);
                }

                this.currentTabForm.controls.filters.setValue(filters, 1);
                this.createReport(this.currentTabForm);
            }
        });
    }

    removeFilter(event, index) {
        event.stopPropagation();
        const filters = this.currentTabForm.controls.filters.value;
        filters.splice(index, 1);
        this.currentTabForm.controls.filters.setValue(filters);
        this.createReport(this.currentTabForm);
    }

}
