import {Component, Input, OnInit} from '@angular/core';
import {
    BaseStepperFormComponent,
    FileUploadService,
    FileUploadType,
    LoadingService,
    Menu,
    MenuCategory,
    MenuItem,
    MenuItemGroup,
    MenuItemGroupItem,
    MenuItemGroupService,
    ToastService,
    UploadImageModalComponent
} from 'brewbill-lib';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController, Platform} from '@ionic/angular';

@Component({
    selector: 'bb-edit-menu-item-group',
    templateUrl: './edit-menu-item-group.component.html',
    styleUrls: ['./edit-menu-item-group.component.scss'],
})
export class EditMenuItemGroupComponent extends BaseStepperFormComponent implements OnInit {
    formGroup: FormGroup = new FormGroup({});
    @Input() menuItemGroup: MenuItemGroup;
    @Input() menu: Menu;
    @Input() addMenuItem: any;

    selectedItems: MenuItemGroupItem[] = [];
    availableItems = [];

    constructor(
        modalController: ModalController,
        private menuItemGroupService: MenuItemGroupService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private formBuilder: FormBuilder,
        private platform: Platform,
        private fileUploadService: FileUploadService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.menuItemGroup = this.menuItemGroup == null ? new MenuItemGroup() : this.menuItemGroup;

        this.selectedItems = this.menuItemGroup.menuItems == null ? [] : [...this.menuItemGroup.menuItems];
        this.availableItems = [...this.menu.menuItems]
            .sort((a: MenuItem, b: MenuItem) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

        this.formGroup = this.formBuilder.group({
            id: this.menuItemGroup.id,
            parentId: this.menu.id,
            name: [this.menuItemGroup.name, Validators.required],
            imageAddress: this.menuItemGroup.imageAddress
        });
    }

    updateSortOrder(items: MenuItemGroupItem[]) {
        this.selectedItems = items;
    }

    selectItem(item: MenuItem) {
        if (this.selectedItems.find(obj => obj.menuItem.id === item.id) == null) {
            const menuItemGroupItem = new MenuItemGroupItem();
            menuItemGroupItem.menuItem = item;
            menuItemGroupItem.sortOrder = this.selectedItems.length + 1;
            this.selectedItems = [...this.selectedItems, menuItemGroupItem];
        }
    }

    async openAddMenuItem() {
        const l = await this.addMenuItem(null, true);
        if (l != null) {
            this.selectItem(l);
        }
    }

    async onSubmit() {
        this.loadingService.present();
        const itemGroup: MenuItemGroup = new MenuItemGroup(this.formGroup.value);
        itemGroup.menuItems = this.selectedItems;

        if (itemGroup.id != null) {
            this.menuItemGroupService.update(itemGroup).subscribe(
                (mig: MenuItemGroup) => {
                    this.toastService.success(`${mig.name} updated.`);
                    this.loadingService.dismiss();
                    this.close(mig);
                }
            );
        } else {
            this.menuItemGroupService.create(itemGroup).subscribe(
                (mig: MenuCategory) => {
                    this.toastService.success(`${mig.name} added.`);
                    this.loadingService.dismiss();
                    this.close(mig);
                }
            );
        }
    }

    async openUploadImageModal() {
        if (this.platform.is('desktop')) {
            const modal = await this.modalController.create({
                component: UploadImageModalComponent,
                cssClass: 'menu-modal'
            });

            modal.onDidDismiss().then(async (dataReturned) => {
                if (dataReturned != null && dataReturned.data != null) {
                    await this.uploadImage(dataReturned.data);
                }
            });

            await modal.present();
        } else {
            const image = await this.fileUploadService.getPhoto();
            this.loadingService.present();
            const imgBlob = this.fileUploadService.b64toBlob(image.base64String, `image/${image.format}`);
            this.uploadImage(imgBlob);
        }
    }

    uploadImage(data: any) {
        this.loadingService.present();
        this.fileUploadService.uploadImage(this.menu.orgId, FileUploadType.MENU_ITEM, data).subscribe(async (imageAddress: any) => {
            this.formGroup.controls.imageAddress.setValue(imageAddress.response);
            this.loadingService.dismiss();
        }, async (err) => {
            console.log(err);
            this.loadingService.dismiss();
            await this.toastService.error('Error uploading image.');
        });
    }

    readFile(menuItem: MenuItem, file: any) {
        const reader = new FileReader();
        reader.onload = async () => {
            const imgBlob = new Blob([reader.result], {
                type: file.type
            });

            this.uploadImage(imgBlob);
        };
        reader.readAsArrayBuffer(file);
    }

    removeImage(event) {
        event.stopPropagation();
        this.formGroup.controls.imageAddress.setValue(null);
    }
}
