import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';

import {
    BaseModalComponent,
    LoadingService,
    MembershipTokenAudit,
    MembershipTokenAuditService,
    MembershipTokenPurse,
    ToastService,
    TokenActions,
    TokenEvents,
} from 'brewbill-lib';

@Component({
    selector: 'bb-add-token-action',
    templateUrl: './add-token-action.component.html',
    styleUrls: ['./add-token-action.component.scss'],
})
export class AddTokenActionComponent extends BaseModalComponent implements OnInit {
    @Input() purse: MembershipTokenPurse;

    formGroup: FormGroup = new FormGroup({});

    tokenActions = TokenActions;
    tokenEvents = TokenEvents;

    constructor(
        modalController: ModalController,
        private formBuilder: FormBuilder,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private membershipTokenAuditService: MembershipTokenAuditService
    ) {
        super(modalController);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group(
            {
                parentId: [this.purse.id, Validators.required],
                count: ['', Validators.required],
                action: ['', Validators.required],
                event: [TokenEvents.ADJUSTMENT, Validators.required],
                comment: ''
            }
        );

        this.formGroup.get('action').valueChanges.subscribe(value => {
            if (value === TokenActions.OUT) {
                this.formGroup.get('count').setValidators([Validators.required, Validators.max(this.purse.count)]);
            } else {
                this.formGroup.get('count').setValidators([Validators.required]);
            }
            this.formGroup.controls.count.updateValueAndValidity();
        });
    }

    addAction() {
        this.loadingService.present();
        const audit = new MembershipTokenAudit(this.formGroup.value);

        this.membershipTokenAuditService.create(audit).subscribe(async (c: MembershipTokenAudit) => {
            this.loadingService.dismiss();
            await this.toastService.success(`${c.count} token${c.count > 1 ? 's have' : ' has'} been ${c.action === TokenActions.IN
                ? 'added'
                : 'removed'}.`);
            await this.close(c);
        }, async error => {
            this.loadingService.dismiss();
            if (error.status === 417) {
                await this.toastService.error('The removed amount is greater than the available amount.');
            }
        });
    }
}
